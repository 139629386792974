// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// ie fixes
@import "ie-fix";

// If you want to add something do it here
@import "custom";


.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane, .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane { 
    flex-shrink: initial;
}

