/*
==========================
General css for New Design
==========================
*/

#root {
   height: 100%;
   min-height: 100%;
}

.bg-red-custom {
   background-color: rgb(220, 53, 69);
   border-color: rgb(220, 53, 69);
}

.bg-blue-custom {
   background-color: rgb(76, 132, 255);
   border-color: rgb(76, 132, 255);
}

.bg-green-custom {
   background-color: rgb(33, 196, 76);
   border-color: rgb(33, 196, 76);
}

.bg-orange-custom {
   background-color: rgb(255, 187, 0);
   border-color: rgb(255, 187, 0);
}

.color-red-custom {
   color: rgb(220, 53, 69);
}

.color-blue-custom {
   color: rgb(76, 132, 255);
}

.color-green-custom {
   color: rgb(33, 196, 76);
}

.color-orange-custom {
   color: rgb(255, 187, 0);
}

.color-white-cust {
   color: white;
}

.btn-no-border {
   border-radius: 0px;
}

/*
==========================
End of General css for New Design
==========================
*/

.app-header .navbar-nav .dropdown-menu-right {
   right: auto;
   left: 1.5em;
}

// Here you can add other styles
.ant-modal-close {
   position: absolute;
   top: 0;
   left: 0;
   right: auto;
}

/*
==========================
Customization for New Design
==========================
*/

// html[dir="rtl"] .sidebar {
//     margin-right: -200px !important;
// }

// html[dir="rtl"] .sidebar-lg-show.sidebar-fixed .main, html[dir="rtl"] .sidebar-lg-show.sidebar-fixed .app-footer, html[dir="rtl"] .sidebar-show.sidebar-fixed .main, html[dir="rtl"] .sidebar-show.sidebar-fixed .app-footer {
//     margin-right: 0px;
// }

.custom-menu {
   background-color: #4c84ff;
}

.custom-menu ul {
   list-style-type: none;
   margin: 0;
   /* padding: 0; */
}

.custom-menu ul li {
   display: inline-block;
   padding: 15px;
   color: white;
   border-left: 1px solid #ffffff42;
}

.custom-menu ul li a {
   color: white;
}

.app-footer {
   // background: transparent;
   border-top: 0px;
}

.app-footer .custom-footer-content {
   width: 100%;
   padding: 25px 0px 15px 0px;
}

.app-footer .custom-footer-content p {
   font-weight: bold;
   margin: 0;
}

/*
==============================
Customize Class
==============================
*/
.cus-btn-prim {
   background-color: #4c84ff;
   border-color: #4c84ff;
   color: white;
   border-radius: 0px;
}

.cus-btn-prim:hover,
.cus-btn-prim:active {
   color: white;
}

.custom-suc-bt-lab {
   background-color: #21c44c;
   color: white;
}

.breadcrumb {
   margin-bottom: 10px !important;
}

.ant-modal-body {
   padding: 14px 24px !important;
}

.steps-content {
   padding: 10px 20px !important;
}

.general-info-form {
   margin-bottom: 0 !important;
}

.test-title {
   font-size: 23px;
   font-weight: bold;
   margin-bottom: 0;
   color: #4c84ff;
}

.ant-modal-footer div {
   display: flex;
}

.ant-modal-footer div button:first-child {
   order: 1;
   margin-right: 10px;
}

.ant-input-disabled,
.ant-input[disabled],
.ant-checkbox-disabled + span,
.ant-input-number-disabled .ant-input-number-input {
   color: rgba(0, 0, 0, 0.65) !important;
}

.main-test-title {
   margin-bottom: 0;
   line-height: 2;
   color: #4b91ff !important;
   font-size: 19px;
}

.uncertanity-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 23px;
}

.uncertanity-name {
   border: 1px solid #e9e9e9;
   padding: 8px 9px;
   background-color: #f9f9f9;
   @media only screen and (max-width: 1500px) {
      padding: 8px;
   }
}

.uncertanity-value {
   border: 1px solid #e9e9e9;
   padding: 8px 20px;
   background-color: #fff;
   border-right: 0;
   @media only screen and (max-width: 1500px) {
      padding: 8px;
   }
}

.ant-table-tbody > tr > td {
   padding: 16px 8px !important;
}

.ant-upload-list:after,
.ant-upload-list:before {
   display: block;
}

.app-header .nav-item .nav-link{
   margin-left: 15px;
}