/*!
 * 
 * antd v3.26.7
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */

body,
html {
  width : 100%;
  height: 100%;
}

input {

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
}

html {
  font-family                : sans-serif;
  line-height                : 1.15;
  -webkit-text-size-adjust   : 100%;
  -ms-text-size-adjust       : 100%;
  -ms-overflow-style         : scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin                       : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-family                  : -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  background-color             : #e4e5e6;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing        : content-box;
  height            : 0;
  overflow          : visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top   : 0;
  margin-bottom: .5em;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 500;
}

p {
  margin-top   : 0;
  margin-bottom: 1em;
}

abbr {

  &[data-original-title],
  &[title] {
    text-decoration        : underline;
    -webkit-text-decoration: underline dotted;
    text-decoration        : underline dotted;
    border-bottom          : 0;
    cursor                 : help;
  }
}

address {
  margin-bottom: 1em;
  font-style   : normal;
  line-height  : inherit;
}

input {

  &[type=number],
  &[type=password],
  &[type=text] {
    -webkit-appearance: none;
  }
}

textarea {
  -webkit-appearance: none;
}

dl,
ol,
ul {
  margin-top   : 0;
  margin-bottom: 1em;
}

ol {

  ol,
  ul {
    margin-bottom: 0;
  }
}

ul {

  ol,
  ul {
    margin-bottom: 0;
  }
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left  : 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position      : relative;
  font-size     : 75%;
  line-height   : 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color                       : #1890ff;
  text-decoration             : none;
  background-color            : rgba(0, 0, 0, 0);
  outline                     : none;
  cursor                      : pointer;
  -webkit-transition          : color .3s;
  transition                  : color .3s;
  -webkit-text-decoration-skip: objects;

  &:hover {
    color: #40a9ff;
  }

  &:active {
    color          : #096dd9;
    text-decoration: none;
    outline        : 0;
  }

  &:hover {
    text-decoration: none;
    outline        : 0;
  }

  &[disabled] {
    color         : rgba(0, 0, 0, 0.25);
    cursor        : not-allowed;
    pointer-events: none;
  }
}

code,
kbd,
pre,
samp {
  font-size  : 1em;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
}

pre {
  margin-top   : 0;
  margin-bottom: 1em;
  overflow     : auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style  : none;
}

svg:not(:root) {
  overflow: hidden;
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action    : manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top   : .75em;
  padding-bottom: .3em;
  color         : rgba(0, 0, 0, 0.45);
  text-align    : right;
  caption-side  : bottom;
}

th {
  text-align: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin     : 0;
  color      : inherit;
  font-size  : inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding     : 0;
  border-style: none;
}

input {

  &[type=checkbox],
  &[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    padding           : 0;
  }

  &[type=date],
  &[type=datetime-local],
  &[type=month],
  &[type=time] {
    -webkit-appearance: listbox;
  }
}

textarea {
  overflow: auto;
  resize  : vertical;
}

fieldset {
  min-width: 0;
  margin   : 0;
  padding  : 0;
  border   : 0;
}

legend {
  display      : block;
  width        : 100%;
  max-width    : 100%;
  margin-bottom: .5em;
  padding      : 0;
  color        : inherit;
  font-size    : 1.5em;
  line-height  : inherit;
  white-space  : normal;
}

progress {
  vertical-align: baseline;
}

[type=number] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type=search] {
  outline-offset    : -2px;
  -webkit-appearance: none;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  font              : inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding         : .2em;
  background-color: #feffe6;
}

::-moz-selection,
::selection {
  color     : #fff;
  background: #1890ff;
}

.clearfix {
  zoom: 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.anticon {
  display                : inline-block;
  color                  : inherit;
  font-style             : normal;
  line-height            : 0;
  text-align             : center;
  text-transform         : none;
  vertical-align         : -.125em;
  text-rendering         : optimizeLegibility;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;

  >* {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &:before {
    display: none;
  }

  .anticon-icon {
    display: block;
  }

  &[tabindex] {
    cursor: pointer;
  }
}

.anticon-spin {
  display          : inline-block;
  -webkit-animation: loadingCircle 1s linear infinite;
  animation        : loadingCircle 1s linear infinite;

  &:before {
    display          : inline-block;
    -webkit-animation: loadingCircle 1s linear infinite;
    animation        : loadingCircle 1s linear infinite;
  }
}

.fade-appear,
.fade-enter,
.fade-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.fade-appear.fade-appear-active,
.fade-enter.fade-enter-active {
  -webkit-animation-name      : antFadeIn;
  animation-name              : antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name      : antFadeOut;
  animation-name              : antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear,
.fade-enter,
.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function        : linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.move-up-appear,
.move-up-enter,
.move-up-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.move-up-appear.move-up-appear-active,
.move-up-enter.move-up-enter-active {
  -webkit-animation-name      : antMoveUpIn;
  animation-name              : antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.move-up-leave.move-up-leave-active {
  -webkit-animation-name      : antMoveUpOut;
  animation-name              : antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.move-up-appear,
.move-up-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function        : cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-down-appear,
.move-down-enter,
.move-down-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.move-down-appear.move-down-appear-active,
.move-down-enter.move-down-enter-active {
  -webkit-animation-name      : antMoveDownIn;
  animation-name              : antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.move-down-leave.move-down-leave-active {
  -webkit-animation-name      : antMoveDownOut;
  animation-name              : antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.move-down-appear,
.move-down-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function        : cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-left-appear,
.move-left-enter,
.move-left-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.move-left-appear.move-left-appear-active,
.move-left-enter.move-left-enter-active {
  -webkit-animation-name      : antMoveLeftIn;
  animation-name              : antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.move-left-leave.move-left-leave-active {
  -webkit-animation-name      : antMoveLeftOut;
  animation-name              : antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.move-left-appear,
.move-left-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function        : cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-right-appear,
.move-right-enter,
.move-right-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.move-right-appear.move-right-appear-active,
.move-right-enter.move-right-enter-active {
  -webkit-animation-name      : antMoveRightIn;
  animation-name              : antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.move-right-leave.move-right-leave-active {
  -webkit-animation-name      : antMoveRightOut;
  animation-name              : antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.move-right-appear,
.move-right-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function        : cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform       : translateY(100%);
    transform               : translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antMoveDownIn {
  0% {
    -webkit-transform       : translateY(100%);
    transform               : translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateY(100%);
    transform               : translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antMoveDownOut {
  0% {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateY(100%);
    transform               : translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform       : translateX(-100%);
    transform               : translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform       : translateX(-100%);
    transform               : translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateX(-100%);
    transform               : translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateX(-100%);
    transform               : translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform       : translateX(100%);
    transform               : translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antMoveRightIn {
  0% {
    -webkit-transform       : translateX(100%);
    transform               : translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateX(100%);
    transform               : translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antMoveRightOut {
  0% {
    -webkit-transform       : translateX(0);
    transform               : translateX(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateX(100%);
    transform               : translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform       : translateY(-100%);
    transform               : translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antMoveUpIn {
  0% {
    -webkit-transform       : translateY(-100%);
    transform               : translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateY(-100%);
    transform               : translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antMoveUpOut {
  0% {
    -webkit-transform       : translateY(0);
    transform               : translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : translateY(-100%);
    transform               : translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
    transform        : rotate(1turn);
  }
}

@keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
    transform        : rotate(1turn);
  }
}

[ant-click-animating-without-extra-node=true],
[ant-click-animating=true] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #1890ff;
}

.ant-click-animating-node,
[ant-click-animating-without-extra-node=true]:after {
  position                   : absolute;
  top                        : 0;
  right                      : 0;
  bottom                     : 0;
  left                       : 0;
  display                    : block;
  border-radius              : inherit;
  -webkit-box-shadow         : 0 0 0 0 #1890ff;
  box-shadow                 : 0 0 0 0 #1890ff;
  -webkit-box-shadow         : 0 0 0 0 var(--antd-wave-shadow-color);
  box-shadow                 : 0 0 0 0 var(--antd-wave-shadow-color);
  opacity                    : .2;
  -webkit-animation          : fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation                  : fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode        : forwards;
  content                    : "";
  pointer-events             : none;
}

@-webkit-keyframes waveEffect {
  to {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow        : 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow        : 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes waveEffect {
  to {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow        : 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow        : 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@-webkit-keyframes fadeEffect {
  to {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  to {
    opacity: 0;
  }
}

.slide-up-appear,
.slide-up-enter,
.slide-up-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.slide-up-appear.slide-up-appear-active,
.slide-up-enter.slide-up-enter-active {
  -webkit-animation-name      : antSlideUpIn;
  animation-name              : antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name      : antSlideUpOut;
  animation-name              : antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.slide-up-appear,
.slide-up-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function        : cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function        : cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-down-appear,
.slide-down-enter,
.slide-down-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.slide-down-appear.slide-down-appear-active,
.slide-down-enter.slide-down-enter-active {
  -webkit-animation-name      : antSlideDownIn;
  animation-name              : antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name      : antSlideDownOut;
  animation-name              : antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.slide-down-appear,
.slide-down-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function        : cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function        : cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-left-appear,
.slide-left-enter,
.slide-left-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.slide-left-appear.slide-left-appear-active,
.slide-left-enter.slide-left-enter-active {
  -webkit-animation-name      : antSlideLeftIn;
  animation-name              : antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name      : antSlideLeftOut;
  animation-name              : antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.slide-left-appear,
.slide-left-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function        : cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function        : cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-right-appear,
.slide-right-enter,
.slide-right-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.slide-right-appear.slide-right-appear-active,
.slide-right-enter.slide-right-enter-active {
  -webkit-animation-name      : antSlideRightIn;
  animation-name              : antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name      : antSlideRightOut;
  animation-name              : antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.slide-right-appear,
.slide-right-enter {
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function        : cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function        : cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 1;
  }
}

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0.8);
    transform               : scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin        : 100% 100%;
    opacity                 : 0;
  }
}

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 1;
  }
}

@keyframes antSlideRightIn {
  0% {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 0;
  }
}

@keyframes antSlideRightOut {
  0% {
    -webkit-transform       : scaleX(1);
    transform               : scaleX(1);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleX(0.8);
    transform               : scaleX(0.8);
    -webkit-transform-origin: 100% 0;
    transform-origin        : 100% 0;
    opacity                 : 0;
  }
}

.swing-appear,
.swing-enter {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.swing-appear.swing-appear-active,
.swing-enter.swing-enter-active {
  -webkit-animation-name      : antSwingIn;
  animation-name              : antSwingIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

@-webkit-keyframes antSwingIn {

  0%,
  to {
    -webkit-transform: translateX(0);
    transform        : translateX(0);
  }

  20% {
    -webkit-transform: translateX(-10px);
    transform        : translateX(-10px);
  }

  40% {
    -webkit-transform: translateX(10px);
    transform        : translateX(10px);
  }

  60% {
    -webkit-transform: translateX(-5px);
    transform        : translateX(-5px);
  }

  80% {
    -webkit-transform: translateX(5px);
    transform        : translateX(5px);
  }
}

@keyframes antSwingIn {

  0%,
  to {
    -webkit-transform: translateX(0);
    transform        : translateX(0);
  }

  20% {
    -webkit-transform: translateX(-10px);
    transform        : translateX(-10px);
  }

  40% {
    -webkit-transform: translateX(10px);
    transform        : translateX(10px);
  }

  60% {
    -webkit-transform: translateX(-5px);
    transform        : translateX(-5px);
  }

  80% {
    -webkit-transform: translateX(5px);
    transform        : translateX(5px);
  }
}

.zoom-appear,
.zoom-enter,
.zoom-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-appear.zoom-appear-active,
.zoom-enter.zoom-enter-active {
  -webkit-animation-name      : antZoomIn;
  animation-name              : antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-leave.zoom-leave-active {
  -webkit-animation-name      : antZoomOut;
  animation-name              : antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-appear,
.zoom-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-appear,
.zoom-big-enter,
.zoom-big-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-big-appear.zoom-big-appear-active,
.zoom-big-enter.zoom-big-enter-active {
  -webkit-animation-name      : antZoomBigIn;
  animation-name              : antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name      : antZoomBigOut;
  animation-name              : antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-big-appear,
.zoom-big-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-fast-appear,
.zoom-big-fast-enter,
.zoom-big-fast-leave {
  -webkit-animation-duration  : .1s;
  animation-duration          : .1s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-big-fast-appear.zoom-big-fast-appear-active,
.zoom-big-fast-enter.zoom-big-fast-enter-active {
  -webkit-animation-name      : antZoomBigIn;
  animation-name              : antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name      : antZoomBigOut;
  animation-name              : antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-big-fast-appear,
.zoom-big-fast-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-up-appear,
.zoom-up-enter,
.zoom-up-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-up-appear.zoom-up-appear-active,
.zoom-up-enter.zoom-up-enter-active {
  -webkit-animation-name      : antZoomUpIn;
  animation-name              : antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name      : antZoomUpOut;
  animation-name              : antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-up-appear,
.zoom-up-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-down-appear,
.zoom-down-enter,
.zoom-down-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-down-appear.zoom-down-appear-active,
.zoom-down-enter.zoom-down-enter-active {
  -webkit-animation-name      : antZoomDownIn;
  animation-name              : antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name      : antZoomDownOut;
  animation-name              : antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-down-appear,
.zoom-down-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-left-appear,
.zoom-left-enter,
.zoom-left-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-left-appear.zoom-left-appear-active,
.zoom-left-enter.zoom-left-enter-active {
  -webkit-animation-name      : antZoomLeftIn;
  animation-name              : antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name      : antZoomLeftOut;
  animation-name              : antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-left-appear,
.zoom-left-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-right-appear,
.zoom-right-enter,
.zoom-right-leave {
  -webkit-animation-duration  : .2s;
  animation-duration          : .2s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.zoom-right-appear.zoom-right-appear-active,
.zoom-right-enter.zoom-right-enter-active {
  -webkit-animation-name      : antZoomRightIn;
  animation-name              : antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name      : antZoomRightOut;
  animation-name              : antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.zoom-right-appear,
.zoom-right-enter {
  -webkit-transform                : scale(0);
  -ms-transform                    : scale(0);
  transform                        : scale(0);
  opacity                          : 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function        : cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform        : scale(0.2);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : 1;
  }
}

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform        : scale(0.2);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }

  to {
    -webkit-transform: scale(0.2);
    transform        : scale(0.2);
    opacity          : 0;
  }
}

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }

  to {
    -webkit-transform: scale(0.2);
    transform        : scale(0.2);
    opacity          : 0;
  }
}

@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : 1;
  }
}

@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : 1;
  }
}

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }

  to {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : 0;
  }
}

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }

  to {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : 0;
  }
}

@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }
}

@keyframes antZoomUpIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }
}

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
    opacity                 : 0;
  }
}

@keyframes antZoomUpOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
    opacity                 : 0;
  }
}

@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
  }
}

@keyframes antZoomLeftIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
  }
}

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
    opacity                 : 0;
  }
}

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 0 50%;
    transform-origin        : 0 50%;
    opacity                 : 0;
  }
}

@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
  }
}

@keyframes antZoomRightIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
  }
}

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
    opacity                 : 0;
  }
}

@keyframes antZoomRightOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin        : 100% 50%;
    opacity                 : 0;
  }
}

@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
  }
}

@keyframes antZoomDownIn {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
  }
}

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
    opacity                 : 0;
  }
}

@keyframes antZoomDownOut {
  0% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
  }

  to {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin        : 50% 100%;
    opacity                 : 0;
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse,
.ant-motion-collapse-legacy-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition        : height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
}

.ant-affix {
  position: fixed;
  z-index : 10;
}

.ant-alert {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  padding                      : 8px 15px 8px 37px;
  word-wrap                    : break-word;
  border-radius                : 4px;

  &.ant-alert-no-icon {
    padding: 8px 15px;
  }

  &.ant-alert-closable {
    padding-right: 30px;
  }
}

.ant-alert-icon {
  position: absolute;
  top     : 11.5px;
  left    : 16px;
}

.ant-alert-description {
  display    : none;
  font-size  : 14px;
  line-height: 22px;
}

.ant-alert-success {
  background-color: #f6ffed;
  border          : 1px solid #b7eb8f;

  .ant-alert-icon {
    color: #52c41a;
  }
}

.ant-alert-info {
  background-color: #e6f7ff;
  border          : 1px solid #91d5ff;

  .ant-alert-icon {
    color: #1890ff;
  }
}

.ant-alert-warning {
  background-color: #fffbe6;
  border          : 1px solid #ffe58f;

  .ant-alert-icon {
    color: #faad14;
  }
}

.ant-alert-error {
  background-color: #fff1f0;
  border          : 1px solid #ffa39e;

  .ant-alert-icon {
    color: #f5222d;
  }
}

.ant-alert-close-icon {
  position        : absolute;
  top             : 8px;
  right           : 16px;
  overflow        : hidden;
  font-size       : 12px;
  line-height     : 22px;
  background-color: rgba(0, 0, 0, 0);
  border          : none;
  outline         : none;
  cursor          : pointer;

  .anticon-close {
    color             : rgba(0, 0, 0, 0.45);
    -webkit-transition: color .3s;
    transition        : color .3s;

    &:hover {
      color: rgba(0, 0, 0, 0.75);
    }
  }
}

.ant-alert-close-text {
  color             : rgba(0, 0, 0, 0.45);
  -webkit-transition: color .3s;
  transition        : color .3s;

  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
}

.ant-alert-with-description {
  position     : relative;
  padding      : 15px 15px 15px 64px;
  color        : rgba(0, 0, 0, 0.65);
  line-height  : 1.5;
  border-radius: 4px;

  &.ant-alert-no-icon {
    padding: 15px;
  }

  .ant-alert-icon {
    position : absolute;
    top      : 16px;
    left     : 24px;
    font-size: 24px;
  }

  .ant-alert-close-icon {
    position : absolute;
    top      : 16px;
    right    : 16px;
    font-size: 14px;
    cursor   : pointer;
  }

  .ant-alert-message {
    display      : block;
    margin-bottom: 4px;
    color        : rgba(0, 0, 0, 0.85);
    font-size    : 16px;
  }
}

.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}

.ant-alert-with-description .ant-alert-description {
  display: block;
}

.ant-alert.ant-alert-closing {
  height                  : 0 !important;
  margin                  : 0;
  padding-top             : 0;
  padding-bottom          : 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin    : 50% 0;
  transform-origin        : 50% 0;
  -webkit-transition      : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition              : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-alert-slide-up-leave {
  -webkit-animation          : antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation                  : antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
}

.ant-alert-banner {
  margin-bottom: 0;
  border       : 0;
  border-radius: 0;
}

@-webkit-keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform       : scaleY(0);
    transform               : scaleY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform       : scaleY(0);
    transform               : scaleY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }

  to {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }
}

@-webkit-keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0);
    transform               : scaleY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

@keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform       : scaleY(1);
    transform               : scaleY(1);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 1;
  }

  to {
    -webkit-transform       : scaleY(0);
    transform               : scaleY(0);
    -webkit-transform-origin: 0 0;
    transform-origin        : 0 0;
    opacity                 : 0;
  }
}

.ant-anchor {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  padding                      : 0 0 0 2px;
}

.ant-anchor-wrapper {
  margin-left     : -4px;
  padding-left    : 4px;
  overflow        : auto;
  background-color: #fff;
}

.ant-anchor-ink {
  position: absolute;
  top     : 0;
  left    : 0;
  height  : 100%;

  &:before {
    position        : relative;
    display         : block;
    width           : 2px;
    height          : 100%;
    margin          : 0 auto;
    background-color: #e8e8e8;
    content         : " ";
  }
}

.ant-anchor-ink-ball {
  position          : absolute;
  left              : 50%;
  display           : none;
  width             : 8px;
  height            : 8px;
  background-color  : #fff;
  border            : 2px solid #1890ff;
  border-radius     : 8px;
  -webkit-transform : translateX(-50%);
  -ms-transform     : translateX(-50%);
  transform         : translateX(-50%);
  -webkit-transition: top .3s ease-in-out;
  transition        : top .3s ease-in-out;

  &.visible {
    display: inline-block;
  }
}

.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}

.ant-anchor-link {
  padding    : 7px 0 7px 16px;
  line-height: 1.143;
}

.ant-anchor-link-title {
  position          : relative;
  display           : block;
  margin-bottom     : 6px;
  overflow          : hidden;
  color             : rgba(0, 0, 0, 0.65);
  white-space       : nowrap;
  text-overflow     : ellipsis;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:only-child {
    margin-bottom: 0;
  }
}

.ant-anchor-link-active>.ant-anchor-link-title {
  color: #1890ff;
}

.ant-anchor-link .ant-anchor-link {
  padding-top   : 5px;
  padding-bottom: 5px;
}

.ant-select-auto-complete {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";

  &.ant-select {
    .ant-select-selection {
      border            : 0;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }

    .ant-select-selection__rendered {
      height      : 100%;
      margin-right: 0;
      margin-left : 0;
      line-height : 32px;
    }

    .ant-select-selection__placeholder {
      margin-right: 12px;
      margin-left : 12px;
    }

    .ant-select-selection--single {
      height: auto;
    }

    .ant-select-search--inline {
      position: static;
      float   : left;
    }
  }

  &.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 0 !important;
  }

  &.ant-select .ant-input {
    height      : 32px;
    line-height : 1.5;
    background  : rgba(0, 0, 0, 0);
    border-width: 1px;

    &:focus,
    &:hover {
      border-color      : #40a9ff;
      border-right-width: 1px !important;
    }

    &[disabled] {
      color           : rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      cursor          : not-allowed;
      opacity         : 1;
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        border-color      : #d9d9d9;
        border-right-width: 1px !important;
      }
    }
  }

  &.ant-select-lg {
    .ant-select-selection__rendered {
      line-height: 40px;
    }

    .ant-input {
      height        : 40px;
      padding-top   : 6px;
      padding-bottom: 6px;
    }
  }

  &.ant-select-sm {
    .ant-select-selection__rendered {
      line-height: 24px;
    }

    .ant-input {
      height        : 24px;
      padding-top   : 1px;
      padding-bottom: 1px;
    }
  }
}

.ant-input-group>.ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float  : none;
}

.ant-avatar {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  overflow                     : hidden;
  color                        : #fff;
  white-space                  : nowrap;
  text-align                   : center;
  vertical-align               : middle;
  background                   : #ccc;
  width                        : 32px;
  height                       : 32px;
  line-height                  : 32px;
  border-radius                : 50%;
}

.ant-avatar-image {
  background: rgba(0, 0, 0, 0);
}

.ant-avatar-string {
  position                : absolute;
  left                    : 50%;
  -webkit-transform-origin: 0 center;
  -ms-transform-origin    : 0 center;
  transform-origin        : 0 center;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.ant-avatar-lg {
  width        : 40px;
  height       : 40px;
  line-height  : 40px;
  border-radius: 50%;
}

.ant-avatar-lg-string {
  position                : absolute;
  left                    : 50%;
  -webkit-transform-origin: 0 center;
  -ms-transform-origin    : 0 center;
  transform-origin        : 0 center;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}

.ant-avatar-sm {
  width        : 24px;
  height       : 24px;
  line-height  : 24px;
  border-radius: 50%;
}

.ant-avatar-sm-string {
  position                : absolute;
  left                    : 50%;
  -webkit-transform-origin: 0 center;
  -ms-transform-origin    : 0 center;
  transform-origin        : 0 center;
}

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}

.ant-avatar-square {
  border-radius: 4px;
}

.ant-avatar>img {
  display      : block;
  width        : 100%;
  height       : 100%;
  -o-object-fit: cover;
  object-fit   : cover;
}

.ant-back-top {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : fixed;
  right                        : 100px;
  bottom                       : 50px;
  z-index                      : 10;
  width                        : 40px;
  height                       : 40px;
  cursor                       : pointer;
}

.ant-back-top-content {
  width             : 40px;
  height            : 40px;
  overflow          : hidden;
  color             : #fff;
  text-align        : center;
  background-color  : rgba(0, 0, 0, 0.45);
  border-radius     : 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color  : rgba(0, 0, 0, 0.65);
  }
}

.ant-back-top-icon {
  width     : 14px;
  height    : 16px;
  margin    : 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100% / 100% no-repeat;
}

@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}

@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

.ant-badge {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  color                        : unset;
  line-height                  : 1;
}

.ant-badge-count {
  z-index           : auto;
  min-width         : 20px;
  height            : 20px;
  padding           : 0 6px;
  color             : #fff;
  font-weight       : 400;
  font-size         : 12px;
  line-height       : 20px;
  white-space       : nowrap;
  text-align        : center;
  background        : #f5222d;
  border-radius     : 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow        : 0 0 0 1px #fff;

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.ant-badge-multiple-words {
  padding: 0 8px;
}

.ant-badge-dot {
  z-index           : auto;
  width             : 6px;
  height            : 6px;
  background        : #f5222d;
  border-radius     : 100%;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow        : 0 0 0 1px #fff;
}

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position                : absolute;
  top                     : 0;
  right                   : 0;
  -webkit-transform       : translate(50%, -50%);
  -ms-transform           : translate(50%, -50%);
  transform               : translate(50%, -50%);
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin    : 100% 0;
  transform-origin        : 100% 0;
}

.ant-badge-status {
  line-height   : inherit;
  vertical-align: baseline;
}

.ant-badge-status-dot {
  position      : relative;
  top           : -1px;
  display       : inline-block;
  width         : 6px;
  height        : 6px;
  vertical-align: middle;
  border-radius : 50%;
}

.ant-badge-status-success {
  background-color: #52c41a;
}

.ant-badge-status-processing {
  position        : relative;
  background-color: #1890ff;

  &:after {
    position         : absolute;
    top              : 0;
    left             : 0;
    width            : 100%;
    height           : 100%;
    border           : 1px solid #1890ff;
    border-radius    : 50%;
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation        : antStatusProcessing 1.2s ease-in-out infinite;
    content          : "";
  }
}

.ant-badge-status-default {
  background-color: #d9d9d9;
}

.ant-badge-status-error {
  background-color: #f5222d;
}

.ant-badge-status-warning {
  background-color: #faad14;
}

.ant-badge-status-magenta,
.ant-badge-status-pink {
  background: #eb2f96;
}

.ant-badge-status-red {
  background: #f5222d;
}

.ant-badge-status-volcano {
  background: #fa541c;
}

.ant-badge-status-orange {
  background: #fa8c16;
}

.ant-badge-status-yellow {
  background: #fadb14;
}

.ant-badge-status-gold {
  background: #faad14;
}

.ant-badge-status-cyan {
  background: #13c2c2;
}

.ant-badge-status-lime {
  background: #a0d911;
}

.ant-badge-status-green {
  background: #52c41a;
}

.ant-badge-status-blue {
  background: #1890ff;
}

.ant-badge-status-geekblue {
  background: #2f54eb;
}

.ant-badge-status-purple {
  background: #722ed1;
}

.ant-badge-status-text {
  margin-left: 8px;
  color      : rgba(0, 0, 0, 0.65);
  font-size  : 14px;
}

.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation          : antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation                  : antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
}

.ant-badge-zoom-leave {
  -webkit-animation          : antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation                  : antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
}

.ant-badge-not-a-wrapper {
  &:not(.ant-badge-status) {
    vertical-align: middle;
  }

  .ant-scroll-number {
    position: relative;
    top     : auto;
    display : block;
  }

  .ant-badge-count {
    -webkit-transform: none;
    -ms-transform    : none;
    transform        : none;
  }
}

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(2.4);
    transform        : scale(2.4);
    opacity          : 0;
  }
}

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform        : scale(0.8);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(2.4);
    transform        : scale(2.4);
    opacity          : 0;
  }
}

.ant-scroll-number {
  overflow: hidden;
}

.ant-scroll-number-only {
  display           : inline-block;
  height            : 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  >p {
    height: 20px;
    margin: 0;
  }
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform        : scale(0) translate(50%, -50%);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform        : scale(1) translate(50%, -50%);
  }
}

@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform        : scale(0) translate(50%, -50%);
    opacity          : 0;
  }

  to {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform        : scale(1) translate(50%, -50%);
  }
}

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform        : scale(1) translate(50%, -50%);
  }

  to {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform        : scale(0) translate(50%, -50%);
    opacity          : 0;
  }
}

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform        : scale(1) translate(50%, -50%);
  }

  to {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform        : scale(0) translate(50%, -50%);
    opacity          : 0;
  }
}

.ant-breadcrumb {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  color                        : rgba(0, 0, 0, 0.45);
  font-size                    : 14px;

  .anticon {
    font-size: 14px;
  }

  a {
    color             : rgba(0, 0, 0, 0.45);
    -webkit-transition: color .3s;
    transition        : color .3s;

    &:hover {
      color: #40a9ff;
    }
  }

  >span:last-child {
    color: rgba(0, 0, 0, 0.65);

    a {
      color: rgba(0, 0, 0, 0.65);
    }

    .ant-breadcrumb-separator {
      display: none;
    }
  }
}

.ant-breadcrumb-separator {
  margin: 0 8px;
  color : rgba(0, 0, 0, 0.45);
}

.ant-breadcrumb-link>.anticon+span,
.ant-breadcrumb-overlay-link>.anticon {
  margin-left: 4px;
}

.ant-btn {
  // line-height: 1.499;
  line-height        : 1;
  position           : relative;
  display            : inline-block;
  font-weight        : 400;
  white-space        : nowrap;
  text-align         : center;
  background-image   : none;
  -webkit-box-shadow : 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow         : 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor             : pointer;
  -webkit-transition : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  -ms-touch-action   : manipulation;
  touch-action       : manipulation;
  height             : 32px;
  padding            : 0 15px;
  font-size          : 14px;
  border-radius      : 4px;
  color              : rgba(0, 0, 0, 0.65);
  background-color   : #fff;
  border             : 1px solid #d9d9d9;

  >.anticon {
    line-height: 1;
  }

  outline: 0;

  &:active,
  &:focus {
    outline: 0;
  }

  &:not([disabled]) {
    &:hover {
      text-decoration: none;
    }

    &:active {
      outline           : 0;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &.disabled>*,
  &[disabled]>* {
    pointer-events: none;
  }
}

.ant-btn-lg {
  height       : 40px;
  padding      : 0 15px;
  font-size    : 16px;
  border-radius: 4px;
}

.ant-btn-sm {
  height       : 24px;
  padding      : 0 7px;
  font-size    : 14px;
  border-radius: 4px;
}

.ant-btn {
  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #40a9ff;
    background-color: #fff;
    border-color    : #40a9ff;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #096dd9;
    background-color: #fff;
    border-color    : #096dd9;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : #f5f5f5;
  border-color      : #d9d9d9;
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    background     : #fff;
  }

  > {

    i,
    span {
      display           : inline-block;
      -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition        : margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      pointer-events    : none;
    }
  }
}

.ant-btn-primary {
  color             : #fff;
  background-color  : #1890ff;
  border-color      : #1890ff;
  text-shadow       : 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow        : 0 2px 0 rgba(0, 0, 0, 0.045);

  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #fff;
    background-color: #40a9ff;
    border-color    : #40a9ff;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #fff;
    background-color: #096dd9;
    border-color    : #096dd9;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-primary-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : #f5f5f5;
  border-color      : #d9d9d9;
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn-primary {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-primary-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn-primary {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-primary-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-primary {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }
}

.ant-btn-group .ant-btn-primary {
  &:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color : #40a9ff;

    &:disabled {
      border-color: #d9d9d9;
    }
  }

  &:first-child:not(:last-child) {
    border-right-color: #40a9ff;

    &[disabled] {
      border-right-color: #d9d9d9;
    }
  }

  +.ant-btn-primary {
    border-left-color: #40a9ff;
  }

  &:last-child:not(:first-child) {
    border-left-color: #40a9ff;

    &[disabled] {
      border-left-color: #d9d9d9;
    }
  }

  +.ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
}

.ant-btn-ghost {
  color           : rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0);
  border-color    : #d9d9d9;

  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #40a9ff;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #40a9ff;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #096dd9;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #096dd9;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-ghost-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : #f5f5f5;
  border-color      : #d9d9d9;
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn-ghost {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-ghost-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn-ghost {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-ghost-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-ghost {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }
}

.ant-btn-dashed {
  color           : rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color    : #d9d9d9;
  border-style    : dashed;

  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #40a9ff;
    background-color: #fff;
    border-color    : #40a9ff;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #096dd9;
    background-color: #fff;
    border-color    : #096dd9;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-dashed-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : #f5f5f5;
  border-color      : #d9d9d9;
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn-dashed {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-dashed-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn-dashed {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-dashed-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-dashed {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }
}

.ant-btn-danger {
  color             : #fff;
  background-color  : #ff4d4f;
  border-color      : #ff4d4f;
  text-shadow       : 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow        : 0 2px 0 rgba(0, 0, 0, 0.045);

  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #fff;
    background-color: #ff7875;
    border-color    : #ff7875;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #fff;
    background-color: #d9363e;
    border-color    : #d9363e;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-danger-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : #f5f5f5;
  border-color      : #d9d9d9;
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn-danger {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-danger-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn-danger {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-danger-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-danger {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }
}

.ant-btn-link {
  color             : #1890ff;
  background-color  : rgba(0, 0, 0, 0);
  border-color      : rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  box-shadow        : none;

  >a:only-child {
    color: currentColor;

    &:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &:focus,
  &:hover {
    color           : #40a9ff;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #40a9ff;
  }

  &:focus>a:only-child,
  &:hover>a:only-child {
    color: currentColor;
  }

  &:focus>a:only-child:after,
  &:hover>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }

  &.active,
  &:active {
    color           : #096dd9;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #096dd9;
  }

  &.active>a:only-child,
  &:active>a:only-child {
    color: currentColor;
  }

  &.active>a:only-child:after,
  &:active>a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-link-disabled {
  background-color: #f5f5f5;
  border-color    : #d9d9d9;

  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: #f5f5f5;
    border-color    : #d9d9d9;
  }
}

.ant-btn-link {
  &.disabled {
    background-color: #f5f5f5;
    border-color    : #d9d9d9;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: #f5f5f5;
      border-color    : #d9d9d9;
    }
  }

  &[disabled] {
    background-color: #f5f5f5;
    border-color    : #d9d9d9;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: #f5f5f5;
      border-color    : #d9d9d9;
    }
  }

  &:active,
  &:focus,
  &:hover {
    border-color: rgba(0, 0, 0, 0);
  }
}

.ant-btn-link-disabled {
  color             : rgba(0, 0, 0, 0.25);
  background-color  : rgba(0, 0, 0, 0);
  border-color      : rgba(0, 0, 0, 0);
  text-shadow       : none;
  -webkit-box-shadow: none;
  box-shadow        : none;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : rgba(0, 0, 0, 0);
    border-color      : rgba(0, 0, 0, 0);
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-btn-link {
  &.disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : rgba(0, 0, 0, 0);
    border-color      : rgba(0, 0, 0, 0);
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : rgba(0, 0, 0, 0);
      border-color      : rgba(0, 0, 0, 0);
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &[disabled] {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : rgba(0, 0, 0, 0);
    border-color      : rgba(0, 0, 0, 0);
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : rgba(0, 0, 0, 0);
      border-color      : rgba(0, 0, 0, 0);
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }
}

.ant-btn-link-disabled {

  &.active>a:only-child,
  &:active>a:only-child,
  &:focus>a:only-child,
  &:hover>a:only-child,
  >a:only-child {
    color: currentColor;
  }
}

.ant-btn-link {
  &.disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &[disabled] {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }
}

.ant-btn-link-disabled {

  &.active>a:only-child:after,
  &:active>a:only-child:after,
  &:focus>a:only-child:after,
  &:hover>a:only-child:after,
  >a:only-child:after {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0);
    content   : "";
  }
}

.ant-btn-link {
  &.disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &[disabled] {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }
}

.ant-btn-icon-only {
  width        : 32px;
  height       : 32px;
  padding      : 0;
  font-size    : 16px;
  border-radius: 4px;

  &.ant-btn-lg {
    width        : 40px;
    height       : 40px;
    padding      : 0;
    font-size    : 18px;
    border-radius: 4px;
  }

  &.ant-btn-sm {
    width        : 24px;
    height       : 24px;
    padding      : 0;
    font-size    : 14px;
    border-radius: 4px;
  }

  >i {
    vertical-align: middle;
  }
}

.ant-btn-round {
  height       : 32px;
  padding      : 0 16px;
  font-size    : 14px;
  border-radius: 32px;

  &.ant-btn-lg {
    height       : 40px;
    padding      : 0 20px;
    font-size    : 16px;
    border-radius: 40px;
  }

  &.ant-btn-sm {
    height       : 24px;
    padding      : 0 12px;
    font-size    : 14px;
    border-radius: 24px;
  }

  &.ant-btn-icon-only {
    width: auto;
  }
}

.ant-btn-circle {
  min-width    : 32px;
  padding-right: 0;
  padding-left : 0;
  text-align   : center;
  border-radius: 50%;
}

.ant-btn-circle-outline {
  min-width    : 32px;
  padding-right: 0;
  padding-left : 0;
  text-align   : center;
  border-radius: 50%;

  &.ant-btn-lg {
    min-width    : 40px;
    border-radius: 50%;
  }
}

.ant-btn-circle.ant-btn-lg {
  min-width    : 40px;
  border-radius: 50%;
}

.ant-btn-circle-outline.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
  min-width    : 24px;
  border-radius: 50%;
}

.ant-btn {
  &:before {
    position          : absolute;
    top               : -1px;
    right             : -1px;
    bottom            : -1px;
    left              : -1px;
    z-index           : 1;
    display           : none;
    background        : #fff;
    border-radius     : inherit;
    opacity           : .35;
    -webkit-transition: opacity .2s;
    transition        : opacity .2s;
    content           : "";
    pointer-events    : none;
  }

  .anticon {
    -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.anticon-minus>svg,
    &.anticon-plus>svg {
      shape-rendering: optimizeSpeed;
    }
  }

  &.ant-btn-loading {
    position: relative;

    &:not([disabled]) {
      pointer-events: none;
    }

    &:before {
      display: block;
    }

    &:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-right: 15px;
      padding-left : initial;

      .anticon:not(:last-child) {
        margin-left: 14px;
      }
    }
  }
}

.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 10px;
  padding-left : initial;


  .anticon {
    margin-right: 17px;
  }
}

.ant-btn-group {
  // display: inline-block;
  display       : inline-flex;
  flex-direction: row-reverse;
  position      : relative;

  > {

    .ant-btn,
    span>.ant-btn {
      position: relative;
    }

    .ant-btn {

      &.active,
      &:active,
      &:focus,
      &:hover {
        z-index: 2;
      }
    }

    span>.ant-btn {

      &.active,
      &:active,
      &:focus,
      &:hover {
        z-index: 2;
      }
    }

    .ant-btn:disabled,
    span>.ant-btn:disabled {
      z-index: 0;
    }

    .ant-btn-icon-only {
      font-size: 14px;
    }
  }
}

.ant-btn-group-lg> {

  .ant-btn,
  span>.ant-btn {
    height       : 40px;
    padding      : 0 15px;
    font-size    : 16px;
    border-radius: 0;
    line-height  : 38px;
  }

  .ant-btn.ant-btn-icon-only {
    width        : 40px;
    height       : 40px;
    padding-right: 0;
    padding-left : 0;
  }
}

.ant-btn-group-sm> {

  .ant-btn,
  span>.ant-btn {
    height       : 24px;
    padding      : 0 7px;
    font-size    : 14px;
    border-radius: 0;
    line-height  : 22px;
  }

  .ant-btn>.anticon,
  span>.ant-btn>.anticon {
    font-size: 14px;
  }

  .ant-btn.ant-btn-icon-only {
    width        : 24px;
    height       : 24px;
    padding-right: 0;
    padding-left : 0;
  }
}

.ant-btn+.ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group {
  + {

    .ant-btn,
    .ant-btn-group {
      margin-left: -1px;
    }
  }

  .ant-btn+ {

    .ant-btn,
    span {
      margin-left: -1px;
    }
  }

  >span+span,
  span+.ant-btn {
    margin-left: -1px;
  }

  .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: rgba(0, 0, 0, 0);
  }

  .ant-btn {
    border-radius: 0;
  }

  > {

    .ant-btn:first-child,
    span:first-child>.ant-btn {
      margin-left: 0;
    }

    .ant-btn:only-child,
    span:only-child>.ant-btn {
      border-radius: 4px;
    }

    .ant-btn:first-child:not(:last-child),
    span:first-child:not(:last-child)>.ant-btn {
      border-top-left-radius   : 4px;
      border-bottom-left-radius: 4px;
    }

    .ant-btn:last-child:not(:first-child),
    span:last-child:not(:first-child)>.ant-btn {
      border-top-right-radius   : 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.ant-btn-group-sm> {

  .ant-btn:only-child,
  span:only-child>.ant-btn {
    border-radius: 4px;
  }

  .ant-btn:first-child:not(:last-child),
  span:first-child:not(:last-child)>.ant-btn {
    border-top-left-radius   : 4px;
    border-bottom-left-radius: 4px;
  }

  .ant-btn:last-child:not(:first-child),
  span:last-child:not(:first-child)>.ant-btn {
    border-top-right-radius   : 4px;
    border-bottom-right-radius: 4px;
  }
}

.ant-btn-group>.ant-btn-group {
  float: left;

  &:not(:first-child):not(:last-child)>.ant-btn {
    border-radius: 0;
  }

  &:first-child:not(:last-child)>.ant-btn:last-child {
    padding-right             : 8px;
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;
  }

  &:last-child:not(:first-child)>.ant-btn:first-child {
    padding-left             : 8px;
    border-top-left-radius   : 0;
    border-bottom-left-radius: 0;
  }
}

.ant-btn {

  &:active>span,
  &:focus>span {
    position: relative;
  }

  > {

    .anticon+span,
    span+.anticon {
      margin-left: 8px;
    }
  }
}

.ant-btn-background-ghost {
  color       : #fff;
  background  : rgba(0, 0, 0, 0) !important;
  border-color: #fff;

  &.ant-btn-primary {
    color           : #1890ff;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #1890ff;
    text-shadow     : none;

    >a:only-child {
      color: currentColor;

      &:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &:focus,
    &:hover {
      color           : #40a9ff;
      background-color: rgba(0, 0, 0, 0);
      border-color    : #40a9ff;
    }

    &:focus>a:only-child,
    &:hover>a:only-child {
      color: currentColor;
    }

    &:focus>a:only-child:after,
    &:hover>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }

    &.active,
    &:active {
      color           : #096dd9;
      background-color: rgba(0, 0, 0, 0);
      border-color    : #096dd9;
    }

    &.active>a:only-child,
    &:active>a:only-child {
      color: currentColor;
    }

    &.active>a:only-child:after,
    &:active>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-primary-disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &.ant-btn-primary {
    &.disabled {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }

    &[disabled] {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }
  }

  &.ant-btn-primary-disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &.ant-btn-primary {
    &.disabled {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }
  }

  &.ant-btn-primary-disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-primary {
    &.disabled {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &[disabled] {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }
  }

  &.ant-btn-danger {
    color           : #ff4d4f;
    background-color: rgba(0, 0, 0, 0);
    border-color    : #ff4d4f;
    text-shadow     : none;

    >a:only-child {
      color: currentColor;

      &:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &:focus,
    &:hover {
      color           : #ff7875;
      background-color: rgba(0, 0, 0, 0);
      border-color    : #ff7875;
    }

    &:focus>a:only-child,
    &:hover>a:only-child {
      color: currentColor;
    }

    &:focus>a:only-child:after,
    &:hover>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }

    &.active,
    &:active {
      color           : #d9363e;
      background-color: rgba(0, 0, 0, 0);
      border-color    : #d9363e;
    }

    &.active>a:only-child,
    &:active>a:only-child {
      color: currentColor;
    }

    &.active>a:only-child:after,
    &:active>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-danger-disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &.ant-btn-danger {
    &.disabled {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }

    &[disabled] {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }
  }

  &.ant-btn-danger-disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &.ant-btn-danger {
    &.disabled {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }
  }

  &.ant-btn-danger-disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-danger {
    &.disabled {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &[disabled] {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }
  }

  &.ant-btn-link {
    color           : #1890ff;
    background-color: rgba(0, 0, 0, 0);
    border-color    : rgba(0, 0, 0, 0);
    text-shadow     : none;
    color           : #fff;

    >a:only-child {
      color: currentColor;

      &:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &:focus,
    &:hover {
      color           : #40a9ff;
      background-color: rgba(0, 0, 0, 0);
      border-color    : rgba(0, 0, 0, 0);
    }

    &:focus>a:only-child,
    &:hover>a:only-child {
      color: currentColor;
    }

    &:focus>a:only-child:after,
    &:hover>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }

    &.active,
    &:active {
      color           : #096dd9;
      background-color: rgba(0, 0, 0, 0);
      border-color    : rgba(0, 0, 0, 0);
    }

    &.active>a:only-child,
    &:active>a:only-child {
      color: currentColor;
    }

    &.active>a:only-child:after,
    &:active>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-link-disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  &.ant-btn-link {
    &.disabled {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }

    &[disabled] {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }
  }

  &.ant-btn-link-disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  &.ant-btn-link {
    &.disabled {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }
  }

  &.ant-btn-link-disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  &.ant-btn-link {
    &.disabled {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &[disabled] {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }
  }
}

.ant-btn-two-chinese-chars {
  &:first-letter {
    letter-spacing: .34em;
  }

  > :not(.anticon) {
    margin-right  : -.34em;
    letter-spacing: .34em;
  }
}

.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  vertical-align: top;
}

a {
  &.ant-btn {
    padding-top: .1px;
    line-height: 30px;
  }

  &.ant-btn-lg {
    line-height: 38px;
  }

  &.ant-btn-sm {
    line-height: 22px;
  }
}

.ant-fullcalendar {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  border-top                   : 1px solid #d9d9d9;
  outline                      : none;
}

.ant-select {
  &.ant-fullcalendar-year-select {
    min-width: 90px;

    &.ant-select-sm {
      min-width: 70px;
    }
  }

  &.ant-fullcalendar-month-select {
    min-width  : 80px;
    margin-left: 8px;

    &.ant-select-sm {
      min-width: 70px;
    }
  }
}

.ant-fullcalendar-header {
  padding   : 11px 16px 11px 0;
  text-align: right;

  .ant-select-dropdown {
    text-align: right;
  }

  .ant-radio-group {
    margin-left: 8px;
    text-align : right;
  }

  label.ant-radio-button {
    height     : 22px;
    padding    : 0 10px;
    line-height: 20px;
  }
}

.ant-fullcalendar-date-panel {
  position: relative;
  outline : none;
}

.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}

.ant-fullcalendar {
  table {
    width           : 100%;
    max-width       : 100%;
    height          : 256px;
    background-color: rgba(0, 0, 0, 0);
    border-collapse : collapse;
    border          : 0;
  }

  td,
  th {
    border: 0;
  }

  td {
    position: relative;
  }
}

.ant-fullcalendar-calendar-table {
  margin-bottom : 0;
  border-spacing: 0;
}

.ant-fullcalendar-column-header {
  width      : 33px;
  padding    : 0;
  line-height: 18px;
  text-align : center;

  .ant-fullcalendar-column-header-inner {
    display    : block;
    font-weight: 400;
  }
}

.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}

.ant-fullcalendar-date,
.ant-fullcalendar-month {
  text-align        : center;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-fullcalendar-value {
  display           : block;
  width             : 24px;
  height            : 24px;
  margin            : 0 auto;
  padding           : 0;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 24px;
  background        : rgba(0, 0, 0, 0);
  border-radius     : 2px;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }

  &:active {
    color     : #fff;
    background: #1890ff;
  }
}

.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}

.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-today .ant-fullcalendar-value {
  -webkit-box-shadow: 0 0 0 1px #1890ff inset;
  box-shadow        : inset 0 0 0 1px #1890ff;
}

.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color     : #fff;
  background: #1890ff;
}

.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius   : 4px;
  border-bottom-left-radius: 4px;
}

.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius   : 4px;
  border-bottom-right-radius: 4px;
}

.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}

.ant-fullcalendar-month-panel-table {
  width          : 100%;
  table-layout   : fixed;
  border-collapse: separate;
}

.ant-fullcalendar-content {
  position: absolute;
  bottom  : -9px;
  left    : 0;
  width   : 100%;
}

.ant-fullcalendar-fullscreen {
  border-top: 0;

  .ant-fullcalendar-table {
    table-layout: fixed;
  }

  .ant-fullcalendar-header {
    .ant-radio-group {
      margin-left: 16px;
    }

    label.ant-radio-button {
      height     : 32px;
      line-height: 30px;
    }
  }

  .ant-fullcalendar-date,
  .ant-fullcalendar-month {
    display           : block;
    height            : 116px;
    margin            : 0 4px;
    padding           : 4px 8px;
    color             : rgba(0, 0, 0, 0.65);
    text-align        : right;
    border-top        : 2px solid #e8e8e8;
    -webkit-transition: background .3s;
    transition        : background .3s;
  }

  .ant-fullcalendar-date:hover,
  .ant-fullcalendar-month:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }

  .ant-fullcalendar-date:active,
  .ant-fullcalendar-month:active {
    background: #bae7ff;
  }

  .ant-fullcalendar-column-header {
    padding-right : 12px;
    padding-bottom: 5px;
    text-align    : right;
  }

  .ant-fullcalendar-value {
    width     : auto;
    text-align: right;
    background: rgba(0, 0, 0, 0);
  }

  .ant-fullcalendar-today .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
  .ant-fullcalendar-today .ant-fullcalendar-date {
    background      : rgba(0, 0, 0, 0);
    border-top-color: #1890ff;
  }

  .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
  .ant-fullcalendar-today .ant-fullcalendar-value {
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
  .ant-fullcalendar-selected-day .ant-fullcalendar-date {
    background: #e6f7ff;
  }

  .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
  .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    color: #1890ff;
  }

  .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
  .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-fullcalendar-content {
    position  : static;
    width     : auto;
    height    : 88px;
    overflow-y: auto;
  }
}

.ant-fullcalendar-disabled-cell {
  .ant-fullcalendar-date {
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:not(.ant-fullcalendar-today) .ant-fullcalendar-date {
    background: rgba(0, 0, 0, 0);

    &:hover {
      background: rgba(0, 0, 0, 0);
    }
  }

  .ant-fullcalendar-value {
    width        : auto;
    color        : rgba(0, 0, 0, 0.25);
    border-radius: 0;
    cursor       : not-allowed;
  }
}

.ant-card {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  background                   : #fff;
  border-radius                : 2px;
  -webkit-transition           : all .3s;
  transition                   : all .3s;
}

.ant-card-hoverable {
  cursor: pointer;

  &:hover {
    border-color      : rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.09);
  }
}

.ant-card-bordered {
  border: 1px solid #e8e8e8;
}

.ant-card-head {
  min-height   : 48px;
  margin-bottom: -1px;
  padding      : 0 24px;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 500;
  font-size    : 16px;
  background   : rgba(0, 0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom         : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-card-head-wrapper {
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.ant-card-head-title {
  display         : inline-block;
  -webkit-box-flex: 1;
  -ms-flex        : 1;
  flex            : 1;
  padding         : 16px 0;
  overflow        : hidden;
  white-space     : nowrap;
  text-overflow   : ellipsis;
}

.ant-card-head {
  .ant-tabs {
    clear        : both;
    margin-bottom: -17px;
    color        : rgba(0, 0, 0, 0.65);
    font-weight  : 400;
    font-size    : 14px;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8;
  }
}

.ant-card-extra {
  float      : right;
  margin-left: auto;
  padding    : 16px 0;
  color      : rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size  : 14px;
}

.ant-card-body {
  padding: 24px;
  zoom   : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin : -1px 0 0 -1px;
  padding: 0;
}

.ant-card-grid {
  float             : left;
  width             : 33.33%;
  padding           : 24px;
  border            : 0;
  border-radius     : 0;
  -webkit-box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  box-shadow        : 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, inset 1px 0 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-card-grid-hoverable:hover {
  position          : relative;
  z-index           : 1;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-card-contain-tabs>.ant-card-head {
  .ant-card-head-title {
    min-height    : 32px;
    padding-bottom: 0;
  }

  .ant-card-extra {
    padding-bottom: 0;
  }
}

.ant-card-cover {
  >* {
    display: block;
    width  : 100%;
  }

  img {
    border-radius: 2px 2px 0 0;
  }
}

.ant-card-actions {
  margin    : 0;
  padding   : 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom      : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  >li {
    float     : left;
    margin    : 12px 0;
    color     : rgba(0, 0, 0, 0.45);
    text-align: center;

    >span {
      position   : relative;
      display    : block;
      min-width  : 32px;
      font-size  : 14px;
      line-height: 22px;
      cursor     : pointer;

      &:hover {
        color             : #1890ff;
        -webkit-transition: color .3s;
        transition        : color .3s;
      }

      >.anticon {
        display           : inline-block;
        width             : 100%;
        color             : rgba(0, 0, 0, 0.45);
        line-height       : 22px;
        -webkit-transition: color .3s;
        transition        : color .3s;
      }

      a:not(.ant-btn) {
        display           : inline-block;
        width             : 100%;
        color             : rgba(0, 0, 0, 0.45);
        line-height       : 22px;
        -webkit-transition: color .3s;
        transition        : color .3s;

        &:hover {
          color: #1890ff;
        }
      }

      >.anticon:hover {
        color: #1890ff;
      }

      >.anticon {
        font-size  : 16px;
        line-height: 22px;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid #e8e8e8;
    }
  }
}

.ant-card-type-inner {
  .ant-card-head {
    padding   : 0 24px;
    background: #fafafa;
  }

  .ant-card-head-title {
    padding  : 12px 0;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 16px 24px;
  }

  .ant-card-extra {
    padding: 13.5px 0;
  }
}

.ant-card-meta {
  margin: -4px 0;
  zoom  : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-card-meta-avatar {
  float        : left;
  padding-right: 16px;
}

.ant-card-meta-detail {
  overflow: hidden;

  >div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.ant-card-meta-title {
  overflow     : hidden;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 500;
  font-size    : 16px;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-card-loading {
  overflow: hidden;

  .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
  }
}

.ant-card-loading-content p {
  margin: 0;
}

.ant-card-loading-block {
  height           : 14px;
  margin           : 4px 0;
  background       : -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background       : linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size  : 600% 600%;
  border-radius    : 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
  animation        : card-loading 1.4s ease infinite;
}

@-webkit-keyframes card-loading {

  0%,
  to {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

@keyframes card-loading {

  0%,
  to {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.ant-card-small> {
  .ant-card-head {
    min-height: 36px;
    padding   : 0 12px;
    font-size : 14px;

    >.ant-card-head-wrapper> {
      .ant-card-head-title {
        padding: 8px 0;
      }

      .ant-card-extra {
        padding  : 8px 0;
        font-size: 14px;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
  }
}

.ant-carousel {
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;

  .slick-slider {
    -webkit-box-sizing         : border-box;
    box-sizing                 : border-box;
    position                   : relative;
    display                    : block;
    -webkit-touch-callout      : none;
    -ms-touch-action           : pan-y;
    touch-action               : pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display : block;
    margin  : 0;
    padding : 0;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
    }

    .slick-slide {
      pointer-events: none;

      &.slick-active {
        pointer-events: auto;
      }
    }
  }

  .slick-slider {

    .slick-list,
    .slick-track {
      -webkit-transform: translateZ(0);
      transform        : translateZ(0);
    }
  }

  .slick-track {
    position: relative;
    top     : 0;
    left    : 0;
    display : block;

    &:after,
    &:before {
      display: table;
      content: "";
    }

    &:after {
      clear: both;
    }
  }
}

.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}

.ant-carousel .slick-slide {
  display   : none;
  float     : left;
  height    : 100%;
  min-height: 1px;
}

[dir=rtl] .ant-carousel .slick-slide {
  float: right;
}

.ant-carousel {
  .slick-slide {
    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height : auto;
    border : 1px solid rgba(0, 0, 0, 0);
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-next,
  .slick-prev {
    position   : absolute;
    top        : 50%;
    display    : block;
    width      : 20px;
    height     : 20px;
    margin-top : -10px;
    padding    : 0;
    font-size  : 0;
    line-height: 0;
    border     : 0;
    cursor     : pointer;
  }

  .slick-next {
    color     : rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
    outline   : none;

    &:focus,
    &:hover {
      color     : rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0);
      outline   : none;
    }
  }

  .slick-prev {
    color     : rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
    outline   : none;

    &:focus,
    &:hover {
      color     : rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0);
      outline   : none;
    }
  }

  .slick-next {

    &:focus:before,
    &:hover:before {
      opacity: 1;
    }
  }

  .slick-prev {

    &:focus:before,
    &:hover:before {
      opacity: 1;
    }
  }

  .slick-next.slick-disabled:before {
    opacity: .25;
  }

  .slick-prev {
    &.slick-disabled:before {
      opacity: .25;
    }

    left: -25px;

    &:before {
      content: "←";
    }
  }

  .slick-next {
    right: -25px;

    &:before {
      content: "→";
    }
  }

  .slick-dots {
    position  : absolute;
    display   : block;
    width     : 100%;
    height    : 3px;
    margin    : 0;
    padding   : 0;
    text-align: center;
    list-style: none;
  }

  .slick-dots-bottom {
    bottom: 12px;
  }

  .slick-dots-top {
    top: 12px;
  }

  .slick-dots li {
    position      : relative;
    display       : inline-block;
    margin        : 0 2px;
    padding       : 0;
    text-align    : center;
    vertical-align: top;

    button {
      display           : block;
      width             : 16px;
      height            : 3px;
      padding           : 0;
      color             : rgba(0, 0, 0, 0);
      font-size         : 0;
      background        : #fff;
      border            : 0;
      border-radius     : 1px;
      outline           : none;
      cursor            : pointer;
      opacity           : .3;
      -webkit-transition: all .5s;
      transition        : all .5s;

      &:focus,
      &:hover {
        opacity: .75;
      }
    }

    &.slick-active button {
      width     : 24px;
      background: #fff;
      opacity   : 1;

      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}

.ant-carousel-vertical {
  .slick-dots {
    top              : 50%;
    bottom           : auto;
    width            : 3px;
    height           : auto;
    -webkit-transform: translateY(-50%);
    -ms-transform    : translateY(-50%);
    transform        : translateY(-50%);
  }

  .slick-dots-left {
    left: 12px;
  }

  .slick-dots-right {
    right: 12px;
  }

  .slick-dots li {
    margin        : 0 2px;
    vertical-align: baseline;

    button {
      width : 3px;
      height: 16px;
    }

    &.slick-active button {
      width : 3px;
      height: 24px;
    }
  }
}

.ant-cascader {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
}

.ant-cascader-input.ant-input {
  position        : static;
  width           : 100%;
  padding-right   : 24px;
  background-color: rgba(0, 0, 0, 0) !important;
  cursor          : pointer;
}

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}

.ant-cascader-picker {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  background-color             : #fff;
  border-radius                : 4px;
  outline                      : 0;
  cursor                       : pointer;
  -webkit-transition           : color .3s;
  transition                   : color .3s;
}

.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: rgba(0, 0, 0, 0);
}

.ant-cascader-picker-disabled {
  color     : rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor    : not-allowed;

  .ant-cascader-input {
    cursor: not-allowed;
  }
}

.ant-cascader-picker:focus .ant-cascader-input {
  border-color      : #40a9ff;
  border-right-width: 1px !important;
  outline           : 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-picker-label {
  position     : absolute;
  top          : 50%;
  left         : 0;
  width        : 100%;
  height       : 20px;
  margin-top   : -10px;
  padding      : 0 20px 0 12px;
  overflow     : hidden;
  line-height  : 20px;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-cascader-picker-clear {
  position          : absolute;
  top               : 50%;
  right             : 12px;
  z-index           : 2;
  width             : 12px;
  height            : 12px;
  margin-top        : -6px;
  color             : rgba(0, 0, 0, 0.25);
  font-size         : 12px;
  line-height       : 12px;
  background        : #fff;
  cursor            : pointer;
  opacity           : 0;
  -webkit-transition: color .3s ease, opacity .15s ease;
  transition        : color .3s ease, opacity .15s ease;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}

.ant-cascader-picker-arrow {
  position          : absolute;
  top               : 50%;
  right             : 12px;
  z-index           : 1;
  width             : 12px;
  height            : 12px;
  margin-top        : -6px;
  color             : rgba(0, 0, 0, 0.25);
  font-size         : 12px;
  line-height       : 12px;
  -webkit-transition: -webkit-transform .2s;
  transition        : -webkit-transform .2s;
  transition        : transform .2s;
  transition        : transform .2s, -webkit-transform .2s;

  &.ant-cascader-picker-arrow-expand {
    -webkit-transform: rotate(180deg);
    -ms-transform    : rotate(180deg);
    transform        : rotate(180deg);
  }
}

.ant-cascader-picker-label:hover+.ant-cascader-input {
  border-color      : #40a9ff;
  border-right-width: 1px !important;
}

.ant-cascader-picker-small {

  .ant-cascader-picker-arrow,
  .ant-cascader-picker-clear {
    right: 8px;
  }
}

.ant-cascader-menus {
  position          : absolute;
  z-index           : 1050;
  font-size         : 14px;
  white-space       : nowrap;
  background        : #fff;
  border-radius     : 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);

  ol,
  ul {
    margin    : 0;
    list-style: none;
  }
}

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}

.ant-cascader-menus {

  &.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
  &.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name        : antSlideUpIn;
  }

  &.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name        : antSlideDownIn;
  }

  &.slide-up-leave.slide-up-leave-active {
    &.ant-cascader-menus-placement-bottomLeft {
      -webkit-animation-name: antSlideUpOut;
      animation-name        : antSlideUpOut;
    }

    &.ant-cascader-menus-placement-topLeft {
      -webkit-animation-name: antSlideDownOut;
      animation-name        : antSlideDownOut;
    }
  }
}

.ant-cascader-menu {
  display           : inline-block;
  min-width         : 111px;
  height            : 180px;
  margin            : 0;
  padding           : 4px 0;
  overflow          : auto;
  vertical-align    : top;
  list-style        : none;
  border-right      : 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    margin-right      : -1px;
    border-right-color: rgba(0, 0, 0, 0);
    border-radius     : 0 4px 4px 0;
  }

  &:only-child {
    border-radius: 4px;
  }
}

.ant-cascader-menu-item {
  padding           : 5px 12px;
  line-height       : 22px;
  white-space       : nowrap;
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:hover {
    background: #e6f7ff;
  }
}

.ant-cascader-menu-item-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    background: rgba(0, 0, 0, 0);
  }
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  font-weight     : 600;
  background-color: #fafafa;

  &:hover {
    font-weight     : 600;
    background-color: #fafafa;
  }
}

.ant-cascader-menu-item-expand {
  position     : relative;
  padding-right: 24px;

  .ant-cascader-menu-item-expand-icon {
    display          : inline-block;
    font-size        : 12px;
    font-size        : 10px\9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform    : scale(0.83333333) rotate(0deg);
    transform        : scale(0.83333333) rotate(0deg);
    position         : absolute;
    right            : 12px;
    color            : rgba(0, 0, 0, 0.45);
  }
}

.ant-cascader-menu-item-loading-icon {
  display          : inline-block;
  font-size        : 12px;
  font-size        : 10px\9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
  -ms-transform    : scale(0.83333333) rotate(0deg);
  transform        : scale(0.83333333) rotate(0deg);
  position         : absolute;
  right            : 12px;
  color            : rgba(0, 0, 0, 0.45);
}

:root {

  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-loading-icon {
    font-size: 12px;
  }
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}

.ant-checkbox {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  top                          : -.09em;
  display                      : inline-block;
  line-height                  : 1;
  white-space                  : nowrap;
  vertical-align               : middle;
  outline                      : none;
  cursor                       : pointer;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1890ff;
}

.ant-checkbox-checked:after {
  position                   : absolute;
  top                        : 0;
  left                       : 0;
  width                      : 100%;
  height                     : 100%;
  border                     : 1px solid #1890ff;
  border-radius              : 2px;
  visibility                 : hidden;
  -webkit-animation          : antCheckboxEffect .36s ease-in-out;
  animation                  : antCheckboxEffect .36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode        : backwards;
  content                    : "";
}

.ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
  visibility: visible;
}

.ant-checkbox-inner {
  position          : relative;
  top               : 0;
  left              : 0;
  display           : block;
  width             : 16px;
  height            : 16px;
  background-color  : #fff;
  border            : 1px solid #d9d9d9;
  border-radius     : 2px;
  border-collapse   : separate;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:after {
    position          : absolute;
    top               : 50%;
    left              : 22%;
    display           : table;
    width             : 5.71428571px;
    height            : 9.14285714px;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(0) translate(-50%, -50%);
    transform         : rotate(45deg) scale(0) translate(-50%, -50%);
    opacity           : 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition        : all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content           : " ";
  }
}

.ant-checkbox-input {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  left    : 0;
  z-index : 1;
  width   : 100%;
  height  : 100%;
  cursor  : pointer;
  opacity : 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  &:after {
    position          : absolute;
    display           : table;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(1) translate(-50%, -50%);
    transform         : rotate(45deg) scale(1) translate(-50%, -50%);
    opacity           : 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition        : all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content           : " ";
  }

  background-color: #1890ff;
  border-color    : #1890ff;
}

.ant-checkbox-disabled {
  cursor: not-allowed;

  &.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color          : rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name        : none;
  }

  .ant-checkbox-input {
    cursor: not-allowed;
  }

  .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color    : #d9d9d9 !important;

    &:after {
      border-color          : #f5f5f5;
      border-collapse       : separate;
      -webkit-animation-name: none;
      animation-name        : none;
    }
  }

  +span {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover:after {
    visibility: hidden;
  }
}

.ant-checkbox-wrapper {
  &:hover .ant-checkbox-disabled:after {
    visibility: hidden;
  }

  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  line-height                  : unset;
  cursor                       : pointer;

  &.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  +.ant-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-checkbox+span {
  padding-right: 8px;
  padding-left : 8px;
}

.ant-checkbox-group {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
}

.ant-checkbox-group-item {
  display     : inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  +.ant-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background-color: #fff;
    border-color    : #d9d9d9;

    &:after {
      top              : 50%;
      left             : 50%;
      width            : 8px;
      height           : 8px;
      background-color : #1890ff;
      border           : 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform    : translate(-50%, -50%) scale(1);
      transform        : translate(-50%, -50%) scale(1);
      opacity          : 1;
      content          : " ";
    }
  }

  &.ant-checkbox-disabled .ant-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color    : rgba(0, 0, 0, 0.25);
  }
}

.ant-collapse {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  background-color             : #fafafa;
  border                       : 1px solid #d9d9d9;
  border-bottom                : 0;
  border-radius                : 4px;

  >.ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
      border-radius: 0 0 4px 4px;

      >.ant-collapse-header {
        border-radius: 0 0 4px 4px;
      }
    }

    >.ant-collapse-header {
      position          : relative;
      padding           : 12px 16px 12px 40px;
      color             : rgba(0, 0, 0, 0.85);
      line-height       : 22px;
      cursor            : pointer;
      -webkit-transition: all .3s;
      transition        : all .3s;

      .ant-collapse-arrow {
        color                  : inherit;
        font-style             : normal;
        line-height            : 0;
        text-align             : center;
        text-transform         : none;
        vertical-align         : -.125em;
        text-rendering         : optimizeLegibility;
        -webkit-font-smoothing : antialiased;
        -moz-osx-font-smoothing: grayscale;
        position               : absolute;
        top                    : 50%;
        left                   : 16px;
        display                : inline-block;
        font-size              : 12px;
        -webkit-transform      : translateY(-50%);
        -ms-transform          : translateY(-50%);
        transform              : translateY(-50%);

        >* {
          line-height: 1;
        }

        svg {
          display: inline-block;
        }

        &:before {
          display: none;
        }

        .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow-icon {
          display: block;
        }

        svg {
          -webkit-transition: -webkit-transform .24s;
          transition        : -webkit-transform .24s;
          transition        : transform .24s;
          transition        : transform .24s, -webkit-transform .24s;
        }
      }

      .ant-collapse-extra {
        float: right;
      }

      &:focus {
        outline: none;
      }
    }

    &.ant-collapse-no-arrow>.ant-collapse-header {
      padding-left: 12px;
    }
  }
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 40px 12px 16px;

  .ant-collapse-arrow {
    right: 16px;
    left : auto;
  }
}

.ant-collapse-anim-active {
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition        : height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-collapse-content {
  overflow        : hidden;
  color           : rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top      : 1px solid #d9d9d9;

  >.ant-collapse-content-box {
    padding: 16px;
  }
}

.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0 0 4px 4px;
}

.ant-collapse-borderless {
  background-color: #fafafa;
  border          : 0;

  >.ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
      border-radius: 0;

      .ant-collapse-header {
        border-radius: 0;
      }
    }

    >.ant-collapse-content {
      background-color: rgba(0, 0, 0, 0);
      border-top      : 0;

      >.ant-collapse-content-box {
        padding-top: 4px;
      }
    }
  }
}

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  >.arrow {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.ant-comment {
  position: relative;
}

.ant-comment-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0;
}

.ant-comment-avatar {
  position         : relative;
  -ms-flex-negative: 0;
  flex-shrink      : 0;
  margin-right     : 12px;
  cursor           : pointer;

  img {
    width        : 32px;
    height       : 32px;
    border-radius: 50%;
  }
}

.ant-comment-content {
  position        : relative;
  -webkit-box-flex: 1;
  -ms-flex        : 1 1 auto;
  flex            : 1 1 auto;
  min-width       : 1px;
  font-size       : 14px;
  word-wrap       : break-word;
}

.ant-comment-content-author {
  display         : -webkit-box;
  display         : -ms-flexbox;
  display         : flex;
  -ms-flex-wrap   : wrap;
  flex-wrap       : wrap;
  -webkit-box-pack: start;
  -ms-flex-pack   : start;
  justify-content : flex-start;
  margin-bottom   : 4px;
  font-size       : 14px;

  > {

    a,
    span {
      padding-right: 8px;
      font-size    : 12px;
      line-height  : 18px;
    }
  }
}

.ant-comment-content-author-name {
  color             : rgba(0, 0, 0, 0.45);
  font-size         : 14px;
  -webkit-transition: color .3s;
  transition        : color .3s;

  > {

    *,
    :hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.ant-comment-content-author-time {
  color      : #ccc;
  white-space: nowrap;
  cursor     : auto;
}

.ant-comment-content-detail p {
  white-space: pre-wrap;
}

.ant-comment-actions {
  margin-top  : 12px;
  padding-left: 0;

  >li {
    display: inline-block;
    color  : rgba(0, 0, 0, 0.45);

    >span {
      padding-right      : 10px;
      color              : rgba(0, 0, 0, 0.45);
      font-size          : 12px;
      cursor             : pointer;
      -webkit-transition : color .3s;
      transition         : color .3s;
      -webkit-user-select: none;
      -moz-user-select   : none;
      -ms-user-select    : none;
      user-select        : none;

      &:hover {
        color: #595959;
      }
    }
  }
}

.ant-comment-nested {
  margin-left: 44px;
}

.ant-calendar-picker-container {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  z-index                      : 1050;
  font-family                  : -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  &.slide-up-appear.slide-up-appear-active {

    &.ant-calendar-picker-container-placement-topLeft,
    &.ant-calendar-picker-container-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-up-enter.slide-up-enter-active {

    &.ant-calendar-picker-container-placement-topLeft,
    &.ant-calendar-picker-container-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-up-appear.slide-up-appear-active {

    &.ant-calendar-picker-container-placement-bottomLeft,
    &.ant-calendar-picker-container-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-up-enter.slide-up-enter-active {

    &.ant-calendar-picker-container-placement-bottomLeft,
    &.ant-calendar-picker-container-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-up-leave.slide-up-leave-active {

    &.ant-calendar-picker-container-placement-topLeft,
    &.ant-calendar-picker-container-placement-topRight {
      -webkit-animation-name: antSlideDownOut;
      animation-name        : antSlideDownOut;
    }

    &.ant-calendar-picker-container-placement-bottomLeft,
    &.ant-calendar-picker-container-placement-bottomRight {
      -webkit-animation-name: antSlideUpOut;
      animation-name        : antSlideUpOut;
    }
  }
}

.ant-calendar-picker {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  outline                      : none;
  cursor                       : text;
  -webkit-transition           : opacity .3s;
  transition                   : opacity .3s;
}

.ant-calendar-picker-input {
  outline: none;

  &.ant-input {
    line-height: 1.5;
  }

  &.ant-input-sm {
    padding-top   : 0;
    padding-bottom: 0;
  }
}

.ant-calendar-picker {
  &:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
  }

  &:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position           : absolute;
  top                : 50%;
  left               : 12px; // rtl
  z-index            : 1;
  width              : 14px;
  height             : 14px;
  margin-top         : -7px;
  font-size          : 12px;
  line-height        : 14px;
  -webkit-transition : all .3s;
  transition         : all .3s;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-calendar-picker-clear {
  z-index       : 2;
  color         : rgba(0, 0, 0, 0.25);
  font-size     : 14px;
  background    : #fff;
  cursor        : pointer;
  opacity       : 0;
  pointer-events: none;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity       : 1;
  pointer-events: auto;
}

.ant-calendar-picker-icon {
  display    : inline-block;
  color      : rgba(0, 0, 0, 0.25);
  font-size  : 14px;
  line-height: 1;
}

.ant-calendar-picker-small {

  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    right: 8px;
  }
}

.ant-calendar {
  position          : relative;
  width             : 280px;
  font-size         : 14px;
  line-height       : 1.5;
  text-align        : right;
  list-style        : none;
  background-color  : #fff;
  background-clip   : padding-box;
  border            : 1px solid #fff;
  border-radius     : 4px;
  outline           : none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-calendar-input-wrap {
  height       : 34px;
  padding      : 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}

.ant-calendar-input {
  width     : 100%;
  height    : 22px;
  color     : rgba(0, 0, 0, 0.65);
  background: #fff;
  border    : 0;
  outline   : 0;
  cursor    : auto;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.ant-calendar-week-number {
  width: 286px;
}

.ant-calendar-week-number-cell {
  text-align: center;
}

.ant-calendar-header {
  height             : 40px;
  line-height        : 40px;
  text-align         : center;
  border-bottom      : 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;

  a:hover {
    color: #40a9ff;
  }

  .ant-calendar-century-select,
  .ant-calendar-decade-select,
  .ant-calendar-month-select,
  .ant-calendar-year-select {
    display    : inline-block;
    padding    : 0 2px;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 40px;
  }

  .ant-calendar-century-select-arrow,
  .ant-calendar-decade-select-arrow,
  .ant-calendar-month-select-arrow,
  .ant-calendar-year-select-arrow {
    display: none;
  }

  .ant-calendar-next-century-btn,
  .ant-calendar-next-decade-btn,
  .ant-calendar-next-month-btn,
  .ant-calendar-next-year-btn,
  .ant-calendar-prev-century-btn,
  .ant-calendar-prev-decade-btn,
  .ant-calendar-prev-month-btn,
  .ant-calendar-prev-year-btn {
    position   : absolute;
    top        : 0;
    display    : inline-block;
    padding    : 0 5px;
    color      : rgba(0, 0, 0, 0.45);
    font-size  : 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px;
  }

  .ant-calendar-prev-century-btn,
  .ant-calendar-prev-decade-btn,
  .ant-calendar-prev-year-btn {
    left  : 7px;
    height: 100%;
  }

  .ant-calendar-prev-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-prev-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-prev-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-prev-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-prev-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-prev-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-prev-century-btn:after,
  .ant-calendar-prev-decade-btn:after,
  .ant-calendar-prev-year-btn:after {
    display : none;
    position: relative;
    left    : -3px;
    display : inline-block;
  }

  .ant-calendar-next-century-btn,
  .ant-calendar-next-decade-btn,
  .ant-calendar-next-year-btn {
    right : 7px;
    height: 100%;
  }

  .ant-calendar-next-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-next-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-next-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-next-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-next-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-next-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-next-century-btn:after,
  .ant-calendar-next-decade-btn:after,
  .ant-calendar-next-year-btn:after {
    display: none;
  }

  .ant-calendar-next-century-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-next-decade-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-next-year-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-next-century-btn:before,
  .ant-calendar-next-decade-btn:before,
  .ant-calendar-next-year-btn:before {
    position: relative;
    left    : 3px;
  }

  .ant-calendar-next-century-btn:after,
  .ant-calendar-next-decade-btn:after,
  .ant-calendar-next-year-btn:after {
    display: inline-block;
  }

  .ant-calendar-prev-month-btn {
    left  : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display: none;
    }
  }

  .ant-calendar-next-month-btn {
    right : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display          : none;
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }

    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }
}

.ant-calendar-body {
  padding: 8px 12px;
}

.ant-calendar {
  table {
    width           : 100%;
    max-width       : 100%;
    background-color: rgba(0, 0, 0, 0);
    border-collapse : collapse;
    text-align      : center;
    border          : 0;
  }

  td,
  th {
    text-align: center;
    border    : 0;
  }
}

.ant-calendar-calendar-table {
  margin-bottom : 0;
  border-spacing: 0;
}

.ant-calendar-column-header {
  width      : 33px;
  padding    : 6px 0;
  line-height: 18px;
  text-align : center;

  .ant-calendar-column-header-inner {
    display    : block;
    font-weight: 400;
  }
}

.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}

.ant-calendar-cell {
  height : 30px;
  padding: 3px 0;
}

.ant-calendar-date {
  display           : block;
  width             : 24px;
  height            : 24px;
  margin            : 0 auto;
  padding           : 0;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 22px;
  text-align        : center;
  background        : rgba(0, 0, 0, 0);
  border            : 1px solid rgba(0, 0, 0, 0);
  border-radius     : 2px;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;
}

.ant-calendar-date-panel {
  position: relative;
  outline : none;
}

.ant-calendar-date {
  &:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }

  &:active {
    color     : #fff;
    background: #40a9ff;
  }
}

.ant-calendar-today .ant-calendar-date {
  color       : #1890ff;
  font-weight : 700;
  border-color: #1890ff;
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #bae7ff;
}

.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color       : rgba(0, 0, 0, 0.25);
  background  : rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);

  &:hover {
    color       : rgba(0, 0, 0, 0.25);
    background  : rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }
}

.ant-calendar-disabled-cell {
  .ant-calendar-date {
    position     : relative;
    width        : auto;
    color        : rgba(0, 0, 0, 0.25);
    background   : #f5f5f5;
    border       : 1px solid rgba(0, 0, 0, 0);
    border-radius: 0;
    cursor       : not-allowed;

    &:hover {
      background: #f5f5f5;
    }
  }

  &.ant-calendar-selected-day .ant-calendar-date:before {
    position     : absolute;
    top          : -1px;
    left         : 5px;
    width        : 24px;
    height       : 24px;
    background   : rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    content      : "";
  }

  &.ant-calendar-today .ant-calendar-date {
    position     : relative;
    padding-right: 5px;
    padding-left : 5px;

    &:before {
      position     : absolute;
      top          : -1px;
      left         : 5px;
      width        : 24px;
      height       : 24px;
      border       : 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      content      : " ";
    }
  }
}

.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius   : 4px;
  border-bottom-left-radius: 4px;
}

.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius   : 4px;
  border-bottom-right-radius: 4px;
}

.ant-calendar-footer {
  padding    : 0 12px;
  line-height: 38px;
  border-top : 1px solid #e8e8e8;

  &:empty {
    border-top: 0;
  }
}

.ant-calendar-footer-btn {
  display   : block;
  text-align: center;
}

.ant-calendar-footer-extra {
  text-align: right;
}

.ant-calendar {

  .ant-calendar-clear-btn,
  .ant-calendar-today-btn {
    display   : inline-block;
    margin    : 0 0 0 8px;
    text-align: center;
  }

  .ant-calendar-clear-btn-disabled,
  .ant-calendar-today-btn-disabled {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .ant-calendar-clear-btn:only-child,
  .ant-calendar-today-btn:only-child {
    margin: 0;
  }

  .ant-calendar-clear-btn {
    position   : absolute;
    top        : 7px;
    right      : 5px;
    display    : none;
    width      : 20px;
    height     : 20px;
    margin     : 0;
    overflow   : hidden;
    line-height: 20px;
    text-align : center;
    text-indent: -76px;

    &:after {
      display           : inline-block;
      width             : 20px;
      color             : rgba(0, 0, 0, 0.25);
      font-size         : 14px;
      line-height       : 1;
      text-indent       : 43px;
      -webkit-transition: color .3s ease;
      transition        : color .3s ease;
    }

    &:hover:after {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .ant-calendar-ok-btn {
    position           : relative;
    display            : inline-block;
    font-weight        : 400;
    white-space        : nowrap;
    text-align         : center;
    background-image   : none;
    -webkit-box-shadow : 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow         : 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor             : pointer;
    -webkit-transition : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition         : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
    -ms-touch-action   : manipulation;
    touch-action       : manipulation;
    height             : 32px;
    color              : #fff;
    background-color   : #1890ff;
    border             : 1px solid #1890ff;
    text-shadow        : 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow : 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow         : 0 2px 0 rgba(0, 0, 0, 0.045);
    height             : 24px;
    padding            : 0 7px;
    font-size          : 14px;
    border-radius      : 4px;
    line-height        : 22px;

    >.anticon {
      line-height: 1;
    }

    outline: 0;

    &:active,
    &:focus {
      outline: 0;
    }

    &:not([disabled]) {
      &:hover {
        text-decoration: none;
      }

      &:active {
        outline           : 0;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;
    }

    &.disabled>*,
    &[disabled]>* {
      pointer-events: none;
    }
  }

  .ant-calendar-ok-btn-lg {
    height       : 40px;
    padding      : 0 15px;
    font-size    : 16px;
    border-radius: 4px;
  }

  .ant-calendar-ok-btn-sm {
    height       : 24px;
    padding      : 0 7px;
    font-size    : 14px;
    border-radius: 4px;
  }

  .ant-calendar-ok-btn {
    >a:only-child {
      color: currentColor;

      &:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &:focus,
    &:hover {
      color           : #fff;
      background-color: #40a9ff;
      border-color    : #40a9ff;
    }

    &:focus>a:only-child,
    &:hover>a:only-child {
      color: currentColor;
    }

    &:focus>a:only-child:after,
    &:hover>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }

    &.active,
    &:active {
      color           : #fff;
      background-color: #096dd9;
      border-color    : #096dd9;
    }

    &.active>a:only-child,
    &:active>a:only-child {
      color: currentColor;
    }

    &.active>a:only-child:after,
    &:active>a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  .ant-calendar-ok-btn-disabled {
    color             : rgba(0, 0, 0, 0.25);
    background-color  : #f5f5f5;
    border-color      : #d9d9d9;
    text-shadow       : none;
    -webkit-box-shadow: none;
    box-shadow        : none;

    &.active,
    &:active,
    &:focus,
    &:hover {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  .ant-calendar-ok-btn {
    &.disabled {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }

    &[disabled] {
      color             : rgba(0, 0, 0, 0.25);
      background-color  : #f5f5f5;
      border-color      : #d9d9d9;
      text-shadow       : none;
      -webkit-box-shadow: none;
      box-shadow        : none;

      &.active,
      &:active,
      &:focus,
      &:hover {
        color             : rgba(0, 0, 0, 0.25);
        background-color  : #f5f5f5;
        border-color      : #d9d9d9;
        text-shadow       : none;
        -webkit-box-shadow: none;
        box-shadow        : none;
      }
    }
  }

  .ant-calendar-ok-btn-disabled {

    &.active>a:only-child,
    &:active>a:only-child,
    &:focus>a:only-child,
    &:hover>a:only-child,
    >a:only-child {
      color: currentColor;
    }
  }

  .ant-calendar-ok-btn {
    &.disabled {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }

    &[disabled] {

      &.active>a:only-child,
      &:active>a:only-child,
      &:focus>a:only-child,
      &:hover>a:only-child,
      >a:only-child {
        color: currentColor;
      }
    }
  }

  .ant-calendar-ok-btn-disabled {

    &.active>a:only-child:after,
    &:active>a:only-child:after,
    &:focus>a:only-child:after,
    &:hover>a:only-child:after,
    >a:only-child:after {
      position  : absolute;
      top       : 0;
      right     : 0;
      bottom    : 0;
      left      : 0;
      background: rgba(0, 0, 0, 0);
      content   : "";
    }
  }

  .ant-calendar-ok-btn {
    &.disabled {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }

    &[disabled] {

      &.active>a:only-child:after,
      &:active>a:only-child:after,
      &:focus>a:only-child:after,
      &:hover>a:only-child:after,
      >a:only-child:after {
        position  : absolute;
        top       : 0;
        right     : 0;
        bottom    : 0;
        left      : 0;
        background: rgba(0, 0, 0, 0);
        content   : "";
      }
    }
  }
}

.ant-calendar-range-picker-input {
  width           : 44%;
  height          : 99%;
  text-align      : center;
  background-color: rgba(0, 0, 0, 0);
  border          : 0;
  outline         : 0;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.ant-calendar-range-picker-separator {
  display       : inline-block;
  min-width     : 10px;
  height        : 100%;
  color         : rgba(0, 0, 0, 0.45);
  white-space   : nowrap;
  text-align    : center;
  vertical-align: top;
  pointer-events: none;
}

.ant-calendar-range {
  width   : 552px;
  overflow: hidden;

  .ant-calendar-date-panel:after {
    display   : block;
    clear     : both;
    height    : 0;
    visibility: hidden;
    content   : ".";
  }
}

.ant-calendar-range-part {
  position: relative;
  width   : 50%;
}

.ant-calendar-range-left {
  float: left;

  .ant-calendar-time-picker-inner {
    border-right: 1px solid #e8e8e8;
  }
}

.ant-calendar-range-right {
  float: right;

  .ant-calendar-time-picker-inner {
    border-left: 1px solid #e8e8e8;
  }
}

.ant-calendar-range-middle {
  position         : absolute;
  left             : 50%;
  z-index          : 1;
  height           : 34px;
  margin           : 1px 0 0;
  padding          : 0 200px 0 0;
  color            : rgba(0, 0, 0, 0.45);
  line-height      : 34px;
  text-align       : center;
  -webkit-transform: translateX(-50%);
  -ms-transform    : translateX(-50%);
  transform        : translateX(-50%);
  pointer-events   : none;
}

.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}

.ant-calendar-range {
  &.ant-calendar-time .ant-calendar-range-middle {
    padding          : 0 10px 0 0;
    -webkit-transform: translateX(-50%);
    -ms-transform    : translateX(-50%);
    transform        : translateX(-50%);
  }

  .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
    color       : #1890ff;
    background  : #bae7ff;
    border-color: #1890ff;
  }

  .ant-calendar-selected-end-date .ant-calendar-date,
  .ant-calendar-selected-start-date .ant-calendar-date {
    color     : #fff;
    background: #1890ff;
    border    : 1px solid rgba(0, 0, 0, 0);
  }

  .ant-calendar-selected-end-date .ant-calendar-date:hover,
  .ant-calendar-selected-start-date .ant-calendar-date:hover {
    background: #1890ff;
  }

  &.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0;
  }

  .ant-calendar-input-wrap {
    position: relative;
    height  : 34px;
  }

  .ant-calendar-input,
  .ant-calendar-time-picker-input {
    position          : relative;
    display           : inline-block;
    width             : 100%;
    height            : 32px;
    color             : rgba(0, 0, 0, 0.65);
    font-size         : 14px;
    line-height       : 1.5;
    background-color  : #fff;
    background-image  : none;
    border-radius     : 4px;
    -webkit-transition: all .3s;
    transition        : all .3s;
    height            : 24px;
    padding           : 4px 0;
    line-height       : 24px;
    border            : 0;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .ant-calendar-input::-moz-placeholder,
  .ant-calendar-time-picker-input::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  .ant-calendar-input:-ms-input-placeholder,
  .ant-calendar-time-picker-input:-ms-input-placeholder,
  .ant-calendar-input::-webkit-input-placeholder,
  .ant-calendar-time-picker-input::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  .ant-calendar-input:placeholder-shown,
  .ant-calendar-time-picker-input:placeholder-shown {
    text-overflow: ellipsis;
  }

  .ant-calendar-input:hover,
  .ant-calendar-time-picker-input:hover {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }

  .ant-calendar-input:focus,
  .ant-calendar-time-picker-input:focus {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-calendar-input-disabled,
  .ant-calendar-time-picker-input-disabled {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;
  }

  .ant-calendar-input-disabled:hover,
  .ant-calendar-time-picker-input-disabled:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }

  .ant-calendar-input[disabled],
  .ant-calendar-time-picker-input[disabled] {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;
  }

  .ant-calendar-input[disabled]:hover,
  .ant-calendar-time-picker-input[disabled]:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

textarea.ant-calendar-range {

  .ant-calendar-input,
  .ant-calendar-time-picker-input {
    max-width         : 100%;
    height            : auto;
    min-height        : 32px;
    line-height       : 1.5;
    vertical-align    : bottom;
    -webkit-transition: all .3s, height 0s;
    transition        : all .3s, height 0s;
  }
}

.ant-calendar-range {

  .ant-calendar-input-lg,
  .ant-calendar-time-picker-input-lg {
    height   : 40px;
    padding  : 6px 11px;
    font-size: 16px;
  }

  .ant-calendar-input-sm,
  .ant-calendar-time-picker-input-sm {
    height : 24px;
    padding: 1px 7px;
  }

  .ant-calendar-input:focus,
  .ant-calendar-time-picker-input:focus {
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .ant-calendar-time-picker-icon {
    display: none;
  }

  &.ant-calendar-week-number {
    width: 574px;

    .ant-calendar-range-part {
      width: 286px;
    }
  }

  .ant-calendar-decade-panel,
  .ant-calendar-month-panel,
  .ant-calendar-year-panel {
    top: 34px;
  }

  .ant-calendar-month-panel .ant-calendar-year-panel {
    top: 0;
  }

  .ant-calendar-decade-panel-table,
  .ant-calendar-month-panel-table,
  .ant-calendar-year-panel-table {
    height: 208px;
  }

  .ant-calendar-in-range-cell {
    position     : relative;
    border-radius: 0;

    >div {
      position: relative;
      z-index : 1;
    }

    &:before {
      position     : absolute;
      top          : 4px;
      right        : 0;
      bottom       : 4px;
      left         : 0;
      display      : block;
      background   : #e6f7ff;
      border       : 0;
      border-radius: 0;
      content      : "";
    }
  }

  .ant-calendar-footer-extra {
    float: left;
  }
}

div.ant-calendar-range-quick-selector {
  text-align: right;

  >a {
    margin-right: 8px;
  }
}

.ant-calendar-range {

  .ant-calendar-decade-panel-header,
  .ant-calendar-header,
  .ant-calendar-month-panel-header,
  .ant-calendar-year-panel-header {
    border-bottom: 0;
  }

  .ant-calendar-body,
  .ant-calendar-decade-panel-body,
  .ant-calendar-month-panel-body,
  .ant-calendar-year-panel-body {
    border-top: 1px solid #e8e8e8;
  }

  &.ant-calendar-time {
    .ant-calendar-time-picker {
      top    : 68px;
      z-index: 2;
      width  : 100%;
      height : 207px;
    }

    .ant-calendar-time-picker-panel {
      height    : 267px;
      margin-top: -34px;
    }

    .ant-calendar-time-picker-inner {
      height     : 100%;
      padding-top: 40px;
      background : none;
    }

    .ant-calendar-time-picker-combobox {
      display         : inline-block;
      height          : 100%;
      background-color: #fff;
      border-top      : 1px solid #e8e8e8;
    }

    .ant-calendar-time-picker-select {
      height: 100%;

      ul {
        max-height: 100%;
      }
    }

    .ant-calendar-footer .ant-calendar-time-picker-btn {
      margin-right: 8px;
    }

    .ant-calendar-today-btn {
      height     : 22px;
      margin     : 8px 12px;
      line-height: 22px;
    }
  }
}

.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}

.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: rgba(0, 0, 0, 0);
}

.ant-calendar-time-picker {
  position        : absolute;
  top             : 40px;
  width           : 100%;
  background-color: #fff;
}

.ant-calendar-time-picker-panel {
  position: absolute;
  z-index : 1050;
  width   : 100%;
}

.ant-calendar-time-picker-inner {
  position        : relative;
  display         : inline-block;
  width           : 100%;
  overflow        : hidden;
  font-size       : 14px;
  line-height     : 1.5;
  text-align      : right;
  list-style      : none;
  background-color: #fff;
  background-clip : padding-box;
  outline         : none;
}

.ant-calendar-time-picker-column-1 {
  width: 100%;

  .ant-calendar-time-picker-select {
    width: 100%;
  }
}

.ant-calendar-time-picker-combobox {
  width: 100%;
}

.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}

.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}

.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}

.ant-calendar-time-picker-input-wrap {
  display: none;
}

.ant-calendar-time-picker-select {
  position    : relative;
  float       : left;
  height      : 226px;
  overflow    : hidden;
  font-size   : 14px;
  border-right: 1px solid #e8e8e8;

  &:hover {
    overflow-y: auto;
  }

  &:first-child {
    margin-left: 0;
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  ul {
    width     : 100%;
    max-height: 206px;
    margin    : 0;
    padding   : 0;
    list-style: none;
  }

  li {
    width              : 100%;
    height             : 24px;
    margin             : 0;
    line-height        : 24px;
    text-align         : center;
    list-style         : none;
    cursor             : pointer;
    -webkit-transition : all .3s;
    transition         : all .3s;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;

    &:last-child:after {
      display: block;
      height : 202px;
      content: "";
    }

    &:hover {
      background: #e6f7ff;
    }

    &:focus {
      color      : #1890ff;
      font-weight: 600;
      outline    : none;
    }
  }
}

li {
  &.ant-calendar-time-picker-select-option-selected {
    font-weight: 600;
    background : #f5f5f5;
  }

  &.ant-calendar-time-picker-select-option-disabled {
    color: rgba(0, 0, 0, 0.25);

    &:hover {
      background: rgba(0, 0, 0, 0);
      cursor    : not-allowed;
    }
  }
}

.ant-calendar-time {
  .ant-calendar-day-select {
    display    : inline-block;
    padding    : 0 2px;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 34px;
  }

  .ant-calendar-footer {
    position: relative;
    height  : auto;
  }

  .ant-calendar-footer-btn {
    text-align: right;
  }

  .ant-calendar-footer {
    .ant-calendar-today-btn {
      float : left;
      margin: 0;
    }

    .ant-calendar-time-picker-btn {
      display     : inline-block;
      margin-right: 8px;
    }

    .ant-calendar-time-picker-btn-disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.ant-calendar-month-panel {
  position     : absolute;
  top          : 0;
  right        : 0;
  bottom       : 0;
  left         : 0;
  z-index      : 10;
  background   : #fff;
  border-radius: 4px;
  outline      : none;

  >div {
    display              : -webkit-box;
    display              : -ms-flexbox;
    display              : flex;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;
    height               : 100%;
  }
}

.ant-calendar-month-panel-hidden {
  display: none;
}

.ant-calendar-month-panel-header {
  height             : 40px;
  line-height        : 40px;
  text-align         : center;
  border-bottom      : 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  position           : relative;

  a:hover {
    color: #40a9ff;
  }

  .ant-calendar-month-panel-century-select,
  .ant-calendar-month-panel-decade-select,
  .ant-calendar-month-panel-month-select,
  .ant-calendar-month-panel-year-select {
    display    : inline-block;
    padding    : 0 2px;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 40px;
  }

  .ant-calendar-month-panel-century-select-arrow,
  .ant-calendar-month-panel-decade-select-arrow,
  .ant-calendar-month-panel-month-select-arrow,
  .ant-calendar-month-panel-year-select-arrow {
    display: none;
  }

  .ant-calendar-month-panel-next-century-btn,
  .ant-calendar-month-panel-next-decade-btn,
  .ant-calendar-month-panel-next-month-btn,
  .ant-calendar-month-panel-next-year-btn,
  .ant-calendar-month-panel-prev-century-btn,
  .ant-calendar-month-panel-prev-decade-btn,
  .ant-calendar-month-panel-prev-month-btn,
  .ant-calendar-month-panel-prev-year-btn {
    position   : absolute;
    top        : 0;
    display    : inline-block;
    padding    : 0 5px;
    color      : rgba(0, 0, 0, 0.45);
    font-size  : 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px;
  }

  .ant-calendar-month-panel-prev-century-btn,
  .ant-calendar-month-panel-prev-decade-btn,
  .ant-calendar-month-panel-prev-year-btn {
    left  : 7px;
    height: 100%;
  }

  .ant-calendar-month-panel-prev-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-prev-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-prev-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-prev-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-prev-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-prev-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-prev-century-btn:after,
  .ant-calendar-month-panel-prev-decade-btn:after,
  .ant-calendar-month-panel-prev-year-btn:after {
    display : none;
    position: relative;
    left    : -3px;
    display : inline-block;
  }

  .ant-calendar-month-panel-next-century-btn,
  .ant-calendar-month-panel-next-decade-btn,
  .ant-calendar-month-panel-next-year-btn {
    right : 7px;
    height: 100%;
  }

  .ant-calendar-month-panel-next-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-next-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-next-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-month-panel-next-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-next-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-next-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-month-panel-next-century-btn:after,
  .ant-calendar-month-panel-next-decade-btn:after,
  .ant-calendar-month-panel-next-year-btn:after {
    display: none;
  }

  .ant-calendar-month-panel-next-century-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-month-panel-next-decade-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-month-panel-next-year-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-month-panel-next-century-btn:before,
  .ant-calendar-month-panel-next-decade-btn:before,
  .ant-calendar-month-panel-next-year-btn:before {
    position: relative;
    left    : 3px;
  }

  .ant-calendar-month-panel-next-century-btn:after,
  .ant-calendar-month-panel-next-decade-btn:after,
  .ant-calendar-month-panel-next-year-btn:after {
    display: inline-block;
  }

  .ant-calendar-month-panel-prev-month-btn {
    left  : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display: none;
    }
  }

  .ant-calendar-month-panel-next-month-btn {
    right : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display          : none;
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }

    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }
}

.ant-calendar-month-panel-body {
  -webkit-box-flex: 1;
  -ms-flex        : 1;
  flex            : 1;
}

.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;

  .ant-calendar-footer-extra {
    padding: 0 12px;
  }
}

.ant-calendar-month-panel-table {
  width          : 100%;
  height         : 100%;
  table-layout   : fixed;
  border-collapse: separate;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color     : #fff;
  background: #1890ff;

  &:hover {
    color     : #fff;
    background: #1890ff;
  }
}

.ant-calendar-month-panel-cell {
  text-align: center;
}

.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month {
  color     : rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor    : not-allowed;

  &:hover {
    color     : rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor    : not-allowed;
  }
}

.ant-calendar-month-panel-month {
  display           : inline-block;
  height            : 24px;
  margin            : 0 auto;
  padding           : 0 8px;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 24px;
  text-align        : center;
  background        : rgba(0, 0, 0, 0);
  border-radius     : 2px;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }
}

.ant-calendar-year-panel {
  position     : absolute;
  top          : 0;
  right        : 0;
  bottom       : 0;
  left         : 0;
  z-index      : 10;
  background   : #fff;
  border-radius: 4px;
  outline      : none;

  >div {
    display              : -webkit-box;
    display              : -ms-flexbox;
    display              : flex;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;
    height               : 100%;
  }
}

.ant-calendar-year-panel-hidden {
  display: none;
}

.ant-calendar-year-panel-header {
  height             : 40px;
  line-height        : 40px;
  text-align         : center;
  border-bottom      : 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  position           : relative;

  a:hover {
    color: #40a9ff;
  }

  .ant-calendar-year-panel-century-select,
  .ant-calendar-year-panel-decade-select,
  .ant-calendar-year-panel-month-select,
  .ant-calendar-year-panel-year-select {
    display    : inline-block;
    padding    : 0 2px;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 40px;
  }

  .ant-calendar-year-panel-century-select-arrow,
  .ant-calendar-year-panel-decade-select-arrow,
  .ant-calendar-year-panel-month-select-arrow,
  .ant-calendar-year-panel-year-select-arrow {
    display: none;
  }

  .ant-calendar-year-panel-next-century-btn,
  .ant-calendar-year-panel-next-decade-btn,
  .ant-calendar-year-panel-next-month-btn,
  .ant-calendar-year-panel-next-year-btn,
  .ant-calendar-year-panel-prev-century-btn,
  .ant-calendar-year-panel-prev-decade-btn,
  .ant-calendar-year-panel-prev-month-btn,
  .ant-calendar-year-panel-prev-year-btn {
    position   : absolute;
    top        : 0;
    display    : inline-block;
    padding    : 0 5px;
    color      : rgba(0, 0, 0, 0.45);
    font-size  : 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px;
  }

  .ant-calendar-year-panel-prev-century-btn,
  .ant-calendar-year-panel-prev-decade-btn,
  .ant-calendar-year-panel-prev-year-btn {
    left  : 7px;
    height: 100%;
  }

  .ant-calendar-year-panel-prev-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-prev-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-prev-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-prev-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-prev-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-prev-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-prev-century-btn:after,
  .ant-calendar-year-panel-prev-decade-btn:after,
  .ant-calendar-year-panel-prev-year-btn:after {
    display : none;
    position: relative;
    left    : -3px;
    display : inline-block;
  }

  .ant-calendar-year-panel-next-century-btn,
  .ant-calendar-year-panel-next-decade-btn,
  .ant-calendar-year-panel-next-year-btn {
    right : 7px;
    height: 100%;
  }

  .ant-calendar-year-panel-next-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-next-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-next-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-year-panel-next-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-next-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-next-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-year-panel-next-century-btn:after,
  .ant-calendar-year-panel-next-decade-btn:after,
  .ant-calendar-year-panel-next-year-btn:after {
    display: none;
  }

  .ant-calendar-year-panel-next-century-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-year-panel-next-decade-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-year-panel-next-year-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-year-panel-next-century-btn:before,
  .ant-calendar-year-panel-next-decade-btn:before,
  .ant-calendar-year-panel-next-year-btn:before {
    position: relative;
    left    : 3px;
  }

  .ant-calendar-year-panel-next-century-btn:after,
  .ant-calendar-year-panel-next-decade-btn:after,
  .ant-calendar-year-panel-next-year-btn:after {
    display: inline-block;
  }

  .ant-calendar-year-panel-prev-month-btn {
    left  : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display: none;
    }
  }

  .ant-calendar-year-panel-next-month-btn {
    right : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display          : none;
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }

    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }
}

.ant-calendar-year-panel-body {
  -webkit-box-flex: 1;
  -ms-flex        : 1;
  flex            : 1;
}

.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;

  .ant-calendar-footer-extra {
    padding: 0 12px;
  }
}

.ant-calendar-year-panel-table {
  width          : 100%;
  height         : 100%;
  table-layout   : fixed;
  border-collapse: separate;
}

.ant-calendar-year-panel-cell {
  text-align: center;
}

.ant-calendar-year-panel-year {
  display           : inline-block;
  height            : 24px;
  margin            : 0 auto;
  padding           : 0 8px;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 24px;
  text-align        : center;
  background        : rgba(0, 0, 0, 0);
  border-radius     : 2px;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color     : #fff;
  background: #1890ff;

  &:hover {
    color     : #fff;
    background: #1890ff;
  }
}

.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color              : rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-calendar-decade-panel {
  position             : absolute;
  top                  : 0;
  right                : 0;
  bottom               : 0;
  left                 : 0;
  z-index              : 10;
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;
  -webkit-box-orient   : vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction   : column;
  flex-direction       : column;
  background           : #fff;
  border-radius        : 4px;
  outline              : none;
}

.ant-calendar-decade-panel-hidden {
  display: none;
}

.ant-calendar-decade-panel-header {
  height             : 40px;
  line-height        : 40px;
  text-align         : center;
  border-bottom      : 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  position           : relative;

  a:hover {
    color: #40a9ff;
  }

  .ant-calendar-decade-panel-century-select,
  .ant-calendar-decade-panel-decade-select,
  .ant-calendar-decade-panel-month-select,
  .ant-calendar-decade-panel-year-select {
    display    : inline-block;
    padding    : 0 2px;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    line-height: 40px;
  }

  .ant-calendar-decade-panel-century-select-arrow,
  .ant-calendar-decade-panel-decade-select-arrow,
  .ant-calendar-decade-panel-month-select-arrow,
  .ant-calendar-decade-panel-year-select-arrow {
    display: none;
  }

  .ant-calendar-decade-panel-next-century-btn,
  .ant-calendar-decade-panel-next-decade-btn,
  .ant-calendar-decade-panel-next-month-btn,
  .ant-calendar-decade-panel-next-year-btn,
  .ant-calendar-decade-panel-prev-century-btn,
  .ant-calendar-decade-panel-prev-decade-btn,
  .ant-calendar-decade-panel-prev-month-btn,
  .ant-calendar-decade-panel-prev-year-btn {
    position   : absolute;
    top        : 0;
    display    : inline-block;
    padding    : 0 5px;
    color      : rgba(0, 0, 0, 0.45);
    font-size  : 16px;
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    line-height: 40px;
  }

  .ant-calendar-decade-panel-prev-century-btn,
  .ant-calendar-decade-panel-prev-decade-btn,
  .ant-calendar-decade-panel-prev-year-btn {
    left  : 7px;
    height: 100%;
  }

  .ant-calendar-decade-panel-prev-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-prev-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-prev-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-prev-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-prev-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-prev-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-prev-century-btn:after,
  .ant-calendar-decade-panel-prev-decade-btn:after,
  .ant-calendar-decade-panel-prev-year-btn:after {
    display : none;
    position: relative;
    left    : -3px;
    display : inline-block;
  }

  .ant-calendar-decade-panel-next-century-btn,
  .ant-calendar-decade-panel-next-decade-btn,
  .ant-calendar-decade-panel-next-year-btn {
    right : 7px;
    height: 100%;
  }

  .ant-calendar-decade-panel-next-century-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-next-decade-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-next-year-btn {

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }
  }

  .ant-calendar-decade-panel-next-century-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-next-decade-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-next-year-btn:hover {

    &:after,
    &:before {
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-decade-panel-next-century-btn:after,
  .ant-calendar-decade-panel-next-decade-btn:after,
  .ant-calendar-decade-panel-next-year-btn:after {
    display: none;
  }

  .ant-calendar-decade-panel-next-century-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-decade-panel-next-decade-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-decade-panel-next-year-btn {

    &:after,
    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }

  .ant-calendar-decade-panel-next-century-btn:before,
  .ant-calendar-decade-panel-next-decade-btn:before,
  .ant-calendar-decade-panel-next-year-btn:before {
    position: relative;
    left    : 3px;
  }

  .ant-calendar-decade-panel-next-century-btn:after,
  .ant-calendar-decade-panel-next-decade-btn:after,
  .ant-calendar-decade-panel-next-year-btn:after {
    display: inline-block;
  }

  .ant-calendar-decade-panel-prev-month-btn {
    left  : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display: none;
    }
  }

  .ant-calendar-decade-panel-next-month-btn {
    right : 29px;
    height: 100%;

    &:after,
    &:before {
      position          : relative;
      top               : -1px;
      display           : inline-block;
      width             : 8px;
      height            : 8px;
      vertical-align    : middle;
      border            : 0 solid #aaa;
      border-width      : 1.5px 0 0 1.5px;
      border-radius     : 1px;
      -webkit-transform : rotate(-45deg) scale(0.8);
      -ms-transform     : rotate(-45deg) scale(0.8);
      transform         : rotate(-45deg) scale(0.8);
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    &:hover {

      &:after,
      &:before {
        border-color: rgba(0, 0, 0, 0.65);
      }
    }

    &:after {
      display          : none;
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }

    &:before {
      -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform    : rotate(135deg) scale(0.8);
      transform        : rotate(135deg) scale(0.8);
    }
  }
}

.ant-calendar-decade-panel-body {
  -webkit-box-flex: 1;
  -ms-flex        : 1;
  flex            : 1;
}

.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;

  .ant-calendar-footer-extra {
    padding: 0 12px;
  }
}

.ant-calendar-decade-panel-table {
  width          : 100%;
  height         : 100%;
  table-layout   : fixed;
  border-collapse: separate;
}

.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align : center;
}

.ant-calendar-decade-panel-decade {
  display           : inline-block;
  height            : 24px;
  margin            : 0 auto;
  padding           : 0 6px;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 24px;
  text-align        : center;
  background        : rgba(0, 0, 0, 0);
  border-radius     : 2px;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover {
    background: #e6f7ff;
    cursor    : pointer;
  }
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color     : #fff;
  background: #1890ff;

  &:hover {
    color     : #fff;
    background: #1890ff;
  }
}

.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color              : rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-calendar-month {
  .ant-calendar-month-header-wrap {
    position: relative;
    height  : 288px;
  }

  .ant-calendar-month-panel,
  .ant-calendar-year-panel {
    top   : 0;
    height: 100%;
  }
}

.ant-calendar-week-number-cell {
  opacity: .5;
}

.ant-calendar-week-number .ant-calendar-body tr {
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:hover {
    background: #e6f7ff;
  }

  &.ant-calendar-active-week {
    font-weight: 700;
    background : #bae7ff;
  }

  .ant-calendar-selected-day {

    .ant-calendar-date,
    &:hover .ant-calendar-date {
      color     : rgba(0, 0, 0, 0.65);
      background: rgba(0, 0, 0, 0);
    }
  }
}

.ant-descriptions-title {
  margin-bottom: 20px;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 700;
  font-size    : 16px;
  line-height  : 1.5;
}

.ant-descriptions-view {
  width        : 100%;
  overflow     : hidden;
  border-radius: 4px;

  table {
    width       : 100%;
    table-layout: fixed;
  }
}

.ant-descriptions-row {
  > {

    td,
    th {
      padding-bottom: 16px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.ant-descriptions-item-label {
  color      : rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size  : 14px;
  line-height: 1.5;
  white-space: nowrap;

  &:after {
    position: relative;
    top     : -.5px;
    margin  : 0 8px 0 2px;
    content : " ";
  }
}

.ant-descriptions-item-colon:after {
  content: ":";
}

.ant-descriptions-item-no-label:after {
  margin : 0;
  content: "";
}

.ant-descriptions-item-content {
  display    : table-cell;
  color      : rgba(0, 0, 0, 0.65);
  font-size  : 14px;
  line-height: 1.5;
}

.ant-descriptions-item {
  padding-bottom: 0;

  >span {
    display: inline-block;
  }
}

.ant-descriptions-middle .ant-descriptions-row> {

  td,
  th {
    padding-bottom: 12px;
  }
}

.ant-descriptions-small .ant-descriptions-row> {

  td,
  th {
    padding-bottom: 8px;
  }
}

.ant-descriptions-bordered {
  .ant-descriptions-view {
    border: 1px solid #e8e8e8;

    >table {
      table-layout: auto;
    }
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    padding    : 16px 24px;
    border-left: 1px solid #e8e8e8; // rtl
  }

  .ant-descriptions-item-content:last-child {
    border-left: none; // rtl
  }

  .ant-descriptions-item-label {
    &:last-child {
      border-left: none; // rtl
    }

    background-color: #fafafa;

    &:after {
      display: none;
    }
  }

  .ant-descriptions-row {
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }

  &.ant-descriptions-middle {

    .ant-descriptions-item-content,
    .ant-descriptions-item-label {
      padding: 12px 24px;
    }
  }

  &.ant-descriptions-small {

    .ant-descriptions-item-content,
    .ant-descriptions-item-label {
      padding: 8px 16px;
    }
  }
}

.ant-divider {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  background                   : #e8e8e8;
  position                     : relative;
  top                          : -.06em;
  display                      : inline-block;
  width                        : 1px;
  height                       : .9em;
  margin                       : 0 8px;
  vertical-align               : middle;
}

.ant-divider-vertical {
  position      : relative;
  top           : -.06em;
  display       : inline-block;
  width         : 1px;
  height        : .9em;
  margin        : 0 8px;
  vertical-align: middle;
}

.ant-divider-horizontal {
  display  : block;
  clear    : both;
  width    : 100%;
  min-width: 100%;
  height   : 1px;
  margin   : 24px 0;

  &.ant-divider-with-text-center,
  &.ant-divider-with-text-left,
  &.ant-divider-with-text-right {
    display    : table;
    margin     : 16px 0;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size  : 16px;
    white-space: nowrap;
    text-align : center;
    background : rgba(0, 0, 0, 0);
  }

  &.ant-divider-with-text-center {

    &:after,
    &:before {
      position         : relative;
      top              : 50%;
      display          : table-cell;
      width            : 50%;
      border-top       : 1px solid #e8e8e8;
      -webkit-transform: translateY(50%);
      -ms-transform    : translateY(50%);
      transform        : translateY(50%);
      content          : "";
    }
  }

  &.ant-divider-with-text-left {

    &:after,
    &:before {
      position         : relative;
      top              : 50%;
      display          : table-cell;
      width            : 50%;
      border-top       : 1px solid #e8e8e8;
      -webkit-transform: translateY(50%);
      -ms-transform    : translateY(50%);
      transform        : translateY(50%);
      content          : "";
    }
  }

  &.ant-divider-with-text-right {

    &:after,
    &:before {
      position         : relative;
      top              : 50%;
      display          : table-cell;
      width            : 50%;
      border-top       : 1px solid #e8e8e8;
      -webkit-transform: translateY(50%);
      -ms-transform    : translateY(50%);
      transform        : translateY(50%);
      content          : "";
    }
  }

  &.ant-divider-with-text-left .ant-divider-inner-text,
  &.ant-divider-with-text-right .ant-divider-inner-text {
    display: inline-block;
    padding: 0 10px;
  }

  &.ant-divider-with-text-left {
    &:before {
      top  : 50%;
      width: 5%;
    }

    &:after {
      top  : 50%;
      width: 95%;
    }
  }

  &.ant-divider-with-text-right {
    &:before {
      top  : 50%;
      width: 95%;
    }

    &:after {
      top  : 50%;
      width: 5%;
    }
  }
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}

.ant-divider-dashed {
  background  : none;
  border      : dashed #e8e8e8;
  border-width: 1px 0 0;
}

.ant-divider-horizontal {

  &.ant-divider-with-text-center.ant-divider-dashed,
  &.ant-divider-with-text-left.ant-divider-dashed,
  &.ant-divider-with-text-right.ant-divider-dashed {
    border-top: 0;
  }

  &.ant-divider-with-text-center.ant-divider-dashed {

    &:after,
    &:before {
      border-style: dashed none none;
    }
  }

  &.ant-divider-with-text-left.ant-divider-dashed {

    &:after,
    &:before {
      border-style: dashed none none;
    }
  }

  &.ant-divider-with-text-right.ant-divider-dashed {

    &:after,
    &:before {
      border-style: dashed none none;
    }
  }
}

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

.ant-drawer {
  position          : fixed;
  z-index           : 1000;
  width             : 0;
  height            : 100%;
  -webkit-transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

  >* {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition        : -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }
}

.ant-drawer-content-wrapper {
  position: absolute;
}

.ant-drawer .ant-drawer-content {
  width : 100%;
  height: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
  top   : 0;
  width : 0;
  height: 100%;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width             : 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 2px 0 8px rgba(0, 0, 0, 0.15);
}

.ant-drawer-right {
  right: 0;

  .ant-drawer-content-wrapper {
    right: 0;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      -webkit-box-shadow: (-2px) 0 8px rgba(0, 0, 0, 0.15);
      box-shadow        : (-2px) 0 8px rgba(0, 0, 0, 0.15);
    }

    &.no-mask {
      right            : 1px;
      -webkit-transform: translateX(1px);
      -ms-transform    : translateX(1px);
      transform        : translateX(1px);
    }
  }
}

.ant-drawer-bottom,
.ant-drawer-top {
  left  : 0;
  width : 100%;
  height: 0%;
}

.ant-drawer-bottom .ant-drawer-content-wrapper,
.ant-drawer-top .ant-drawer-content-wrapper {
  width: 100%;
}

.ant-drawer-bottom.ant-drawer-open,
.ant-drawer-top.ant-drawer-open {
  height            : 100%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition        : transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}

.ant-drawer-top {
  &.ant-drawer-open {
    &.no-mask {
      height: 0%;
    }

    .ant-drawer-content-wrapper {
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }

  top: 0;
}

.ant-drawer-bottom {
  bottom: 0;

  .ant-drawer-content-wrapper {
    bottom: 0;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      -webkit-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
      box-shadow        : 0 -2px 8px rgba(0, 0, 0, 0.15);
    }

    &.no-mask {
      bottom           : 1px;
      -webkit-transform: translateY(1px);
      -ms-transform    : translateY(1px);
      transform        : translateY(1px);
    }
  }
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height            : 100%;
  opacity           : 1;
  -webkit-transition: none;
  transition        : none;
  -webkit-animation : antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation         : antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-title {
  margin     : 0;
  color      : rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size  : 16px;
  line-height: 22px;
}

.ant-drawer-content {
  position        : relative;
  z-index         : 1;
  overflow        : auto;
  background-color: #fff;
  background-clip : padding-box;
  border          : 0;
}

.ant-drawer-close {
  position          : absolute;
  top               : 0;
  right             : 0;
  z-index           : 10;
  display           : block;
  width             : 56px;
  height            : 56px;
  padding           : 0;
  color             : rgba(0, 0, 0, 0.45);
  font-weight       : 700;
  font-size         : 16px;
  font-style        : normal;
  line-height       : 56px;
  text-align        : center;
  text-transform    : none;
  text-decoration   : none;
  background        : rgba(0, 0, 0, 0);
  border            : 0;
  outline           : 0;
  cursor            : pointer;
  -webkit-transition: color .3s;
  transition        : color .3s;
  text-rendering    : auto;

  &:focus,
  &:hover {
    color          : rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
}

.ant-drawer-header {
  position     : relative;
  padding      : 16px 24px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  color        : rgba(0, 0, 0, 0.65);
  background   : #fff;
}

.ant-drawer-header-no-title {
  color     : rgba(0, 0, 0, 0.65);
  background: #fff;
}

.ant-drawer-body {
  padding    : 24px;
  font-size  : 14px;
  line-height: 1.5;
  word-wrap  : break-word;
}

.ant-drawer-wrapper-body {
  height  : 100%;
  overflow: auto;
}

.ant-drawer-mask {
  position          : absolute;
  top               : 0;
  left              : 0;
  width             : 100%;
  height            : 0;
  background-color  : rgba(0, 0, 0, 0.45);
  opacity           : 0;
  filter            : alpha(opacity=45);
  -webkit-transition: opacity .3s linear, height 0s ease .3s;
  transition        : opacity .3s linear, height 0s ease .3s;
}

.ant-drawer-open-content {
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 4px 12px rgba(0, 0, 0, 0.15);
}

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ant-dropdown {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  top                          : -9999px;
  left                         : -9999px;
  z-index                      : 1050;
  display                      : block;

  &:before {
    position: absolute;
    top     : -7px;
    right   : 0;
    bottom  : -7px;
    left    : -7px;
    z-index : -9999;
    opacity : .0001;
    content : " ";
  }
}

.ant-dropdown-wrap {
  position: relative;

  .ant-btn>.anticon-down {
    display          : inline-block;
    font-size        : 12px;
    font-size        : 10px\9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform    : scale(0.83333333) rotate(0deg);
    transform        : scale(0.83333333) rotate(0deg);
  }
}

:root .ant-dropdown-wrap .ant-btn>.anticon-down {
  font-size: 12px;
}

.ant-dropdown-wrap .anticon-down:before {
  -webkit-transition: -webkit-transform .2s;
  transition        : -webkit-transform .2s;
  transition        : transform .2s;
  transition        : transform .2s, -webkit-transform .2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
  -webkit-transform: rotate(180deg);
  -ms-transform    : rotate(180deg);
  transform        : rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}

.ant-dropdown-menu {
  position          : relative;
  margin            : 0;
  padding           : 4px 0;
  text-align        : right;
  list-style-type   : none;
  background-color  : #fff;
  background-clip   : padding-box;
  border-radius     : 4px;
  outline           : none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform : translateZ(0);
  transform         : translateZ(0);
}

.ant-dropdown-menu-item-group-title {
  padding           : 5px 12px;
  color             : rgba(0, 0, 0, 0.45);
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index : 1050;

  >.ant-dropdown-menu {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin    : 0 0;
    transform-origin        : 0 0;
  }

  li {
    list-style: none;
  }

  ul {
    list-style  : none;
    margin-right: .3em;
    margin-left : .3em;
    padding     : 0;
  }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear             : both;
  margin            : 0;
  padding           : 5px 12px;
  color             : rgba(0, 0, 0, 0.65);
  font-weight       : 400;
  font-size         : 14px;
  line-height       : 22px;
  white-space       : nowrap;
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-dropdown-menu-item> {

  .anticon:first-child,
  span>.anticon:first-child {
    min-width   : 12px;
    margin-right: 8px;
    font-size   : 12px;
  }
}

.ant-dropdown-menu-submenu-title> {

  .anticon:first-child,
  span>.anticon:first-child {
    min-width   : 12px;
    margin-right: 8px;
    font-size   : 12px;
  }
}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
  display           : block;
  margin            : -5px -12px;
  padding           : 5px 12px;
  color             : rgba(0, 0, 0, 0.65);
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color           : #1890ff;
  background-color: #e6f7ff;

  >a {
    color           : #1890ff;
    background-color: #e6f7ff;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor          : not-allowed;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height          : 1px;
  margin          : 4px 0;
  overflow        : hidden;
  line-height     : 0;
  background-color: #e8e8e8;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right   : 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color            : rgba(0, 0, 0, 0.45);
  font-style       : normal;
  display          : inline-block;
  font-size        : 12px;
  font-size        : 10px\9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
  -ms-transform    : scale(0.83333333) rotate(0deg);
  transform        : scale(0.83333333) rotate(0deg);
}

:root {

  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    font-size: 12px;
  }
}

.ant-dropdown-menu-item-group-list {
  margin    : 0 8px;
  padding   : 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;

  >.ant-dropdown-menu {
    position                : absolute;
    top                     : 0;
    left                    : 100%;
    min-width               : 100%;
    margin-left             : 4px;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin    : 0 0;
    transform-origin        : 0 0;
  }
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor          : not-allowed;

  .ant-dropdown-menu-submenu-arrow-icon {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor          : not-allowed;
  }
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}

.ant-dropdown {
  &.slide-down-appear.slide-down-appear-active {

    &.ant-dropdown-placement-bottomCenter,
    &.ant-dropdown-placement-bottomLeft,
    &.ant-dropdown-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-down-enter.slide-down-enter-active {

    &.ant-dropdown-placement-bottomCenter,
    &.ant-dropdown-placement-bottomLeft,
    &.ant-dropdown-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-up-appear.slide-up-appear-active {

    &.ant-dropdown-placement-topCenter,
    &.ant-dropdown-placement-topLeft,
    &.ant-dropdown-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-up-enter.slide-up-enter-active {

    &.ant-dropdown-placement-topCenter,
    &.ant-dropdown-placement-topLeft,
    &.ant-dropdown-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-down-leave.slide-down-leave-active {

    &.ant-dropdown-placement-bottomCenter,
    &.ant-dropdown-placement-bottomLeft,
    &.ant-dropdown-placement-bottomRight {
      -webkit-animation-name: antSlideUpOut;
      animation-name        : antSlideUpOut;
    }
  }

  &.slide-up-leave.slide-up-leave-active {

    &.ant-dropdown-placement-topCenter,
    &.ant-dropdown-placement-topLeft,
    &.ant-dropdown-placement-topRight {
      -webkit-animation-name: antSlideDownOut;
      animation-name        : antSlideDownOut;
    }
  }
}

.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-trigger>.anticon.anticon-down {
  display          : inline-block;
  font-size        : 12px;
  font-size        : 10px\9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
  -ms-transform    : scale(0.83333333) rotate(0deg);
  transform        : scale(0.83333333) rotate(0deg);
}

:root {

  .ant-dropdown-link>.anticon.anticon-down,
  .ant-dropdown-trigger>.anticon.anticon-down {
    font-size: 12px;
  }
}

.ant-dropdown-button {
  white-space: nowrap;

  &.ant-btn-group>.ant-btn:last-child:not(:first-child) {
    padding-right: 8px;
    padding-left : 8px;
  }

  .anticon.anticon-down {
    display          : inline-block;
    font-size        : 12px;
    font-size        : 10px\9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    -ms-transform    : scale(0.83333333) rotate(0deg);
    transform        : scale(0.83333333) rotate(0deg);
  }
}

:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}

.ant-dropdown-menu-dark {
  background: #001529;

  .ant-dropdown-menu {
    background: #001529;
  }

  .ant-dropdown-menu-item {
    color: hsla(0, 0%, 100%, 0.65);

    .ant-dropdown-menu-submenu-arrow:after {
      color: hsla(0, 0%, 100%, 0.65);
    }

    >a {
      color: hsla(0, 0%, 100%, 0.65);

      .ant-dropdown-menu-submenu-arrow:after {
        color: hsla(0, 0%, 100%, 0.65);
      }
    }
  }

  .ant-dropdown-menu-submenu-title {
    color: hsla(0, 0%, 100%, 0.65);

    .ant-dropdown-menu-submenu-arrow:after {
      color: hsla(0, 0%, 100%, 0.65);
    }
  }

  .ant-dropdown-menu-item {

    &:hover,
    >a:hover {
      color     : #fff;
      background: rgba(0, 0, 0, 0);
    }
  }

  .ant-dropdown-menu-submenu-title:hover {
    color     : #fff;
    background: rgba(0, 0, 0, 0);
  }

  .ant-dropdown-menu-item-selected {
    color     : #fff;
    background: #1890ff;

    &:hover,
    >a {
      color     : #fff;
      background: #1890ff;
    }
  }
}

.ant-empty {
  margin     : 0 8px;
  font-size  : 14px;
  line-height: 22px;
  text-align : center;
}

.ant-empty-image {
  height       : 100px;
  margin-bottom: 8px;

  img {
    height: 100%;
  }

  svg {
    height: 100%;
    margin: auto;
  }
}

.ant-empty-description {
  margin: 0;
}

.ant-empty-footer {
  margin-top: 16px;
}

.ant-empty-normal {
  margin: 32px 0;
  color : rgba(0, 0, 0, 0.25);

  .ant-empty-image {
    height: 40px;
  }
}

.ant-empty-small {
  margin: 8px 0;
  color : rgba(0, 0, 0, 0.25);

  .ant-empty-image {
    height: 35px;
  }
}

.ant-form {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";

  legend {
    display      : block;
    width        : 100%;
    margin-bottom: 20px;
    padding      : 0;
    color        : rgba(0, 0, 0, 0.45);
    font-size    : 16px;
    line-height  : inherit;
    border       : 0;
    border-bottom: 1px solid #d9d9d9;
  }

  label {
    font-size: 14px;
  }

  input {
    &[type=search] {
      -webkit-box-sizing: border-box;
      box-sizing        : border-box;
    }

    &[type=checkbox],
    &[type=radio] {
      line-height: normal;
    }

    &[type=file] {
      display: block;
    }

    &[type=range] {
      display: block;
      width  : 100%;
    }
  }

  select {

    &[multiple],
    &[size] {
      height: auto;
    }
  }

  input {

    &[type=checkbox]:focus,
    &[type=file]:focus,
    &[type=radio]:focus {
      outline       : thin dotted;
      outline       : 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  output {
    display    : block;
    padding-top: 15px;
    color      : rgba(0, 0, 0, 0.65);
    font-size  : 14px;
    line-height: 1.5;
  }
}

.ant-form-item-required:before {
  display     : inline-block;
  margin-right: 4px;
  color       : #f5222d;
  font-size   : 14px;
  font-family : SimSun, sans-serif;
  line-height : 1;
  content     : "*";
}

.ant-form-hide-required-mark .ant-form-item-required:before {
  display: none;
}

.ant-form-item-label>label {
  color: rgba(0, 0, 0, 0.85);

  &:after {
    content : ":";
    position: relative;
    top     : -.5px;
    margin  : 0 8px 0 2px;
  }

  &.ant-form-item-no-colon:after {
    content: " ";
  }
}

.ant-form-item {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : 0 0 24px;
  vertical-align               : top;

  label {
    position: relative;

    >.anticon {
      font-size     : 14px;
      vertical-align: top;
    }
  }
}

.ant-form-item-control {
  position   : relative;
  line-height: 40px;
  zoom       : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-form-item-children {
  position: relative;
}

.ant-form-item-with-help {
  margin-bottom: 5px;
}

.ant-form-item-label {
  display       : inline-block;
  overflow      : hidden;
  line-height   : 39.9999px;
  white-space   : nowrap;
  text-align    : right;
  vertical-align: middle;
}

.ant-form-item-label-left {
  text-align: right;
}

.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}

.ant-form-explain,
.ant-form-extra {
  clear             : both;
  min-height        : 22px;
  margin-top        : -2px;
  color             : rgba(0, 0, 0, 0.45);
  font-size         : 14px;
  line-height       : 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition        : color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-form-explain {
  margin-bottom: -1px;
}

.ant-form-extra {
  padding-top: 4px;
}

.ant-form-text {
  display      : inline-block;
  padding-right: 8px;
}

.ant-form-split {
  display   : block;
  text-align: center;
}

form {
  .has-feedback {
    .ant-input {
      padding-right: 30px;
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        padding-right: 18px;
      }

      .ant-input {
        padding-right: 49px;
      }

      &.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
        padding-right: 68px;
      }
    }

    :not(.ant-input-group-addon) {
      >.ant-select {

        .ant-select-arrow,
        .ant-select-selection__clear {
          right: 28px;
        }
      }

      >.ant-select .ant-select-selection-selected-value {
        padding-right: 42px;
      }
    }

    >.ant-select {

      .ant-select-arrow,
      .ant-select-selection__clear {
        right: 28px;
      }
    }

    >.ant-select .ant-select-selection-selected-value {
      padding-right: 42px;
    }

    .ant-cascader-picker-arrow {
      margin-right: 17px;
    }

    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon,
    .ant-cascader-picker-clear,
    .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix,
    .ant-time-picker-clear,
    .ant-time-picker-icon {
      right: 28px;
    }
  }

  .ant-mentions,
  textarea.ant-input {
    height       : auto;
    margin-bottom: 4px;
  }

  .ant-upload {
    background: rgba(0, 0, 0, 0);
  }

  input {

    &[type=checkbox],
    &[type=radio] {
      width : 14px;
      height: 14px;
    }
  }

  .ant-checkbox-inline,
  .ant-radio-inline {
    display       : inline-block;
    margin-left   : 8px;
    font-weight   : 400;
    vertical-align: middle;
    cursor        : pointer;
  }

  .ant-checkbox-inline:first-child,
  .ant-radio-inline:first-child {
    margin-left: 0;
  }

  .ant-checkbox-vertical,
  .ant-radio-vertical {
    display: block;
  }

  .ant-checkbox-vertical+.ant-checkbox-vertical,
  .ant-radio-vertical+.ant-radio-vertical {
    margin-left: 0;
  }

  .ant-input-number+.ant-form-text {
    margin-left: 8px;
  }

  .ant-input-number-handler-wrap {
    z-index: 2;
  }

  .ant-cascader-picker,
  .ant-select {
    width: 100%;
  }

  .ant-input-group {

    .ant-cascader-picker,
    .ant-select {
      width: auto;
    }
  }

  .ant-input-group-wrapper,
  :not(.ant-input-group-wrapper)>.ant-input-group {
    display       : inline-block;
    vertical-align: middle;
  }

  &:not(.ant-form-vertical) {

    .ant-input-group-wrapper,
    :not(.ant-input-group-wrapper)>.ant-input-group {
      position: relative;
      top     : -1px;
    }
  }
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  display    : block;
  margin     : 0;
  padding    : 0 0 8px;
  line-height: 1.5;
  white-space: normal;
  text-align : right;
}

.ant-col-24.ant-form-item-label label:after,
.ant-col-xl-24.ant-form-item-label label:after {
  display: none;
}

.ant-form-vertical {
  .ant-form-item-label label:after {
    display: none;
  }

  .ant-form-item {
    padding-bottom: 8px;
  }

  .ant-form-item-control {
    line-height: 1.5;
  }

  .ant-form-explain {
    margin-top   : 2px;
    margin-bottom: -5px;
  }

  .ant-form-extra {
    margin-top   : 2px;
    margin-bottom: -4px;
  }
}

@media (max-width: 575px) {
  .ant-form-item-control-wrapper {
    display: block;
    width  : 100%;
  }

  .ant-form-item-label {
    display    : block;
    width      : 100%;
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }

  .ant-col-xs-24.ant-form-item-label {
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display    : block;
    margin     : 0;
    padding    : 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align : right;

    label:after {
      display: none;
    }
  }
}

.ant-form-inline {
  .ant-form-item {
    display      : inline-block;
    margin-right : 16px;
    margin-bottom: 0;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-form-item> {

    .ant-form-item-control-wrapper,
    .ant-form-item-label {
      display       : inline-block;
      vertical-align: top;
    }
  }

  .ant-form-text,
  .has-feedback {
    display: inline-block;
  }
}

.has-error.has-feedback .ant-form-item-children-icon,
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position         : absolute;
  top              : 50%;
  right            : 0;
  z-index          : 1;
  width            : 32px;
  height           : 20px;
  margin-top       : -10px;
  font-size        : 14px;
  line-height      : 20px;
  text-align       : center;
  visibility       : visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation        : zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events   : none;
}

.has-error.has-feedback .ant-form-item-children-icon svg,
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  left    : 0;
  margin  : auto;
}

.has-success.has-feedback .ant-form-item-children-icon {
  color                 : #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name        : diffZoomIn1 !important;
}

.has-warning {

  .ant-form-explain,
  .ant-form-split {
    color: #faad14;
  }

  .ant-input {
    background-color: #fff;
    border-color    : #faad14;

    &:hover {
      background-color: #fff;
      border-color    : #faad14;
    }

    &:focus {
      border-color      : #ffc53d;
      border-right-width: 1px !important;
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
      box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
    }

    &:not([disabled]):hover {
      border-color: #faad14;
    }
  }

  .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color      : #ffc53d;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
  }

  .ant-input-affix-wrapper {
    .ant-input {
      background-color: #fff;
      border-color    : #faad14;

      &:hover {
        background-color: #fff;
        border-color    : #faad14;
      }

      &:focus {
        border-color      : #ffc53d;
        border-right-width: 1px !important;
        outline           : 0;
        -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
        box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
      }
    }

    &:hover .ant-input:not(.ant-input-disabled) {
      border-color: #faad14;
    }
  }

  .ant-input-prefix {
    color: #faad14;
  }

  .ant-input-group-addon {
    color           : #faad14;
    background-color: #fff;
    border-color    : #faad14;
  }

  .has-feedback {
    color: #faad14;
  }

  &.has-feedback .ant-form-item-children-icon {
    color                 : #faad14;
    -webkit-animation-name: diffZoomIn3 !important;
    animation-name        : diffZoomIn3 !important;
  }

  .ant-select-selection {
    border-color: #faad14;

    &:hover {
      border-color: #faad14;
    }
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection {
    border-color      : #ffc53d;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
  }

  .ant-calendar-picker-icon:after,
  .ant-cascader-picker-arrow,
  .ant-picker-icon:after,
  .ant-select-arrow,
  .ant-time-picker-icon:after {
    color: #faad14;
  }

  .ant-input-number,
  .ant-time-picker-input {
    border-color: #faad14;
  }

  .ant-input-number-focused,
  .ant-input-number:focus,
  .ant-time-picker-input-focused,
  .ant-time-picker-input:focus {
    border-color      : #ffc53d;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
  }

  .ant-input-number:not([disabled]):hover,
  .ant-time-picker-input:not([disabled]):hover {
    border-color: #faad14;
  }

  .ant-cascader-picker {
    &:focus .ant-cascader-input {
      border-color      : #ffc53d;
      border-right-width: 1px !important;
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
      box-shadow        : 0 0 0 2px rgba(250, 173, 20, 0.2);
    }

    &:hover .ant-cascader-input {
      border-color: #faad14;
    }
  }
}

.has-error {

  .ant-form-explain,
  .ant-form-split {
    color: #f5222d;
  }

  .ant-input {
    background-color: #fff;
    border-color    : #f5222d;

    &:hover {
      background-color: #fff;
      border-color    : #f5222d;
    }

    &:focus {
      border-color      : #ff4d4f;
      border-right-width: 1px !important;
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
    }

    &:not([disabled]):hover {
      border-color: #f5222d;
    }
  }

  .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color      : #ff4d4f;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
  }

  .ant-input-affix-wrapper {
    .ant-input {
      background-color: #fff;
      border-color    : #f5222d;

      &:hover {
        background-color: #fff;
        border-color    : #f5222d;
      }

      &:focus {
        border-color      : #ff4d4f;
        border-right-width: 1px !important;
        outline           : 0;
        -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
        box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
    }

    &:hover .ant-input:not(.ant-input-disabled) {
      border-color: #f5222d;
    }
  }

  .ant-input-prefix {
    color: #f5222d;
  }

  .ant-input-group-addon {
    color           : #f5222d;
    background-color: #fff;
    border-color    : #f5222d;
  }

  .has-feedback {
    color: #f5222d;
  }

  &.has-feedback .ant-form-item-children-icon {
    color                 : #f5222d;
    -webkit-animation-name: diffZoomIn2 !important;
    animation-name        : diffZoomIn2 !important;
  }

  .ant-select-selection {
    border-color: #f5222d;

    &:hover {
      border-color: #f5222d;
    }
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection {
    border-color      : #ff4d4f;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
  }

  .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #f5222d;
  }

  .ant-input-group-addon .ant-select-selection {
    border-color      : rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .ant-calendar-picker-icon:after,
  .ant-cascader-picker-arrow,
  .ant-picker-icon:after,
  .ant-select-arrow,
  .ant-time-picker-icon:after {
    color: #f5222d;
  }

  .ant-input-number,
  .ant-time-picker-input {
    border-color: #f5222d;
  }

  .ant-input-number-focused,
  .ant-input-number:focus,
  .ant-time-picker-input-focused,
  .ant-time-picker-input:focus {
    border-color      : #ff4d4f;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
  }

  .ant-input-number:not([disabled]):hover {
    border-color: #f5222d;
  }

  .ant-mention-wrapper .ant-mention-editor {
    border-color: #f5222d;

    &:not([disabled]):hover {
      border-color: #f5222d;
    }
  }

  .ant-time-picker-input:not([disabled]):hover {
    border-color: #f5222d;
  }

  .ant-cascader-picker:focus .ant-cascader-input {
    border-color      : #ff4d4f;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
  }

  .ant-mention-wrapper {

    &.ant-mention-active:not([disabled]) .ant-mention-editor,
    .ant-mention-editor:not([disabled]):focus {
      border-color      : #ff4d4f;
      border-right-width: 1px !important;
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      box-shadow        : 0 0 0 2px rgba(245, 34, 45, 0.2);
    }
  }

  .ant-cascader-picker:hover .ant-cascader-input,
  .ant-transfer-list {
    border-color: #f5222d;
  }

  .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;

    &:hover {
      border-color      : #40a9ff;
      border-right-width: 1px !important;
    }

    &:focus {
      border-color      : #40a9ff;
      border-right-width: 1px !important;
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
}

.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color  : #1890ff;
}

.ant-advanced-search-form {
  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-form-item-with-help {
    margin-bottom: 5px;
  }
}

.show-help-appear,
.show-help-enter,
.show-help-leave {
  -webkit-animation-duration  : .3s;
  animation-duration          : .3s;
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.show-help-appear.show-help-appear-active,
.show-help-enter.show-help-enter-active {
  -webkit-animation-name      : antShowHelpIn;
  animation-name              : antShowHelpIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.show-help-leave.show-help-leave-active {
  -webkit-animation-name      : antShowHelpOut;
  animation-name              : antShowHelpOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
  pointer-events              : none;
}

.show-help-appear,
.show-help-enter {
  opacity: 0;
}

.show-help-appear,
.show-help-enter,
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function        : cubic-bezier(0.645, 0.045, 0.355, 1);
}

@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform        : translateY(-5px);
    opacity          : 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}

@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform        : translateY(-5px);
    opacity          : 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}

@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform        : translateY(-5px);
    opacity          : 0;
  }
}

@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform        : translateY(-5px);
    opacity          : 0;
  }
}

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

.ant-row {
  position          : relative;
  height            : auto;
  margin-right      : 0;
  margin-left       : 0;
  zoom              : 1;
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-row-flex {
  -webkit-box-orient   : horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow        : row wrap;
  flex-flow            : row wrap;
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;

  &:after,
  &:before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.ant-row-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack   : start;
  justify-content : flex-start;
}

.ant-row-flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack   : center;
  justify-content : center;
}

.ant-row-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack   : end;
  justify-content : flex-end;
}

.ant-row-flex-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack   : justify;
  justify-content : space-between;
}

.ant-row-flex-space-around {
  -ms-flex-pack  : distribute;
  justify-content: space-around;
}

.ant-row-flex-top {
  -webkit-box-align: start;
  -ms-flex-align   : start;
  align-items      : flex-start;
}

.ant-row-flex-middle {
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

.ant-row-flex-bottom {
  -webkit-box-align: end;
  -ms-flex-align   : end;
  align-items      : flex-end;
}

.ant-col {
  position  : relative;
  min-height: 1px;
}

.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24,
.ant-col-lg-1,
.ant-col-lg-2,
.ant-col-lg-3,
.ant-col-lg-4,
.ant-col-lg-5,
.ant-col-lg-6,
.ant-col-lg-7,
.ant-col-lg-8,
.ant-col-lg-9,
.ant-col-lg-10,
.ant-col-lg-11,
.ant-col-lg-12,
.ant-col-lg-13,
.ant-col-lg-14,
.ant-col-lg-15,
.ant-col-lg-16,
.ant-col-lg-17,
.ant-col-lg-18,
.ant-col-lg-19,
.ant-col-lg-20,
.ant-col-lg-21,
.ant-col-lg-22,
.ant-col-lg-23,
.ant-col-lg-24,
.ant-col-md-1,
.ant-col-md-2,
.ant-col-md-3,
.ant-col-md-4,
.ant-col-md-5,
.ant-col-md-6,
.ant-col-md-7,
.ant-col-md-8,
.ant-col-md-9,
.ant-col-md-10,
.ant-col-md-11,
.ant-col-md-12,
.ant-col-md-13,
.ant-col-md-14,
.ant-col-md-15,
.ant-col-md-16,
.ant-col-md-17,
.ant-col-md-18,
.ant-col-md-19,
.ant-col-md-20,
.ant-col-md-21,
.ant-col-md-22,
.ant-col-md-23,
.ant-col-md-24,
.ant-col-sm-1,
.ant-col-sm-2,
.ant-col-sm-3,
.ant-col-sm-4,
.ant-col-sm-5,
.ant-col-sm-6,
.ant-col-sm-7,
.ant-col-sm-8,
.ant-col-sm-9,
.ant-col-sm-10,
.ant-col-sm-11,
.ant-col-sm-12,
.ant-col-sm-13,
.ant-col-sm-14,
.ant-col-sm-15,
.ant-col-sm-16,
.ant-col-sm-17,
.ant-col-sm-18,
.ant-col-sm-19,
.ant-col-sm-20,
.ant-col-sm-21,
.ant-col-sm-22,
.ant-col-sm-23,
.ant-col-sm-24,
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  position     : relative;
  padding-right: 0;
  padding-left : 0;
}

.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23 {
  -webkit-box-flex: 0;
  -ms-flex        : 0 0 auto;
  flex            : 0 0 auto;
  float           : right;
}

.ant-col-24 {
  -webkit-box-flex  : 0;
  -ms-flex          : 0 0 auto;
  flex              : 0 0 auto;
  float             : right;
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 100%;
}

.ant-col-push-24 {
  left: 100%;
}

.ant-col-pull-24 {
  right: 100%;
}

.ant-col-offset-24 {
  margin-left: 100%;
}

.ant-col-order-24 {
  -webkit-box-ordinal-group: 25;
  -ms-flex-order           : 24;
  order                    : 24;
}

.ant-col-23 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 95.83333333%;
}

.ant-col-push-23 {
  left: 95.83333333%;
}

.ant-col-pull-23 {
  right: 95.83333333%;
}

.ant-col-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-order-23 {
  -webkit-box-ordinal-group: 24;
  -ms-flex-order           : 23;
  order                    : 23;
}

.ant-col-22 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 91.66666667%;
}

.ant-col-push-22 {
  left: 91.66666667%;
}

.ant-col-pull-22 {
  right: 91.66666667%;
}

.ant-col-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-order-22 {
  -webkit-box-ordinal-group: 23;
  -ms-flex-order           : 22;
  order                    : 22;
}

.ant-col-21 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 87.5%;
}

.ant-col-push-21 {
  left: 87.5%;
}

.ant-col-pull-21 {
  right: 87.5%;
}

.ant-col-offset-21 {
  margin-left: 87.5%;
}

.ant-col-order-21 {
  -webkit-box-ordinal-group: 22;
  -ms-flex-order           : 21;
  order                    : 21;
}

.ant-col-20 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 83.33333333%;
}

.ant-col-push-20 {
  left: 83.33333333%;
}

.ant-col-pull-20 {
  right: 83.33333333%;
}

.ant-col-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-order-20 {
  -webkit-box-ordinal-group: 21;
  -ms-flex-order           : 20;
  order                    : 20;
}

.ant-col-19 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 79.16666667%;
}

.ant-col-push-19 {
  left: 79.16666667%;
}

.ant-col-pull-19 {
  right: 79.16666667%;
}

.ant-col-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-order-19 {
  -webkit-box-ordinal-group: 20;
  -ms-flex-order           : 19;
  order                    : 19;
}

.ant-col-18 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 75%;
}

.ant-col-push-18 {
  left: 75%;
}

.ant-col-pull-18 {
  right: 75%;
}

.ant-col-offset-18 {
  margin-left: 75%;
}

.ant-col-order-18 {
  -webkit-box-ordinal-group: 19;
  -ms-flex-order           : 18;
  order                    : 18;
}

.ant-col-17 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 70.83333333%;
}

.ant-col-push-17 {
  left: 70.83333333%;
}

.ant-col-pull-17 {
  right: 70.83333333%;
}

.ant-col-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-order-17 {
  -webkit-box-ordinal-group: 18;
  -ms-flex-order           : 17;
  order                    : 17;
}

.ant-col-16 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 66.66666667%;
}

.ant-col-push-16 {
  left: 66.66666667%;
}

.ant-col-pull-16 {
  right: 66.66666667%;
}

.ant-col-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-order-16 {
  -webkit-box-ordinal-group: 17;
  -ms-flex-order           : 16;
  order                    : 16;
}

.ant-col-15 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 62.5%;
}

.ant-col-push-15 {
  left: 62.5%;
}

.ant-col-pull-15 {
  right: 62.5%;
}

.ant-col-offset-15 {
  margin-left: 62.5%;
}

.ant-col-order-15 {
  -webkit-box-ordinal-group: 16;
  -ms-flex-order           : 15;
  order                    : 15;
}

.ant-col-14 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 58.33333333%;
}

.ant-col-push-14 {
  left: 58.33333333%;
}

.ant-col-pull-14 {
  right: 58.33333333%;
}

.ant-col-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-order-14 {
  -webkit-box-ordinal-group: 15;
  -ms-flex-order           : 14;
  order                    : 14;
}

.ant-col-13 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 54.16666667%;
}

.ant-col-push-13 {
  left: 54.16666667%;
}

.ant-col-pull-13 {
  right: 54.16666667%;
}

.ant-col-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-order-13 {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order           : 13;
  order                    : 13;
}

.ant-col-12 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 50%;
}

.ant-col-push-12 {
  left: 50%;
}

.ant-col-pull-12 {
  right: 50%;
}

.ant-col-offset-12 {
  margin-left: 50%;
}

.ant-col-order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order           : 12;
  order                    : 12;
}

.ant-col-11 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 45.83333333%;
}

.ant-col-push-11 {
  left: 45.83333333%;
}

.ant-col-pull-11 {
  right: 45.83333333%;
}

.ant-col-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order           : 11;
  order                    : 11;
}

.ant-col-10 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 41.66666667%;
}

.ant-col-push-10 {
  left: 41.66666667%;
}

.ant-col-pull-10 {
  right: 41.66666667%;
}

.ant-col-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order           : 10;
  order                    : 10;
}

.ant-col-9 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 37.5%;
}

.ant-col-push-9 {
  left: 37.5%;
}

.ant-col-pull-9 {
  right: 37.5%;
}

.ant-col-offset-9 {
  margin-left: 37.5%;
}

.ant-col-order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order           : 9;
  order                    : 9;
}

.ant-col-8 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 33.33333333%;
}

.ant-col-push-8 {
  left: 33.33333333%;
}

.ant-col-pull-8 {
  right: 33.33333333%;
}

.ant-col-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order           : 8;
  order                    : 8;
}

.ant-col-7 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 29.16666667%;
}

.ant-col-push-7 {
  left: 29.16666667%;
}

.ant-col-pull-7 {
  right: 29.16666667%;
}

.ant-col-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order           : 7;
  order                    : 7;
}

.ant-col-6 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 25%;
}

.ant-col-push-6 {
  left: 25%;
}

.ant-col-pull-6 {
  right: 25%;
}

.ant-col-offset-6 {
  margin-left: 25%;
}

.ant-col-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order           : 6;
  order                    : 6;
}

.ant-col-5 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 20.83333333%;
}

.ant-col-push-5 {
  left: 20.83333333%;
}

.ant-col-pull-5 {
  right: 20.83333333%;
}

.ant-col-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order           : 5;
  order                    : 5;
}

.ant-col-4 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 16.66666667%;
}

.ant-col-push-4 {
  left: 16.66666667%;
}

.ant-col-pull-4 {
  right: 16.66666667%;
}

.ant-col-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order           : 4;
  order                    : 4;
}

.ant-col-3 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 12.5%;
}

.ant-col-push-3 {
  left: 12.5%;
}

.ant-col-pull-3 {
  right: 12.5%;
}

.ant-col-offset-3 {
  margin-left: 12.5%;
}

.ant-col-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order           : 3;
  order                    : 3;
}

.ant-col-2 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 8.33333333%;
}

.ant-col-push-2 {
  left: 8.33333333%;
}

.ant-col-pull-2 {
  right: 8.33333333%;
}

.ant-col-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order           : 2;
  order                    : 2;
}

.ant-col-1 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 4.16666667%;
}

.ant-col-push-1 {
  left: 4.16666667%;
}

.ant-col-pull-1 {
  right: 4.16666667%;
}

.ant-col-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order           : 1;
  order                    : 1;
}

.ant-col-0 {
  display: none;
}

.ant-col-offset-0 {
  margin-left: 0;
}

.ant-col-order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order           : 0;
  order                    : 0;
}

.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23 {
  -webkit-box-flex: 0;
  -ms-flex        : 0 0 auto;
  flex            : 0 0 auto;
  float           : left;
}

.ant-col-xs-24 {
  -webkit-box-flex  : 0;
  -ms-flex          : 0 0 auto;
  flex              : 0 0 auto;
  float             : left;
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 100%;
}

.ant-col-xs-push-24 {
  left: 100%;
}

.ant-col-xs-pull-24 {
  right: 100%;
}

.ant-col-xs-offset-24 {
  margin-left: 100%;
}

.ant-col-xs-order-24 {
  -webkit-box-ordinal-group: 25;
  -ms-flex-order           : 24;
  order                    : 24;
}

.ant-col-xs-23 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 95.83333333%;
}

.ant-col-xs-push-23 {
  left: 95.83333333%;
}

.ant-col-xs-pull-23 {
  right: 95.83333333%;
}

.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-xs-order-23 {
  -webkit-box-ordinal-group: 24;
  -ms-flex-order           : 23;
  order                    : 23;
}

.ant-col-xs-22 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 91.66666667%;
}

.ant-col-xs-push-22 {
  left: 91.66666667%;
}

.ant-col-xs-pull-22 {
  right: 91.66666667%;
}

.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-xs-order-22 {
  -webkit-box-ordinal-group: 23;
  -ms-flex-order           : 22;
  order                    : 22;
}

.ant-col-xs-21 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 87.5%;
}

.ant-col-xs-push-21 {
  left: 87.5%;
}

.ant-col-xs-pull-21 {
  right: 87.5%;
}

.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}

.ant-col-xs-order-21 {
  -webkit-box-ordinal-group: 22;
  -ms-flex-order           : 21;
  order                    : 21;
}

.ant-col-xs-20 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 83.33333333%;
}

.ant-col-xs-push-20 {
  left: 83.33333333%;
}

.ant-col-xs-pull-20 {
  right: 83.33333333%;
}

.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-xs-order-20 {
  -webkit-box-ordinal-group: 21;
  -ms-flex-order           : 20;
  order                    : 20;
}

.ant-col-xs-19 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 79.16666667%;
}

.ant-col-xs-push-19 {
  left: 79.16666667%;
}

.ant-col-xs-pull-19 {
  right: 79.16666667%;
}

.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-xs-order-19 {
  -webkit-box-ordinal-group: 20;
  -ms-flex-order           : 19;
  order                    : 19;
}

.ant-col-xs-18 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 75%;
}

.ant-col-xs-push-18 {
  left: 75%;
}

.ant-col-xs-pull-18 {
  right: 75%;
}

.ant-col-xs-offset-18 {
  margin-left: 75%;
}

.ant-col-xs-order-18 {
  -webkit-box-ordinal-group: 19;
  -ms-flex-order           : 18;
  order                    : 18;
}

.ant-col-xs-17 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 70.83333333%;
}

.ant-col-xs-push-17 {
  left: 70.83333333%;
}

.ant-col-xs-pull-17 {
  right: 70.83333333%;
}

.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-xs-order-17 {
  -webkit-box-ordinal-group: 18;
  -ms-flex-order           : 17;
  order                    : 17;
}

.ant-col-xs-16 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 66.66666667%;
}

.ant-col-xs-push-16 {
  left: 66.66666667%;
}

.ant-col-xs-pull-16 {
  right: 66.66666667%;
}

.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-xs-order-16 {
  -webkit-box-ordinal-group: 17;
  -ms-flex-order           : 16;
  order                    : 16;
}

.ant-col-xs-15 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 62.5%;
}

.ant-col-xs-push-15 {
  left: 62.5%;
}

.ant-col-xs-pull-15 {
  right: 62.5%;
}

.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}

.ant-col-xs-order-15 {
  -webkit-box-ordinal-group: 16;
  -ms-flex-order           : 15;
  order                    : 15;
}

.ant-col-xs-14 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 58.33333333%;
}

.ant-col-xs-push-14 {
  left: 58.33333333%;
}

.ant-col-xs-pull-14 {
  right: 58.33333333%;
}

.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-xs-order-14 {
  -webkit-box-ordinal-group: 15;
  -ms-flex-order           : 14;
  order                    : 14;
}

.ant-col-xs-13 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 54.16666667%;
}

.ant-col-xs-push-13 {
  left: 54.16666667%;
}

.ant-col-xs-pull-13 {
  right: 54.16666667%;
}

.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-xs-order-13 {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order           : 13;
  order                    : 13;
}

.ant-col-xs-12 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 50%;
}

.ant-col-xs-push-12 {
  left: 50%;
}

.ant-col-xs-pull-12 {
  right: 50%;
}

.ant-col-xs-offset-12 {
  margin-left: 50%;
}

.ant-col-xs-order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order           : 12;
  order                    : 12;
}

.ant-col-xs-11 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 45.83333333%;
}

.ant-col-xs-push-11 {
  left: 45.83333333%;
}

.ant-col-xs-pull-11 {
  right: 45.83333333%;
}

.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-xs-order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order           : 11;
  order                    : 11;
}

.ant-col-xs-10 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 41.66666667%;
}

.ant-col-xs-push-10 {
  left: 41.66666667%;
}

.ant-col-xs-pull-10 {
  right: 41.66666667%;
}

.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-xs-order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order           : 10;
  order                    : 10;
}

.ant-col-xs-9 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 37.5%;
}

.ant-col-xs-push-9 {
  left: 37.5%;
}

.ant-col-xs-pull-9 {
  right: 37.5%;
}

.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}

.ant-col-xs-order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order           : 9;
  order                    : 9;
}

.ant-col-xs-8 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 33.33333333%;
}

.ant-col-xs-push-8 {
  left: 33.33333333%;
}

.ant-col-xs-pull-8 {
  right: 33.33333333%;
}

.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-xs-order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order           : 8;
  order                    : 8;
}

.ant-col-xs-7 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 29.16666667%;
}

.ant-col-xs-push-7 {
  left: 29.16666667%;
}

.ant-col-xs-pull-7 {
  right: 29.16666667%;
}

.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-xs-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order           : 7;
  order                    : 7;
}

.ant-col-xs-6 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 25%;
}

.ant-col-xs-push-6 {
  left: 25%;
}

.ant-col-xs-pull-6 {
  right: 25%;
}

.ant-col-xs-offset-6 {
  margin-left: 25%;
}

.ant-col-xs-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order           : 6;
  order                    : 6;
}

.ant-col-xs-5 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 20.83333333%;
}

.ant-col-xs-push-5 {
  left: 20.83333333%;
}

.ant-col-xs-pull-5 {
  right: 20.83333333%;
}

.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-xs-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order           : 5;
  order                    : 5;
}

.ant-col-xs-4 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 16.66666667%;
}

.ant-col-xs-push-4 {
  left: 16.66666667%;
}

.ant-col-xs-pull-4 {
  right: 16.66666667%;
}

.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-xs-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order           : 4;
  order                    : 4;
}

.ant-col-xs-3 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 12.5%;
}

.ant-col-xs-push-3 {
  left: 12.5%;
}

.ant-col-xs-pull-3 {
  right: 12.5%;
}

.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}

.ant-col-xs-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order           : 3;
  order                    : 3;
}

.ant-col-xs-2 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 8.33333333%;
}

.ant-col-xs-push-2 {
  left: 8.33333333%;
}

.ant-col-xs-pull-2 {
  right: 8.33333333%;
}

.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-xs-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order           : 2;
  order                    : 2;
}

.ant-col-xs-1 {
  display           : block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  width             : 4.16666667%;
}

.ant-col-xs-push-1 {
  left: 4.16666667%;
}

.ant-col-xs-pull-1 {
  right: 4.16666667%;
}

.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-xs-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order           : 1;
  order                    : 1;
}

.ant-col-xs-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-xs-push-0 {
  left: auto;
}

.ant-col-xs-pull-0 {
  right: auto;
}

.ant-col-xs-offset-0 {
  margin-left: 0;
}

.ant-col-xs-order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order           : 0;
  order                    : 0;
}

@media (min-width: 576px) {

  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23 {
    -webkit-box-flex: 0;
    -ms-flex        : 0 0 auto;
    flex            : 0 0 auto;
    float           : left;
  }

  .ant-col-sm-24 {
    -webkit-box-flex  : 0;
    -ms-flex          : 0 0 auto;
    flex              : 0 0 auto;
    float             : left;
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 100%;
  }

  .ant-col-sm-push-24 {
    left: 100%;
  }

  .ant-col-sm-pull-24 {
    right: 100%;
  }

  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }

  .ant-col-sm-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order           : 24;
    order                    : 24;
  }

  .ant-col-sm-23 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 95.83333333%;
  }

  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }

  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-sm-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order           : 23;
    order                    : 23;
  }

  .ant-col-sm-22 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 91.66666667%;
  }

  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }

  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-sm-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order           : 22;
    order                    : 22;
  }

  .ant-col-sm-21 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 87.5%;
  }

  .ant-col-sm-push-21 {
    left: 87.5%;
  }

  .ant-col-sm-pull-21 {
    right: 87.5%;
  }

  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-sm-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order           : 21;
    order                    : 21;
  }

  .ant-col-sm-20 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 83.33333333%;
  }

  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }

  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-sm-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order           : 20;
    order                    : 20;
  }

  .ant-col-sm-19 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 79.16666667%;
  }

  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }

  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-sm-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order           : 19;
    order                    : 19;
  }

  .ant-col-sm-18 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 75%;
  }

  .ant-col-sm-push-18 {
    left: 75%;
  }

  .ant-col-sm-pull-18 {
    right: 75%;
  }

  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }

  .ant-col-sm-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order           : 18;
    order                    : 18;
  }

  .ant-col-sm-17 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 70.83333333%;
  }

  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }

  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-sm-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order           : 17;
    order                    : 17;
  }

  .ant-col-sm-16 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 66.66666667%;
  }

  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }

  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-sm-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order           : 16;
    order                    : 16;
  }

  .ant-col-sm-15 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 62.5%;
  }

  .ant-col-sm-push-15 {
    left: 62.5%;
  }

  .ant-col-sm-pull-15 {
    right: 62.5%;
  }

  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-sm-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order           : 15;
    order                    : 15;
  }

  .ant-col-sm-14 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 58.33333333%;
  }

  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }

  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-sm-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order           : 14;
    order                    : 14;
  }

  .ant-col-sm-13 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 54.16666667%;
  }

  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }

  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-sm-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order           : 13;
    order                    : 13;
  }

  .ant-col-sm-12 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 50%;
  }

  .ant-col-sm-push-12 {
    left: 50%;
  }

  .ant-col-sm-pull-12 {
    right: 50%;
  }

  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }

  .ant-col-sm-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order           : 12;
    order                    : 12;
  }

  .ant-col-sm-11 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 45.83333333%;
  }

  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }

  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-sm-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order           : 11;
    order                    : 11;
  }

  .ant-col-sm-10 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 41.66666667%;
  }

  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }

  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-sm-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order           : 10;
    order                    : 10;
  }

  .ant-col-sm-9 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 37.5%;
  }

  .ant-col-sm-push-9 {
    left: 37.5%;
  }

  .ant-col-sm-pull-9 {
    right: 37.5%;
  }

  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-sm-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order           : 9;
    order                    : 9;
  }

  .ant-col-sm-8 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 33.33333333%;
  }

  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }

  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-sm-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order           : 8;
    order                    : 8;
  }

  .ant-col-sm-7 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 29.16666667%;
  }

  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }

  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-sm-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order           : 7;
    order                    : 7;
  }

  .ant-col-sm-6 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 25%;
  }

  .ant-col-sm-push-6 {
    left: 25%;
  }

  .ant-col-sm-pull-6 {
    right: 25%;
  }

  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }

  .ant-col-sm-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order           : 6;
    order                    : 6;
  }

  .ant-col-sm-5 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 20.83333333%;
  }

  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }

  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-sm-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order           : 5;
    order                    : 5;
  }

  .ant-col-sm-4 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 16.66666667%;
  }

  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }

  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-sm-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order           : 4;
    order                    : 4;
  }

  .ant-col-sm-3 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 12.5%;
  }

  .ant-col-sm-push-3 {
    left: 12.5%;
  }

  .ant-col-sm-pull-3 {
    right: 12.5%;
  }

  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-sm-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order           : 3;
    order                    : 3;
  }

  .ant-col-sm-2 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 8.33333333%;
  }

  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }

  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-sm-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order           : 2;
    order                    : 2;
  }

  .ant-col-sm-1 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 4.16666667%;
  }

  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }

  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-sm-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order           : 1;
    order                    : 1;
  }

  .ant-col-sm-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-sm-push-0 {
    left: auto;
  }

  .ant-col-sm-pull-0 {
    right: auto;
  }

  .ant-col-sm-offset-0 {
    margin-left: 0;
  }

  .ant-col-sm-order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order           : 0;
    order                    : 0;
  }
}

@media (min-width: 768px) {

  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23 {
    -webkit-box-flex: 0;
    -ms-flex        : 0 0 auto;
    flex            : 0 0 auto;
    float           : left;
  }

  .ant-col-md-24 {
    -webkit-box-flex  : 0;
    -ms-flex          : 0 0 auto;
    flex              : 0 0 auto;
    float             : left;
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 100%;
  }

  .ant-col-md-push-24 {
    left: 100%;
  }

  .ant-col-md-pull-24 {
    right: 100%;
  }

  .ant-col-md-offset-24 {
    margin-left: 100%;
  }

  .ant-col-md-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order           : 24;
    order                    : 24;
  }

  .ant-col-md-23 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 95.83333333%;
  }

  .ant-col-md-push-23 {
    left: 95.83333333%;
  }

  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-md-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order           : 23;
    order                    : 23;
  }

  .ant-col-md-22 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 91.66666667%;
  }

  .ant-col-md-push-22 {
    left: 91.66666667%;
  }

  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-md-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order           : 22;
    order                    : 22;
  }

  .ant-col-md-21 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 87.5%;
  }

  .ant-col-md-push-21 {
    left: 87.5%;
  }

  .ant-col-md-pull-21 {
    right: 87.5%;
  }

  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-md-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order           : 21;
    order                    : 21;
  }

  .ant-col-md-20 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 83.33333333%;
  }

  .ant-col-md-push-20 {
    left: 83.33333333%;
  }

  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-md-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order           : 20;
    order                    : 20;
  }

  .ant-col-md-19 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 79.16666667%;
  }

  .ant-col-md-push-19 {
    left: 79.16666667%;
  }

  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-md-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order           : 19;
    order                    : 19;
  }

  .ant-col-md-18 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 75%;
  }

  .ant-col-md-push-18 {
    left: 75%;
  }

  .ant-col-md-pull-18 {
    right: 75%;
  }

  .ant-col-md-offset-18 {
    margin-left: 75%;
  }

  .ant-col-md-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order           : 18;
    order                    : 18;
  }

  .ant-col-md-17 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 70.83333333%;
  }

  .ant-col-md-push-17 {
    left: 70.83333333%;
  }

  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-md-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order           : 17;
    order                    : 17;
  }

  .ant-col-md-16 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 66.66666667%;
  }

  .ant-col-md-push-16 {
    left: 66.66666667%;
  }

  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-md-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order           : 16;
    order                    : 16;
  }

  .ant-col-md-15 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 62.5%;
  }

  .ant-col-md-push-15 {
    left: 62.5%;
  }

  .ant-col-md-pull-15 {
    right: 62.5%;
  }

  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-md-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order           : 15;
    order                    : 15;
  }

  .ant-col-md-14 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 58.33333333%;
  }

  .ant-col-md-push-14 {
    left: 58.33333333%;
  }

  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-md-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order           : 14;
    order                    : 14;
  }

  .ant-col-md-13 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 54.16666667%;
  }

  .ant-col-md-push-13 {
    left: 54.16666667%;
  }

  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-md-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order           : 13;
    order                    : 13;
  }

  .ant-col-md-12 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 50%;
  }

  .ant-col-md-push-12 {
    left: 50%;
  }

  .ant-col-md-pull-12 {
    right: 50%;
  }

  .ant-col-md-offset-12 {
    margin-left: 50%;
  }

  .ant-col-md-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order           : 12;
    order                    : 12;
  }

  .ant-col-md-11 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 45.83333333%;
  }

  .ant-col-md-push-11 {
    left: 45.83333333%;
  }

  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-md-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order           : 11;
    order                    : 11;
  }

  .ant-col-md-10 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 41.66666667%;
  }

  .ant-col-md-push-10 {
    left: 41.66666667%;
  }

  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-md-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order           : 10;
    order                    : 10;
  }

  .ant-col-md-9 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 37.5%;
  }

  .ant-col-md-push-9 {
    left: 37.5%;
  }

  .ant-col-md-pull-9 {
    right: 37.5%;
  }

  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-md-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order           : 9;
    order                    : 9;
  }

  .ant-col-md-8 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 33.33333333%;
  }

  .ant-col-md-push-8 {
    left: 33.33333333%;
  }

  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-md-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order           : 8;
    order                    : 8;
  }

  .ant-col-md-7 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 29.16666667%;
  }

  .ant-col-md-push-7 {
    left: 29.16666667%;
  }

  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-md-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order           : 7;
    order                    : 7;
  }

  .ant-col-md-6 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 25%;
  }

  .ant-col-md-push-6 {
    left: 25%;
  }

  .ant-col-md-pull-6 {
    right: 25%;
  }

  .ant-col-md-offset-6 {
    margin-left: 25%;
  }

  .ant-col-md-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order           : 6;
    order                    : 6;
  }

  .ant-col-md-5 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 20.83333333%;
  }

  .ant-col-md-push-5 {
    left: 20.83333333%;
  }

  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-md-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order           : 5;
    order                    : 5;
  }

  .ant-col-md-4 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 16.66666667%;
  }

  .ant-col-md-push-4 {
    left: 16.66666667%;
  }

  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-md-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order           : 4;
    order                    : 4;
  }

  .ant-col-md-3 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 12.5%;
  }

  .ant-col-md-push-3 {
    left: 12.5%;
  }

  .ant-col-md-pull-3 {
    right: 12.5%;
  }

  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-md-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order           : 3;
    order                    : 3;
  }

  .ant-col-md-2 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 8.33333333%;
  }

  .ant-col-md-push-2 {
    left: 8.33333333%;
  }

  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-md-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order           : 2;
    order                    : 2;
  }

  .ant-col-md-1 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 4.16666667%;
  }

  .ant-col-md-push-1 {
    left: 4.16666667%;
  }

  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-md-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order           : 1;
    order                    : 1;
  }

  .ant-col-md-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-md-push-0 {
    left: auto;
  }

  .ant-col-md-pull-0 {
    right: auto;
  }

  .ant-col-md-offset-0 {
    margin-left: 0;
  }

  .ant-col-md-order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order           : 0;
    order                    : 0;
  }
}

@media (min-width: 992px) {

  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23 {
    -webkit-box-flex: 0;
    -ms-flex        : 0 0 auto;
    flex            : 0 0 auto;
    float           : left;
  }

  .ant-col-lg-24 {
    -webkit-box-flex  : 0;
    -ms-flex          : 0 0 auto;
    flex              : 0 0 auto;
    float             : left;
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 100%;
  }

  .ant-col-lg-push-24 {
    left: 100%;
  }

  .ant-col-lg-pull-24 {
    right: 100%;
  }

  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }

  .ant-col-lg-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order           : 24;
    order                    : 24;
  }

  .ant-col-lg-23 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 95.83333333%;
  }

  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }

  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-lg-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order           : 23;
    order                    : 23;
  }

  .ant-col-lg-22 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 91.66666667%;
  }

  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }

  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-lg-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order           : 22;
    order                    : 22;
  }

  .ant-col-lg-21 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 87.5%;
  }

  .ant-col-lg-push-21 {
    left: 87.5%;
  }

  .ant-col-lg-pull-21 {
    right: 87.5%;
  }

  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-lg-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order           : 21;
    order                    : 21;
  }

  .ant-col-lg-20 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 83.33333333%;
  }

  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }

  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-lg-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order           : 20;
    order                    : 20;
  }

  .ant-col-lg-19 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 79.16666667%;
  }

  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }

  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-lg-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order           : 19;
    order                    : 19;
  }

  .ant-col-lg-18 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 75%;
  }

  .ant-col-lg-push-18 {
    left: 75%;
  }

  .ant-col-lg-pull-18 {
    right: 75%;
  }

  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }

  .ant-col-lg-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order           : 18;
    order                    : 18;
  }

  .ant-col-lg-17 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 70.83333333%;
  }

  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }

  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-lg-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order           : 17;
    order                    : 17;
  }

  .ant-col-lg-16 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 66.66666667%;
  }

  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }

  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-lg-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order           : 16;
    order                    : 16;
  }

  .ant-col-lg-15 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 62.5%;
  }

  .ant-col-lg-push-15 {
    left: 62.5%;
  }

  .ant-col-lg-pull-15 {
    right: 62.5%;
  }

  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-lg-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order           : 15;
    order                    : 15;
  }

  .ant-col-lg-14 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 58.33333333%;
  }

  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }

  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-lg-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order           : 14;
    order                    : 14;
  }

  .ant-col-lg-13 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 54.16666667%;
  }

  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }

  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-lg-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order           : 13;
    order                    : 13;
  }

  .ant-col-lg-12 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 50%;
  }

  .ant-col-lg-push-12 {
    left: 50%;
  }

  .ant-col-lg-pull-12 {
    right: 50%;
  }

  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }

  .ant-col-lg-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order           : 12;
    order                    : 12;
  }

  .ant-col-lg-11 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 45.83333333%;
  }

  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }

  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-lg-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order           : 11;
    order                    : 11;
  }

  .ant-col-lg-10 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 41.66666667%;
  }

  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }

  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-lg-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order           : 10;
    order                    : 10;
  }

  .ant-col-lg-9 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 37.5%;
  }

  .ant-col-lg-push-9 {
    left: 37.5%;
  }

  .ant-col-lg-pull-9 {
    right: 37.5%;
  }

  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-lg-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order           : 9;
    order                    : 9;
  }

  .ant-col-lg-8 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 33.33333333%;
  }

  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }

  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-lg-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order           : 8;
    order                    : 8;
  }

  .ant-col-lg-7 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 29.16666667%;
  }

  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }

  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-lg-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order           : 7;
    order                    : 7;
  }

  .ant-col-lg-6 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 25%;
  }

  .ant-col-lg-push-6 {
    left: 25%;
  }

  .ant-col-lg-pull-6 {
    right: 25%;
  }

  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }

  .ant-col-lg-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order           : 6;
    order                    : 6;
  }

  .ant-col-lg-5 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 20.83333333%;
  }

  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }

  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-lg-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order           : 5;
    order                    : 5;
  }

  .ant-col-lg-4 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 16.66666667%;
  }

  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }

  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-lg-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order           : 4;
    order                    : 4;
  }

  .ant-col-lg-3 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 12.5%;
  }

  .ant-col-lg-push-3 {
    left: 12.5%;
  }

  .ant-col-lg-pull-3 {
    right: 12.5%;
  }

  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-lg-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order           : 3;
    order                    : 3;
  }

  .ant-col-lg-2 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 8.33333333%;
  }

  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }

  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-lg-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order           : 2;
    order                    : 2;
  }

  .ant-col-lg-1 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 4.16666667%;
  }

  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }

  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-lg-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order           : 1;
    order                    : 1;
  }

  .ant-col-lg-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-lg-push-0 {
    left: auto;
  }

  .ant-col-lg-pull-0 {
    right: auto;
  }

  .ant-col-lg-offset-0 {
    margin-left: 0;
  }

  .ant-col-lg-order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order           : 0;
    order                    : 0;
  }
}

@media (min-width: 1200px) {

  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23 {
    -webkit-box-flex: 0;
    -ms-flex        : 0 0 auto;
    flex            : 0 0 auto;
    float           : left;
  }

  .ant-col-xl-24 {
    -webkit-box-flex  : 0;
    -ms-flex          : 0 0 auto;
    flex              : 0 0 auto;
    float             : left;
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 100%;
  }

  .ant-col-xl-push-24 {
    left: 100%;
  }

  .ant-col-xl-pull-24 {
    right: 100%;
  }

  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xl-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order           : 24;
    order                    : 24;
  }

  .ant-col-xl-23 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 95.83333333%;
  }

  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xl-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order           : 23;
    order                    : 23;
  }

  .ant-col-xl-22 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 91.66666667%;
  }

  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xl-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order           : 22;
    order                    : 22;
  }

  .ant-col-xl-21 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 87.5%;
  }

  .ant-col-xl-push-21 {
    left: 87.5%;
  }

  .ant-col-xl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xl-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order           : 21;
    order                    : 21;
  }

  .ant-col-xl-20 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 83.33333333%;
  }

  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xl-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order           : 20;
    order                    : 20;
  }

  .ant-col-xl-19 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 79.16666667%;
  }

  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xl-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order           : 19;
    order                    : 19;
  }

  .ant-col-xl-18 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 75%;
  }

  .ant-col-xl-push-18 {
    left: 75%;
  }

  .ant-col-xl-pull-18 {
    right: 75%;
  }

  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xl-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order           : 18;
    order                    : 18;
  }

  .ant-col-xl-17 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 70.83333333%;
  }

  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xl-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order           : 17;
    order                    : 17;
  }

  .ant-col-xl-16 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 66.66666667%;
  }

  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xl-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order           : 16;
    order                    : 16;
  }

  .ant-col-xl-15 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 62.5%;
  }

  .ant-col-xl-push-15 {
    left: 62.5%;
  }

  .ant-col-xl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xl-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order           : 15;
    order                    : 15;
  }

  .ant-col-xl-14 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 58.33333333%;
  }

  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xl-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order           : 14;
    order                    : 14;
  }

  .ant-col-xl-13 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 54.16666667%;
  }

  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xl-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order           : 13;
    order                    : 13;
  }

  .ant-col-xl-12 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 50%;
  }

  .ant-col-xl-push-12 {
    left: 50%;
  }

  .ant-col-xl-pull-12 {
    right: 50%;
  }

  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xl-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order           : 12;
    order                    : 12;
  }

  .ant-col-xl-11 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 45.83333333%;
  }

  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xl-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order           : 11;
    order                    : 11;
  }

  .ant-col-xl-10 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 41.66666667%;
  }

  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xl-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order           : 10;
    order                    : 10;
  }

  .ant-col-xl-9 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 37.5%;
  }

  .ant-col-xl-push-9 {
    left: 37.5%;
  }

  .ant-col-xl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xl-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order           : 9;
    order                    : 9;
  }

  .ant-col-xl-8 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 33.33333333%;
  }

  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xl-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order           : 8;
    order                    : 8;
  }

  .ant-col-xl-7 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 29.16666667%;
  }

  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xl-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order           : 7;
    order                    : 7;
  }

  .ant-col-xl-6 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 25%;
  }

  .ant-col-xl-push-6 {
    left: 25%;
  }

  .ant-col-xl-pull-6 {
    right: 25%;
  }

  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xl-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order           : 6;
    order                    : 6;
  }

  .ant-col-xl-5 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 20.83333333%;
  }

  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xl-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order           : 5;
    order                    : 5;
  }

  .ant-col-xl-4 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 16.66666667%;
  }

  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xl-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order           : 4;
    order                    : 4;
  }

  .ant-col-xl-3 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 12.5%;
  }

  .ant-col-xl-push-3 {
    left: 12.5%;
  }

  .ant-col-xl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xl-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order           : 3;
    order                    : 3;
  }

  .ant-col-xl-2 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 8.33333333%;
  }

  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xl-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order           : 2;
    order                    : 2;
  }

  .ant-col-xl-1 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 4.16666667%;
  }

  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xl-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order           : 1;
    order                    : 1;
  }

  .ant-col-xl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xl-push-0 {
    left: auto;
  }

  .ant-col-xl-pull-0 {
    right: auto;
  }

  .ant-col-xl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xl-order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order           : 0;
    order                    : 0;
  }
}

@media (min-width: 1600px) {

  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23 {
    -webkit-box-flex: 0;
    -ms-flex        : 0 0 auto;
    flex            : 0 0 auto;
    float           : left;
  }

  .ant-col-xxl-24 {
    -webkit-box-flex  : 0;
    -ms-flex          : 0 0 auto;
    flex              : 0 0 auto;
    float             : left;
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 100%;
  }

  .ant-col-xxl-push-24 {
    left: 100%;
  }

  .ant-col-xxl-pull-24 {
    right: 100%;
  }

  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xxl-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order           : 24;
    order                    : 24;
  }

  .ant-col-xxl-23 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 95.83333333%;
  }

  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xxl-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order           : 23;
    order                    : 23;
  }

  .ant-col-xxl-22 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 91.66666667%;
  }

  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xxl-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order           : 22;
    order                    : 22;
  }

  .ant-col-xxl-21 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 87.5%;
  }

  .ant-col-xxl-push-21 {
    left: 87.5%;
  }

  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xxl-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order           : 21;
    order                    : 21;
  }

  .ant-col-xxl-20 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 83.33333333%;
  }

  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xxl-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order           : 20;
    order                    : 20;
  }

  .ant-col-xxl-19 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 79.16666667%;
  }

  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xxl-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order           : 19;
    order                    : 19;
  }

  .ant-col-xxl-18 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 75%;
  }

  .ant-col-xxl-push-18 {
    left: 75%;
  }

  .ant-col-xxl-pull-18 {
    right: 75%;
  }

  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xxl-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order           : 18;
    order                    : 18;
  }

  .ant-col-xxl-17 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 70.83333333%;
  }

  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xxl-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order           : 17;
    order                    : 17;
  }

  .ant-col-xxl-16 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 66.66666667%;
  }

  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xxl-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order           : 16;
    order                    : 16;
  }

  .ant-col-xxl-15 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 62.5%;
  }

  .ant-col-xxl-push-15 {
    left: 62.5%;
  }

  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xxl-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order           : 15;
    order                    : 15;
  }

  .ant-col-xxl-14 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 58.33333333%;
  }

  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xxl-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order           : 14;
    order                    : 14;
  }

  .ant-col-xxl-13 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 54.16666667%;
  }

  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xxl-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order           : 13;
    order                    : 13;
  }

  .ant-col-xxl-12 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 50%;
  }

  .ant-col-xxl-push-12 {
    left: 50%;
  }

  .ant-col-xxl-pull-12 {
    right: 50%;
  }

  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xxl-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order           : 12;
    order                    : 12;
  }

  .ant-col-xxl-11 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 45.83333333%;
  }

  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xxl-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order           : 11;
    order                    : 11;
  }

  .ant-col-xxl-10 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 41.66666667%;
  }

  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xxl-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order           : 10;
    order                    : 10;
  }

  .ant-col-xxl-9 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 37.5%;
  }

  .ant-col-xxl-push-9 {
    left: 37.5%;
  }

  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xxl-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order           : 9;
    order                    : 9;
  }

  .ant-col-xxl-8 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 33.33333333%;
  }

  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xxl-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order           : 8;
    order                    : 8;
  }

  .ant-col-xxl-7 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 29.16666667%;
  }

  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xxl-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order           : 7;
    order                    : 7;
  }

  .ant-col-xxl-6 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 25%;
  }

  .ant-col-xxl-push-6 {
    left: 25%;
  }

  .ant-col-xxl-pull-6 {
    right: 25%;
  }

  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xxl-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order           : 6;
    order                    : 6;
  }

  .ant-col-xxl-5 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 20.83333333%;
  }

  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xxl-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order           : 5;
    order                    : 5;
  }

  .ant-col-xxl-4 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 16.66666667%;
  }

  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xxl-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order           : 4;
    order                    : 4;
  }

  .ant-col-xxl-3 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 12.5%;
  }

  .ant-col-xxl-push-3 {
    left: 12.5%;
  }

  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xxl-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order           : 3;
    order                    : 3;
  }

  .ant-col-xxl-2 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 8.33333333%;
  }

  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xxl-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order           : 2;
    order                    : 2;
  }

  .ant-col-xxl-1 {
    display           : block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    width             : 4.16666667%;
  }

  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xxl-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order           : 1;
    order                    : 1;
  }

  .ant-col-xxl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xxl-push-0 {
    left: auto;
  }

  .ant-col-xxl-pull-0 {
    right: auto;
  }

  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xxl-order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order           : 0;
    order                    : 0;
  }
}

.ant-input-number {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  font-variant                 : tabular-nums;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  width                        : 100%;
  height                       : 32px;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  line-height                  : 1.5;
  background-color             : #fff;
  background-image             : none;
  -webkit-transition           : all .3s;
  transition                   : all .3s;
  display                      : inline-block;
  width                        : 90px;
  margin                       : 0;
  padding                      : 0;
  border                       : 1px solid #d9d9d9;
  border-radius                : 4px;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:focus {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &[disabled] {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;

    &:hover {
      border-color      : #d9d9d9;
      border-right-width: 1px !important;
    }
  }
}

textarea.ant-input-number {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-input-number-lg {
  height : 40px;
  padding: 6px 11px;
}

.ant-input-number-sm {
  height : 24px;
  padding: 1px 7px;
}

.ant-input-number-handler {
  position          : relative;
  display           : block;
  width             : 100%;
  height            : 50%;
  overflow          : hidden;
  color             : rgba(0, 0, 0, 0.45);
  font-weight       : 700;
  line-height       : 0;
  text-align        : center;
  -webkit-transition: all .1s linear;
  transition        : all .1s linear;

  &:active {
    background: #f4f4f4;
  }

  &:hover {

    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      color: #40a9ff;
    }
  }
}

.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
  display                : inline-block;
  color                  : inherit;
  font-style             : normal;
  line-height            : 0;
  text-align             : center;
  text-transform         : none;
  vertical-align         : -.125em;
  text-rendering         : optimizeLegibility;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  position               : absolute;
  right                  : 4px;
  width                  : 12px;
  height                 : 12px;
  color                  : rgba(0, 0, 0, 0.45);
  line-height            : 12px;
  -webkit-transition     : all .1s linear;
  transition             : all .1s linear;
  -webkit-user-select    : none;
  -moz-user-select       : none;
  -ms-user-select        : none;
  user-select            : none;
}

.ant-input-number-handler-down-inner>*,
.ant-input-number-handler-up-inner>* {
  line-height: 1;
}

.ant-input-number-handler-down-inner svg,
.ant-input-number-handler-up-inner svg {
  display: inline-block;
}

.ant-input-number-handler-down-inner:before,
.ant-input-number-handler-up-inner:before {
  display: none;
}

.ant-input-number-handler-down-inner {

  .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-up-inner-icon {
    display: block;
  }
}

.ant-input-number-handler-up-inner {

  .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-up-inner-icon {
    display: block;
  }
}

.ant-input-number-focused,
.ant-input-number:hover {
  border-color      : #40a9ff;
  border-right-width: 1px !important;
}

.ant-input-number-focused {
  outline           : 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-input-number-disabled {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }

  .ant-input-number-input {
    cursor: not-allowed;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-input {
  width             : 100%;
  height            : 30px;
  padding           : 0 11px;
  text-align        : right;
  background-color  : rgba(0, 0, 0, 0);
  border            : 0;
  border-radius     : 4px;
  outline           : 0;
  -webkit-transition: all .3s linear;
  transition        : all .3s linear;
  -moz-appearance   : textfield !important;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &[type=number] {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin            : 0;
      -webkit-appearance: none;
    }
  }
}

.ant-input-number-lg {
  padding  : 0;
  font-size: 16px;

  input {
    height: 38px;
  }
}

.ant-input-number-sm {
  padding: 0;

  input {
    height : 22px;
    padding: 0 7px;
  }
}

.ant-input-number-handler-wrap {
  position          : absolute;
  top               : 0;
  left              : 0;
  right             : auto;
  width             : 22px;
  height            : 100%;
  background        : #fff;
  border-left       : 1px solid #d9d9d9;
  border-radius     : 0 4px 4px 0;
  opacity           : 0;
  -webkit-transition: opacity .24s linear .1s;
  transition        : opacity .24s linear .1s;

  .ant-input-number-handler {

    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      display          : inline-block;
      font-size        : 12px;
      font-size        : 7px\9;
      -webkit-transform: scale(0.58333333) rotate(0deg);
      -ms-transform    : scale(0.58333333) rotate(0deg);
      transform        : scale(0.58333333) rotate(0deg);
      min-width        : auto;
      margin-right     : 0;
    }
  }
}

:root .ant-input-number-handler-wrap .ant-input-number-handler {

  .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-inner {
    font-size: 12px;
  }
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-up {
  border-top-right-radius: 4px;
  cursor                 : pointer;
}

.ant-input-number-handler-up-inner {
  top       : 50%;
  margin-top: -5px;
  text-align: center;
}

.ant-input-number-handler-up:hover {
  height: 60% !important;
}

.ant-input-number-handler-down {
  top                       : 0;
  border-top                : 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  cursor                    : pointer;
}

.ant-input-number-handler-down-inner {
  top       : 50%;
  margin-top: -6px;
  text-align: center;
}

.ant-input-number-handler-down:hover {
  height: 60% !important;
}

.ant-input-number-handler-down-disabled,
.ant-input-number-handler-up-disabled {
  cursor: not-allowed;
}

.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  font-variant                 : tabular-nums;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  width                        : 100%;
  height                       : 32px;
  padding                      : 4px 11px;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  line-height                  : 1.5;
  background-color             : #fff;
  background-image             : none;
  border                       : 1px solid #d9d9d9;
  border-radius                : 4px;
  -webkit-transition           : all .3s;
  transition                   : all .3s;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:focus,
  &:hover {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-input-disabled,
.ant-input[disabled] {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

textarea.ant-input {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-input-lg {
  height   : 40px;
  padding  : 6px 11px;
  font-size: 16px;
}

.ant-input-sm {
  height : 24px;
  padding: 1px 7px;
}

.ant-input-group {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : table;
  width                        : 100%;
  border-collapse              : separate;
  border-spacing               : 0;

  &[class*=col-] {
    float        : none;
    padding-right: 0;
    padding-left : 0;
  }

  >[class*=col-] {
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group>.ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon {
  width         : 1px;
  white-space   : nowrap;
  vertical-align: middle;
}

.ant-input-group-wrap {
  width         : 1px;
  white-space   : nowrap;
  vertical-align: middle;

  >* {
    display: block !important;
  }
}

.ant-input-group .ant-input {
  float        : left;
  width        : 100%;
  margin-bottom: 0;
  text-align   : inherit;

  &:focus,
  &:hover {
    z-index           : 1;
    border-right-width: 1px;
  }
}

.ant-input-group-addon {
  position          : relative;
  padding           : 0 11px;
  color             : rgba(0, 0, 0, 0.65);
  font-weight       : 400;
  font-size         : 14px;
  text-align        : center;
  background-color  : #fafafa;
  border            : 1px solid #d9d9d9;
  border-radius     : 4px;
  -webkit-transition: all .3s;
  transition        : all .3s;

  .ant-select {
    margin: -5px -11px;

    .ant-select-selection {
      margin            : -1px;
      background-color  : inherit;
      border            : 1px solid rgba(0, 0, 0, 0);
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection {
    color: #1890ff;
  }

  >i:only-child:after {
    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
    content : "";
  }

  &:first-child {
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;

    .ant-select .ant-select-selection {
      border-top-right-radius   : 0;
      border-bottom-right-radius: 0;
    }
  }
}

.ant-input-group> {
  .ant-input:first-child {
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;

    .ant-select .ant-select-selection {
      border-top-right-radius   : 0;
      border-bottom-right-radius: 0;
    }
  }

  .ant-input-affix-wrapper {
    &:not(:first-child) .ant-input {
      border-top-left-radius   : 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) .ant-input {
      border-top-right-radius   : 0;
      border-bottom-right-radius: 0;
    }
  }
}

.ant-input-group-addon {
  &:first-child {
    border-right: 0;
  }

  &:last-child {
    border-left              : 0;
    border-top-left-radius   : 0;
    border-bottom-left-radius: 0;

    .ant-select .ant-select-selection {
      border-top-left-radius   : 0;
      border-bottom-left-radius: 0;
    }
  }
}

.ant-input-group>.ant-input:last-child {
  border-top-left-radius   : 0;
  border-bottom-left-radius: 0;

  .ant-select .ant-select-selection {
    border-top-left-radius   : 0;
    border-bottom-left-radius: 0;
  }
}

.ant-input-group-lg {

  .ant-input,
  >.ant-input-group-addon {
    height   : 40px;
    padding  : 6px 11px;
    font-size: 16px;
  }
}

.ant-input-group-sm {

  .ant-input,
  >.ant-input-group-addon {
    height : 24px;
    padding: 1px 7px;
  }
}

.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}

.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}

.ant-input-group {
  .ant-input-affix-wrapper {
    display: table-cell;
    float  : left;
    width  : 100%;
  }

  &.ant-input-group-compact {
    display: block;
    zoom   : 1;

    &:after,
    &:before {
      display: table;
      content: "";
    }

    &:after {
      clear: both;
    }
  }

  &.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  &.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  &.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }

  &.ant-input-group-compact-addon:not(:first-child):not(:last-child) {

    &:focus,
    &:hover {
      z-index: 1;
    }
  }

  &.ant-input-group-compact-wrap:not(:first-child):not(:last-child) {

    &:focus,
    &:hover {
      z-index: 1;
    }
  }

  &.ant-input-group-compact {
    > {
      .ant-input:not(:first-child):not(:last-child) {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      * {
        display       : inline-block;
        float         : none;
        vertical-align: top;
        border-radius : 0;
      }

      :not(:last-child) {
        margin-right      : -1px;
        border-right-width: 1px;
      }
    }

    .ant-input {
      float: none;
    }

    > {

      .ant-calendar-picker .ant-input,
      .ant-cascader-picker .ant-input,
      .ant-input-group-wrapper .ant-input,
      .ant-mention-wrapper .ant-mention-editor,
      .ant-select-auto-complete .ant-input,
      .ant-select>.ant-select-selection,
      .ant-time-picker .ant-time-picker-input {
        border-right-width: 1px;
        border-radius     : 0;
      }

      .ant-calendar-picker .ant-input {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-cascader-picker .ant-input {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-input-group-wrapper .ant-input {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-mention-wrapper .ant-mention-editor {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-select-auto-complete .ant-input {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-select>.ant-select-selection {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-time-picker .ant-time-picker-input {

        &:focus,
        &:hover {
          z-index: 1;
        }
      }

      .ant-calendar-picker:first-child .ant-input,
      .ant-cascader-picker:first-child .ant-input,
      .ant-mention-wrapper:first-child .ant-mention-editor,
      .ant-select-auto-complete:first-child .ant-input,
      .ant-select:first-child>.ant-select-selection,
      .ant-time-picker:first-child .ant-time-picker-input,
      :first-child {
        border-top-left-radius   : 4px;
        border-bottom-left-radius: 4px;
      }

      .ant-calendar-picker:last-child .ant-input,
      .ant-cascader-picker-focused:last-child .ant-input,
      .ant-cascader-picker:last-child .ant-input,
      .ant-mention-wrapper:last-child .ant-mention-editor,
      .ant-select-auto-complete:last-child .ant-input,
      .ant-select:last-child>.ant-select-selection,
      .ant-time-picker:last-child .ant-time-picker-input,
      :last-child {
        border-right-width        : 1px;
        border-top-right-radius   : 4px;
        border-bottom-right-radius: 4px;
      }

      .ant-select-auto-complete .ant-input {
        vertical-align: top;
      }
    }
  }
}

.ant-input-group-wrapper {
  display       : inline-block;
  width         : 100%;
  text-align    : start;
  vertical-align: top;
}

.ant-input-affix-wrapper {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  width                        : 100%;
  text-align                   : start;

  &:hover .ant-input:not(.ant-input-disabled) {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }

  .ant-input {
    position  : relative;
    text-align: inherit;
  }

  .ant-input-prefix,
  .ant-input-suffix {
    position         : absolute;
    top              : 50%;
    z-index          : 2;
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    color            : rgba(0, 0, 0, 0.65);
    line-height      : 0;
    -webkit-transform: translateY(-50%);
    -ms-transform    : translateY(-50%);
    transform        : translateY(-50%);
  }

  .ant-input-prefix :not(.anticon),
  .ant-input-suffix :not(.anticon) {
    line-height: 1.5;
  }

  .ant-input-disabled~.ant-input-suffix .anticon {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .ant-input-prefix {
    left: 12px;
  }

  .ant-input-suffix {
    right: 12px;
  }

  .ant-input {
    &:not(:first-child) {
      padding-left: 30px;
    }

    &:not(:last-child) {
      padding-right: 30px;
    }
  }

  &.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
    padding-right: 49px;
  }

  &.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
    padding-right: 22px;
  }

  .ant-input {
    min-height: 100%;
  }
}

.ant-input-password-icon {
  color             : rgba(0, 0, 0, 0.45);
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:hover {
    color: #333;
  }
}

.ant-input-clear-icon {
  color             : rgba(0, 0, 0, 0.25);
  font-size         : 12px;
  cursor            : pointer;
  -webkit-transition: color .3s;
  transition        : color .3s;
  vertical-align    : 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }

  &:active {
    color: rgba(0, 0, 0, 0.65);
  }

  +i {
    margin-left: 6px;
  }
}

.ant-input-textarea-clear-icon {
  color             : rgba(0, 0, 0, 0.25);
  font-size         : 12px;
  cursor            : pointer;
  -webkit-transition: color .3s;
  transition        : color .3s;
  position          : absolute;
  top               : 0;
  right             : 0;
  margin            : 8px 8px 0 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }

  &:active {
    color: rgba(0, 0, 0, 0.65);
  }

  +i {
    margin-left: 6px;
  }
}

.ant-input-search-icon {
  color             : rgba(0, 0, 0, 0.45);
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
}

.ant-input-search-enter-button {
  input {
    border-right: 0;

    +.ant-input-group-addon {
      padding: 0;
      border : 0;
    }

    +.ant-input-group-addon .ant-input-search-button {
      border-top-left-radius   : 0;
      border-bottom-left-radius: 0;
    }
  }

  +.ant-input-group-addon {
    padding: 0;
    border : 0;
  }

  +.ant-input-group-addon .ant-input-search-button {
    border-top-left-radius   : 0;
    border-bottom-left-radius: 0;
  }
}

.ant-layout {
  display              : -webkit-box;
  display              : -ms-flexbox;
  display              : flex;
  -webkit-box-flex     : 1;
  -ms-flex             : auto;
  flex                 : auto;
  -webkit-box-orient   : vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction   : column;
  flex-direction       : column;
  min-height           : 0;
  background           : #f0f2f5;
  -webkit-box-sizing   : border-box;
  box-sizing           : border-box;

  * {
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
  }

  &.ant-layout-has-sider {
    -webkit-box-orient   : horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction   : row;
    flex-direction       : row;

    > {

      .ant-layout,
      .ant-layout-content {
        overflow-x: hidden;
      }
    }
  }
}

.ant-layout-footer {
  -webkit-box-flex: 0;
  -ms-flex        : 0 0 auto;
  flex            : 0 0 auto;
}

.ant-layout-header {
  -webkit-box-flex: 0;
  -ms-flex        : 0 0 auto;
  flex            : 0 0 auto;
  height          : 64px;
  padding         : 0 50px;
  line-height     : 64px;
  background      : #001529;
}

.ant-layout-footer {
  padding   : 24px 50px;
  color     : rgba(0, 0, 0, 0.65);
  font-size : 14px;
  background: #f0f2f5;
}

.ant-layout-content {
  -webkit-box-flex: 1;
  -ms-flex        : auto;
  flex            : auto;
  min-height      : 0;
}

.ant-layout-sider {
  position          : relative;
  min-width         : 0;
  background        : #001529;
  -webkit-transition: all .2s;
  transition        : all .2s;
}

.ant-layout-sider-children {
  height     : 100%;
  margin-top : -.1px;
  padding-top: .1px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}

.ant-layout-sider-right {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order           : 1;
  order                    : 1;
}

.ant-layout-sider-trigger {
  position          : fixed;
  bottom            : 0;
  z-index           : 1;
  height            : 48px;
  color             : #fff;
  line-height       : 48px;
  text-align        : center;
  background        : #002140;
  cursor            : pointer;
  -webkit-transition: all .2s;
  transition        : all .2s;
}

.ant-layout-sider-zero-width>* {
  overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
  position          : absolute;
  top               : 64px;
  right             : -36px;
  z-index           : 1;
  width             : 36px;
  height            : 42px;
  color             : #fff;
  font-size         : 18px;
  line-height       : 42px;
  text-align        : center;
  background        : #001529;
  border-radius     : 0 4px 4px 0;
  cursor            : pointer;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover {
    background: #192c3e;
  }
}

.ant-layout-sider-zero-width-trigger-right {
  left         : -36px;
  border-radius: 4px 0 0 4px;
}

.ant-layout-sider-light {
  background: #fff;

  .ant-layout-sider-trigger,
  .ant-layout-sider-zero-width-trigger {
    color     : rgba(0, 0, 0, 0.65);
    background: #fff;
  }
}

.ant-list {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;

  * {
    outline: none;
  }
}

.ant-list-pagination {
  margin-top: 24px;
  text-align: right;

  .ant-pagination-options {
    text-align: right;
  }
}

.ant-list-more {
  margin-top: 12px;
  text-align: center;

  button {
    padding-right: 32px;
    padding-left : 32px;
  }
}

.ant-list-spin {
  min-height: 40px;
  text-align: center;
}

.ant-list-empty-text {
  padding   : 16px;
  color     : rgba(0, 0, 0, 0.25);
  font-size : 14px;
  text-align: center;
}

.ant-list-items {
  margin    : 0;
  padding   : 0;
  list-style: none;
}

.ant-list-item {
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
  padding          : 12px 0;
}

.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}

.ant-list-item-meta {
  display          : -webkit-box;
  display          : -ms-flexbox;
  display          : flex;
  -webkit-box-flex : 1;
  -ms-flex         : 1;
  flex             : 1;
  -webkit-box-align: start;
  -ms-flex-align   : start;
  align-items      : flex-start;
  font-size        : 0;
}

.ant-list-item-meta-avatar {
  margin-right: 16px;
}

.ant-list-item-meta-content {
  -webkit-box-flex: 1;
  -ms-flex        : 1 0;
  flex            : 1 0;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color        : rgba(0, 0, 0, 0.65);
  font-size    : 14px;
  line-height  : 22px;

  >a {
    color             : rgba(0, 0, 0, 0.65);
    -webkit-transition: all .3s;
    transition        : all .3s;

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-list-item-meta-description {
  color      : rgba(0, 0, 0, 0.45);
  font-size  : 14px;
  line-height: 22px;
}

.ant-list-item-action {
  -webkit-box-flex: 0;
  -ms-flex        : 0 0 auto;
  flex            : 0 0 auto;
  margin-left     : 48px;
  padding         : 0;
  font-size       : 0;
  list-style      : none;

  >li {
    position   : relative;
    display    : inline-block;
    padding    : 0 8px;
    color      : rgba(0, 0, 0, 0.45);
    font-size  : 14px;
    line-height: 22px;
    text-align : center;
    cursor     : pointer;

    &:first-child {
      padding-left: 0;
    }
  }
}

.ant-list-item-action-split {
  position        : absolute;
  top             : 50%;
  right           : 0;
  width           : 1px;
  height          : 14px;
  margin-top      : -7px;
  background-color: #e8e8e8;
}

.ant-list-footer,
.ant-list-header {
  background: rgba(0, 0, 0, 0);
}

.ant-list-footer,
.ant-list-header {
  padding-top   : 12px;
  padding-bottom: 12px;
}

.ant-list-empty {
  padding   : 16px 0;
  color     : rgba(0, 0, 0, 0.45);
  font-size : 12px;
  text-align: center;
}

.ant-list-split {
  .ant-list-item {
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }

  .ant-list-header {
    border-bottom: 1px solid #e8e8e8;
  }
}

.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}

.ant-list-something-after-last-item .ant-spin-container>.ant-list-items>.ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.ant-list-lg .ant-list-item {
  padding-top   : 16px;
  padding-bottom: 16px;
}

.ant-list-sm .ant-list-item {
  padding-top   : 8px;
  padding-bottom: 8px;
}

.ant-list-vertical {
  .ant-list-item {
    -webkit-box-align: initial;
    -ms-flex-align   : initial;
    align-items      : normal;
  }

  .ant-list-item-main {
    display         : block;
    -webkit-box-flex: 1;
    -ms-flex        : 1;
    flex            : 1;
  }

  .ant-list-item-extra {
    margin-left: 40px;
  }

  .ant-list-item-meta {
    margin-bottom: 16px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 12px;
    color        : rgba(0, 0, 0, 0.85);
    font-size    : 16px;
    line-height  : 24px;
  }

  .ant-list-item-action {
    margin-top : 16px;
    margin-left: auto;

    >li {
      padding: 0 16px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.ant-list-grid .ant-col>.ant-list-item {
  display       : block;
  max-width     : 100%;
  margin-bottom : 16px;
  padding-top   : 0;
  padding-bottom: 0;
  border-bottom : none;
}

.ant-list-item-no-flex {
  display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}

.ant-list-bordered {
  border       : 1px solid #d9d9d9;
  border-radius: 4px;

  .ant-list-footer,
  .ant-list-header {
    padding-right: 24px;
    padding-left : 24px;
  }

  .ant-list-item {
    padding-right: 24px;
    padding-left : 24px;
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-list-pagination {
    margin: 16px 24px;
  }

  &.ant-list-sm {
    .ant-list-item {
      padding-right: 16px;
      padding-left : 16px;
    }

    .ant-list-footer,
    .ant-list-header {
      padding: 8px 16px;
    }
  }

  &.ant-list-lg {

    .ant-list-footer,
    .ant-list-header {
      padding: 16px 24px;
    }
  }
}

@media screen and (max-width: 768px) {

  .ant-list-item-action,
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}

@media screen and (max-width: 576px) {
  .ant-list-item {
    -ms-flex-wrap: wrap;
    flex-wrap    : wrap;
  }

  .ant-list-item-action {
    margin-left: 12px;
  }

  .ant-list-vertical {
    .ant-list-item {
      -ms-flex-wrap: wrap-reverse;
      flex-wrap    : wrap-reverse;
    }

    .ant-list-item-main {
      min-width: 220px;
    }

    .ant-list-item-extra {
      margin: auto auto 16px;
    }
  }
}

.ant-mention-wrapper {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  vertical-align               : middle;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  line-height                  : 1.5;
  position                     : relative;
  width                        : 100%;

  .ant-mention-editor {
    padding           : 0;
    color             : rgba(0, 0, 0, 0.65);
    line-height       : 1.5;
    position          : relative;
    width             : 100%;
    display           : inline-block;
    height            : 32px;
    font-size         : 14px;
    background-color  : #fff;
    background-image  : none;
    border            : 1px solid #d9d9d9;
    border-radius     : 4px;
    -webkit-transition: all .3s;
    transition        : all .3s;
    display           : block;
    height            : auto;
    min-height        : 32px;

    &::-moz-placeholder {
      color  : #bfbfbf;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #bfbfbf;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:focus,
    &:hover {
      border-color      : #40a9ff;
      border-right-width: 1px !important;
    }

    &:focus {
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-mention-editor-disabled,
  .ant-mention-editor[disabled] {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;

    &:hover {
      border-color      : #d9d9d9;
      border-right-width: 1px !important;
    }
  }
}

textarea.ant-mention-wrapper .ant-mention-editor {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-mention-wrapper {
  .ant-mention-editor-lg {
    height   : 40px;
    padding  : 6px 11px;
    font-size: 16px;
  }

  .ant-mention-editor-sm {
    height : 24px;
    padding: 1px 7px;
  }

  .ant-mention-editor-wrapper {
    height    : auto;
    overflow-y: auto;
  }

  &.ant-mention-active:not(.disabled) .ant-mention-editor {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &.disabled .ant-mention-editor {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;

    &:hover {
      border-color      : #d9d9d9;
      border-right-width: 1px !important;
    }
  }

  .public-DraftEditorPlaceholder-root {
    position      : absolute;
    pointer-events: none;

    .public-DraftEditorPlaceholder-inner {
      height     : auto;
      padding    : 5px 11px;
      color      : #bfbfbf;
      white-space: pre-wrap;
      word-wrap  : break-word;
      outline    : none;
      opacity    : 1;
    }
  }

  .DraftEditor-editorContainer .public-DraftEditor-content {
    height : auto;
    padding: 5px 11px;
  }
}

.ant-mention-dropdown {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  top                          : -9999px;
  left                         : -9999px;
  z-index                      : 1050;
  min-width                    : 120px;
  max-height                   : 250px;
  margin                       : 1.5em 0 0;
  overflow-x                   : hidden;
  overflow-y                   : auto;
  background-color             : #fff;
  border-radius                : 4px;
  outline                      : none;
  -webkit-box-shadow           : 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow                   : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-mention-dropdown-placement-top {
  margin-top: -.1em;
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);

  .anticon-loading {
    display   : block;
    color     : #1890ff;
    text-align: center;
  }
}

.ant-mention-dropdown-item {
  position          : relative;
  display           : block;
  padding           : 5px 12px;
  overflow          : hidden;
  color             : rgba(0, 0, 0, 0.65);
  font-weight       : 400;
  line-height       : 22px;
  white-space       : nowrap;
  text-overflow     : ellipsis;
  cursor            : pointer;
  -webkit-transition: background .3s;
  transition        : background .3s;
}

.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}

.ant-mention-dropdown-item {

  &.focus,
  &:hover {
    background-color: #e6f7ff;
  }
}

.ant-mention-dropdown-item-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor          : not-allowed;
  }
}

.ant-mention-dropdown-item-selected {
  color           : rgba(0, 0, 0, 0.65);
  font-weight     : 700;
  background-color: #f5f5f5;

  &:hover {
    color           : rgba(0, 0, 0, 0.65);
    font-weight     : 700;
    background-color: #f5f5f5;
  }
}

.ant-mention-dropdown-item-divider {
  height          : 1px;
  margin          : 1px 0;
  overflow        : hidden;
  line-height     : 0;
  background-color: #e8e8e8;
}

.ant-mentions {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  font-variant                 : tabular-nums;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  width                        : 100%;
  height                       : 32px;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  background-color             : #fff;
  background-image             : none;
  border                       : 1px solid #d9d9d9;
  border-radius                : 4px;
  -webkit-transition           : all .3s;
  transition                   : all .3s;
  position                     : relative;
  display                      : inline-block;
  height                       : auto;
  padding                      : 0;
  overflow                     : hidden;
  line-height                  : 1.5;
  white-space                  : pre-wrap;
  vertical-align               : bottom;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:focus,
  &:hover {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-mentions-disabled,
.ant-mentions[disabled] {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

textarea.ant-mentions {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-mentions-lg {
  height   : 40px;
  padding  : 6px 11px;
  font-size: 16px;
}

.ant-mentions-sm {
  height : 24px;
  padding: 1px 7px;
}

.ant-mentions-disabled>textarea {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

.ant-mentions-focused {
  border-color      : #40a9ff;
  border-right-width: 1px !important;
  outline           : 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-mentions-measure {
  min-height      : 30px;
  margin          : 0;
  padding         : 4px 11px;
  overflow        : inherit;
  overflow-x      : hidden;
  overflow-y      : auto;
  font-weight     : inherit;
  font-size       : inherit;
  font-family     : inherit;
  font-style      : inherit;
  font-variant    : inherit;
  font-size-adjust: inherit;
  font-stretch    : inherit;
  line-height     : inherit;
  direction       : inherit;
  letter-spacing  : inherit;
  white-space     : inherit;
  text-align      : inherit;
  vertical-align  : top;
  word-wrap       : break-word;
  word-break      : inherit;
  -moz-tab-size   : inherit;
  -o-tab-size     : inherit;
  tab-size        : inherit;
}

.ant-mentions>textarea {
  min-height      : 30px;
  margin          : 0;
  padding         : 4px 11px;
  overflow        : inherit;
  overflow-x      : hidden;
  overflow-y      : auto;
  font-weight     : inherit;
  font-size       : inherit;
  font-family     : inherit;
  font-style      : inherit;
  font-variant    : inherit;
  font-size-adjust: inherit;
  font-stretch    : inherit;
  line-height     : inherit;
  direction       : inherit;
  letter-spacing  : inherit;
  white-space     : inherit;
  text-align      : inherit;
  vertical-align  : top;
  word-wrap       : break-word;
  word-break      : inherit;
  -moz-tab-size   : inherit;
  -o-tab-size     : inherit;
  tab-size        : inherit;
  width           : 100%;
  border          : none;
  outline         : none;
  resize          : none;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:-moz-read-only,
  &:read-only {
    cursor: default;
  }
}

.ant-mentions-measure {
  position      : absolute;
  top           : 0;
  right         : 0;
  bottom        : 0;
  left          : 0;
  z-index       : -1;
  color         : rgba(0, 0, 0, 0);
  pointer-events: none;

  >span {
    display   : inline-block;
    min-height: 1em;
  }
}

.ant-mentions-dropdown {
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  top                          : -9999px;
  left                         : -9999px;
  z-index                      : 1050;
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  font-size                    : 14px;
  font-variant                 : normal;
  background-color             : #fff;
  border-radius                : 4px;
  outline                      : none;
  -webkit-box-shadow           : 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow                   : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-mentions-dropdown-hidden {
  display: none;
}

.ant-mentions-dropdown-menu {
  max-height   : 250px;
  margin-bottom: 0;
  padding-left : 0;
  overflow     : auto;
  list-style   : none;
  outline      : none;
}

.ant-mentions-dropdown-menu-item {
  position          : relative;
  display           : block;
  min-width         : 100px;
  padding           : 5px 12px;
  overflow          : hidden;
  color             : rgba(0, 0, 0, 0.65);
  font-weight       : 400;
  line-height       : 22px;
  white-space       : nowrap;
  text-overflow     : ellipsis;
  cursor            : pointer;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover {
    background-color: #e6f7ff;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.ant-mentions-dropdown-menu-item-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor          : not-allowed;
  }
}

.ant-mentions-dropdown-menu-item-selected {
  color           : rgba(0, 0, 0, 0.65);
  font-weight     : 600;
  background-color: #fafafa;
}

.ant-mentions-dropdown-menu-item-active {
  background-color: #e6f7ff;
}

.ant-menu {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  line-height                  : 0;
  list-style                   : none;
  background                   : #fff;
  outline                      : none;
  -webkit-box-shadow           : 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow                   : 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transition           : background .3s, width .2s;
  transition                   : background .3s, width .2s;
  zoom                         : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  ol,
  ul {
    margin    : 0;
    padding   : 0;
    list-style: none;
  }
}

.ant-menu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  padding           : 8px 16px;
  color             : rgba(0, 0, 0, 0.45);
  font-size         : 14px;
  line-height       : 1.5;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  -webkit-transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff;
}

.ant-menu-submenu .ant-menu-sub {
  cursor            : auto;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item> {
  a {
    display: block;
    color  : rgba(0, 0, 0, 0.65);

    &:hover {
      color: #1890ff;
    }

    &:before {
      position        : absolute;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      background-color: rgba(0, 0, 0, 0);
      content         : "";
    }
  }

  .ant-badge>a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-menu-item-divider {
  height          : 1px;
  overflow        : hidden;
  line-height     : 0;
  background-color: #e8e8e8;
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #1890ff;
}

.ant-menu-horizontal {

  .ant-menu-item,
  .ant-menu-submenu {
    margin-top: -1px;
  }

  > {

    .ant-menu-item-active,
    .ant-menu-item:hover,
    .ant-menu-submenu .ant-menu-submenu-title:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.ant-menu-item-selected {
  color: #1890ff;

  >a {
    color: #1890ff;

    &:hover {
      color: #1890ff;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}

.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
  min-width               : 160px;
  padding                 : 0;
  border-right            : 0;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin    : 0 0;
  transform-origin        : 0 0;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  left        : 0;
  margin-left : 0;
  border-right: 0;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
  border-right: 0;
}

.ant-menu-vertical-left.ant-menu-sub> {

  .ant-menu-item,
  .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin    : 0 0;
    transform-origin        : 0 0;
  }
}

.ant-menu-vertical-right.ant-menu-sub> {

  .ant-menu-item,
  .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin    : 0 0;
    transform-origin        : 0 0;
  }
}

.ant-menu-vertical.ant-menu-sub> {

  .ant-menu-item,
  .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin    : 0 0;
    transform-origin        : 0 0;
  }
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-item,
.ant-menu-submenu-title {
  position          : relative;
  display           : block;
  margin            : 0;
  padding           : 0 20px;
  white-space       : nowrap;
  cursor            : pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width         : 14px;
  margin-right      : 10px;
  font-size         : 14px;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .anticon+span,
.ant-menu-submenu-title .anticon+span {
  opacity           : 1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu>.ant-menu-item-divider {
  height          : 1px;
  margin          : 1px 0;
  padding         : 0;
  overflow        : hidden;
  line-height     : 0;
  background-color: #e8e8e8;
}

.ant-menu-submenu-popup {
  position     : absolute;
  z-index      : 1050;
  background   : #fff;
  border-radius: 4px;

  .submenu-title-wrapper {
    padding-right: 20px;
  }

  &:before {
    position: absolute;
    top     : -7px;
    right   : 0;
    bottom  : 0;
    left    : 0;
    opacity : .0001;
    content : " ";
  }
}

.ant-menu-submenu> {
  .ant-menu {
    background-color: #fff;
    border-radius   : 4px;
  }

  .ant-menu-submenu-title:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  position          : absolute;
  top               : 50%;
  right             : 16px;
  width             : 10px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {

  &:after,
  &:before {
    position          : absolute;
    width             : 6px;
    height            : 1.5px;
    background        : #fff;
    background        : rgba(0, 0, 0, 0.65) \9;
    background-image  : -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
    background-image  : linear-gradient(90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image  : none\9;
    border-radius     : 2px;
    -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content           : "";
  }
}

.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow {

  &:after,
  &:before {
    position          : absolute;
    width             : 6px;
    height            : 1.5px;
    background        : #fff;
    background        : rgba(0, 0, 0, 0.65) \9;
    background-image  : -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
    background-image  : linear-gradient(90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image  : none\9;
    border-radius     : 2px;
    -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content           : "";
  }
}

.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow {

  &:after,
  &:before {
    position          : absolute;
    width             : 6px;
    height            : 1.5px;
    background        : #fff;
    background        : rgba(0, 0, 0, 0.65) \9;
    background-image  : -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
    background-image  : linear-gradient(90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image  : none\9;
    border-radius     : 2px;
    -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content           : "";
  }
}

.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow {

  &:after,
  &:before {
    position          : absolute;
    width             : 6px;
    height            : 1.5px;
    background        : #fff;
    background        : rgba(0, 0, 0, 0.65) \9;
    background-image  : -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
    background-image  : linear-gradient(90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image  : none\9;
    border-radius     : 2px;
    -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content           : "";
  }
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  -webkit-transform: rotate(45deg) translateY(-2px);
  -ms-transform    : rotate(45deg) translateY(-2px);
  transform        : rotate(45deg) translateY(-2px);
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(2px);
  -ms-transform    : rotate(-45deg) translateY(2px);
  transform        : rotate(-45deg) translateY(2px);
}

.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
    background: linear-gradient(90deg, #1890ff, #1890ff);
  }
}

.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
    background: linear-gradient(90deg, #1890ff, #1890ff);
  }
}

.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
    background: linear-gradient(90deg, #1890ff, #1890ff);
  }
}

.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
    background: linear-gradient(90deg, #1890ff, #1890ff);
  }
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  &:before {
    -webkit-transform: rotate(-45deg) translateX(2px);
    -ms-transform    : rotate(-45deg) translateX(2px);
    transform        : rotate(-45deg) translateX(2px);
  }

  &:after {
    -webkit-transform: rotate(45deg) translateX(-2px);
    -ms-transform    : rotate(45deg) translateX(-2px);
    transform        : rotate(45deg) translateX(-2px);
  }
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
  -ms-transform    : translateY(-2px);
  transform        : translateY(-2px);

  &:after {
    -webkit-transform: rotate(-45deg) translateX(-2px);
    -ms-transform    : rotate(-45deg) translateX(-2px);
    transform        : rotate(-45deg) translateX(-2px);
  }

  &:before {
    -webkit-transform: rotate(45deg) translateX(2px);
    -ms-transform    : rotate(45deg) translateX(2px);
    transform        : rotate(45deg) translateX(2px);
  }
}

.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected,
.ant-menu-vertical .ant-menu-submenu-selected {
  color: #1890ff;

  >a {
    color: #1890ff;
  }
}

.ant-menu-horizontal {
  line-height       : 46px;
  white-space       : nowrap;
  border            : 0;
  border-bottom     : 1px solid #e8e8e8;
  -webkit-box-shadow: none;
  box-shadow        : none;

  > {

    .ant-menu-item,
    .ant-menu-submenu {
      position      : relative;
      top           : 1px;
      display       : inline-block;
      vertical-align: bottom;
      border-bottom : 2px solid rgba(0, 0, 0, 0);
    }

    .ant-menu-item-active,
    .ant-menu-item-open,
    .ant-menu-item-selected,
    .ant-menu-item:hover,
    .ant-menu-submenu-active,
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected,
    .ant-menu-submenu:hover {
      color        : #1890ff;
      border-bottom: 2px solid #1890ff;
    }

    .ant-menu-item>a {
      display: block;
      color  : rgba(0, 0, 0, 0.65);

      &:hover {
        color: #1890ff;
      }

      &:before {
        bottom: -2px;
      }
    }

    .ant-menu-item-selected>a {
      color: #1890ff;
    }
  }

  &:after {
    display: block;
    clear  : both;
    height : 0;
    content: "\20";
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
  position: relative;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  position          : absolute;
  top               : 0;
  right             : 0;
  bottom            : 0;
  border-right      : 3px solid #1890ff;
  -webkit-transform : scaleY(0.0001);
  -ms-transform     : scaleY(0.0001);
  transform         : scaleY(0.0001);
  opacity           : 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition        : opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition        : transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition        : transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content           : "";
}

.ant-menu-inline {

  .ant-menu-item,
  .ant-menu-submenu-title {
    height       : 40px;
    margin-top   : 4px;
    margin-bottom: 4px;
    padding      : 0 16px;
    overflow     : hidden;
    font-size    : 14px;
    line-height  : 40px;
    text-overflow: ellipsis;
  }
}

.ant-menu-vertical-left {

  .ant-menu-item,
  .ant-menu-submenu-title {
    height       : 40px;
    margin-top   : 4px;
    margin-bottom: 4px;
    padding      : 0 16px;
    overflow     : hidden;
    font-size    : 14px;
    line-height  : 40px;
    text-overflow: ellipsis;
  }
}

.ant-menu-vertical-right {

  .ant-menu-item,
  .ant-menu-submenu-title {
    height       : 40px;
    margin-top   : 4px;
    margin-bottom: 4px;
    padding      : 0 16px;
    overflow     : hidden;
    font-size    : 14px;
    line-height  : 40px;
    text-overflow: ellipsis;
  }
}

.ant-menu-vertical {

  .ant-menu-item,
  .ant-menu-submenu-title {
    height       : 40px;
    margin-top   : 4px;
    margin-bottom: 4px;
    padding      : 0 16px;
    overflow     : hidden;
    font-size    : 14px;
    line-height  : 40px;
    text-overflow: ellipsis;
  }
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  padding-bottom: .02px;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-inline> {

  .ant-menu-item,
  .ant-menu-submenu>.ant-menu-submenu-title {
    height     : 40px;
    line-height: 40px;
  }
}

.ant-menu-vertical-left> {

  .ant-menu-item,
  .ant-menu-submenu>.ant-menu-submenu-title {
    height     : 40px;
    line-height: 40px;
  }
}

.ant-menu-vertical-right> {

  .ant-menu-item,
  .ant-menu-submenu>.ant-menu-submenu-title {
    height     : 40px;
    line-height: 40px;
  }
}

.ant-menu-vertical> {

  .ant-menu-item,
  .ant-menu-submenu>.ant-menu-submenu-title {
    height     : 40px;
    line-height: 40px;
  }
}

.ant-menu-inline {
  width: 100%;

  .ant-menu-item-selected:after,
  .ant-menu-selected:after {
    -webkit-transform : scaleY(1);
    -ms-transform     : scaleY(1);
    transform         : scaleY(1);
    opacity           : 1;
    -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-menu-item {
    width: calc(100% + 1px);
  }

  .ant-menu-submenu-title {
    width        : calc(100% + 1px);
    padding-right: 34px;
  }
}

.ant-menu-inline-collapsed {
  width: 80px;

  > {
    .ant-menu-item {
      left         : 0;
      padding      : 0 32px !important;
      text-overflow: clip;
    }

    .ant-menu-item-group>.ant-menu-item-group-list> {

      .ant-menu-item,
      .ant-menu-submenu>.ant-menu-submenu-title {
        left         : 0;
        padding      : 0 32px !important;
        text-overflow: clip;
      }
    }

    .ant-menu-submenu>.ant-menu-submenu-title {
      left         : 0;
      padding      : 0 32px !important;
      text-overflow: clip;
    }

    .ant-menu-item-group>.ant-menu-item-group-list> {

      .ant-menu-item .ant-menu-submenu-arrow,
      .ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
        display: none;
      }
    }

    .ant-menu-item .ant-menu-submenu-arrow,
    .ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-menu-item-group>.ant-menu-item-group-list> {

      .ant-menu-item .anticon,
      .ant-menu-submenu>.ant-menu-submenu-title .anticon {
        margin     : 0;
        font-size  : 16px;
        line-height: 40px;
      }
    }

    .ant-menu-item .anticon,
    .ant-menu-submenu>.ant-menu-submenu-title .anticon {
      margin     : 0;
      font-size  : 16px;
      line-height: 40px;
    }

    .ant-menu-item-group>.ant-menu-item-group-list> {

      .ant-menu-item .anticon+span,
      .ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
        display  : inline-block;
        max-width: 0;
        opacity  : 0;
      }
    }

    .ant-menu-item .anticon+span,
    .ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
      display  : inline-block;
      max-width: 0;
      opacity  : 0;
    }
  }
}

.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;

  .anticon {
    display: none;
  }

  a {
    color: hsla(0, 0%, 100%, 0.85);
  }
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left : 4px;
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-menu-item-group-list {
  margin : 0;
  padding: 0;

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
}

.ant-menu-root {

  &.ant-menu-inline,
  &.ant-menu-vertical,
  &.ant-menu-vertical-left,
  &.ant-menu-vertical-right {
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-menu-sub.ant-menu-inline {
  -webkit-box-shadow: none;
  box-shadow        : none;
  padding           : 0;
  border            : 0;
  border-radius     : 0;

  > {

    .ant-menu-item,
    .ant-menu-submenu>.ant-menu-submenu-title {
      height             : 40px;
      line-height        : 40px;
      list-style-position: inside;
      list-style-type    : disc;
    }
  }

  .ant-menu-item-group-title {
    padding-left: 32px;
  }
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color       : rgba(0, 0, 0, 0.25) !important;
  background  : none;
  border-color: rgba(0, 0, 0, 0) !important;
  cursor      : not-allowed;
}

.ant-menu-item-disabled>a,
.ant-menu-submenu-disabled>a {
  color         : rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}

.ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-submenu-disabled>.ant-menu-submenu-title {
  color : rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow {

  &:after,
  &:before {
    background: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-menu-dark {
  color     : hsla(0, 0%, 100%, 0.65);
  background: #001529;

  .ant-menu-sub {
    color     : hsla(0, 0%, 100%, 0.65);
    background: #001529;

    .ant-menu-submenu-title .ant-menu-submenu-arrow {
      opacity           : .45;
      -webkit-transition: all .3s;
      transition        : all .3s;
    }
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity           : .45;
    -webkit-transition: all .3s;
    transition        : all .3s;
  }

  .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {

    &:after,
    &:before {
      background: #fff;
    }
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {

    &:after,
    &:before {
      background: #fff;
    }
  }

  &.ant-menu-submenu-popup {
    background: rgba(0, 0, 0, 0);
  }

  .ant-menu-inline.ant-menu-sub {
    background        : #000c17;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
    box-shadow        : inset 0 2px 8px rgba(0, 0, 0, 0.45);
  }

  &.ant-menu-horizontal {
    border-bottom: 0;

    > {

      .ant-menu-item,
      .ant-menu-submenu {
        top          : 0;
        margin-top   : 0;
        border-color : #001529;
        border-bottom: 0;
      }

      .ant-menu-item>a:before {
        bottom: 0;
      }
    }
  }

  .ant-menu-item,
  .ant-menu-item-group-title,
  .ant-menu-item>a {
    color: hsla(0, 0%, 100%, 0.65);
  }

  &.ant-menu-inline,
  &.ant-menu-vertical,
  &.ant-menu-vertical-left,
  &.ant-menu-vertical-right {
    border-right: 0;
  }

  &.ant-menu-inline .ant-menu-item,
  &.ant-menu-vertical-left .ant-menu-item,
  &.ant-menu-vertical-right .ant-menu-item,
  &.ant-menu-vertical .ant-menu-item {
    left        : 0;
    margin-left : 0;
    border-right: 0;
  }

  &.ant-menu-inline .ant-menu-item:after,
  &.ant-menu-vertical-left .ant-menu-item:after,
  &.ant-menu-vertical-right .ant-menu-item:after,
  &.ant-menu-vertical .ant-menu-item:after {
    border-right: 0;
  }

  &.ant-menu-inline {

    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 100%;
    }
  }

  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover {
    color           : #fff;
    background-color: rgba(0, 0, 0, 0);
  }

  .ant-menu-item-active>a,
  .ant-menu-item:hover>a,
  .ant-menu-submenu-active>a,
  .ant-menu-submenu-open>a,
  .ant-menu-submenu-selected>a,
  .ant-menu-submenu-title:hover>a {
    color: #fff;
  }

  .ant-menu-item-active>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-item:hover>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-submenu-active>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-submenu-open>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-submenu-selected>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-submenu-title:hover>.ant-menu-submenu-title {

    &:hover>.ant-menu-submenu-arrow,
    >.ant-menu-submenu-arrow {
      opacity: 1;
    }
  }

  .ant-menu-item-active>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-item:hover>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-submenu-active>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-submenu-open>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-submenu-selected>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-submenu-title:hover>.ant-menu-submenu-title {
    &:hover>.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }

    >.ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: #fff;
      }
    }
  }

  .ant-menu-item:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .ant-menu-item-selected {
    color       : #fff;
    border-right: 0;

    &:after {
      border-right: 0;
    }

    .anticon {
      color: #fff;

      +span {
        color: #fff;
      }
    }

    >a {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}

.ant-menu-dark {

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    color  : hsla(0, 0%, 100%, 0.35) !important;
    opacity: .8;

    >a {
      color  : hsla(0, 0%, 100%, 0.35) !important;
      opacity: .8;
    }
  }

  .ant-menu-item-disabled>.ant-menu-submenu-title,
  .ant-menu-submenu-disabled>.ant-menu-submenu-title {
    color: hsla(0, 0%, 100%, 0.35) !important;
  }

  .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow {

    &:after,
    &:before {
      background: hsla(0, 0%, 100%, 0.35) !important;
    }
  }

  .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow {

    &:after,
    &:before {
      background: hsla(0, 0%, 100%, 0.35) !important;
    }
  }
}

.ant-message {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : fixed;
  top                          : 16px;
  left                         : 0;
  z-index                      : 2010;
  width                        : 100%;
  pointer-events               : none;
}

.ant-message-notice {
  padding   : 8px;
  text-align: center;

  &:first-child {
    margin-top: -8px;
  }
}

.ant-message-notice-content {
  display           : inline-block;
  padding           : 10px 16px;
  background        : #fff;
  border-radius     : 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events    : all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #f5222d;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  position : relative;
  top      : -3px;
  margin   : 0 8px;
  font-size: 16px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow                  : hidden;
  -webkit-animation-name    : MessageMoveOut;
  animation-name            : MessageMoveOut;
  -webkit-animation-duration: .3s;
  animation-duration        : .3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding   : 8px;
    opacity   : 1;
  }

  to {
    max-height: 0;
    padding   : 0;
    opacity   : 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding   : 8px;
    opacity   : 1;
  }

  to {
    max-height: 0;
    padding   : 0;
    opacity   : 0;
  }
}

.ant-modal {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  top                          : 100px;
  width                        : auto;
  margin                       : 0 auto;
  padding                      : 0 0 24px;
  pointer-events               : none;
}

.ant-modal-wrap {
  position                  : fixed;
  top                       : 0;
  right                     : 0;
  bottom                    : 0;
  left                      : 0;
  z-index                   : 1022;
  overflow                  : auto;
  outline                   : 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-title {
  margin     : 0;
  color      : rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size  : 16px;
  line-height: 22px;
  word-wrap  : break-word;
}

.ant-modal-content {
  position          : relative;
  background-color  : #fff;
  background-clip   : padding-box;
  border            : 0;
  border-radius     : 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events    : auto;
}

.ant-modal-close {
  position          : absolute;
  top               : 0;
  left              : 0;
  right             : auto;
  z-index           : 10;
  padding           : 0;
  color             : rgba(0, 0, 0, 0.45);
  font-weight       : 700;
  line-height       : 1;
  text-decoration   : none;
  background        : rgba(0, 0, 0, 0);
  border            : 0;
  outline           : 0;
  cursor            : pointer;
  -webkit-transition: color .3s;
  transition        : color .3s;
}

.ant-modal-close-x {
  display       : block;
  width         : 56px;
  height        : 56px;
  font-size     : 16px;
  font-style    : normal;
  line-height   : 56px;
  text-align    : center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close {

  &:focus,
  &:hover {
    color          : rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
}

.ant-modal-header {
  padding      : 16px 24px;
  color        : rgba(0, 0, 0, 0.65);
  background   : #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.ant-modal-body {
  padding    : 24px;
  font-size  : 14px;
  line-height: 1.5;
  word-wrap  : break-word;
}

.ant-modal-footer {
  padding      : 10px 16px;
  text-align   : right;
  background   : rgba(0, 0, 0, 0);
  border-top   : 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;

  button+button {
    margin-bottom: 0;
    margin-right : 8px;
  }
}

.ant-modal {

  &.zoom-appear,
  &.zoom-enter {
    -webkit-transform         : none;
    -ms-transform             : none;
    transform                 : none;
    opacity                   : 0;
    -webkit-animation-duration: .3s;
    animation-duration        : .3s;
    -webkit-user-select       : none;
    -moz-user-select          : none;
    -ms-user-select           : none;
    user-select               : none;
  }
}

.ant-modal-mask {
  position        : fixed;
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  z-index         : 1022;
  height          : 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter          : alpha(opacity=50);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;

  &:before {
    display       : inline-block;
    width         : 0;
    height        : 100%;
    vertical-align: middle;
    content       : "";
  }

  .ant-modal {
    top           : 0;
    display       : inline-block;
    text-align    : right;
    vertical-align: middle;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin   : 8px auto;
  }

  .ant-modal-centered .ant-modal {
    -webkit-box-flex: 1;
    -ms-flex        : 1;
    flex            : 1;
  }
}

.ant-modal-confirm {

  .ant-modal-close,
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 32px 32px 24px;
  }
}

.ant-modal-confirm-body-wrapper {
  zoom: 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-modal-confirm-body {
  .ant-modal-confirm-title {
    display    : block;
    overflow   : hidden;
    color      : rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size  : 16px;
    line-height: 1.4;
  }

  .ant-modal-confirm-content {
    margin-top: 8px;
    color     : rgba(0, 0, 0, 0.65);
    font-size : 14px;
  }

  >.anticon {
    float       : left;
    margin-right: 16px;
    font-size   : 22px;

    +.ant-modal-confirm-title+.ant-modal-confirm-content {
      margin-left: 38px;
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float     : right;
  margin-top: 24px;

  button+button {
    margin-bottom: 0;
    margin-left  : 8px;
  }
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
  color: #f5222d;
}

.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
  color: #52c41a;
}

.ant-notification {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : fixed;
  z-index                      : 1010;
  width                        : 384px;
  max-width                    : calc(100vw - 32px);
  margin                       : 0 24px 0 0;
}

.ant-notification-bottomLeft,
.ant-notification-topLeft {
  margin-right: 0;
  margin-left : 24px;
}

.ant-notification-bottomLeft {

  .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name        : NotificationLeftFadeIn;
  }
}

.ant-notification-topLeft {

  .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name        : NotificationLeftFadeIn;
  }
}

.ant-notification-close-icon {
  font-size: 14px;
  cursor   : pointer;
}

.ant-notification-notice {
  position          : relative;
  margin-bottom     : 16px;
  padding           : 16px 24px;
  overflow          : hidden;
  line-height       : 1.5;
  background        : #fff;
  border-radius     : 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-notification-notice-message {
  display      : inline-block;
  margin-bottom: 8px;
  color        : rgba(0, 0, 0, 0.85);
  font-size    : 16px;
  line-height  : 24px;
}

.ant-notification-notice-message-single-line-auto-margin {
  display         : block;
  width           : calc(264px - 100%);
  max-width       : 4px;
  background-color: rgba(0, 0, 0, 0);
  pointer-events  : none;

  &:before {
    display: block;
    content: "";
  }
}

.ant-notification-notice-description {
  font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}

.ant-notification-notice-with-icon {
  .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left  : 48px;
    font-size    : 16px;
  }

  .ant-notification-notice-description {
    margin-left: 48px;
    font-size  : 14px;
  }
}

.ant-notification-notice-icon {
  position   : absolute;
  margin-left: 4px;
  font-size  : 24px;
  line-height: 24px;
}

.anticon {
  &.ant-notification-notice-icon-success {
    color: #52c41a;
  }

  &.ant-notification-notice-icon-info {
    color: #1890ff;
  }

  &.ant-notification-notice-icon-warning {
    color: #faad14;
  }

  &.ant-notification-notice-icon-error {
    color: #f5222d;
  }
}

.ant-notification-notice-close {
  position: absolute;
  top     : 16px;
  right   : 22px;
  color   : rgba(0, 0, 0, 0.45);
  outline : none;

  &:hover {
    color: rgba(0, 0, 0, 0.67);
  }
}

.ant-notification-notice-btn {
  float     : right;
  margin-top: 16px;
}

.ant-notification .notification-fade-effect {
  -webkit-animation-duration       : .24s;
  animation-duration               : .24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function        : cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode      : both;
  animation-fill-mode              : both;
}

.ant-notification-fade-appear,
.ant-notification-fade-enter {
  opacity                     : 0;
  -webkit-animation-play-state: paused;
  animation-play-state        : paused;
}

.ant-notification-fade-appear,
.ant-notification-fade-enter {
  -webkit-animation-duration       : .24s;
  animation-duration               : .24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function        : cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode      : both;
  animation-fill-mode              : both;
}

.ant-notification-fade-leave {
  -webkit-animation-duration       : .24s;
  animation-duration               : .24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function        : cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode      : both;
  animation-fill-mode              : both;
  -webkit-animation-duration       : .2s;
  animation-duration               : .2s;
  -webkit-animation-play-state     : paused;
  animation-play-state             : paused;
}

.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-fade-enter.ant-notification-fade-enter-active {
  -webkit-animation-name      : NotificationFadeIn;
  animation-name              : NotificationFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name      : NotificationFadeOut;
  animation-name              : NotificationFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state        : running;
}

@-webkit-keyframes NotificationFadeIn {
  0% {
    left   : 384px;
    opacity: 0;
  }

  to {
    left   : 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeIn {
  0% {
    left   : 384px;
    opacity: 0;
  }

  to {
    left   : 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right  : 384px;
    opacity: 0;
  }

  to {
    right  : 0;
    opacity: 1;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    right  : 384px;
    opacity: 0;
  }

  to {
    right  : 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height    : 150px;
    margin-bottom : 16px;
    padding-top   : 16px 24px;
    padding-bottom: 16px 24px;
    opacity       : 1;
  }

  to {
    max-height    : 0;
    margin-bottom : 0;
    padding-top   : 0;
    padding-bottom: 0;
    opacity       : 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    max-height    : 150px;
    margin-bottom : 16px;
    padding-top   : 16px 24px;
    padding-bottom: 16px 24px;
    opacity       : 1;
  }

  to {
    max-height    : 0;
    margin-bottom : 0;
    padding-top   : 0;
    padding-bottom: 0;
    opacity       : 0;
  }
}

.ant-page-header {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  padding                      : 16px 24px;
  background-color             : #fff;
}

.ant-page-header-ghost {
  background-color: inherit;
}

.ant-page-header {
  &.has-breadcrumb {
    padding-top: 12px;
  }

  &.has-footer {
    padding-bottom: 0;
  }
}

.ant-page-header-back {
  float      : left;
  margin     : 8px 16px 8px 0;
  font-size  : 16px;
  line-height: 1;
}

.ant-page-header-back-button {
  color             : #1890ff;
  text-decoration   : none;
  outline           : none;
  -webkit-transition: color .3s;
  transition        : color .3s;
  color             : #000;
  cursor            : pointer;

  &:focus,
  &:hover {
    color: #40a9ff;
  }

  &:active {
    color: #096dd9;
  }
}

.ant-page-header .ant-divider-vertical {
  height        : 14px;
  margin        : 0 12px;
  vertical-align: middle;
}

.ant-breadcrumb+.ant-page-header-heading {
  margin-top: 8px;
}

.ant-page-header-heading {
  width   : 100%;
  overflow: hidden;
}

.ant-page-header-heading-title {
  display      : block;
  float        : left;
  margin-bottom: 0;
  padding-right: 12px;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 600;
  font-size    : 20px;
  line-height  : 32px;
}

.ant-page-header-heading .ant-avatar {
  float       : left;
  margin-right: 12px;
}

.ant-page-header-heading-sub-title {
  float      : left;
  margin     : 5px 12px 5px 0;
  color      : rgba(0, 0, 0, 0.45);
  font-size  : 14px;
  line-height: 22px;
}

.ant-page-header-heading-tags {
  float : left;
  margin: 4px 0;
}

.ant-page-header-heading-extra {
  float: right;

  > {
    * {
      margin-left: 8px;
    }

    :first-child {
      margin-left: 0;
    }
  }
}

.ant-page-header-content {
  padding-top: 12px;
  overflow   : hidden;
}

.ant-page-header-footer {
  margin-top: 16px;

  .ant-tabs-bar {
    margin-bottom: 1px;
    border-bottom: 0;

    .ant-tabs-nav .ant-tabs-tab {
      padding  : 8px;
      font-size: 16px;
    }
  }
}

@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display    : block;
    float      : unset;
    width      : 100%;
    padding-top: 12px;
    overflow   : hidden;
  }
}

.ant-pagination {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : 0;
  padding                      : 0;
  list-style                   : none;

  ol,
  ul {
    margin    : 0;
    padding   : 0;
    list-style: none;
  }

  &:after {
    display   : block;
    clear     : both;
    height    : 0;
    overflow  : hidden;
    visibility: hidden;
    content   : " ";
  }
}

.ant-pagination-item,
.ant-pagination-total-text {
  display       : inline-block;
  height        : 32px;
  margin-left   : 8px;
  margin-right  : 0;
  line-height   : 30px;
  vertical-align: middle;
}

.ant-pagination-item {
  min-width          : 32px;
  font-family        : Arial;
  text-align         : center;
  list-style         : none;
  background-color   : #fff;
  border             : 1px solid #d9d9d9;
  border-radius      : 4px;
  outline            : 0;
  cursor             : pointer;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;

  a {
    display           : block;
    padding           : 0 6px;
    color             : rgba(0, 0, 0, 0.65);
    -webkit-transition: none;
    transition        : none;

    &:hover {
      text-decoration: none;
    }
  }

  &:focus,
  &:hover {
    border-color      : #1890ff;
    -webkit-transition: all .3s;
    transition        : all .3s;
  }

  &:focus a,
  &:hover a {
    color: #1890ff;
  }
}

.ant-pagination-item-active {
  font-weight : 500;
  background  : #fff;
  border-color: #1890ff;

  a {
    color: #1890ff;
  }

  &:focus,
  &:hover {
    border-color: #40a9ff;
  }

  &:focus a,
  &:hover a {
    color: #40a9ff;
  }
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  outline: 0;
}

.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container {
  position: relative;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  display           : inline-block;
  font-size         : 12px;
  font-size         : 12px\9;
  -webkit-transform : scale(1) rotate(0deg);
  -ms-transform     : scale(1) rotate(0deg);
  transform         : scale(1) rotate(0deg);
  color             : #1890ff;
  letter-spacing    : -1px;
  opacity           : 0;
  -webkit-transition: all .2s;
  transition        : all .2s;
}

:root {

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    font-size: 12px;
  }
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top   : 0;
  right : 0;
  bottom: 0;
  left  : 0;
  margin: auto;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position          : absolute;
  top               : 0;
  right             : 0;
  bottom            : 0;
  left              : 0;
  display           : block;
  margin            : auto;
  color             : rgba(0, 0, 0, 0.25);
  letter-spacing    : 2px;
  text-align        : center;
  text-indent       : .13em;
  opacity           : 1;
  -webkit-transition: all .2s;
  transition        : all .2s;
}

.ant-pagination-jump-next {

  &:focus .ant-pagination-item-link-icon,
  &:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
}

.ant-pagination-jump-prev {

  &:focus .ant-pagination-item-link-icon,
  &:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
}

.ant-pagination-jump-next {

  &:focus .ant-pagination-item-ellipsis,
  &:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
}

.ant-pagination-jump-prev {

  &:focus .ant-pagination-item-ellipsis,
  &:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev {
  margin-left: 8px;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  display           : inline-block;
  min-width         : 32px;
  height            : 32px;
  color             : rgba(0, 0, 0, 0.65);
  font-family       : Arial;
  line-height       : 32px;
  text-align        : center;
  vertical-align    : middle;
  list-style        : none;
  border-radius     : 4px;
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-pagination-next,
.ant-pagination-prev {
  outline: 0;
}

.ant-pagination-next a,
.ant-pagination-prev a {
  color              : rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-pagination-next:hover a,
.ant-pagination-prev:hover a {
  border-color: #40a9ff;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display           : block;
  height            : 100%;
  font-size         : 12px;
  text-align        : center;
  background-color  : #fff;
  border            : 1px solid #d9d9d9;
  border-radius     : 4px;
  outline           : none;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-pagination-next {

  &:focus .ant-pagination-item-link,
  &:hover .ant-pagination-item-link {
    color       : #1890ff;
    border-color: #1890ff;
  }
}

.ant-pagination-prev {

  &:focus .ant-pagination-item-link,
  &:hover .ant-pagination-item-link {
    color       : #1890ff;
    border-color: #1890ff;
  }
}

.ant-pagination-disabled {
  cursor: not-allowed;

  &:focus,
  &:hover {
    cursor: not-allowed;
  }

  .ant-pagination-item-link {
    color       : rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor      : not-allowed;
  }

  &:focus {

    .ant-pagination-item-link,
    a {
      color       : rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor      : not-allowed;
    }
  }

  &:hover {

    .ant-pagination-item-link,
    a {
      color       : rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor      : not-allowed;
    }
  }

  a {
    color       : rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor      : not-allowed;
  }
}

.ant-pagination-slash {
  margin: 0 10px 0 5px;
}

.ant-pagination-options {
  display       : inline-block;
  margin-left   : 16px;
  vertical-align: middle;
}

.ant-pagination-options-size-changer.ant-select {
  display     : inline-block;
  width       : auto;
  margin-right: 8px;
}

.ant-pagination-options-quick-jumper {
  display       : inline-block;
  height        : 32px;
  line-height   : 32px;
  vertical-align: top;

  input {
    position          : relative;
    display           : inline-block;
    width             : 100%;
    height            : 32px;
    padding           : 4px 11px;
    color             : rgba(0, 0, 0, 0.65);
    font-size         : 14px;
    line-height       : 1.5;
    background-color  : #fff;
    background-image  : none;
    border            : 1px solid #d9d9d9;
    border-radius     : 4px;
    -webkit-transition: all .3s;
    transition        : all .3s;
    width             : 50px;
    margin            : 0 8px;

    &::-moz-placeholder {
      color  : #bfbfbf;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #bfbfbf;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:focus,
    &:hover {
      border-color      : #40a9ff;
      border-right-width: 1px !important;
    }

    &:focus {
      outline           : 0;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  input-disabled,
  input[disabled] {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor          : not-allowed;
    opacity         : 1;

    &:hover {
      border-color      : #d9d9d9;
      border-right-width: 1px !important;
    }
  }
}

textarea.ant-pagination-options-quick-jumper input {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-pagination-options-quick-jumper {
  input-lg {
    height   : 40px;
    padding  : 6px 11px;
    font-size: 16px;
  }

  input-sm {
    height : 24px;
    padding: 1px 7px;
  }
}

.ant-pagination-simple {

  .ant-pagination-next,
  .ant-pagination-prev {
    height        : 24px;
    line-height   : 24px;
    vertical-align: top;
  }

  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    height: 24px;
    border: 0;
  }

  .ant-pagination-next .ant-pagination-item-link:after,
  .ant-pagination-prev .ant-pagination-item-link:after {
    height     : 24px;
    line-height: 24px;
  }

  .ant-pagination-simple-pager {
    display     : inline-block;
    height      : 24px;
    margin-right: 8px;

    input {
      -webkit-box-sizing: border-box;
      box-sizing        : border-box;
      height            : 100%;
      margin-right      : 8px;
      padding           : 0 6px;
      text-align        : center;
      background-color  : #fff;
      border            : 1px solid #d9d9d9;
      border-radius     : 4px;
      outline           : none;
      -webkit-transition: border-color .3s;
      transition        : border-color .3s;

      &:hover {
        border-color: #1890ff;
      }
    }
  }
}

.ant-pagination {
  &.mini {

    .ant-pagination-simple-pager,
    .ant-pagination-total-text {
      height     : 24px;
      line-height: 24px;
    }

    .ant-pagination-item {
      min-width  : 24px;
      height     : 24px;
      margin     : 0;
      line-height: 22px;

      &:not(.ant-pagination-item-active) {
        background  : rgba(0, 0, 0, 0);
        border-color: rgba(0, 0, 0, 0);
      }
    }

    .ant-pagination-next,
    .ant-pagination-prev {
      min-width  : 24px;
      height     : 24px;
      margin     : 0;
      line-height: 24px;
    }

    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link {
      background  : rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
    }

    .ant-pagination-next .ant-pagination-item-link:after,
    .ant-pagination-prev .ant-pagination-item-link:after {
      height     : 24px;
      line-height: 24px;
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      height      : 24px;
      margin-right: 0;
      line-height : 24px;
    }

    .ant-pagination-options {
      margin-left: 2px;
    }

    .ant-pagination-options-quick-jumper {
      height     : 24px;
      line-height: 24px;

      input {
        height : 24px;
        padding: 1px 7px;
        width  : 44px;
      }
    }
  }

  &.ant-pagination-disabled {
    cursor: not-allowed;

    .ant-pagination-item {
      background  : #f5f5f5;
      border-color: #d9d9d9;
      cursor      : not-allowed;

      a {
        color     : rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0);
        border    : none;
        cursor    : not-allowed;
      }
    }

    .ant-pagination-item-active {
      background  : #dbdbdb;
      border-color: rgba(0, 0, 0, 0);

      a {
        color: #fff;
      }
    }

    .ant-pagination-item-link {
      color       : rgba(0, 0, 0, 0.45);
      background  : #f5f5f5;
      border-color: #d9d9d9;
      cursor      : not-allowed;

      &:focus,
      &:hover {
        color       : rgba(0, 0, 0, 0.45);
        background  : #f5f5f5;
        border-color: #d9d9d9;
        cursor      : not-allowed;
      }
    }

    .ant-pagination-jump-next {

      &:focus .ant-pagination-item-link-icon,
      &:hover .ant-pagination-item-link-icon {
        opacity: 0;
      }
    }

    .ant-pagination-jump-prev {

      &:focus .ant-pagination-item-link-icon,
      &:hover .ant-pagination-item-link-icon {
        opacity: 0;
      }
    }

    .ant-pagination-jump-next {

      &:focus .ant-pagination-item-ellipsis,
      &:hover .ant-pagination-item-ellipsis {
        opacity: 1;
      }
    }

    .ant-pagination-jump-prev {

      &:focus .ant-pagination-item-ellipsis,
      &:hover .ant-pagination-item-ellipsis {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 992px) {

  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

.ant-popover {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  top                          : 0;
  left                         : 0;
  z-index                      : 1030;
  font-weight                  : 400;
  white-space                  : normal;
  text-align                   : right;
  cursor                       : auto;
  -webkit-user-select          : text;
  -moz-user-select             : text;
  -ms-user-select              : text;
  user-select                  : text;

  &:after {
    position  : absolute;
    background: hsla(0, 0%, 100%, 0.01);
    content   : "";
  }
}

.ant-popover-hidden {
  display: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}

.ant-popover-placement-right,
.ant-popover-placement-rightBottom,
.ant-popover-placement-rightTop {
  padding-left: 10px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}

.ant-popover-placement-left,
.ant-popover-placement-leftBottom,
.ant-popover-placement-leftTop {
  padding-right: 10px;
}

.ant-popover-inner {
  background-color  : #fff;
  background-clip   : padding-box;
  border-radius     : 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  box-shadow        : 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

@media (-ms-high-contrast: none),
screen and (-ms-high-contrast: active) {
  .ant-popover-inner {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.ant-popover-title {
  min-width    : 177px;
  min-height   : 32px;
  margin       : 0;
  padding      : 5px 16px 4px;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 500;
  border-bottom: 1px solid #e8e8e8;
}

.ant-popover-inner-content {
  padding: 12px 16px;
  color  : rgba(0, 0, 0, 0.65);
}

.ant-popover-message {
  position : relative;
  padding  : 4px 0 12px;
  color    : rgba(0, 0, 0, 0.65);
  font-size: 14px;

  >.anticon {
    position : absolute;
    top      : 8px;
    color    : #faad14;
    font-size: 14px;
  }
}

.ant-popover-message-title {
  padding-right: 22px;
}

.ant-popover-buttons {
  margin-bottom: 4px;
  text-align   : right;

  button {
    margin-right: 8px;
  }
}

.ant-popover-arrow {
  position         : absolute;
  display          : block;
  width            : 8.48528137px;
  height           : 8.48528137px;
  background       : rgba(0, 0, 0, 0);
  border-style     : solid;
  border-width     : 4.24264069px;
  -webkit-transform: rotate(45deg);
  -ms-transform    : rotate(45deg);
  transform        : rotate(45deg);
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
  bottom            : 6.2px;
  border-color      : rgba(0, 0, 0, 0) #fff #fff rgba(0, 0, 0, 0);
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
  left             : 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform    : translateX(-50%) rotate(45deg);
  transform        : translateX(-50%) rotate(45deg);
}

.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
  left              : 6px;
  border-color      : rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff;
  -webkit-box-shadow: (-3px) 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : (-3px) 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow {
  top              : 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform    : translateY(-50%) rotate(45deg);
  transform        : translateY(-50%) rotate(45deg);
}

.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
  top               : 6px;
  border-color      : #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
  -webkit-box-shadow: (-2px) -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow        : (-2px) -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
  left             : 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform    : translateX(-50%) rotate(45deg);
  transform        : translateX(-50%) rotate(45deg);
}

.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
  right             : 6px;
  border-color      : #fff #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : 3px -3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow {
  top              : 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform    : translateY(-50%) rotate(45deg);
  transform        : translateY(-50%) rotate(45deg);
}

.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
  bottom: 12px;
}

.ant-progress {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
}

.ant-progress-line {
  position : relative;
  width    : 100%;
  font-size: 14px;
}

.ant-progress-small.ant-progress-line {
  font-size: 12px;

  .ant-progress-text .anticon {
    font-size: 12px;
  }
}

.ant-progress-outer {
  display      : inline-block;
  width        : 100%;
  margin-right : 0;
  padding-right: 0;
}

.ant-progress-show-info .ant-progress-outer {
  margin-right : calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}

.ant-progress-inner {
  position        : relative;
  display         : inline-block;
  width           : 100%;
  overflow        : hidden;
  vertical-align  : middle;
  background-color: #f5f5f5;
  border-radius   : 100px;
}

.ant-progress-circle-trail {
  stroke: #f5f5f5;
}

.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear .3s;
  animation        : ant-progress-appear .3s;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}

.ant-progress-bg {
  position          : relative;
  background-color  : #1890ff;
  border-radius     : 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition        : all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-success-bg {
  position          : relative;
  background-color  : #1890ff;
  border-radius     : 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition        : all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position          : absolute;
  top               : 0;
  left              : 0;
  background-color  : #52c41a;
}

.ant-progress-text {
  display       : inline-block;
  width         : 2em;
  margin-left   : 8px;
  color         : rgba(0, 0, 0, 0.45);
  font-size     : 1em;
  line-height   : 1;
  white-space   : nowrap;
  text-align    : right;
  vertical-align: middle;
  word-break    : normal;

  .anticon {
    font-size: 14px;
  }
}

.ant-progress-status-active .ant-progress-bg:before {
  position         : absolute;
  top              : 0;
  right            : 0;
  bottom           : 0;
  left             : 0;
  background       : #fff;
  border-radius    : 10px;
  opacity          : 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation        : ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content          : "";
}

.ant-progress-status-exception {
  .ant-progress-bg {
    background-color: #f5222d;
  }

  .ant-progress-text {
    color: #f5222d;
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #f5222d;
  }
}

.ant-progress-status-success {
  .ant-progress-bg {
    background-color: #52c41a;
  }

  .ant-progress-text {
    color: #52c41a;
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #52c41a;
  }
}

.ant-progress-circle {
  .ant-progress-inner {
    position        : relative;
    line-height     : 1;
    background-color: rgba(0, 0, 0, 0);
  }

  .ant-progress-text {
    position         : absolute;
    top              : 50%;
    left             : 50%;
    width            : 100%;
    margin           : 0;
    padding          : 0;
    color            : rgba(0, 0, 0, 0.65);
    line-height      : 1;
    white-space      : normal;
    text-align       : center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform    : translate(-50%, -50%);
    transform        : translate(-50%, -50%);

    .anticon {
      font-size: 1.16666667em;
    }
  }

  &.ant-progress-status-exception .ant-progress-text {
    color: #f5222d;
  }

  &.ant-progress-status-success .ant-progress-text {
    color: #52c41a;
  }
}

@-webkit-keyframes ant-progress-active {
  0% {
    width  : 0;
    opacity: .1;
  }

  20% {
    width  : 0;
    opacity: .5;
  }

  to {
    width  : 100%;
    opacity: 0;
  }
}

@keyframes ant-progress-active {
  0% {
    width  : 0;
    opacity: .1;
  }

  20% {
    width  : 0;
    opacity: .5;
  }

  to {
    width  : 100%;
    opacity: 0;
  }
}

.ant-radio-group {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
}

.ant-radio-wrapper {
  margin: 0 8px 0 0;
}

.ant-radio,
.ant-radio-wrapper {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  white-space                  : nowrap;
  cursor                       : pointer;
}

.ant-radio {
  margin        : 0;
  line-height   : 1;
  vertical-align: sub;
  outline       : none;
}

.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-input:focus+.ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow        : 0 0 0 3px rgba(24, 144, 255, 0.08);
}

.ant-radio-checked:after {
  position                   : absolute;
  top                        : 0;
  left                       : 0;
  width                      : 100%;
  height                     : 100%;
  border                     : 1px solid #1890ff;
  border-radius              : 50%;
  visibility                 : hidden;
  -webkit-animation          : antRadioEffect .36s ease-in-out;
  animation                  : antRadioEffect .36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode        : both;
  content                    : "";
}

.ant-radio-wrapper:hover .ant-radio:after,
.ant-radio:hover:after {
  visibility: visible;
}

.ant-radio-inner {
  position          : relative;
  top               : 0;
  left              : 0;
  display           : block;
  width             : 16px;
  height            : 16px;
  background-color  : #fff;
  border            : 1px solid #d9d9d9;
  border-radius     : 100px;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:after {
    position          : absolute;
    top               : 3px;
    left              : 3px;
    display           : table;
    width             : 8px;
    height            : 8px;
    background-color  : #1890ff;
    border-top        : 0;
    border-left       : 0;
    border-radius     : 8px;
    -webkit-transform : scale(0);
    -ms-transform     : scale(0);
    transform         : scale(0);
    opacity           : 0;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition        : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content           : " ";
  }
}

.ant-radio-input {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  left    : 0;
  z-index : 1;
  cursor  : pointer;
  opacity : 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;

  &:after {
    -webkit-transform : scale(1);
    -ms-transform     : scale(1);
    transform         : scale(1);
    opacity           : 1;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition        : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}

.ant-radio-disabled {
  .ant-radio-inner {
    background-color: #f5f5f5;
    border-color    : #d9d9d9 !important;
    cursor          : not-allowed;

    &:after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .ant-radio-input {
    cursor: not-allowed;
  }

  +span {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

span.ant-radio+* {
  padding-right: 8px;
  padding-left : 8px;
}

.ant-radio-button-wrapper {
  position          : relative;
  display           : inline-block;
  height            : 32px;
  margin            : 0;
  padding           : 0 15px;
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 30px;
  background        : #fff;
  border            : 1px solid #d9d9d9;
  border-top        : 1.02px solid #d9d9d9;
  border-left       : 0;
  cursor            : pointer;
  -webkit-transition: color .3s, background .3s, border-color .3s;
  transition        : color .3s, background .3s, border-color .3s;

  a {
    color: rgba(0, 0, 0, 0.65);
  }

  >.ant-radio-button {
    display    : block;
    width      : 0;
    height     : 0;
    margin-left: 0;
  }
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height     : 40px;
  font-size  : 16px;
  line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height     : 24px;
  padding    : 0 7px;
  line-height: 22px;
}

.ant-radio-button-wrapper {
  &:not(:first-child):before {
    position        : absolute;
    top             : 0;
    left            : -1px;
    display         : block;
    width           : 1px;
    height          : 100%;
    background-color: #d9d9d9;
    content         : "";
  }

  &:first-child {
    border-left  : 1px solid #d9d9d9;
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &:first-child:last-child {
    border-radius: 4px;
  }

  &:hover {
    position: relative;
    color   : #1890ff;
  }

  &:focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06);
  }

  .ant-radio-inner {
    width         : 0;
    height        : 0;
    opacity       : 0;
    pointer-events: none;
  }

  input {

    &[type=checkbox],
    &[type=radio] {
      width         : 0;
      height        : 0;
      opacity       : 0;
      pointer-events: none;
    }
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index           : 1;
  color             : #1890ff;
  background        : #fff;
  border-color      : #1890ff;
  -webkit-box-shadow: -1px 0 0 0 #1890ff;
  box-shadow        : -1px 0 0 0 #1890ff;

  &:before {
    background-color: #1890ff !important;
    opacity         : .1;
  }

  &:first-child {
    border-color      : #1890ff;
    -webkit-box-shadow: none !important;
    box-shadow        : none !important;
  }

  &:hover {
    color             : #40a9ff;
    border-color      : #40a9ff;
    -webkit-box-shadow: -1px 0 0 0 #40a9ff;
    box-shadow        : -1px 0 0 0 #40a9ff;
  }

  &:active {
    color             : #096dd9;
    border-color      : #096dd9;
    -webkit-box-shadow: -1px 0 0 0 #096dd9;
    box-shadow        : -1px 0 0 0 #096dd9;
  }

  &:focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06);
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color       : #fff;
  background  : #1890ff;
  border-color: #1890ff;

  &:hover {
    color       : #fff;
    background  : #40a9ff;
    border-color: #40a9ff;
  }

  &:active {
    color       : #fff;
    background  : #096dd9;
    border-color: #096dd9;
  }

  &:focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06);
  }
}

.ant-radio-button-wrapper-disabled {
  cursor          : not-allowed;
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color    : #d9d9d9;

  &:first-child,
  &:hover {
    color           : rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color    : #d9d9d9;
  }

  &:first-child {
    border-left-color: #d9d9d9;
  }

  &.ant-radio-button-wrapper-checked {
    color             : #fff;
    background-color  : #e6e6e6;
    border-color      : #d9d9d9;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(1.6);
    transform        : scale(1.6);
    opacity          : 0;
  }
}

@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(1.6);
    transform        : scale(1.6);
    opacity          : 0;
  }
}

@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}

.ant-rate {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  margin                       : 0;
  padding                      : 0;
  color                        : #fadb14;
  font-size                    : 20px;
  line-height                  : unset;
  list-style                   : none;
  outline                      : none;
}

.ant-rate-disabled .ant-rate-star {
  cursor: default;

  &:hover {
    -webkit-transform: scale(1);
    -ms-transform    : scale(1);
    transform        : scale(1);
  }
}

.ant-rate-star {
  position          : relative;
  display           : inline-block;
  margin            : 0;
  padding           : 0;
  color             : inherit;
  cursor            : pointer;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:not(:last-child) {
    margin-right: 8px;
  }

  >div {
    &:focus {
      outline          : 0;
      -webkit-transform: scale(1.1);
      -ms-transform    : scale(1.1);
      transform        : scale(1.1);
    }

    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform    : scale(1.1);
      transform        : scale(1.1);
    }
  }
}

.ant-rate-star-first,
.ant-rate-star-second {
  color              : #e8e8e8;
  -webkit-transition : all .3s;
  transition         : all .3s;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}

.ant-rate-star-first {
  position: absolute;
  top     : 0;
  left    : 0;
  width   : 50%;
  height  : 100%;
  overflow: hidden;
  opacity : 0;
}

.ant-rate-star-half {

  .ant-rate-star-first,
  .ant-rate-star-second {
    opacity: 1;
  }
}

.ant-rate-star-full .ant-rate-star-second,
.ant-rate-star-half .ant-rate-star-first {
  color: inherit;
}

.ant-rate-text {
  display    : inline-block;
  margin-left: 8px;
  font-size  : 14px;
}

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon>.anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon>.anticon {
  color: #f5222d;
}

.ant-result-info .ant-result-icon>.anticon {
  color: #1890ff;
}

.ant-result-warning .ant-result-icon>.anticon {
  color: #faad14;
}

.ant-result-image {
  width : 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  margin-bottom: 24px;
  text-align   : center;

  >.anticon {
    font-size: 72px;
  }
}

.ant-result-title {
  color      : rgba(0, 0, 0, 0.85);
  font-size  : 24px;
  line-height: 1.8;
  text-align : center;
}

.ant-result-subtitle {
  color      : rgba(0, 0, 0, 0.45);
  font-size  : 14px;
  line-height: 1.6;
  text-align : center;
}

.ant-result-extra {
  margin-top: 32px;
  text-align: center;

  > {
    * {
      margin-right: 8px;
    }

    :last-child {
      margin-right: 0;
    }
  }
}

.ant-result-content {
  margin-top      : 24px;
  padding         : 24px 40px;
  background-color: #fafafa;
}

.ant-select {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  outline                      : 0;
  margin                       : 0;
  padding                      : 0;
  list-style                   : none;

  ol,
  ul {
    margin    : 0;
    padding   : 0;
    list-style: none;
  }

  >ul>li>a {
    padding         : 0;
    background-color: #fff;
  }
}

.ant-select-arrow {
  display                 : inline-block;
  color                   : inherit;
  font-style              : normal;
  line-height             : 0;
  text-align              : center;
  text-transform          : none;
  vertical-align          : -.125em;
  text-rendering          : optimizeLegibility;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
  position                : absolute;
  top                     : 50%;
  right                   : 11px;
  margin-top              : -6px;
  color                   : rgba(0, 0, 0, 0.25);
  font-size               : 12px;
  line-height             : 1;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin    : 50% 50%;
  transform-origin        : 50% 50%;

  >* {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &:before {
    display: none;
  }

  .ant-select-arrow-icon {
    display: block;

    svg {
      -webkit-transition: -webkit-transform .3s;
      transition        : -webkit-transform .3s;
      transition        : transform .3s;
      transition        : transform .3s, -webkit-transform .3s;
    }
  }
}

.ant-select-selection {
  display            : block;
  -webkit-box-sizing : border-box;
  box-sizing         : border-box;
  background-color   : #fff;
  border             : 1px solid #d9d9d9;
  border-top         : 1.02px solid #d9d9d9;
  border-radius      : 4px;
  outline            : none;
  -webkit-transition : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;

  &:hover {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }
}

.ant-select-focused .ant-select-selection {
  border-color      : #40a9ff;
  border-right-width: 1px !important;
  outline           : 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-select-selection {

  &:active,
  &:focus {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-select-selection__clear {
  position          : absolute;
  top               : 50%;
  right             : 11px;
  z-index           : 1;
  display           : inline-block;
  width             : 12px;
  height            : 12px;
  margin-top        : -6px;
  color             : rgba(0, 0, 0, 0.25);
  font-size         : 12px;
  font-style        : normal;
  line-height       : 12px;
  text-align        : center;
  text-transform    : none;
  background        : #fff;
  cursor            : pointer;
  opacity           : 0;
  -webkit-transition: color .3s ease, opacity .15s ease;
  transition        : color .3s ease, opacity .15s ease;
  text-rendering    : auto;

  &:before {
    display: block;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}

.ant-select-selection-selected-value {
  float        : left;
  max-width    : 100%;
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);

  .ant-select-selection {
    background: #f5f5f5;
    cursor    : not-allowed;

    &:active,
    &:focus,
    &:hover {
      border-color      : #d9d9d9;
      -webkit-box-shadow: none;
      box-shadow        : none;
    }
  }

  .ant-select-selection__clear {
    display       : none;
    visibility    : hidden;
    pointer-events: none;
  }

  .ant-select-selection--multiple {
    .ant-select-selection__choice {
      padding-right: 10px;
      color        : rgba(0, 0, 0, 0.33);
      background   : #f5f5f5;
    }

    .ant-select-selection__choice__remove {
      display: none;
    }
  }
}

.ant-select-selection--single {
  position: relative;
  height  : 32px;
  cursor  : pointer;

  .ant-select-selection__rendered {
    margin-right: 24px;
  }
}

.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}

.ant-select-selection__rendered {
  position    : relative;
  display     : block;
  margin-right: 11px;
  margin-left : 11px;
  line-height : 30px;

  &:after {
    display       : inline-block;
    width         : 0;
    visibility    : hidden;
    content       : ".";
    pointer-events: none;
  }
}

.ant-select-lg {
  font-size: 16px;

  .ant-select-selection--single {
    height: 40px;
  }

  .ant-select-selection__rendered {
    line-height: 38px;
  }

  .ant-select-selection--multiple {
    min-height: 40px;

    .ant-select-selection__rendered li {
      height     : 32px;
      line-height: 32px;
    }

    .ant-select-arrow,
    .ant-select-selection__clear {
      top: 20px;
    }
  }
}

.ant-select-sm {
  .ant-select-selection--single {
    height: 24px;
  }

  .ant-select-selection__rendered {
    margin-left: 7px;
    line-height: 22px;
  }

  .ant-select-selection--multiple {
    min-height: 24px;

    .ant-select-selection__rendered li {
      height     : 16px;
      line-height: 14px;
    }

    .ant-select-arrow,
    .ant-select-selection__clear {
      top: 12px;
    }
  }

  .ant-select-arrow,
  .ant-select-selection__clear {
    right: 8px;
  }
}

.ant-select-disabled .ant-select-selection__choice__remove {
  color : rgba(0, 0, 0, 0.25);
  cursor: default;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-select-search__field__wrap {
  position: relative;
  display : inline-block;
}

.ant-select-search__field__placeholder,
.ant-select-selection__placeholder {
  position     : absolute;
  top          : 50%;
  right        : 9px;
  left         : 0;
  max-width    : 100%;
  height       : 20px;
  margin-top   : -10px;
  overflow     : hidden;
  color        : #bfbfbf;
  line-height  : 20px;
  white-space  : nowrap;
  text-align   : right;
  text-overflow: ellipsis;
}

.ant-select-search__field__placeholder {
  left: 12px;
}

.ant-select-search__field__mirror {
  position      : absolute;
  top           : 0;
  left          : 0;
  white-space   : pre;
  opacity       : 0;
  pointer-events: none;
}

.ant-select-search--inline {
  position: absolute;
  width   : 100%;
  height  : 100%;

  .ant-select-search__field__wrap {
    width : 100%;
    height: 100%;
  }

  .ant-select-search__field {
    width        : 100%;
    height       : 100%;
    font-size    : 100%;
    line-height  : 1;
    background   : rgba(0, 0, 0, 0);
    border-width : 0;
    border-radius: 4px;
    outline      : 0;
  }

  >i {
    float: right;
  }
}

.ant-select-selection--multiple {
  min-height    : 32px;
  padding-bottom: 3px;
  cursor        : text;
  zoom          : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  .ant-select-search--inline {
    position : static;
    float    : left;
    width    : auto;
    max-width: 100%;
    padding  : 0;

    .ant-select-search__field {
      width    : .75em;
      max-width: 100%;
    }
  }

  .ant-select-selection__rendered {
    height       : auto;
    margin-bottom: -3px;
    margin-left  : 5px;
  }

  .ant-select-selection__placeholder {
    margin-left: 6px;
  }

  .ant-select-selection__rendered>ul>li,
  >ul>li {
    height     : 24px;
    margin-top : 3px;
    line-height: 22px;
  }

  .ant-select-selection__choice {
    position          : relative;
    float             : left;
    max-width         : 99%;
    margin-right      : 4px;
    padding           : 0 20px 0 10px;
    overflow          : hidden;
    color             : rgba(0, 0, 0, 0.65);
    background-color  : #fafafa;
    border            : 1px solid #e8e8e8;
    border-radius     : 2px;
    cursor            : default;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-select-selection__choice__disabled {
    padding: 0 10px;
  }

  .ant-select-selection__choice__content {
    display           : inline-block;
    max-width         : 100%;
    overflow          : hidden;
    white-space       : nowrap;
    text-overflow     : ellipsis;
    -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-select-selection__choice__remove {
    color                  : inherit;
    font-style             : normal;
    line-height            : 0;
    text-align             : center;
    text-transform         : none;
    vertical-align         : -.125em;
    text-rendering         : optimizeLegibility;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    position               : absolute;
    right                  : 4px;
    color                  : rgba(0, 0, 0, 0.45);
    font-weight            : 700;
    line-height            : inherit;
    cursor                 : pointer;
    -webkit-transition     : all .3s;
    transition             : all .3s;
    display                : inline-block;
    font-size              : 12px;
    font-size              : 10px\9;
    -webkit-transform      : scale(0.83333333) rotate(0deg);
    -ms-transform          : scale(0.83333333) rotate(0deg);
    transform              : scale(0.83333333) rotate(0deg);

    >* {
      line-height: 1;
    }

    svg {
      display: inline-block;
    }

    &:before {
      display: none;
    }

    .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
      display: block;
    }
  }
}

:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}

.ant-select-selection--multiple {
  .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }

  .ant-select-arrow,
  .ant-select-selection__clear {
    top: 16px;
  }
}

.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}

.ant-select-open {
  .ant-select-arrow-icon svg {
    -webkit-transform: rotate(180deg);
    -ms-transform    : rotate(180deg);
    transform        : rotate(180deg);
  }

  .ant-select-selection {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-select-combobox {
  .ant-select-arrow {
    display: none;
  }

  .ant-select-search--inline {
    float : none;
    width : 100%;
    height: 100%;
  }

  .ant-select-search__field__wrap {
    width : 100%;
    height: 100%;
  }

  .ant-select-search__field {
    position          : relative;
    z-index           : 1;
    width             : 100%;
    height            : 100%;
    -webkit-box-shadow: none;
    box-shadow        : none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
    transition        : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  }

  &.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
  &.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px;
  }
}

.ant-select-dropdown {
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  top                          : -9999px;
  left                         : -9999px;
  z-index                      : 1050;
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  font-size                    : 14px;
  font-variant                 : normal;
  background-color             : #fff;
  border-radius                : 4px;
  outline                      : none;
  -webkit-box-shadow           : 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow                   : 0 2px 8px rgba(0, 0, 0, 0.15);

  &.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
  &.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name        : antSlideUpIn;
  }

  &.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name        : antSlideDownIn;
  }

  &.slide-up-leave.slide-up-leave-active {
    &.ant-select-dropdown-placement-bottomLeft {
      -webkit-animation-name: antSlideUpOut;
      animation-name        : antSlideUpOut;
    }

    &.ant-select-dropdown-placement-topLeft {
      -webkit-animation-name: antSlideDownOut;
      animation-name        : antSlideDownOut;
    }
  }
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-menu {
  max-height   : 250px;
  margin-bottom: 0;
  padding      : 4px 0;
  overflow     : auto;
  list-style   : none;
  outline      : none;
}

.ant-select-dropdown-menu-item-group-list {
  margin : 0;
  padding: 0;

  >.ant-select-dropdown-menu-item {
    padding-left: 20px;
  }
}

.ant-select-dropdown-menu-item-group-title {
  height     : 32px;
  padding    : 0 12px;
  color      : rgba(0, 0, 0, 0.45);
  font-size  : 12px;
  line-height: 32px;
}

.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}

.ant-select-dropdown-menu-item {
  position          : relative;
  display           : block;
  padding           : 5px 12px;
  overflow          : hidden;
  color             : rgba(0, 0, 0, 0.65);
  font-weight       : 400;
  font-size         : 14px;
  line-height       : 22px;
  white-space       : nowrap;
  text-overflow     : ellipsis;
  cursor            : pointer;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
  }
}

.ant-select-dropdown-menu-item-selected {
  color           : rgba(0, 0, 0, 0.65);
  font-weight     : 600;
  background-color: #fafafa;
}

.ant-select-dropdown-menu-item-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff;
}

.ant-select-dropdown-menu-item-divider {
  height          : 1px;
  margin          : 1px 0;
  overflow        : hidden;
  line-height     : 0;
  background-color: #e8e8e8;
}

.ant-select-dropdown.ant-select-dropdown--multiple {
  .ant-select-dropdown-menu-item {
    padding-right: 32px;

    .ant-select-selected-icon {
      position          : absolute;
      top               : 50%;
      right             : 12px;
      color             : rgba(0, 0, 0, 0);
      font-weight       : 700;
      font-size         : 12px;
      text-shadow       : 0 .1px 0, .1px 0 0, 0 -.1px 0, -.1px 0;
      -webkit-transform : translateY(-50%);
      -ms-transform     : translateY(-50%);
      transform         : translateY(-50%);
      -webkit-transition: all .2s;
      transition        : all .2s;
    }

    &:hover .ant-select-selected-icon {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
    display: none;
  }

  .ant-select-dropdown-menu-item-selected {

    .ant-select-selected-icon,
    &:hover .ant-select-selected-icon {
      display: inline-block;
      color  : #1890ff;
    }
  }
}

.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}

.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

.ant-skeleton {
  display: table;
  width  : 100%;
}

.ant-skeleton-header {
  display       : table-cell;
  padding-right : 16px;
  vertical-align: top;

  .ant-skeleton-avatar {
    display       : inline-block;
    vertical-align: top;
    background    : #f2f2f2;
    width         : 32px;
    height        : 32px;
    line-height   : 32px;

    &.ant-skeleton-avatar-circle {
      border-radius: 50%;
    }
  }

  .ant-skeleton-avatar-lg {
    width      : 40px;
    height     : 40px;
    line-height: 40px;

    &.ant-skeleton-avatar-circle {
      border-radius: 50%;
    }
  }

  .ant-skeleton-avatar-sm {
    width      : 24px;
    height     : 24px;
    line-height: 24px;

    &.ant-skeleton-avatar-circle {
      border-radius: 50%;
    }
  }
}

.ant-skeleton-content {
  display       : table-cell;
  width         : 100%;
  vertical-align: top;

  .ant-skeleton-title {
    width     : 100%;
    height    : 16px;
    margin-top: 16px;
    background: #f2f2f2;

    +.ant-skeleton-paragraph {
      margin-top: 24px;
    }
  }

  .ant-skeleton-paragraph {
    padding: 0;

    >li {
      width     : 100%;
      height    : 16px;
      list-style: none;
      background: #f2f2f2;

      &:last-child:not(:first-child):not(:nth-child(2)) {
        width: 61%;
      }

      +li {
        margin-top: 16px;
      }
    }
  }
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;

  +.ant-skeleton-paragraph {
    margin-top: 28px;
  }
}

.ant-skeleton.ant-skeleton-active {
  .ant-skeleton-avatar {
    background       : -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
    background       : linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size  : 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation        : ant-skeleton-loading 1.4s ease infinite;
  }

  .ant-skeleton-content {

    .ant-skeleton-paragraph>li,
    .ant-skeleton-title {
      background       : -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
      background       : linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size  : 400% 100%;
      -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
      animation        : ant-skeleton-loading 1.4s ease infinite;
    }
  }
}

@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.ant-slider {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  height                       : 12px;
  margin                       : 14px 6px 10px;
  padding                      : 4px 0;
  cursor                       : pointer;
  -ms-touch-action             : none;
  touch-action                 : none;
}

.ant-slider-vertical {
  width  : 12px;
  height : 100%;
  margin : 6px 10px;
  padding: 0 4px;

  .ant-slider-rail {
    width : 4px;
    height: 100%;
  }

  .ant-slider-track {
    width: 4px;
  }

  .ant-slider-handle {
    margin-bottom: -7px;
    margin-left  : -5px;
  }

  .ant-slider-mark {
    top   : 0;
    left  : 12px;
    width : 18px;
    height: 100%;
  }

  .ant-slider-mark-text {
    left       : 4px;
    white-space: nowrap;
  }

  .ant-slider-step {
    width : 4px;
    height: 100%;
  }

  .ant-slider-dot {
    top          : auto;
    left         : 2px;
    margin-bottom: -4px;
  }
}

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}

.ant-slider-with-marks {
  margin-bottom: 28px;
}

.ant-slider-rail {
  width             : 100%;
  background-color  : #f5f5f5;
  border-radius     : 2px;
  position          : absolute;
  height            : 4px;
  -webkit-transition: background-color .3s;
  transition        : background-color .3s;
}

.ant-slider-track {
  position          : absolute;
  height            : 4px;
  -webkit-transition: background-color .3s;
  transition        : background-color .3s;
  background-color  : #91d5ff;
  border-radius     : 4px;
}

.ant-slider-handle {
  position          : absolute;
  width             : 14px;
  height            : 14px;
  margin-top        : -5px;
  background-color  : #fff;
  border            : 2px solid #91d5ff;
  border-radius     : 50%;
  -webkit-box-shadow: 0;
  box-shadow        : 0;
  cursor            : pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition        : border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition        : border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition        : border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  &:focus {
    border-color      : #46a6ff;
    outline           : none;
    -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 5px rgba(24, 144, 255, 0.2);
  }

  &.ant-tooltip-open {
    border-color: #1890ff;
  }
}

.ant-slider:hover {
  .ant-slider-rail {
    background-color: #e1e1e1;
  }

  .ant-slider-track {
    background-color: #69c0ff;
  }

  .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }
}

.ant-slider-mark {
  position : absolute;
  top      : 14px;
  left     : 0;
  width    : 100%;
  font-size: 14px;
}

.ant-slider-mark-text {
  position  : absolute;
  display   : inline-block;
  color     : rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor    : pointer;
}

.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}

.ant-slider-step {
  position  : absolute;
  width     : 100%;
  height    : 4px;
  background: rgba(0, 0, 0, 0);
}

.ant-slider-dot {
  position        : absolute;
  top             : -2px;
  width           : 8px;
  height          : 8px;
  background-color: #fff;
  border          : 2px solid #e8e8e8;
  border-radius   : 50%;
  cursor          : pointer;
  margin-left     : -4px;

  &:first-child,
  &:last-child {
    margin-left: -4px;
  }
}

.ant-slider-dot-active {
  border-color: #8cc8ff;
}

.ant-slider-disabled {
  cursor: not-allowed;

  .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-slider-dot,
  .ant-slider-handle {
    background-color  : #fff;
    border-color      : rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: none;
    box-shadow        : none;
    cursor            : not-allowed;
  }

  .ant-slider-dot,
  .ant-slider-mark-text {
    cursor: not-allowed !important;
  }
}

.ant-spin {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  display                      : none;
  color                        : #1890ff;
  text-align                   : center;
  vertical-align               : middle;
  opacity                      : 0;
  -webkit-transition           : -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition                   : -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition                   : transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition                   : transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-spin-spinning {
  position: static;
  display : inline-block;
  opacity : 1;
}

.ant-spin-nested-loading {
  position: relative;

  >div> {
    .ant-spin {
      position  : absolute;
      top       : 0;
      left      : 0;
      z-index   : 4;
      display   : block;
      width     : 100%;
      height    : 100%;
      max-height: 400px;

      .ant-spin-dot {
        position: absolute;
        top     : 50%;
        left    : 50%;
        margin  : -10px;
      }

      .ant-spin-text {
        position   : absolute;
        top        : 50%;
        width      : 100%;
        padding-top: 5px;
        text-shadow: 0 1px 2px #fff;
      }

      &.ant-spin-show-text .ant-spin-dot {
        margin-top: -20px;
      }
    }

    .ant-spin-sm {
      .ant-spin-dot {
        margin: -7px;
      }

      .ant-spin-text {
        padding-top: 2px;
      }

      &.ant-spin-show-text .ant-spin-dot {
        margin-top: -17px;
      }
    }

    .ant-spin-lg {
      .ant-spin-dot {
        margin: -16px;
      }

      .ant-spin-text {
        padding-top: 11px;
      }

      &.ant-spin-show-text .ant-spin-dot {
        margin-top: -26px;
      }
    }
  }
}

.ant-spin-container {
  position          : relative;
  -webkit-transition: opacity .3s;
  transition        : opacity .3s;

  &:after {
    position          : absolute;
    top               : 0;
    right             : 0;
    bottom            : 0;
    left              : 0;
    z-index           : 10;
    display           : none\9;
    width             : 100%;
    height            : 100%;
    background        : #fff;
    opacity           : 0;
    -webkit-transition: all .3s;
    transition        : all .3s;
    content           : "";
    pointer-events    : none;
  }
}

.ant-spin-blur {
  clear              : both;
  overflow           : hidden;
  opacity            : .5;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  pointer-events     : none;

  &:after {
    opacity       : .4;
    pointer-events: auto;
  }
}

.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}

.ant-spin-dot {
  position : relative;
  display  : inline-block;
  font-size: 20px;
  width    : 1em;
  height   : 1em;
}

.ant-spin-dot-item {
  position                : absolute;
  display                 : block;
  width                   : 9px;
  height                  : 9px;
  background-color        : #1890ff;
  border-radius           : 100%;
  -webkit-transform       : scale(0.75);
  -ms-transform           : scale(0.75);
  transform               : scale(0.75);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin    : 50% 50%;
  transform-origin        : 50% 50%;
  opacity                 : .3;
  -webkit-animation       : antSpinMove 1s linear infinite alternate;
  animation               : antSpinMove 1s linear infinite alternate;

  &:first-child {
    top : 0;
    left: 0;
  }

  &:nth-child(2) {
    top                    : 0;
    right                  : 0;
    -webkit-animation-delay: .4s;
    animation-delay        : .4s;
  }

  &:nth-child(3) {
    right                  : 0;
    bottom                 : 0;
    -webkit-animation-delay: .8s;
    animation-delay        : .8s;
  }

  &:nth-child(4) {
    bottom                 : 0;
    left                   : 0;
    -webkit-animation-delay: 1.2s;
    animation-delay        : 1.2s;
  }
}

.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  -ms-transform    : rotate(45deg);
  transform        : rotate(45deg);
  -webkit-animation: antRotate 1.2s linear infinite;
  animation        : antRotate 1.2s linear infinite;
}

.ant-spin-sm .ant-spin-dot {
  font-size: 14px;

  i {
    width : 6px;
    height: 6px;
  }
}

.ant-spin-lg .ant-spin-dot {
  font-size: 32px;

  i {
    width : 14px;
    height: 14px;
  }
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ant-spin-blur {
    background: #fff;
    opacity   : .5;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform        : rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform        : rotate(405deg);
  }
}

.ant-statistic {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
}

.ant-statistic-title {
  margin-bottom: 4px;
  color        : rgba(0, 0, 0, 0.45);
  font-size    : 14px;
}

.ant-statistic-content {
  color      : rgba(0, 0, 0, 0.85);
  font-size  : 24px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.ant-statistic-content-value-decimal {
  font-size: 16px;
}

.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}

.ant-statistic-content-prefix {
  margin-right: 4px;
}

.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size  : 16px;
}

.ant-steps {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : -webkit-box;
  display                      : -ms-flexbox;
  display                      : flex;
  width                        : 100%;
  font-size                    : 0;

  //custom
  justify-content: center;
  padding        : 15px;

}

@media only screen and (max-width: 576px) {
  .ant-steps {
    overflow-x: auto;
  }
}

.ant-steps-item {
  position        : relative;
  display         : inline-block;
  -webkit-box-flex: 1;
  -ms-flex        : 1;
  flex            : 1;
  overflow        : hidden;
  vertical-align  : top;
}

.ant-steps-item-container {
  outline: none;
}

.ant-steps-item:last-child {
  -webkit-box-flex: 0;
  -ms-flex        : none;
  flex            : none;

  >.ant-steps-item-container> {

    .ant-steps-item-content>.ant-steps-item-title:after,
    .ant-steps-item-tail {
      display: none;
    }
  }
}

.ant-steps-item-content {
  display       : inline-block;
  vertical-align: top;
}

.ant-steps-item-icon {
  display           : inline-block;
  vertical-align    : top;
  width             : 32px;
  height            : 32px;
  margin-left       : 8px;
  font-size         : 16px;
  // font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  line-height       : 32px;
  text-align        : center;
  border            : 1px solid rgba(0, 0, 0, 0.25);
  border-radius     : 32px;
  -webkit-transition: background-color .3s, border-color .3s;
  transition        : background-color .3s, border-color .3s;

  >.ant-steps-icon {
    position   : relative;
    top        : -1px;
    color      : #1890ff;
    line-height: 1;
  }
}

.ant-steps-item-tail {
  position: absolute;
  top     : 12px;
  right   : 0;
  width   : 100%;
  padding : 0 10px;

  &:after {
    display           : inline-block;
    width             : 100%;
    height            : 1px;
    background        : #e8e8e8;
    border-radius     : 1px;
    -webkit-transition: background .3s;
    transition        : background .3s;
    content           : "";
  }
}

.ant-steps-item-title {
  position     : relative;
  display      : inline-block;
  padding-right: 16px;
  color        : rgba(0, 0, 0, 0.65);
  font-size    : 16px;
  line-height  : 32px;

  &:after {
    position  : absolute;
    top       : 16px;
    left      : 100%;
    display   : block;
    width     : 9999px;
    height    : 1px;
    background: #e8e8e8;
    content   : "";
  }
}

.ant-steps-item-subtitle {
  display    : inline;
  margin-left: 8px;
  font-weight: 400;
}

.ant-steps-item-description,
.ant-steps-item-subtitle {
  color    : rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps-item-wait {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color    : rgba(0, 0, 0, 0.25);

    >.ant-steps-icon {
      color: rgba(0, 0, 0, 0.25);

      .ant-steps-icon-dot {
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }

  >.ant-steps-item-container> {
    .ant-steps-item-content> {
      .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.45);

        &:after {
          background-color: #e8e8e8;
        }
      }

      .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .ant-steps-item-tail:after {
      background-color: #e8e8e8;
    }
  }
}

.ant-steps-item-process {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color    : #1890ff;

    >.ant-steps-icon {
      color: #1890ff;

      .ant-steps-icon-dot {
        background: #1890ff;
      }
    }

    background: #1890ff;

    >.ant-steps-icon {
      color: #fff;
    }
  }

  >.ant-steps-item-container> {
    .ant-steps-item-content> {
      .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.85);

        &:after {
          background-color: #e8e8e8;
        }
      }

      .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-steps-item-tail:after {
      background-color: #e8e8e8;
    }
  }

  .ant-steps-item-title {
    font-weight: 500;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color    : #1890ff;

    >.ant-steps-icon {
      color: #1890ff;

      .ant-steps-icon-dot {
        background: #1890ff;
      }
    }
  }

  >.ant-steps-item-container> {
    .ant-steps-item-content> {
      .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.65);

        &:after {
          background-color: #1890ff;
        }
      }

      .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .ant-steps-item-tail:after {
      background-color: #1890ff;
    }
  }
}

.ant-steps-item-error {
  .ant-steps-item-icon {
    background-color: #fff;
    border-color    : #f5222d;

    >.ant-steps-icon {
      color: #f5222d;

      .ant-steps-icon-dot {
        background: #f5222d;
      }
    }
  }

  >.ant-steps-item-container> {
    .ant-steps-item-content> {
      .ant-steps-item-title {
        color: #f5222d;

        &:after {
          background-color: #e8e8e8;
        }
      }

      .ant-steps-item-description {
        color: #f5222d;
      }
    }

    .ant-steps-item-tail:after {
      background-color: #e8e8e8;
    }
  }
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
  background: #f5222d;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) {
  >.ant-steps-item-container[role=button] {
    cursor: pointer;

    .ant-steps-item-description,
    .ant-steps-item-icon .ant-steps-icon,
    .ant-steps-item-title {
      -webkit-transition: color .3s;
      transition        : color .3s;
    }

    &:hover {

      .ant-steps-item-description,
      .ant-steps-item-subtitle,
      .ant-steps-item-title {
        color: #1890ff;
      }
    }
  }

  &:not(.ant-steps-item-process)>.ant-steps-item-container[role=button]:hover .ant-steps-item-icon {
    border-color: #1890ff;

    .ant-steps-icon {
      color: #1890ff;
    }
  }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) {
  .ant-steps-item {
    margin-right: 16px;
    white-space : nowrap;

    &:first-child {
      margin-right: 0;

      .ant-steps-item-title {
        padding-right: 0;
      }
    }
  }

  .ant-steps-item-tail {
    display: none;
  }

  .ant-steps-item-description {
    max-width  : 140px;
    white-space: normal;
  }
}

.ant-steps-item-custom {
  .ant-steps-item-icon {
    height    : auto;
    background: none;
    border    : 0;

    >.ant-steps-icon {
      top        : 0;
      left       : .5px;
      width      : 32px;
      height     : 32px;
      font-size  : 24px;
      line-height: 32px;
    }
  }

  &.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #1890ff;
  }
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}

.ant-steps-small {
  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 12px;

    &:first-child {
      margin-right: 0;
    }
  }

  .ant-steps-item-icon {
    width        : 24px;
    height       : 24px;
    font-size    : 12px;
    line-height  : 24px;
    text-align   : center;
    border-radius: 24px;
  }

  .ant-steps-item-title {
    padding-right: 12px;
    font-size    : 14px;
    line-height  : 24px;

    &:after {
      top: 12px;
    }
  }

  .ant-steps-item-description {
    color    : rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }

  .ant-steps-item-tail {
    top: 8px;
  }

  .ant-steps-item-custom .ant-steps-item-icon {
    width        : inherit;
    height       : inherit;
    line-height  : inherit;
    background   : none;
    border       : 0;
    border-radius: 0;

    >.ant-steps-icon {
      font-size        : 24px;
      line-height      : 24px;
      -webkit-transform: none;
      -ms-transform    : none;
      transform        : none;
    }
  }
}

.ant-steps-vertical {
  display: block;

  .ant-steps-item {
    display : block;
    overflow: visible;
  }

  .ant-steps-item-icon {
    float       : left;
    margin-right: 16px;
  }

  .ant-steps-item-content {
    display   : block;
    min-height: 48px;
    overflow  : hidden;
  }

  .ant-steps-item-title {
    line-height: 32px;
  }

  .ant-steps-item-description {
    padding-bottom: 12px;
  }

  >.ant-steps-item {
    >.ant-steps-item-container>.ant-steps-item-tail {
      position: absolute;
      top     : 0;
      left    : 16px;
      width   : 1px;
      height  : 100%;
      padding : 38px 0 6px;

      &:after {
        width : 1px;
        height: 100%;
      }
    }

    &:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
      display: block;
    }

    >.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
      display: none;
    }
  }

  &.ant-steps-small .ant-steps-item-container {
    .ant-steps-item-tail {
      position: absolute;
      top     : 0;
      left    : 12px;
      padding : 30px 0 6px;
    }

    .ant-steps-item-title {
      line-height: 24px;
    }
  }
}

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;

    .ant-steps-item {
      display : block;
      overflow: visible;
    }

    .ant-steps-item-icon {
      float       : left;
      margin-right: 16px;
    }

    .ant-steps-item-content {
      display   : block;
      min-height: 48px;
      overflow  : hidden;
    }

    .ant-steps-item-title {
      line-height: 32px;
    }

    .ant-steps-item-description {
      padding-bottom: 12px;
    }

    >.ant-steps-item {
      >.ant-steps-item-container>.ant-steps-item-tail {
        position: absolute;
        top     : 0;
        left    : 16px;
        width   : 1px;
        height  : 100%;
        padding : 38px 0 6px;

        &:after {
          width : 1px;
          height: 100%;
        }
      }

      &:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
        display: block;
      }

      >.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
        display: none;
      }
    }

    &.ant-steps-small .ant-steps-item-container {
      .ant-steps-item-tail {
        position: absolute;
        top     : 0;
        left    : 12px;
        padding : 30px 0 6px;
      }

      .ant-steps-item-title {
        line-height: 24px;
      }
    }
  }
}

.ant-steps-label-vertical {
  .ant-steps-item {
    overflow: visible;
  }

  .ant-steps-item-tail {
    margin-left: 58px;
    padding    : 3.5px 24px;
  }

  .ant-steps-item-content {
    display   : block;
    width     : 116px;
    margin-top: 8px;
    text-align: center;
  }

  .ant-steps-item-icon {
    display    : inline-block;
    margin-left: 42px;
  }

  .ant-steps-item-title {
    padding-right: 0;

    &:after {
      display: none;
    }
  }

  .ant-steps-item-subtitle {
    display      : block;
    margin-bottom: 4px;
    margin-left  : 0;
    line-height  : 1.5;
  }

  &.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 40px;
  }
}

.ant-steps-dot {

  .ant-steps-item-title,
  &.ant-steps-small .ant-steps-item-title {
    line-height: 1.5;
  }

  .ant-steps-item-tail,
  &.ant-steps-small .ant-steps-item-tail {
    top    : 2px;
    width  : 100%;
    margin : 0 70px 0 0px;
    padding: 0;
  }

  .ant-steps-item-tail:after,
  &.ant-steps-small .ant-steps-item-tail:after {
    width       : calc(100% - 20px);
    height      : 3px;
    margin-right: 12px;
  }

  .ant-steps-item:first-child .ant-steps-icon-dot,
  &.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 2px;
  }

  .ant-steps-item-icon,
  &.ant-steps-small .ant-steps-item-icon {
    width       : 8px;
    height      : 8px;
    margin-right: 67px;
    padding-left: 0;
    line-height : 8px;
    background  : rgba(0, 0, 0, 0);
    border      : 0;
  }

  .ant-steps-item-icon .ant-steps-icon-dot,
  &.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    position          : relative;
    float             : right;
    width             : 100%;
    height            : 100%;
    border-radius     : 100px;
    -webkit-transition: all .3s;
    transition        : all .3s;
  }

  .ant-steps-item-icon .ant-steps-icon-dot:after,
  &.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
    position  : absolute;
    top       : -12px;
    right     : -26px;
    width     : 60px;
    height    : 32px;
    background: rgba(0, 0, 0, 0.001);
    content   : "";
  }

  .ant-steps-item-content,
  &.ant-steps-small .ant-steps-item-content {
    width: 140px;
  }

  .ant-steps-item-process .ant-steps-item-icon,
  &.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    width      : 10px;
    height     : 10px;
    line-height: 10px;
  }

  .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
  &.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
    top: -1px;
  }
}

.ant-steps-vertical.ant-steps-dot {
  .ant-steps-item-icon {
    margin-top : 8px;
    margin-left: 0;
  }

  .ant-steps-item {
    >.ant-steps-item-container>.ant-steps-item-tail {
      top    : 2px;
      left   : -9px;
      margin : 0;
      padding: 22px 0 4px;
    }

    &:first-child .ant-steps-icon-dot {
      right: 0;
    }
  }

  .ant-steps-item-process .ant-steps-icon-dot {
    right: -2px;
  }
}

.ant-steps-navigation {
  padding-top: 12px;

  &.ant-steps-small .ant-steps-item-container {
    margin-right: -12px;
  }

  .ant-steps-item {
    overflow  : visible;
    text-align: center;
  }

  .ant-steps-item-container {
    display           : inline-block;
    height            : 100%;
    margin-right      : -16px;
    padding-bottom    : 12px;
    text-align        : right;
    -webkit-transition: opacity .3s;
    transition        : opacity .3s;

    .ant-steps-item-content {
      max-width: auto;
    }

    .ant-steps-item-title {
      max-width    : 100%;
      padding-right: 0;
      overflow     : hidden;
      white-space  : nowrap;
      text-overflow: ellipsis;

      &:after {
        display: none;
      }
    }
  }

  .ant-steps-item {
    &:not(.ant-steps-item-active) .ant-steps-item-container[role=button] {
      cursor: pointer;

      &:hover {
        opacity: .85;
      }
    }

    &:last-child {
      -webkit-box-flex: 1;
      -ms-flex        : 1;
      flex            : 1;

      &:after {
        display: none;
      }
    }

    &:after {
      position         : absolute;
      top              : 50%;
      right            : 100%;
      display          : inline-block;
      width            : 12px;
      height           : 12px;
      margin-top       : -14px;
      margin-right     : -2px;
      border           : 1px solid rgba(0, 0, 0, 0.25);
      border-top       : none;
      border-right     : none;
      -webkit-transform: rotate(45deg);
      -ms-transform    : rotate(45deg);
      transform        : rotate(45deg);
      content          : "";
    }

    &:before {
      position                          : absolute;
      bottom                            : 0;
      left                              : 50%;
      display                           : inline-block;
      width                             : 0;
      height                            : 3px;
      background-color                  : #1890ff;
      -webkit-transition                : width .3s, left .3s;
      transition                        : width .3s, left .3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function        : ease-out;
      content                           : "";
    }

    &.ant-steps-item-active:before {
      left : 0;
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .ant-steps-navigation>.ant-steps-item {
    margin-right: 0 !important;

    &:before {
      display: none;
    }

    &.ant-steps-item-active:before {
      top    : 0;
      right  : 0;
      left   : unset;
      display: block;
      width  : 3px;
      height : calc(100% - 24px);
    }

    &:after {
      position         : relative;
      top              : -2px;
      left             : 50%;
      display          : block;
      width            : 8px;
      height           : 8px;
      margin-bottom    : 8px;
      text-align       : center;
      -webkit-transform: rotate(135deg);
      -ms-transform    : rotate(135deg);
      transform        : rotate(135deg);
    }

    >.ant-steps-item-container>.ant-steps-item-tail {
      visibility: hidden;
    }
  }
}

.ant-steps-flex-not-supported {
  &.ant-steps-horizontal.ant-steps-label-horizontal {
    .ant-steps-item {
      margin-left : -16px;
      padding-left: 16px;
      background  : #fff;
    }

    &.ant-steps-small .ant-steps-item {
      margin-left : -12px;
      padding-left: 12px;
    }
  }

  &.ant-steps-dot {
    .ant-steps-item {
      &:last-child {
        overflow: hidden;

        .ant-steps-icon-dot:after {
          right: -200px;
          width: 200px;
        }
      }

      .ant-steps-icon-dot {

        &:after,
        &:before {
          position  : absolute;
          top       : 0;
          left      : -10px;
          width     : 10px;
          height    : 8px;
          background: #fff;
          content   : "";
        }

        &:after {
          right: -10px;
          left : auto;
        }
      }
    }

    .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
      background: #ccc;
    }
  }
}

.ant-switch {
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  display                      : inline-block;
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  min-width                    : 44px;
  height                       : 22px;
  line-height                  : 20px;
  vertical-align               : middle;
  background-color             : rgba(0, 0, 0, 0.25);
  border                       : 1px solid rgba(0, 0, 0, 0);
  border-radius                : 100px;
  cursor                       : pointer;
  -webkit-transition           : all .36s;
  transition                   : all .36s;
  -webkit-user-select          : none;
  -moz-user-select             : none;
  -ms-user-select              : none;
  user-select                  : none;
}

.ant-switch-inner {
  display     : block;
  margin-right: 6px;
  margin-left : 24px;
  color       : #fff;
  font-size   : 12px;
}

.ant-switch-loading-icon {
  position          : absolute;
  top               : 1px;
  left              : 1px;
  width             : 18px;
  height            : 18px;
  background-color  : #fff;
  border-radius     : 18px;
  cursor            : pointer;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition        : all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content           : " ";
}

.ant-switch {
  &:after {
    position          : absolute;
    top               : 1px;
    left              : 1px;
    width             : 18px;
    height            : 18px;
    background-color  : #fff;
    border-radius     : 18px;
    cursor            : pointer;
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition        : all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content           : " ";
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    box-shadow        : 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }

  &:not(.ant-switch-disabled):active {

    &:after,
    &:before {
      width: 24px;
    }
  }
}

.ant-switch-loading-icon {
  z-index   : 1;
  display   : none;
  font-size : 12px;
  background: rgba(0, 0, 0, 0);

  svg {
    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
    margin  : auto;
  }
}

.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color  : rgba(0, 0, 0, 0.65);
}

.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #1890ff;
}

.ant-switch:focus {
  outline           : 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);

  &:hover {
    -webkit-box-shadow: none;
    box-shadow        : none;
  }
}

.ant-switch-small {
  min-width  : 28px;
  height     : 16px;
  line-height: 14px;

  .ant-switch-inner {
    margin-right: 3px;
    margin-left : 18px;
    font-size   : 12px;
  }

  &:after {
    width : 12px;
    height: 12px;
  }

  &:active {

    &:after,
    &:before {
      width: 16px;
    }
  }

  .ant-switch-loading-icon {
    width : 12px;
    height: 12px;
  }

  &.ant-switch-checked {
    .ant-switch-inner {
      margin-right: 18px;
      margin-left : 3px;
    }

    .ant-switch-loading-icon {
      left       : 100%;
      margin-left: -13px;
    }
  }

  &.ant-switch-loading .ant-switch-loading-icon {
    font-weight      : 700;
    -webkit-transform: scale(0.66667);
    -ms-transform    : scale(0.66667);
    transform        : scale(0.66667);
  }
}

.ant-switch-checked {
  background-color: #1890ff;

  .ant-switch-inner {
    margin-right: 24px;
    margin-left : 6px;
  }

  &:after {
    left             : 100%;
    margin-left      : -1px;
    -webkit-transform: translateX(-100%);
    -ms-transform    : translateX(-100%);
    transform        : translateX(-100%);
  }

  .ant-switch-loading-icon {
    left       : 100%;
    margin-left: -19px;
  }
}

.ant-switch-disabled,
.ant-switch-loading {
  cursor : not-allowed;
  opacity: .4;
}

.ant-switch-disabled {

  *,
  &:after,
  &:before {
    cursor: not-allowed;
  }
}

.ant-switch-loading {

  *,
  &:after,
  &:before {
    cursor: not-allowed;
  }
}

@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform       : rotate(0deg) scale(0.66667);
    transform               : rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
    transform-origin        : 50% 50%;
  }

  to {
    -webkit-transform       : rotate(1turn) scale(0.66667);
    transform               : rotate(1turn) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
    transform-origin        : 50% 50%;
  }
}

@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform       : rotate(0deg) scale(0.66667);
    transform               : rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
    transform-origin        : 50% 50%;
  }

  to {
    -webkit-transform       : rotate(1turn) scale(0.66667);
    transform               : rotate(1turn) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
    transform-origin        : 50% 50%;
  }
}

.ant-table-wrapper {
  zoom: 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-table {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0 0 1em 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  clear                        : both;
  overflow-x                   : auto;
}

.ant-table-body {
  -webkit-transition: opacity .3s;
  transition        : opacity .3s;
  background-color  : #fff;
}

.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.ant-table table {
  width          : 100%;
  text-align     : right;
  border-radius  : 4px 4px 0 0;
  border-collapse: separate;
  border-spacing : 0;
}

.ant-table-layout-fixed table {
  table-layout: fixed;
}

.ant-table-thead>tr>th {
  color             : rgba(0, 0, 0, 0.85);
  font-weight       : 500;
  text-align        : right;
  background        : #fafafa;
  border-bottom     : 1px solid #e8e8e8;
  -webkit-transition: background .3s ease;
  transition        : background .3s ease;

  &[colspan]:not([colspan="1"]) {
    text-align: center;
  }

  .ant-table-filter-icon,
  .anticon-filter {
    position          : absolute;
    top               : 0;
    right             : 0;
    width             : 28px;
    height            : 100%;
    color             : #bfbfbf;
    font-size         : 12px;
    text-align        : center;
    cursor            : pointer;
    -webkit-transition: all .3s;
    transition        : all .3s;
  }

  .ant-table-filter-icon>svg,
  .anticon-filter>svg {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    margin-top : -5px;
    margin-left: -6px;
  }

  .ant-table-filter-selected.anticon-filter {
    color: #1890ff;
  }

  .ant-table-column-sorter {
    display       : table-cell;
    vertical-align: middle;

    .ant-table-column-sorter-inner {
      height            : 1em;
      margin-top        : .35em;
      margin-left       : .57142857em;
      color             : #bfbfbf;
      line-height       : 1em;
      text-align        : center;
      -webkit-transition: all .3s;
      transition        : all .3s;

      .ant-table-column-sorter-down,
      .ant-table-column-sorter-up {
        display           : inline-block;
        font-size         : 12px;
        font-size         : 11px\9;
        -webkit-transform : scale(0.91666667) rotate(0deg);
        -ms-transform     : scale(0.91666667) rotate(0deg);
        transform         : scale(0.91666667) rotate(0deg);
        display           : block;
        height            : 1em;
        line-height       : 1em;
        -webkit-transition: all .3s;
        transition        : all .3s;
      }
    }
  }
}

:root .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner {

  .ant-table-column-sorter-down,
  .ant-table-column-sorter-up {
    font-size: 12px;
  }
}

.ant-table-thead>tr {
  >th {
    .ant-table-column-sorter {
      .ant-table-column-sorter-inner {

        .ant-table-column-sorter-down.on,
        .ant-table-column-sorter-up.on {
          color: #1890ff;
        }
      }

      .ant-table-column-sorter-inner-full {
        margin-top: -.15em;

        .ant-table-column-sorter-down,
        .ant-table-column-sorter-up {
          height     : .5em;
          line-height: .5em;
        }

        .ant-table-column-sorter-down {
          margin-top: .125em;
        }
      }
    }

    &.ant-table-column-has-actions {
      position               : relative;
      background-clip        : padding-box;
      -webkit-background-clip: border-box;

      &.ant-table-column-has-filters {
        padding-right: 30px !important;

        .ant-table-filter-icon.ant-table-filter-open,
        .anticon-filter.ant-table-filter-open {
          color     : rgba(0, 0, 0, 0.45);
          background: #e5e5e5;
        }

        &:hover {

          .ant-table-filter-icon:hover,
          .anticon-filter:hover {
            color     : rgba(0, 0, 0, 0.45);
            background: #e5e5e5;
          }

          .ant-table-filter-icon:active,
          .anticon-filter:active {
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      &.ant-table-column-has-sorters {
        cursor: pointer;

        &:hover {
          background: #f2f2f2;

          .ant-table-filter-icon,
          .anticon-filter {
            background: #f2f2f2;
          }
        }

        &:active {

          .ant-table-column-sorter-down:not(.on),
          .ant-table-column-sorter-up:not(.on) {
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }

    .ant-table-header-column {
      display       : inline-block;
      max-width     : 100%;
      vertical-align: top;

      .ant-table-column-sorters {
        display: table;

        > {
          .ant-table-column-title {
            display       : table-cell;
            vertical-align: middle;
          }

          :not(.ant-table-column-sorter) {
            position: relative;
          }
        }

        &:before {
          position          : absolute;
          top               : 0;
          right             : 0;
          bottom            : 0;
          left              : 0;
          background        : rgba(0, 0, 0, 0);
          -webkit-transition: all .3s;
          transition        : all .3s;
          content           : "";
        }

        &:hover:before {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }

    &.ant-table-column-has-sorters {
      -webkit-user-select: none;
      -moz-user-select   : none;
      -ms-user-select    : none;
      user-select        : none;
    }
  }

  &:first-child>th {
    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  &:not(:last-child)>th[colspan] {
    border-bottom: 0;
  }
}

.ant-table-tbody>tr {
  >td {
    border-bottom     : 1px solid #e8e8e8;
    -webkit-transition: all .3s, border 0s;
    transition        : all .3s, border 0s;
  }

  -webkit-transition: all .3s,
  height 0s;
  transition: all .3s,
  height 0s;
}

.ant-table-thead>tr {
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-table-tbody>tr {

  &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: #e6f7ff;
  }
}

.ant-table-thead>tr {

  &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: #e6f7ff;
  }
}

.ant-table-tbody>tr {
  &.ant-table-row-selected>td.ant-table-column-sort {
    background: #fafafa;
  }

  &:hover.ant-table-row-selected>td {
    background: #fafafa;

    &.ant-table-column-sort {
      background: #fafafa;
    }
  }
}

.ant-table-thead>tr {
  &.ant-table-row-selected>td.ant-table-column-sort {
    background: #fafafa;
  }

  &:hover {
    &.ant-table-row-selected>td {
      background: #fafafa;

      &.ant-table-column-sort {
        background: #fafafa;
      }
    }

    background: none;
  }
}

.ant-table-footer {
  position     : relative;
  padding      : 16px;
  color        : rgba(0, 0, 0, 0.85);
  background   : #fafafa;
  border-top   : 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;

  &:before {
    position  : absolute;
    top       : -1px;
    left      : 0;
    width     : 100%;
    height    : 1px;
    background: #fafafa;
    content   : "";
  }
}

.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}

.ant-table-title {
  position     : relative;
  top          : 1px;
  padding      : 16px 0;
  border-radius: 4px 4px 0 0;
}

.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left : 16px;
  border       : 1px solid #e8e8e8;
}

.ant-table-title+.ant-table-content {
  position     : relative;
  border-radius: 4px 4px 0 0;
}

.ant-table-bordered .ant-table-title+.ant-table-content {
  border-radius: 0;

  .ant-table-thead>tr:first-child>th,
  table {
    border-radius: 0;
  }
}

.ant-table-without-column-header {

  .ant-table-title+.ant-table-content,
  table {
    border-radius: 0;
  }

  &.ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-top   : 1px solid #e8e8e8;
    border-radius: 4px;
  }
}

.ant-table-tbody>tr.ant-table-row-selected td {
  color     : inherit;
  background: #fafafa;
}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #f5f5f5;
}

.ant-table-tbody>tr>td {
  &.ant-table-column-sort {
    background: rgba(0, 0, 0, 0.01);
  }

  padding      : 16px;
  overflow-wrap: break-word;
}

.ant-table-thead>tr>th {
  padding      : 16px;
  overflow-wrap: break-word;
}

.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width     : 50px;
  min-width : 50px;
  text-align: center;
}

.ant-table-header {
  overflow  : hidden;
  background: #fafafa;

  table {
    border-radius: 4px 4px 0 0;
  }
}

.ant-table-loading {
  position: relative;

  .ant-table-body {
    background: #fff;
    opacity   : .5;
  }

  .ant-table-spin-holder {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    height     : 20px;
    margin-left: -30px;
    line-height: 20px;
  }

  .ant-table-with-pagination {
    margin-top: -20px;
  }

  .ant-table-without-pagination {
    margin-top: 10px;
  }
}

.ant-table-bordered {

  .ant-table-body>table,
  .ant-table-fixed-left table,
  .ant-table-fixed-right table,
  .ant-table-header>table {
    border       : 1px solid #e8e8e8;
    border-right : 0;
    border-bottom: 0;
  }

  &.ant-table-empty .ant-table-placeholder {
    border-right: 1px solid #e8e8e8;
    border-left : 1px solid #e8e8e8;
  }

  &.ant-table-fixed-header {
    .ant-table-header>table {
      border-bottom: 0;
    }

    .ant-table-body>table {
      border-top-left-radius : 0;
      border-top-right-radius: 0;
    }

    .ant-table-body-inner>table,
    .ant-table-header+.ant-table-body>table {
      border-top: 0;
    }
  }

  .ant-table-thead>tr:not(:last-child)>th {
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    border-right: 1px solid #e8e8e8;
  }
}

.ant-table-placeholder {
  position     : relative;
  z-index      : 1;
  margin-top   : -1px;
  padding      : 16px;
  color        : rgba(0, 0, 0, 0.25);
  font-size    : 14px;
  text-align   : center;
  background   : #fff;
  border-top   : 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.ant-table-pagination.ant-pagination {
  float : right;
  margin: 16px 0;
}

.ant-table-filter-dropdown {
  position          : relative;
  min-width         : 96px;
  margin-left       : -8px;
  background        : #fff;
  border-radius     : 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);

  .ant-dropdown-menu {
    border            : 0;
    border-radius     : 4px 4px 0 0;
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  .ant-dropdown-menu-without-submenu {
    max-height: 400px;
    overflow-x: hidden;
  }

  .ant-dropdown-menu-item>label+span {
    padding-right: 0;
  }

  .ant-dropdown-menu-sub {
    border-radius     : 4px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
    color      : #1890ff;
    font-weight: 700;
    text-shadow: 0 0 2px #bae7ff;
  }

  .ant-dropdown-menu-item {
    overflow: hidden;
  }

  >.ant-dropdown-menu> {

    .ant-dropdown-menu-item:last-child,
    .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
      border-radius: 0;
    }
  }
}

.ant-table-filter-dropdown-btns {
  padding   : 7px 8px;
  overflow  : hidden;
  border-top: 1px solid #e8e8e8;
}

.ant-table-filter-dropdown-link {
  color: #1890ff;

  &:hover {
    color: #40a9ff;
  }

  &:active {
    color: #096dd9;
  }

  &.confirm {
    float: left;
  }

  &.clear {
    float: right;
  }
}

.ant-table-selection {
  white-space: nowrap;
}

.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}

.ant-table-selection .anticon-down {
  color             : #bfbfbf;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-table-selection-menu {
  min-width         : 96px;
  margin-top        : 5px;
  margin-left       : -30px;
  background        : #fff;
  border-radius     : 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);

  .ant-action-down {
    color: #bfbfbf;
  }
}

.ant-table-selection-down {
  display    : inline-block;
  padding    : 0;
  line-height: 1;
  cursor     : pointer;

  &:hover .anticon-down {
    color: rgba(0, 0, 0, 0.6);
  }
}

.ant-table-row-expand-icon {
  color              : #1890ff;
  text-decoration    : none;
  cursor             : pointer;
  -webkit-transition : color .3s;
  transition         : color .3s;
  display            : inline-block;
  width              : 17px;
  height             : 17px;
  color              : inherit;
  line-height        : 13px;
  text-align         : center;
  background         : #fff;
  border             : 1px solid #e8e8e8;
  border-radius      : 2px;
  outline            : none;
  -webkit-transition : all .3s;
  transition         : all .3s;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;

  &:focus,
  &:hover {
    color: #40a9ff;
  }

  &:active {
    color       : #096dd9;
    border-color: currentColor;
  }

  &:focus,
  &:hover {
    border-color: currentColor;
  }
}

.ant-table-row-expanded:after {
  content: "-";
}

.ant-table-row-collapsed:after {
  content: "+";
}

.ant-table-row-spaced {
  visibility: hidden;

  &:after {
    content: ".";
  }
}

.ant-table-row-cell-ellipsis {
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;

  .ant-table-column-title {
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    display      : block;
  }
}

.ant-table-row-cell-break-word {
  word-wrap : break-word;
  word-break: break-word;
}

tr.ant-table-expanded-row {
  background: #fbfbfb;

  &:hover {
    background: #fbfbfb;
  }

  td>.ant-table-wrapper {
    margin: -16px -16px -17px;
  }
}

.ant-table .ant-table-row-indent+.ant-table-row-expand-icon {
  margin-right: 8px;
}

.ant-table-scroll {
  overflow  : auto;
  overflow-x: hidden;

  table {
    min-width: 100%;

    .ant-table-fixed-columns-in-body:not([colspan]) {
      color: rgba(0, 0, 0, 0);

      >* {
        visibility: hidden;
      }
    }
  }
}

.ant-table-body-inner {
  height: 100%;
}

.ant-table-fixed-header {
  >.ant-table-content>.ant-table-scroll>.ant-table-body {
    position  : relative;
    background: #fff;
  }

  .ant-table-body-inner {
    overflow: scroll;
  }

  .ant-table-scroll .ant-table-header {
    margin-bottom : -20px;
    padding-bottom: 20px;
    overflow      : scroll;
    opacity       : .9999;

    &::-webkit-scrollbar {
      border      : solid #e8e8e8;
      border-width: 0 0 1px;
    }
  }
}

.ant-table-hide-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  min-width      : unset;

  &::-webkit-scrollbar {
    min-width       : inherit;
    background-color: rgba(0, 0, 0, 0);
  }
}

.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  &::-webkit-scrollbar {
    border           : 1px solid #e8e8e8;
    border-left-width: 0;
  }

  &.ant-table-hide-scrollbar .ant-table-thead>tr:only-child>th:last-child {
    border-right-color: rgba(0, 0, 0, 0);
  }
}

.ant-table-fixed-left,
.ant-table-fixed-right {
  position          : absolute;
  top               : 0;
  z-index           : auto;
  overflow          : hidden;
  border-radius     : 0;
  -webkit-transition: -webkit-box-shadow .3s ease;
  transition        : -webkit-box-shadow .3s ease;
  transition        : box-shadow .3s ease;
  transition        : box-shadow .3s ease, -webkit-box-shadow .3s ease;
}

.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width     : auto;
  background: #fff;
}

.ant-table-fixed-header {

  .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
  .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
    border-radius: 0;
  }
}

.ant-table-fixed-left {
  left              : 0;
  -webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
  box-shadow        : 6px 0 6px -4px rgba(0, 0, 0, 0.15);

  .ant-table-header {
    overflow-y: hidden;
  }

  .ant-table-body-inner {
    margin-right : -20px;
    padding-right: 20px;
  }
}

.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}

.ant-table-fixed-left {
  border-radius: 4px 0 0 0;

  table {
    border-radius: 4px 0 0 0;
  }

  .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 0;
  }
}

.ant-table-fixed-right {
  right             : 0;
  -webkit-box-shadow: (-6px) 0 6px -4px rgba(0, 0, 0, 0.15);
  box-shadow        : (-6px) 0 6px -4px rgba(0, 0, 0, 0.15);
  border-radius     : 0 4px 0 0;

  table {
    border-radius: 0 4px 0 0;
  }

  .ant-table-expanded-row {
    color         : rgba(0, 0, 0, 0);
    pointer-events: none;
  }

  .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 0;
  }
}

.ant-table {

  &.ant-table-scroll-position-left .ant-table-fixed-left,
  &.ant-table-scroll-position-right .ant-table-fixed-right {
    -webkit-box-shadow: none;
    box-shadow        : none;
  }

  colgroup>col.ant-table-selection-col {
    width: 60px;
  }
}

.ant-table-thead>tr>th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}

.ant-table-tbody>tr>td.ant-table-selection-column,
.ant-table-thead>tr>th.ant-table-selection-column {
  text-align: center;
}

.ant-table-tbody>tr>td.ant-table-selection-column .ant-radio-wrapper,
.ant-table-thead>tr>th.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}

.ant-table-row[class*=ant-table-row-level-0] .ant-table-selection-column>span {
  display: inline-block;
}

.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper+span,
.ant-table-filter-dropdown .ant-checkbox-wrapper+span {
  padding-left: 8px;
}

@supports (-moz-appearance: meterbar) {
  .ant-table-thead>tr>th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}

.ant-table-middle {
  > {
    .ant-table-content> {
      .ant-table-body>table> {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th {
          padding: 12px 8px;
        }
      }

      .ant-table-fixed-left> {
        .ant-table-body-outer>.ant-table-body-inner>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }

        .ant-table-header>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }
      }

      .ant-table-fixed-right> {
        .ant-table-body-outer>.ant-table-body-inner>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }

        .ant-table-header>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }
      }

      .ant-table-footer {
        padding: 12px 8px;
      }

      .ant-table-header>table> {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th {
          padding: 12px 8px;
        }
      }

      .ant-table-scroll> {
        .ant-table-body>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }

        .ant-table-header>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 12px 8px;
          }
        }
      }
    }

    .ant-table-title {
      padding: 12px 8px;
    }
  }

  tr.ant-table-expanded-row td>.ant-table-wrapper {
    margin: -12px -8px -13px;
  }
}

.ant-table-small {
  border       : 1px solid #e8e8e8;
  border-radius: 4px;

  > {
    .ant-table-content>.ant-table-footer {
      padding: 8px;
    }

    .ant-table-title {
      padding      : 8px;
      top          : 0;
      border-bottom: 1px solid #e8e8e8;
    }

    .ant-table-content {
      > {
        .ant-table-footer {
          background-color: rgba(0, 0, 0, 0);
          border-top      : 1px solid #e8e8e8;

          &:before {
            background-color: rgba(0, 0, 0, 0);
          }
        }

        .ant-table-body {
          margin: 0 8px;

          >table {
            border: 0;
          }
        }

        .ant-table-fixed-left> {

          .ant-table-body-outer>.ant-table-body-inner>table,
          .ant-table-header>table {
            border: 0;
          }
        }

        .ant-table-fixed-right> {

          .ant-table-body-outer>.ant-table-body-inner>table,
          .ant-table-header>table {
            border: 0;
          }
        }

        .ant-table-header>table {
          border: 0;
        }

        .ant-table-scroll> {

          .ant-table-body>table,
          .ant-table-header>table {
            border: 0;
          }
        }

        .ant-table-body>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 8px;
          }
        }

        .ant-table-fixed-left> {
          .ant-table-body-outer>.ant-table-body-inner>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }

          .ant-table-header>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }
        }

        .ant-table-fixed-right> {
          .ant-table-body-outer>.ant-table-body-inner>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }

          .ant-table-header>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }
        }

        .ant-table-header>table> {

          .ant-table-tbody>tr>td,
          .ant-table-thead>tr>th {
            padding: 8px;
          }
        }

        .ant-table-scroll> {
          .ant-table-body>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }

          .ant-table-header>table> {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th {
              padding: 8px;
            }
          }
        }

        .ant-table-body>table>.ant-table-thead>tr>th {
          background-color: rgba(0, 0, 0, 0);
        }

        .ant-table-fixed-left> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
          .ant-table-header>table>.ant-table-thead>tr>th {
            background-color: rgba(0, 0, 0, 0);
          }
        }

        .ant-table-fixed-right> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th,
          .ant-table-header>table>.ant-table-thead>tr>th {
            background-color: rgba(0, 0, 0, 0);
          }
        }

        .ant-table-header>table>.ant-table-thead>tr>th {
          background-color: rgba(0, 0, 0, 0);
        }

        .ant-table-scroll> {

          .ant-table-body>table>.ant-table-thead>tr>th,
          .ant-table-header>table>.ant-table-thead>tr>th {
            background-color: rgba(0, 0, 0, 0);
          }
        }

        .ant-table-body>table>.ant-table-thead>tr {
          border-bottom: 1px solid #e8e8e8;
        }

        .ant-table-fixed-left> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr,
          .ant-table-header>table>.ant-table-thead>tr {
            border-bottom: 1px solid #e8e8e8;
          }
        }

        .ant-table-fixed-right> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr,
          .ant-table-header>table>.ant-table-thead>tr {
            border-bottom: 1px solid #e8e8e8;
          }
        }

        .ant-table-header>table>.ant-table-thead>tr {
          border-bottom: 1px solid #e8e8e8;
        }

        .ant-table-scroll> {

          .ant-table-body>table>.ant-table-thead>tr,
          .ant-table-header>table>.ant-table-thead>tr {
            border-bottom: 1px solid #e8e8e8;
          }
        }

        .ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort {
          background-color: rgba(0, 0, 0, 0.01);
        }

        .ant-table-fixed-left> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort,
          .ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort {
            background-color: rgba(0, 0, 0, 0.01);
          }
        }

        .ant-table-fixed-right> {

          .ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort,
          .ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort {
            background-color: rgba(0, 0, 0, 0.01);
          }
        }

        .ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort {
          background-color: rgba(0, 0, 0, 0.01);
        }

        .ant-table-scroll> {

          .ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort,
          .ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort {
            background-color: rgba(0, 0, 0, 0.01);
          }
        }

        .ant-table-fixed-left> {

          .ant-table-body-outer>.ant-table-body-inner>table,
          .ant-table-header>table {
            padding: 0;
          }
        }

        .ant-table-fixed-right> {

          .ant-table-body-outer>.ant-table-body-inner>table,
          .ant-table-header>table {
            padding: 0;
          }
        }

        .ant-table-scroll> {

          .ant-table-body>table,
          .ant-table-header>table {
            padding: 0;
          }
        }
      }

      .ant-table-header {
        background-color: rgba(0, 0, 0, 0);
        border-radius   : 4px 4px 0 0;
      }

      .ant-table-placeholder,
      .ant-table-row:last-child td {
        border-bottom: 0;
      }
    }
  }

  &.ant-table-bordered {
    border-right: 0;

    .ant-table-title {
      border       : 0;
      border-right : 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
    }

    .ant-table-content {
      border-right: 1px solid #e8e8e8;
    }

    .ant-table-footer {
      border    : 0;
      border-top: 1px solid #e8e8e8;

      &:before {
        display: none;
      }
    }

    .ant-table-placeholder {
      border-right : 0;
      border-bottom: 0;
      border-left  : 0;
    }

    .ant-table-tbody>tr>td:last-child,
    .ant-table-thead>tr>th.ant-table-row-cell-last {
      border-right: none;
    }

    .ant-table-fixed-left {

      .ant-table-tbody>tr>td:last-child,
      .ant-table-thead>tr>th:last-child {
        border-right: 1px solid #e8e8e8;
      }
    }

    .ant-table-fixed-right {
      border-right: 1px solid #e8e8e8;
      border-left : 1px solid #e8e8e8;
    }
  }

  tr.ant-table-expanded-row td>.ant-table-wrapper {
    margin: -8px -8px -9px;
  }

  &.ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
    border-radius: 0 0 4px 4px;
  }
}

.ant-tabs {
  &.ant-tabs-card {
    .ant-tabs-card-bar {
      .ant-tabs-nav-container {
        height: 40px;
      }

      .ant-tabs-ink-bar {
        visibility: hidden;
      }

      .ant-tabs-tab {
        height            : 40px;
        margin            : 0 2px 0 0;
        padding           : 0 16px;
        line-height       : 38px;
        background        : #fafafa;
        border            : 1px solid #e8e8e8;
        border-radius     : 4px 4px 0 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition        : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .ant-tabs-tab-active {
        height       : 40px;
        color        : #1890ff;
        background   : #fff;
        border-color : #e8e8e8;
        border-bottom: 1px solid #fff;

        &:before {
          border-top: 2px solid rgba(0, 0, 0, 0);
        }
      }

      .ant-tabs-tab-disabled {
        color: #1890ff;
        color: rgba(0, 0, 0, 0.25);
      }

      .ant-tabs-tab-inactive {
        padding: 0;
      }

      .ant-tabs-nav-wrap {
        margin-bottom: 0;
      }

      .ant-tabs-tab .ant-tabs-close-x {
        width             : 16px;
        height            : 16px;
        height            : 14px;
        margin-right      : -5px;
        margin-left       : 3px;
        overflow          : hidden;
        color             : rgba(0, 0, 0, 0.45);
        font-size         : 12px;
        vertical-align    : middle;
        -webkit-transition: all .3s;
        transition        : all .3s;

        &:hover {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .ant-tabs-card-content>.ant-tabs-tabpane {
      -webkit-transition: none !important;
      transition        : none !important;
    }
  }

  &.ant-tabs-editable-card .ant-tabs-card-content>.ant-tabs-tabpane {
    -webkit-transition: none !important;
    transition        : none !important;
  }

  &.ant-tabs-card .ant-tabs-card-content>.ant-tabs-tabpane-inactive,
  &.ant-tabs-editable-card .ant-tabs-card-content>.ant-tabs-tabpane-inactive {
    overflow: hidden;
  }

  &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
    opacity: 1;
  }
}

.ant-tabs-extra-content {
  line-height: 45px;

  .ant-tabs-new-tab {
    position          : relative;
    width             : 20px;
    height            : 20px;
    color             : rgba(0, 0, 0, 0.65);
    font-size         : 12px;
    line-height       : 20px;
    text-align        : center;
    border            : 1px solid #e8e8e8;
    border-radius     : 2px;
    cursor            : pointer;
    -webkit-transition: all .3s;
    transition        : all .3s;

    &:hover {
      color       : #1890ff;
      border-color: #1890ff;
    }

    svg {
      position: absolute;
      top     : 0;
      right   : 0;
      bottom  : 0;
      left    : 0;
      margin  : auto;
    }
  }
}

.ant-tabs {
  &.ant-tabs-large .ant-tabs-extra-content {
    line-height: 56px;
  }

  &.ant-tabs-small .ant-tabs-extra-content {
    line-height: 37px;
  }

  &.ant-tabs-card .ant-tabs-extra-content {
    line-height: 40px;
  }
}

.ant-tabs-vertical.ant-tabs-card {
  .ant-tabs-card-bar {

    &.ant-tabs-left-bar .ant-tabs-nav-container,
    &.ant-tabs-right-bar .ant-tabs-nav-container {
      height: 100%;
    }

    &.ant-tabs-left-bar .ant-tabs-tab,
    &.ant-tabs-right-bar .ant-tabs-tab {
      margin-bottom: 8px;
      border-bottom: 1px solid #e8e8e8;
    }

    &.ant-tabs-left-bar .ant-tabs-tab-active,
    &.ant-tabs-right-bar .ant-tabs-tab-active {
      padding-bottom: 4px;
    }

    &.ant-tabs-left-bar .ant-tabs-tab:last-child,
    &.ant-tabs-right-bar .ant-tabs-tab:last-child {
      margin-bottom: 8px;
    }

    &.ant-tabs-left-bar .ant-tabs-new-tab,
    &.ant-tabs-right-bar .ant-tabs-new-tab {
      width: 90%;
    }
  }

  &.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar {
    .ant-tabs-nav-wrap {
      margin-right: 0;
    }

    .ant-tabs-tab {
      margin-right : 1px;
      border-right : 0;
      border-radius: 4px 0 0 4px;
    }

    .ant-tabs-tab-active {
      margin-right : -1px;
      padding-right: 18px;
    }
  }

  &.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar {
    .ant-tabs-nav-wrap {
      margin-left: 0;
    }

    .ant-tabs-tab {
      margin-left  : 1px;
      border-left  : 0;
      border-radius: 0 4px 4px 0;
    }

    .ant-tabs-tab-active {
      margin-left : -1px;
      padding-left: 18px;
    }
  }
}

.ant-tabs {
  .ant-tabs-card-bar.ant-tabs-bottom-bar {
    .ant-tabs-tab {
      height       : auto;
      border-top   : 0;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0 0 4px 4px;
    }

    .ant-tabs-tab-active {
      padding-top   : 1px;
      padding-bottom: 0;
      color         : #1890ff;
    }
  }

  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  overflow                     : hidden;
  zoom                         : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-tabs-ink-bar {
  position                : absolute;
  bottom                  : 1px;
  right                   : 0; // rtl
  z-index                 : 1;
  -webkit-box-sizing      : border-box;
  box-sizing              : border-box;
  width                   : 0;
  height                  : 2px;
  background-color        : #1890ff;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin    : 0 0;
  transform-origin        : 0 0;
}

.ant-tabs-bar {
  margin            : 0 0 16px;
  border-bottom     : 1px solid #e8e8e8;
  outline           : none;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-nav-container {
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position          : relative;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  margin-bottom     : -1px;
  overflow          : hidden;
  font-size         : 14px;
  line-height       : 1.5;
  white-space       : nowrap;
  zoom              : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left : 32px;
}

.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top   : 16px;
  margin-bottom: 0;
  border-top   : 1px solid #e8e8e8;
  border-bottom: none;

  .ant-tabs-ink-bar {
    top   : 1px;
    bottom: auto;
  }

  .ant-tabs-nav-container {
    margin-top   : -1px;
    margin-bottom: 0;
  }
}

.ant-tabs-tab-next,
.ant-tabs-tab-prev {
  position           : absolute;
  z-index            : 2;
  width              : 0;
  height             : 100%;
  color              : rgba(0, 0, 0, 0.45);
  text-align         : center;
  background-color   : rgba(0, 0, 0, 0);
  border             : 0;
  cursor             : pointer;
  opacity            : 0;
  -webkit-transition : width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  pointer-events     : none;
}

.ant-tabs-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  width         : 32px;
  height        : 100%;
  opacity       : 1;
  pointer-events: auto;
}

.ant-tabs-tab-next:hover,
.ant-tabs-tab-prev:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-tabs-tab-next-icon,
.ant-tabs-tab-prev-icon {
  position         : absolute;
  top              : 50%;
  left             : 50%;
  font-weight      : 700;
  font-style       : normal;
  font-variant     : normal;
  line-height      : inherit;
  text-align       : center;
  text-transform   : none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform    : translate(-50%, -50%);
  transform        : translate(-50%, -50%);
}

.ant-tabs-tab-next-icon-target,
.ant-tabs-tab-prev-icon-target {
  display          : block;
  display          : inline-block;
  font-size        : 12px;
  font-size        : 10px\9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
  -ms-transform    : scale(0.83333333) rotate(0deg);
  transform        : scale(0.83333333) rotate(0deg);
}

:root {

  .ant-tabs-tab-next-icon-target,
  .ant-tabs-tab-prev-icon-target {
    font-size: 12px;
  }
}

.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
  color : rgba(0, 0, 0, 0.25);

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-tabs-tab-next {
  right: 2px;
}

.ant-tabs-tab-prev {
  left: 0;
}

:root .ant-tabs-tab-prev {
  -webkit-filter: none;
  filter        : none;
}

.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow     : hidden;
}

.ant-tabs-nav-scroll {
  overflow   : hidden;
  white-space: nowrap;
}

.ant-tabs-nav {
  position          : relative;
  display           : inline-block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  margin            : 0;
  padding-left      : 0;
  list-style        : none;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:after,
  &:before {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }

  .ant-tabs-tab {
    position          : relative;
    display           : inline-block;
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
    height            : 100%;
    margin            : 0 0px 0 0;
    padding           : 12px 16px;
    text-decoration   : none;
    cursor            : pointer;
    -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition        : color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:before {
      position          : absolute;
      top               : -1px;
      left              : 0;
      width             : 100%;
      border-top        : 2px solid rgba(0, 0, 0, 0);
      border-radius     : 4px 4px 0 0;
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
      pointer-events    : none;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #40a9ff;
    }

    &:active {
      color: #096dd9;
    }

    .anticon {
      margin-left: 8px;
    }
  }

  .ant-tabs-tab-active {
    color      : #1890ff;
    font-weight: 500;
  }

  .ant-tabs-tab-disabled {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;

    &:hover {
      color : rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }
}

.ant-tabs {
  .ant-tabs-large-bar {
    .ant-tabs-nav-container {
      font-size: 16px;
    }

    .ant-tabs-tab {
      padding: 16px;
    }
  }

  .ant-tabs-small-bar {
    .ant-tabs-nav-container {
      font-size: 14px;
    }

    .ant-tabs-tab {
      padding: 8px 16px;
    }
  }
}

.ant-tabs-content:before {
  display : block;
  overflow: hidden;
  content : "";
}

.ant-tabs {

  .ant-tabs-bottom-content,
  .ant-tabs-top-content {
    width: 100%;
  }

  .ant-tabs-bottom-content>.ant-tabs-tabpane,
  .ant-tabs-top-content>.ant-tabs-tabpane {
    -ms-flex-negative : 0;
    flex-shrink       : 0;
    width             : 100%;
    opacity           : 1;
    -webkit-transition: opacity .45s;
    transition        : opacity .45s;
  }

  .ant-tabs-bottom-content>.ant-tabs-tabpane-inactive,
  .ant-tabs-top-content>.ant-tabs-tabpane-inactive {
    height        : 0;
    padding       : 0 !important;
    overflow      : hidden;
    opacity       : 0;
    pointer-events: none;
  }

  .ant-tabs-bottom-content>.ant-tabs-tabpane-inactive input,
  .ant-tabs-top-content>.ant-tabs-tabpane-inactive input {
    visibility: hidden;
  }

  .ant-tabs-bottom-content.ant-tabs-content-animated,
  .ant-tabs-top-content.ant-tabs-content-animated {
    display              : -webkit-box;
    display              : -ms-flexbox;
    display              : flex;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;
    -webkit-transition   : margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition           : margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change          : margin-left;
  }

  .ant-tabs-left-bar,
  .ant-tabs-right-bar {
    height       : 100%;
    border-bottom: 0;
  }

  .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
  .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
    width : 100%;
    height: 32px;
  }

  .ant-tabs-left-bar .ant-tabs-tab,
  .ant-tabs-right-bar .ant-tabs-tab {
    display: block;
    float  : none;
    margin : 0 0 16px;
    padding: 8px 24px;
  }

  .ant-tabs-left-bar .ant-tabs-tab:last-child,
  .ant-tabs-right-bar .ant-tabs-tab:last-child {
    margin-bottom: 0;
  }

  .ant-tabs-left-bar .ant-tabs-extra-content,
  .ant-tabs-right-bar .ant-tabs-extra-content {
    text-align: center;
  }

  .ant-tabs-left-bar .ant-tabs-nav-scroll,
  .ant-tabs-right-bar .ant-tabs-nav-scroll {
    width: auto;
  }

  .ant-tabs-left-bar {

    .ant-tabs-nav-container,
    .ant-tabs-nav-wrap {
      height: 100%;
    }
  }

  .ant-tabs-right-bar {

    .ant-tabs-nav-container,
    .ant-tabs-nav-wrap {
      height: 100%;
    }
  }

  .ant-tabs-left-bar .ant-tabs-nav-container,
  .ant-tabs-right-bar .ant-tabs-nav-container {
    margin-bottom: 0;
  }

  .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
  .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
    padding: 32px 0;
  }

  .ant-tabs-left-bar .ant-tabs-nav-wrap,
  .ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }

  .ant-tabs-left-bar .ant-tabs-nav,
  .ant-tabs-right-bar .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-left-bar .ant-tabs-ink-bar,
  .ant-tabs-right-bar .ant-tabs-ink-bar {
    top   : 0;
    bottom: auto;
    left  : auto;
    width : 2px;
    height: 0;
  }

  .ant-tabs-left-bar .ant-tabs-tab-next,
  .ant-tabs-right-bar .ant-tabs-tab-next {
    right : 0;
    bottom: 0;
    width : 100%;
    height: 32px;
  }

  .ant-tabs-left-bar .ant-tabs-tab-prev,
  .ant-tabs-right-bar .ant-tabs-tab-prev {
    top   : 0;
    width : 100%;
    height: 32px;
  }

  .ant-tabs-left-content,
  .ant-tabs-right-content {
    width     : auto;
    margin-top: 0 !important;
    overflow  : hidden;
  }

  .ant-tabs-left-bar {
    float        : left;
    margin-right : -1px;
    margin-bottom: 0;
    border-right : 1px solid #e8e8e8;

    .ant-tabs-tab {
      text-align: right;
    }

    .ant-tabs-nav-container,
    .ant-tabs-nav-wrap {
      margin-right: -1px;
    }

    .ant-tabs-ink-bar {
      right: 1px;
    }
  }

  .ant-tabs-left-content {
    padding-left: 24px;
    border-left : 1px solid #e8e8e8;
  }

  .ant-tabs-right-bar {
    float        : right;
    margin-bottom: 0;
    margin-left  : -1px;
    border-left  : 1px solid #e8e8e8;

    .ant-tabs-nav-container,
    .ant-tabs-nav-wrap {
      margin-left: -1px;
    }

    .ant-tabs-ink-bar {
      left: 1px;
    }
  }

  .ant-tabs-right-content {
    padding-right: 24px;
    border-right : 1px solid #e8e8e8;
  }
}

.ant-tabs-bottom .ant-tabs-ink-bar-animated,
.ant-tabs-top .ant-tabs-ink-bar-animated {
  -webkit-transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  -webkit-transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition        : transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-content-animated,
.no-flex>.ant-tabs-content>.ant-tabs-content-animated {
  margin-left      : 0 !important;
  -webkit-transform: none !important;
  -ms-transform    : none !important;
  transform        : none !important;
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-tabpane-inactive,
.no-flex>.ant-tabs-content>.ant-tabs-tabpane-inactive {
  height        : 0;
  padding       : 0 !important;
  overflow      : hidden;
  opacity       : 0;
  pointer-events: none;
}

.ant-tabs-no-animation>.ant-tabs-content>.ant-tabs-tabpane-inactive input,
.no-flex>.ant-tabs-content>.ant-tabs-tabpane-inactive input {
  visibility: hidden;
}

.ant-tabs-left-content>.ant-tabs-content-animated,
.ant-tabs-right-content>.ant-tabs-content-animated {
  margin-left      : 0 !important;
  -webkit-transform: none !important;
  -ms-transform    : none !important;
  transform        : none !important;
}

.ant-tabs-left-content>.ant-tabs-tabpane-inactive,
.ant-tabs-right-content>.ant-tabs-tabpane-inactive {
  height        : 0;
  padding       : 0 !important;
  overflow      : hidden;
  opacity       : 0;
  pointer-events: none;
}

.ant-tabs-left-content>.ant-tabs-tabpane-inactive input,
.ant-tabs-right-content>.ant-tabs-tabpane-inactive input {
  visibility: hidden;
}

.ant-tag {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  height                       : auto;
  margin                       : 0 8px 0 0;
  padding                      : 0 7px;
  font-size                    : 12px;
  line-height                  : 20px;
  white-space                  : nowrap;
  background                   : #fafafa;
  border                       : 1px solid #d9d9d9;
  border-radius                : 4px;
  cursor                       : default;
  opacity                      : 1;
  -webkit-transition           : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition                   : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &:hover {
    opacity: .85;
  }

  color: rgba(0, 0, 0, 0.65);

  a {
    color: rgba(0, 0, 0, 0.65);

    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  >a:first-child:last-child {
    display: inline-block;
    margin : 0 -8px;
    padding: 0 8px;
  }

  .anticon-close {
    display           : inline-block;
    font-size         : 12px;
    font-size         : 10px\9;
    -webkit-transform : scale(0.83333333) rotate(0deg);
    -ms-transform     : scale(0.83333333) rotate(0deg);
    transform         : scale(0.83333333) rotate(0deg);
    margin-left       : 3px;
    color             : rgba(0, 0, 0, 0.45);
    font-weight       : 700;
    cursor            : pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition        : all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}

:root .ant-tag .anticon-close {
  font-size: 12px;
}

.ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tag-has-color {
  border-color: rgba(0, 0, 0, 0);
  color       : #fff;

  .anticon-close,
  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.ant-tag-checkable {
  background-color: rgba(0, 0, 0, 0);
  border-color    : rgba(0, 0, 0, 0);

  &:not(.ant-tag-checkable-checked):hover {
    color: #1890ff;
  }
}

.ant-tag-checkable-checked,
.ant-tag-checkable:active {
  color: #fff;
}

.ant-tag-checkable-checked {
  background-color: #1890ff;
}

.ant-tag-checkable:active {
  background-color: #096dd9;
}

.ant-tag-hidden {
  display: none;
}

.ant-tag-pink {
  color       : #eb2f96;
  background  : #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-pink-inverse {
  color       : #fff;
  background  : #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-magenta {
  color       : #eb2f96;
  background  : #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-magenta-inverse {
  color       : #fff;
  background  : #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-red {
  color       : #f5222d;
  background  : #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-red-inverse {
  color       : #fff;
  background  : #f5222d;
  border-color: #f5222d;
}

.ant-tag-volcano {
  color       : #fa541c;
  background  : #fff2e8;
  border-color: #ffbb96;
}

.ant-tag-volcano-inverse {
  color       : #fff;
  background  : #fa541c;
  border-color: #fa541c;
}

.ant-tag-orange {
  color       : #fa8c16;
  background  : #fff7e6;
  border-color: #ffd591;
}

.ant-tag-orange-inverse {
  color       : #fff;
  background  : #fa8c16;
  border-color: #fa8c16;
}

.ant-tag-yellow {
  color       : #fadb14;
  background  : #feffe6;
  border-color: #fffb8f;
}

.ant-tag-yellow-inverse {
  color       : #fff;
  background  : #fadb14;
  border-color: #fadb14;
}

.ant-tag-gold {
  color       : #faad14;
  background  : #fffbe6;
  border-color: #ffe58f;
}

.ant-tag-gold-inverse {
  color       : #fff;
  background  : #faad14;
  border-color: #faad14;
}

.ant-tag-cyan {
  color       : #13c2c2;
  background  : #e6fffb;
  border-color: #87e8de;
}

.ant-tag-cyan-inverse {
  color       : #fff;
  background  : #13c2c2;
  border-color: #13c2c2;
}

.ant-tag-lime {
  color       : #a0d911;
  background  : #fcffe6;
  border-color: #eaff8f;
}

.ant-tag-lime-inverse {
  color       : #fff;
  background  : #a0d911;
  border-color: #a0d911;
}

.ant-tag-green {
  color       : #52c41a;
  background  : #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-green-inverse {
  color       : #fff;
  background  : #52c41a;
  border-color: #52c41a;
}

.ant-tag-blue {
  color       : #1890ff;
  background  : #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-blue-inverse {
  color       : #fff;
  background  : #1890ff;
  border-color: #1890ff;
}

.ant-tag-geekblue {
  color       : #2f54eb;
  background  : #f0f5ff;
  border-color: #adc6ff;
}

.ant-tag-geekblue-inverse {
  color       : #fff;
  background  : #2f54eb;
  border-color: #2f54eb;
}

.ant-tag-purple {
  color       : #722ed1;
  background  : #f9f0ff;
  border-color: #d3adf7;
}

.ant-tag-purple-inverse {
  color       : #fff;
  background  : #722ed1;
  border-color: #722ed1;
}

.ant-time-picker-panel {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  z-index                      : 1050;
  font-family                  : -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.ant-time-picker-panel-inner {
  position          : relative;
  left              : -2px;
  font-size         : 14px;
  text-align        : right;
  list-style        : none;
  background-color  : #fff;
  background-clip   : padding-box;
  border-radius     : 4px;
  outline           : none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-time-picker-panel-input {
  width      : 100%;
  max-width  : 154px;
  margin     : 0;
  padding    : 0;
  line-height: normal;
  border     : 0;
  outline    : 0;
  cursor     : auto;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.ant-time-picker-panel-input-wrap {
  position     : relative;
  padding      : 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}

.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}

.ant-time-picker-panel-select {
  position   : relative;
  float      : left;
  width      : 56px;
  max-height : 192px;
  overflow   : hidden;
  font-size  : 14px;
  border-left: 1px solid #e8e8e8;

  &:hover {
    overflow-y: auto;
  }

  &:first-child {
    margin-left: 0;
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &:only-child {
    width: 100%;
  }

  ul {
    width     : 56px;
    margin    : 0;
    padding   : 0 0 160px;
    list-style: none;
  }

  li {
    width              : 100%;
    height             : 32px;
    margin             : 0;
    padding            : 0 0 0 12px;
    line-height        : 32px;
    text-align         : right;
    list-style         : none;
    cursor             : pointer;
    -webkit-transition : all .3s;
    transition         : all .3s;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;

    &:focus {
      color      : #1890ff;
      font-weight: 600;
      outline    : none;
    }

    &:hover {
      background: #e6f7ff;
    }
  }
}

li {
  &.ant-time-picker-panel-select-option-selected {
    font-weight: 600;
    background : #f5f5f5;

    &:hover {
      background: #f5f5f5;
    }
  }

  &.ant-time-picker-panel-select-option-disabled {
    color: rgba(0, 0, 0, 0.25);

    &:hover {
      background: rgba(0, 0, 0, 0);
      cursor    : not-allowed;
    }

    &:focus {
      color      : rgba(0, 0, 0, 0.25);
      font-weight: inherit;
    }
  }
}

.ant-time-picker-panel-combobox {
  zoom: 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-time-picker-panel-addon {
  padding   : 8px;
  border-top: 1px solid #e8e8e8;
}

.ant-time-picker-panel {
  &.slide-up-appear.slide-up-appear-active {

    &.ant-time-picker-panel-placement-topLeft,
    &.ant-time-picker-panel-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-up-enter.slide-up-enter-active {

    &.ant-time-picker-panel-placement-topLeft,
    &.ant-time-picker-panel-placement-topRight {
      -webkit-animation-name: antSlideDownIn;
      animation-name        : antSlideDownIn;
    }
  }

  &.slide-up-appear.slide-up-appear-active {

    &.ant-time-picker-panel-placement-bottomLeft,
    &.ant-time-picker-panel-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-up-enter.slide-up-enter-active {

    &.ant-time-picker-panel-placement-bottomLeft,
    &.ant-time-picker-panel-placement-bottomRight {
      -webkit-animation-name: antSlideUpIn;
      animation-name        : antSlideUpIn;
    }
  }

  &.slide-up-leave.slide-up-leave-active {

    &.ant-time-picker-panel-placement-topLeft,
    &.ant-time-picker-panel-placement-topRight {
      -webkit-animation-name: antSlideDownOut;
      animation-name        : antSlideDownOut;
    }

    &.ant-time-picker-panel-placement-bottomLeft,
    &.ant-time-picker-panel-placement-bottomRight {
      -webkit-animation-name: antSlideUpOut;
      animation-name        : antSlideUpOut;
    }
  }
}

.ant-time-picker {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  width                        : 128px;
  outline                      : none;
  cursor                       : text;
  -webkit-transition           : opacity .3s;
  transition                   : opacity .3s;
  color                        : rgba(0, 0, 0, 0.65);
  line-height                  : 1.5;
  position                     : relative;
  display                      : inline-block;
}

.ant-time-picker-input {
  color             : rgba(0, 0, 0, 0.65);
  line-height       : 1.5;
  position          : relative;
  display           : inline-block;
  width             : 100%;
  height            : 32px;
  padding           : 4px 11px;
  font-size         : 14px;
  background-color  : #fff;
  background-image  : none;
  border            : 1px solid #d9d9d9;
  border-radius     : 4px;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &::-moz-placeholder {
    color  : #bfbfbf;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &:focus,
  &:hover {
    border-color      : #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    outline           : 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.ant-time-picker-input-disabled {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

textarea.ant-time-picker-input {
  max-width         : 100%;
  height            : auto;
  min-height        : 32px;
  line-height       : 1.5;
  vertical-align    : bottom;
  -webkit-transition: all .3s, height 0s;
  transition        : all .3s, height 0s;
}

.ant-time-picker-input-lg {
  height   : 40px;
  padding  : 6px 11px;
  font-size: 16px;
}

.ant-time-picker-input-sm {
  height : 24px;
  padding: 1px 7px;
}

.ant-time-picker-input[disabled] {
  color           : rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor          : not-allowed;
  opacity         : 1;

  &:hover {
    border-color      : #d9d9d9;
    border-right-width: 1px !important;
  }
}

.ant-time-picker-open {
  opacity: 0;
}

.ant-time-picker-clear,
.ant-time-picker-icon {
  position           : absolute;
  top                : 50%;
  right              : 11px;
  z-index            : 1;
  width              : 14px;
  height             : 14px;
  margin-top         : -7px;
  color              : rgba(0, 0, 0, 0.25);
  line-height        : 14px;
  -webkit-transition : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.ant-time-picker-clear .ant-time-picker-clock-icon,
.ant-time-picker-icon .ant-time-picker-clock-icon {
  display    : block;
  color      : rgba(0, 0, 0, 0.25);
  line-height: 1;
}

.ant-time-picker-clear {
  z-index       : 2;
  background    : #fff;
  opacity       : 0;
  pointer-events: none;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.ant-time-picker:hover .ant-time-picker-clear {
  opacity       : 1;
  pointer-events: auto;
}

.ant-time-picker-large .ant-time-picker-input {
  height   : 40px;
  padding  : 6px 11px;
  font-size: 16px;
}

.ant-time-picker-small {
  .ant-time-picker-input {
    height : 24px;
    padding: 1px 7px;
  }

  .ant-time-picker-clear,
  .ant-time-picker-icon {
    right: 7px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.5;
    }
  }
}

.ant-timeline {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : 0;
  padding                      : 0;
  list-style                   : none;
}

.ant-timeline-item {
  position  : relative;
  margin    : 0;
  padding   : 0 0 20px;
  font-size : 14px;
  list-style: none;
}

.ant-timeline-item-tail {
  position   : absolute;
  top        : 10px;
  left       : 4px;
  height     : calc(100% - 10px);
  border-left: 2px solid #e8e8e8;
}

.ant-timeline-item-pending {
  .ant-timeline-item-head {
    font-size       : 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  .ant-timeline-item-tail {
    display: none;
  }
}

.ant-timeline-item-head {
  position        : absolute;
  width           : 10px;
  height          : 10px;
  background-color: #fff;
  border          : 2px solid rgba(0, 0, 0, 0);
  border-radius   : 100px;
}

.ant-timeline-item-head-blue {
  color       : #1890ff;
  border-color: #1890ff;
}

.ant-timeline-item-head-red {
  color       : #f5222d;
  border-color: #f5222d;
}

.ant-timeline-item-head-green {
  color       : #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-head-gray {
  color       : rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-timeline-item-head-custom {
  position         : absolute;
  top              : 5.5px;
  left             : 5px;
  width            : auto;
  height           : auto;
  margin-top       : 0;
  padding          : 3px 1px;
  line-height      : 1;
  text-align       : center;
  border           : 0;
  border-radius    : 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform    : translate(-50%, -50%);
  transform        : translate(-50%, -50%);
}

.ant-timeline-item-content {
  position  : relative;
  top       : -6px;
  margin    : 0 0 0 18px;
  word-break: break-word;
}

.ant-timeline-item-last> {
  .ant-timeline-item-tail {
    display: none;
  }

  .ant-timeline-item-content {
    min-height: 48px;
  }
}

.ant-timeline {
  &.ant-timeline-alternate {

    .ant-timeline-item-head,
    .ant-timeline-item-head-custom,
    .ant-timeline-item-tail {
      left: 50%;
    }
  }

  &.ant-timeline-right {

    .ant-timeline-item-head,
    .ant-timeline-item-head-custom,
    .ant-timeline-item-tail {
      left: 50%;
    }
  }

  &.ant-timeline-alternate .ant-timeline-item-head,
  &.ant-timeline-right .ant-timeline-item-head {
    margin-left: -4px;
  }

  &.ant-timeline-alternate .ant-timeline-item-head-custom,
  &.ant-timeline-right .ant-timeline-item-head-custom {
    margin-left: 1px;
  }

  &.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  &.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
    left      : calc(50% - 4px);
    width     : calc(50% - 14px);
    text-align: right;
  }

  &.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content {
    width     : calc(50% - 12px);
    margin    : 0;
    text-align: right;
  }

  &.ant-timeline-right .ant-timeline-item-right {
    .ant-timeline-item-content {
      width     : calc(50% - 12px);
      margin    : 0;
      text-align: right;
    }

    .ant-timeline-item-head,
    .ant-timeline-item-head-custom,
    .ant-timeline-item-tail {
      left: calc(100% - 6px);
    }

    .ant-timeline-item-content {
      width: calc(100% - 18px);
    }
  }

  &.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    display    : block;
    height     : calc(100% - 14px);
    border-left: 2px dotted #e8e8e8;
  }

  &.ant-timeline-reverse {
    .ant-timeline-item-last .ant-timeline-item-tail {
      display: none;
    }

    .ant-timeline-item-pending {
      .ant-timeline-item-tail {
        top        : 15px;
        display    : block;
        height     : calc(100% - 15px);
        border-left: 2px dotted #e8e8e8;
      }

      .ant-timeline-item-content {
        min-height: 48px;
      }
    }
  }
}

.ant-tooltip {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : absolute;
  z-index                      : 1060;
  display                      : block;
  max-width                    : 250px;
  visibility                   : visible;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightBottom,
.ant-tooltip-placement-rightTop {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftBottom,
.ant-tooltip-placement-leftTop {
  padding-right: 8px;
}

.ant-tooltip-inner {
  min-width         : 30px;
  min-height        : 32px;
  padding           : 6px 8px;
  color             : #fff;
  text-align        : right;
  text-decoration   : none;
  word-wrap         : break-word;
  background-color  : rgba(0, 0, 0, 0.75);
  border-radius     : 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow        : 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-tooltip-arrow {
  position      : absolute;
  display       : block;
  width         : 13.07106781px;
  height        : 13.07106781px;
  overflow      : hidden;
  background    : rgba(0, 0, 0, 0);
  pointer-events: none;

  &:before {
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    display         : block;
    width           : 5px;
    height          : 5px;
    margin          : auto;
    background-color: rgba(0, 0, 0, 0.75);
    content         : "";
    pointer-events  : auto;
  }
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow:before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow:before {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform : translateY(-6.53553391px) rotate(45deg);
  -ms-transform     : translateY(-6.53553391px) rotate(45deg);
  transform         : translateY(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left             : 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform    : translateX(-50%);
  transform        : translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  left: -5.07106781px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow:before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow:before {
  -webkit-box-shadow: (-3px) 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : (-3px) 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform : translateX(6.53553391px) rotate(45deg);
  -ms-transform     : translateX(6.53553391px) rotate(45deg);
  transform         : translateX(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top              : 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform    : translateY(-50%);
  transform        : translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  right: -5.07106781px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow:before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow:before {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform : translateX(-6.53553391px) rotate(45deg);
  -ms-transform     : translateX(-6.53553391px) rotate(45deg);
  transform         : translateX(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top              : 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform    : translateY(-50%);
  transform        : translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow:before {
  -webkit-box-shadow: (-3px) -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow        : (-3px) -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform : translateY(6.53553391px) rotate(45deg);
  -ms-transform     : translateY(6.53553391px) rotate(45deg);
  transform         : translateY(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left             : 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform    : translateX(-50%);
  transform        : translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-transfer-customize-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .ant-transfer-operation {
    -webkit-box-flex   : 0;
    -ms-flex           : none;
    flex               : none;
    -ms-flex-item-align: center;
    align-self         : center;
  }

  .ant-transfer-list {
    -webkit-box-flex: 1;
    -ms-flex        : auto;
    flex            : auto;
    width           : auto;
    height          : auto;
    min-height      : 200px;
  }

  .ant-transfer-list-body-with-search {
    padding-top: 0;
  }

  .ant-transfer-list-body-search-wrapper {
    position      : relative;
    padding-bottom: 0;
  }

  .ant-transfer-list-body-customize-wrapper {
    padding: 12px;
  }

  .ant-table-wrapper {
    .ant-table-small {
      border       : 0;
      border-radius: 0;

      >.ant-table-content {
        >.ant-table-body>table>.ant-table-thead>tr>th {
          background: #fafafa;
        }

        .ant-table-row:last-child td {
          border-bottom: 1px solid #e8e8e8;
        }
      }

      .ant-table-body {
        margin: 0;
      }
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 0 4px;
    }
  }
}

.ant-transfer {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
}

.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}

.ant-transfer-list {
  position      : relative;
  display       : inline-block;
  width         : 180px;
  height        : 200px;
  padding-top   : 40px;
  vertical-align: middle;
  border        : 1px solid #d9d9d9;
  border-radius : 4px;
}

.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}

.ant-transfer-list-search {
  padding: 0 24px 0 8px;
}

.ant-transfer-list-search-action {
  position   : absolute;
  top        : 12px;
  right      : 12px;
  bottom     : 12px;
  width      : 28px;
  color      : rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align : center;

  .anticon {
    color             : rgba(0, 0, 0, 0.25);
    -webkit-transition: all .3s;
    transition        : all .3s;

    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

span.ant-transfer-list-search-action {
  pointer-events: none;
}

.ant-transfer-list-header {
  position     : absolute;
  top          : 0;
  left         : 0;
  width        : 100%;
  padding      : 8px 12px 9px;
  overflow     : hidden;
  color        : rgba(0, 0, 0, 0.65);
  background   : #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.ant-transfer-list-header-title {
  position: absolute;
  right   : 12px;
}

.ant-transfer-list-header .ant-checkbox-wrapper+span {
  padding-left: 8px;
}

.ant-transfer-list-body {
  position : relative;
  height   : 100%;
  font-size: 14px;
}

.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top     : 0;
  left    : 0;
  width   : 100%;
  padding : 12px;
}

.ant-transfer-list-body-with-search {
  padding-top: 56px;
}

.ant-transfer-list-content {
  height    : 100%;
  margin    : 0;
  padding   : 0;
  overflow  : auto;
  list-style: none;

  >.LazyLoad {
    -webkit-animation: transferHighlightIn 1s;
    animation        : transferHighlightIn 1s;
  }
}

.ant-transfer-list-content-item {
  min-height        : 32px;
  padding           : 6px 12px;
  overflow          : hidden;
  white-space       : nowrap;
  text-overflow     : ellipsis;
  -webkit-transition: all .3s;
  transition        : all .3s;

  >span {
    padding-right: 0;
  }
}

.ant-transfer-list-content-item-text {
  padding-left: 8px;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #e6f7ff;
  cursor          : pointer;
}

.ant-transfer-list-content-item-disabled {
  color : rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-transfer-list-body-not-found {
  position         : absolute;
  top              : 50%;
  width            : 100%;
  padding-top      : 0;
  color            : rgba(0, 0, 0, 0.25);
  text-align       : center;
  -webkit-transform: translateY(-50%);
  -ms-transform    : translateY(-50%);
  transform        : translateY(-50%);
}

.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 16px;
}

.ant-transfer-list-footer {
  position     : absolute;
  bottom       : 0;
  left         : 0;
  width        : 100%;
  border-top   : 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.ant-transfer-operation {
  display       : inline-block;
  margin        : 0 8px;
  overflow      : hidden;
  vertical-align: middle;

  .ant-btn {
    display: block;

    &:first-child {
      margin-bottom: 4px;
    }

    .anticon {
      font-size: 12px;
    }
  }
}

@-webkit-keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }

  to {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }

  to {
    background: rgba(0, 0, 0, 0);
  }
}

.ant-select-tree-checkbox {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  top                          : -.09em;
  display                      : inline-block;
  line-height                  : 1;
  white-space                  : nowrap;
  vertical-align               : middle;
  outline                      : none;
  cursor                       : pointer;
}

.ant-select-tree-checkbox-input:focus+.ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-select-tree-checkbox-checked:after {
  position                   : absolute;
  top                        : 0;
  left                       : 0;
  width                      : 100%;
  height                     : 100%;
  border                     : 1px solid #1890ff;
  border-radius              : 2px;
  visibility                 : hidden;
  -webkit-animation          : antCheckboxEffect .36s ease-in-out;
  animation                  : antCheckboxEffect .36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode        : backwards;
  content                    : "";
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after,
.ant-select-tree-checkbox:hover:after {
  visibility: visible;
}

.ant-select-tree-checkbox-inner {
  position          : relative;
  top               : 0;
  left              : 0;
  display           : block;
  width             : 16px;
  height            : 16px;
  background-color  : #fff;
  border            : 1px solid #d9d9d9;
  border-radius     : 2px;
  border-collapse   : separate;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:after {
    position          : absolute;
    top               : 50%;
    left              : 22%;
    display           : table;
    width             : 5.71428571px;
    height            : 9.14285714px;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(0) translate(-50%, -50%);
    transform         : rotate(45deg) scale(0) translate(-50%, -50%);
    opacity           : 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition        : all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content           : " ";
  }
}

.ant-select-tree-checkbox-input {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  left    : 0;
  z-index : 1;
  width   : 100%;
  height  : 100%;
  cursor  : pointer;
  opacity : 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  &:after {
    position          : absolute;
    display           : table;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(1) translate(-50%, -50%);
    transform         : rotate(45deg) scale(1) translate(-50%, -50%);
    opacity           : 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition        : all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content           : " ";
  }

  background-color: #1890ff;
  border-color    : #1890ff;
}

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;

  &.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    border-color          : rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name        : none;
  }

  .ant-select-tree-checkbox-input {
    cursor: not-allowed;
  }

  .ant-select-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color    : #d9d9d9 !important;

    &:after {
      border-color          : #f5f5f5;
      border-collapse       : separate;
      -webkit-animation-name: none;
      animation-name        : none;
    }
  }

  +span {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover:after {
    visibility: hidden;
  }
}

.ant-select-tree-checkbox-wrapper {
  &:hover .ant-select-tree-checkbox-disabled:after {
    visibility: hidden;
  }

  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  line-height                  : unset;
  cursor                       : pointer;

  &.ant-select-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  +.ant-select-tree-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-select-tree-checkbox+span {
  padding-right: 8px;
  padding-left : 8px;
}

.ant-select-tree-checkbox-group {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
}

.ant-select-tree-checkbox-group-item {
  display     : inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  +.ant-select-tree-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-select-tree-checkbox-indeterminate {
  .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border-color    : #d9d9d9;

    &:after {
      top              : 50%;
      left             : 50%;
      width            : 8px;
      height           : 8px;
      background-color : #1890ff;
      border           : 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform    : translate(-50%, -50%) scale(1);
      transform        : translate(-50%, -50%) scale(1);
      opacity          : 1;
      content          : " ";
    }
  }

  &.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color    : rgba(0, 0, 0, 0.25);
  }
}

.ant-select-tree {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : -4px 0 0;
  padding                      : 0 4px;

  li {
    margin     : 8px 0;
    padding    : 0;
    white-space: nowrap;
    list-style : none;
    outline    : 0;

    &.filter-node>span {
      font-weight: 500;
    }

    ul {
      margin : 0;
      padding: 0 0 0 18px;
    }

    .ant-select-tree-node-content-wrapper {
      display           : inline-block;
      width             : calc(100% - 24px);
      margin            : 0;
      padding           : 3px 5px;
      color             : rgba(0, 0, 0, 0.65);
      text-decoration   : none;
      border-radius     : 2px;
      cursor            : pointer;
      -webkit-transition: all .3s;
      transition        : all .3s;

      &:hover {
        background-color: #e6f7ff;
      }

      &.ant-select-tree-node-selected {
        background-color: #bae7ff;
      }
    }

    span {
      &.ant-select-tree-checkbox {
        margin: 0 4px 0 0;

        +.ant-select-tree-node-content-wrapper {
          width: calc(100% - 46px);
        }
      }

      &.ant-select-tree-iconEle,
      &.ant-select-tree-switcher {
        display       : inline-block;
        width         : 24px;
        height        : 24px;
        margin        : 0;
        line-height   : 22px;
        text-align    : center;
        vertical-align: middle;
        border        : 0;
        outline       : none;
        cursor        : pointer;
      }

      &.ant-select-icon_loading .ant-select-switcher-loading-icon {
        position         : absolute;
        left             : 0;
        display          : inline-block;
        color            : #1890ff;
        font-size        : 14px;
        -webkit-transform: none;
        -ms-transform    : none;
        transform        : none;

        svg {
          position: absolute;
          top     : 0;
          right   : 0;
          bottom  : 0;
          left    : 0;
          margin  : auto;
        }
      }

      &.ant-select-tree-switcher {
        position: relative;

        &.ant-select-tree-switcher-noop {
          cursor: auto;
        }

        &.ant-select-tree-switcher_open {

          .ant-select-switcher-icon,
          .ant-tree-switcher-icon {
            font-size        : 12px;
            font-size        : 10px\9;
            -webkit-transform: scale(0.83333333) rotate(0deg);
            -ms-transform    : scale(0.83333333) rotate(0deg);
            transform        : scale(0.83333333) rotate(0deg);
            display          : inline-block;
            font-weight      : 700;
          }
        }
      }
    }
  }
}

:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open {

  .ant-select-switcher-icon,
  .ant-tree-switcher-icon {
    font-size: 12px;
  }
}

.ant-select-tree li span.ant-select-tree-switcher {
  &.ant-select-tree-switcher_open {

    .ant-select-switcher-icon svg,
    .ant-tree-switcher-icon svg {
      -webkit-transition: -webkit-transform .3s;
      transition        : -webkit-transform .3s;
      transition        : transform .3s;
      transition        : transform .3s, -webkit-transform .3s;
    }
  }

  &.ant-select-tree-switcher_close {

    .ant-select-switcher-icon,
    .ant-tree-switcher-icon {
      font-size        : 12px;
      font-size        : 10px\9;
      -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform    : scale(0.83333333) rotate(0deg);
      transform        : scale(0.83333333) rotate(0deg);
      display          : inline-block;
      font-weight      : 700;
    }
  }
}

:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close {

  .ant-select-switcher-icon,
  .ant-tree-switcher-icon {
    font-size: 12px;
  }
}

.ant-select-tree li span.ant-select-tree-switcher {
  &.ant-select-tree-switcher_close {

    .ant-select-switcher-icon svg,
    .ant-tree-switcher-icon svg {
      -webkit-transition: -webkit-transform .3s;
      transition        : -webkit-transform .3s;
      transition        : transform .3s;
      transition        : transform .3s, -webkit-transform .3s;
    }

    .ant-select-switcher-icon svg {
      -webkit-transform: rotate(-90deg);
      -ms-transform    : rotate(-90deg);
      transform        : rotate(-90deg);
    }

    .ant-select-switcher-loading-icon {
      position         : absolute;
      left             : 0;
      display          : inline-block;
      width            : 24px;
      height           : 24px;
      color            : #1890ff;
      font-size        : 14px;
      -webkit-transform: none;
      -ms-transform    : none;
      transform        : none;
    }
  }

  &.ant-select-tree-switcher_open .ant-select-switcher-loading-icon {
    position         : absolute;
    left             : 0;
    display          : inline-block;
    width            : 24px;
    height           : 24px;
    color            : #1890ff;
    font-size        : 14px;
    -webkit-transform: none;
    -ms-transform    : none;
    transform        : none;
  }

  &.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg,
  &.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg {
    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
    margin  : auto;
  }
}

.ant-select-tree-child-tree,
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}

.ant-select-tree-child-tree-open {
  display: block;
}

li.ant-select-tree-treenode-disabled> {
  .ant-select-tree-node-content-wrapper {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;

    span {
      color : rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }

  span:not(.ant-select-tree-switcher) {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .ant-select-tree-node-content-wrapper:hover {
    background: rgba(0, 0, 0, 0);
  }
}

.ant-select-tree-icon__close,
.ant-select-tree-icon__open {
  margin-right  : 2px;
  vertical-align: top;
}

.ant-select-tree-dropdown {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";

  .ant-select-dropdown-search {
    position  : -webkit-sticky;
    position  : sticky;
    top       : 0;
    z-index   : 1;
    display   : block;
    padding   : 4px;
    background: #fff;

    .ant-select-search__field__wrap {
      width: 100%;
    }

    .ant-select-search__field {
      -webkit-box-sizing: border-box;
      box-sizing        : border-box;
      width             : 100%;
      padding           : 4px 7px;
      border            : 1px solid #d9d9d9;
      border-radius     : 4px;
      outline           : none;
    }

    &.ant-select-search--hide {
      display: none;
    }
  }

  .ant-select-not-found {
    display: block;
    padding: 7px 16px;
    color  : rgba(0, 0, 0, 0.25);
    cursor : not-allowed;
  }
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(1.6);
    transform        : scale(1.6);
    opacity          : 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform        : scale(1);
    opacity          : .5;
  }

  to {
    -webkit-transform: scale(1.6);
    transform        : scale(1.6);
    opacity          : 0;
  }
}

.ant-tree.ant-tree-directory {
  position: relative;

  .ant-tree-child-tree {
    >li span.ant-tree-switcher {
      position: relative;
      z-index : 1;
    }

    >li span.ant-tree-switcher.ant-tree-switcher-noop {
      pointer-events: none;
    }

    >li span.ant-tree-checkbox {
      position: relative;
      z-index : 1;
    }

    >li span.ant-tree-node-content-wrapper {
      border-radius      : 0;
      -webkit-user-select: none;
      -moz-user-select   : none;
      -ms-user-select    : none;
      user-select        : none;
    }

    >li span.ant-tree-node-content-wrapper:hover {
      background: rgba(0, 0, 0, 0);
    }

    >li span.ant-tree-node-content-wrapper:hover:before {
      background: #e6f7ff;
    }

    >li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
      color     : #fff;
      background: rgba(0, 0, 0, 0);
    }

    >li span.ant-tree-node-content-wrapper:before {
      position          : absolute;
      right             : 0;
      left              : 0;
      height            : 24px;
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : "";
    }

    >li span.ant-tree-node-content-wrapper>span {
      position: relative;
      z-index : 1;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-switcher {
      color: #fff;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-checkbox .ant-tree-checkbox-inner {
      border-color: #1890ff;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked:after {
      border-color: #fff;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background: #fff;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
      border-color: #1890ff;
    }

    >li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper:before {
      background: #1890ff;
    }
  }

  >li span.ant-tree-switcher {
    position: relative;
    z-index : 1;
  }

  >li span.ant-tree-switcher.ant-tree-switcher-noop {
    pointer-events: none;
  }

  >li span.ant-tree-checkbox {
    position: relative;
    z-index : 1;
  }

  >li span.ant-tree-node-content-wrapper {
    border-radius      : 0;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
  }

  >li span.ant-tree-node-content-wrapper:hover {
    background: rgba(0, 0, 0, 0);
  }

  >li span.ant-tree-node-content-wrapper:hover:before {
    background: #e6f7ff;
  }

  >li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    color     : #fff;
    background: rgba(0, 0, 0, 0);
  }

  >li span.ant-tree-node-content-wrapper:before {
    position          : absolute;
    right             : 0;
    left              : 0;
    height            : 24px;
    -webkit-transition: all .3s;
    transition        : all .3s;
    content           : "";
  }

  >li span.ant-tree-node-content-wrapper>span {
    position: relative;
    z-index : 1;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-switcher {
    color: #fff;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-checkbox .ant-tree-checkbox-inner {
    border-color: #1890ff;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked:after {
    border-color: #fff;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background: #fff;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    border-color: #1890ff;
  }

  >li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper:before {
    background: #1890ff;
  }
}

.ant-tree-checkbox {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  position                     : relative;
  top                          : -.09em;
  display                      : inline-block;
  line-height                  : 1;
  white-space                  : nowrap;
  vertical-align               : middle;
  outline                      : none;
  cursor                       : pointer;
}

.ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-tree-checkbox-checked:after {
  top                        : 0;
  height                     : 100%;
  border                     : 1px solid #1890ff;
  border-radius              : 2px;
  visibility                 : hidden;
  -webkit-animation          : antCheckboxEffect .36s ease-in-out;
  animation                  : antCheckboxEffect .36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode        : backwards;
  content                    : "";
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after,
.ant-tree-checkbox:hover:after {
  visibility: visible;
}

.ant-tree-checkbox-inner {
  position          : relative;
  top               : 0;
  left              : 0;
  display           : block;
  width             : 16px;
  height            : 16px;
  background-color  : #fff;
  border            : 1px solid #d9d9d9;
  border-radius     : 2px;
  border-collapse   : separate;
  -webkit-transition: all .3s;
  transition        : all .3s;

  &:after {
    position          : absolute;
    top               : 50%;
    left              : 22%;
    display           : table;
    width             : 5.71428571px;
    height            : 9.14285714px;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(0) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(0) translate(-50%, -50%);
    transform         : rotate(45deg) scale(0) translate(-50%, -50%);
    opacity           : 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition        : all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content           : " ";
  }
}

.ant-tree-checkbox-input {
  position: absolute;
  top     : 0;
  right   : 0;
  bottom  : 0;
  left    : 0;
  z-index : 1;
  width   : 100%;
  height  : 100%;
  cursor  : pointer;
  opacity : 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  &:after {
    position          : absolute;
    display           : table;
    border            : 2px solid #fff;
    border-top        : 0;
    border-left       : 0;
    -webkit-transform : rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform     : rotate(45deg) scale(1) translate(-50%, -50%);
    transform         : rotate(45deg) scale(1) translate(-50%, -50%);
    opacity           : 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition        : all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content           : " ";
  }

  background-color: #1890ff;
  border-color    : #1890ff;
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;

  &.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    border-color          : rgba(0, 0, 0, 0.25);
    -webkit-animation-name: none;
    animation-name        : none;
  }

  .ant-tree-checkbox-input {
    cursor: not-allowed;
  }

  .ant-tree-checkbox-inner {
    background-color: #f5f5f5;
    border-color    : #d9d9d9 !important;

    &:after {
      border-color          : #f5f5f5;
      border-collapse       : separate;
      -webkit-animation-name: none;
      animation-name        : none;
    }
  }

  +span {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &:hover:after {
    visibility: hidden;
  }
}

.ant-tree-checkbox-wrapper {
  &:hover .ant-tree-checkbox-disabled:after {
    visibility: hidden;
  }

  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
  line-height                  : unset;
  cursor                       : pointer;

  &.ant-tree-checkbox-wrapper-disabled {
    cursor: not-allowed;
  }

  +.ant-tree-checkbox-wrapper {
    margin-left: 8px;
  }
}

.ant-tree-checkbox+span {
  padding-right: 8px;
  padding-left : 8px;
}

.ant-tree-checkbox-group {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  display                      : inline-block;
}

.ant-tree-checkbox-group-item {
  display     : inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  +.ant-tree-checkbox-group-item {
    margin-left: 0;
  }
}

.ant-tree-checkbox-indeterminate {
  .ant-tree-checkbox-inner {
    background-color: #fff;
    border-color    : #d9d9d9;

    &:after {
      top              : 50%;
      left             : 50%;
      width            : 8px;
      height           : 8px;
      background-color : #1890ff;
      border           : 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform    : translate(-50%, -50%) scale(1);
      transform        : translate(-50%, -50%) scale(1);
      opacity          : 1;
      content          : " ";
    }
  }

  &.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-color    : rgba(0, 0, 0, 0.25);
  }
}

.ant-tree {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  margin                       : 0;
  padding                      : 0;
}

.ant-tree-checkbox-checked:after {
  position: absolute;
  top     : 16.67%;
  left    : 0;
  width   : 100%;
  height  : 66.67%;
}

.ant-tree {

  ol,
  ul {
    margin    : 0;
    padding   : 0;
    list-style: none;
  }

  li {
    margin     : 0;
    padding    : 4px 0;
    white-space: nowrap;
    list-style : none;
    outline    : 0;

    span {

      &[draggable=true],
      &[draggable] {
        line-height        : 20px;
        border-top         : 2px solid rgba(0, 0, 0, 0);
        border-bottom      : 2px solid rgba(0, 0, 0, 0);
        -webkit-user-select: none;
        -moz-user-select   : none;
        -ms-user-select    : none;
        user-select        : none;
        -khtml-user-drag   : element;
        -webkit-user-drag  : element;
      }
    }

    &.drag-over>span[draggable] {
      color           : #fff;
      background-color: #1890ff;
      opacity         : .8;
    }

    &.drag-over-gap-top>span[draggable] {
      border-top-color: #1890ff;
    }

    &.drag-over-gap-bottom>span[draggable] {
      border-bottom-color: #1890ff;
    }

    &.filter-node>span {
      color      : #f5222d !important;
      font-weight: 500 !important;
    }

    &.ant-tree-treenode-loading span.ant-tree-switcher {

      &.ant-tree-switcher_close .ant-tree-switcher-loading-icon,
      &.ant-tree-switcher_open .ant-tree-switcher-loading-icon {
        position         : absolute;
        left             : 0;
        display          : inline-block;
        width            : 24px;
        height           : 24px;
        color            : #1890ff;
        font-size        : 14px;
        -webkit-transform: none;
        -ms-transform    : none;
        transform        : none;
      }

      &.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg,
      &.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg {
        position: absolute;
        top     : 0;
        right   : 0;
        bottom  : 0;
        left    : 0;
        margin  : auto;
      }
    }
  }
}

:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher {

  &.ant-tree-switcher_close:after,
  &.ant-tree-switcher_open:after {
    opacity: 0;
  }
}

.ant-tree li {
  ul {
    margin : 0;
    padding: 0 0 0 18px;
  }

  .ant-tree-node-content-wrapper {
    display           : inline-block;
    height            : 24px;
    margin            : 0;
    padding           : 0 5px;
    color             : rgba(0, 0, 0, 0.65);
    line-height       : 24px;
    text-decoration   : none;
    vertical-align    : top;
    border-radius     : 2px;
    cursor            : pointer;
    -webkit-transition: all .3s;
    transition        : all .3s;

    &:hover {
      background-color: #e6f7ff;
    }

    &.ant-tree-node-selected {
      background-color: #bae7ff;
    }
  }

  span {
    &.ant-tree-checkbox {
      top    : auto;
      height : 24px;
      margin : 0 4px 0 2px;
      padding: 4px 0;
    }

    &.ant-tree-iconEle,
    &.ant-tree-switcher {
      display       : inline-block;
      width         : 24px;
      height        : 24px;
      margin        : 0;
      line-height   : 24px;
      text-align    : center;
      vertical-align: top;
      border        : 0;
      outline       : none;
      cursor        : pointer;
    }

    &.ant-tree-iconEle:empty {
      display: none;
    }

    &.ant-tree-switcher {
      position: relative;

      &.ant-tree-switcher-noop {
        cursor: default;
      }

      &.ant-tree-switcher_open {

        .ant-select-switcher-icon,
        .ant-tree-switcher-icon {
          font-size        : 12px;
          font-size        : 10px\9;
          -webkit-transform: scale(0.83333333) rotate(0deg);
          -ms-transform    : scale(0.83333333) rotate(0deg);
          transform        : scale(0.83333333) rotate(0deg);
          display          : inline-block;
          font-weight      : 700;
        }
      }
    }
  }
}

:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open {

  .ant-select-switcher-icon,
  .ant-tree-switcher-icon {
    font-size: 12px;
  }
}

.ant-tree li span.ant-tree-switcher {
  &.ant-tree-switcher_open {

    .ant-select-switcher-icon svg,
    .ant-tree-switcher-icon svg {
      -webkit-transition: -webkit-transform .3s;
      transition        : -webkit-transform .3s;
      transition        : transform .3s;
      transition        : transform .3s, -webkit-transform .3s;
    }
  }

  &.ant-tree-switcher_close {

    .ant-select-switcher-icon,
    .ant-tree-switcher-icon {
      font-size        : 12px;
      font-size        : 10px\9;
      -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform    : scale(0.83333333) rotate(0deg);
      transform        : scale(0.83333333) rotate(0deg);
      display          : inline-block;
      font-weight      : 700;
    }
  }
}

:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close {

  .ant-select-switcher-icon,
  .ant-tree-switcher-icon {
    font-size: 12px;
  }
}

.ant-tree {
  li {
    span.ant-tree-switcher.ant-tree-switcher_close {
      .ant-select-switcher-icon svg {
        -webkit-transition: -webkit-transform .3s;
        transition        : -webkit-transform .3s;
        transition        : transform .3s;
        transition        : transform .3s, -webkit-transform .3s;
      }

      .ant-tree-switcher-icon svg {
        -webkit-transition: -webkit-transform .3s;
        transition        : -webkit-transform .3s;
        transition        : transform .3s;
        transition        : transform .3s, -webkit-transform .3s;
        -webkit-transform : rotate(-90deg);
        -ms-transform     : rotate(-90deg);
        transform         : rotate(-90deg);
      }
    }

    &:last-child>span {

      &.ant-tree-iconEle:before,
      &.ant-tree-switcher:before {
        display: none;
      }
    }
  }

  >li {
    &:first-child {
      padding-top: 7px;
    }

    &:last-child {
      padding-bottom: 7px;
    }
  }
}

.ant-tree-child-tree>li {
  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

li.ant-tree-treenode-disabled> {
  .ant-tree-node-content-wrapper {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;

    span {
      color : rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }

  span:not(.ant-tree-switcher) {
    color : rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .ant-tree-node-content-wrapper:hover {
    background: rgba(0, 0, 0, 0);
  }
}

.ant-tree-icon__close,
.ant-tree-icon__open {
  margin-right  : 2px;
  vertical-align: top;
}

.ant-tree {
  &.ant-tree-show-line li {
    position: relative;

    span.ant-tree-switcher {
      color     : rgba(0, 0, 0, 0.45);
      background: #fff;

      &.ant-tree-switcher-noop {

        .ant-select-switcher-icon,
        .ant-tree-switcher-icon {
          display    : inline-block;
          font-weight: 400;
          font-size  : 12px;
        }

        .ant-select-switcher-icon svg,
        .ant-tree-switcher-icon svg {
          -webkit-transition: -webkit-transform .3s;
          transition        : -webkit-transform .3s;
          transition        : transform .3s;
          transition        : transform .3s, -webkit-transform .3s;
        }
      }

      &.ant-tree-switcher_open {

        .ant-select-switcher-icon,
        .ant-tree-switcher-icon {
          display    : inline-block;
          font-weight: 400;
          font-size  : 12px;
        }

        .ant-select-switcher-icon svg,
        .ant-tree-switcher-icon svg {
          -webkit-transition: -webkit-transform .3s;
          transition        : -webkit-transform .3s;
          transition        : transform .3s;
          transition        : transform .3s, -webkit-transform .3s;
        }
      }

      &.ant-tree-switcher_close {

        .ant-select-switcher-icon,
        .ant-tree-switcher-icon {
          display    : inline-block;
          font-weight: 400;
          font-size  : 12px;
        }

        .ant-select-switcher-icon svg,
        .ant-tree-switcher-icon svg {
          -webkit-transition: -webkit-transform .3s;
          transition        : -webkit-transform .3s;
          transition        : transform .3s;
          transition        : transform .3s, -webkit-transform .3s;
        }
      }
    }

    &:not(:last-child):before {
      position   : absolute;
      left       : 12px;
      width      : 1px;
      height     : 100%;
      height     : calc(100% - 22px);
      margin     : 22px 0 0;
      border-left: 1px solid #d9d9d9;
      content    : " ";
    }
  }

  &.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
    display: none;
  }

  &.ant-tree-block-node li {
    .ant-tree-node-content-wrapper {
      width: calc(100% - 24px);
    }

    span.ant-tree-checkbox+.ant-tree-node-content-wrapper {
      width: calc(100% - 46px);
    }
  }
}

.ant-typography {
  color: rgba(0, 0, 0, 0.65);

  &.ant-typography-secondary {
    color: rgba(0, 0, 0, 0.45);
  }

  &.ant-typography-warning {
    color: #faad14;
  }

  &.ant-typography-danger {
    color: #f5222d;
  }

  &.ant-typography-disabled {
    color              : rgba(0, 0, 0, 0.25);
    cursor             : not-allowed;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
  }

  p {
    margin-bottom: 1em;
  }
}

div.ant-typography {
  margin-bottom: 1em;
}

.ant-typography h1,
h1.ant-typography {
  margin-bottom: .5em;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 600;
  font-size    : 38px;
  line-height  : 1.23;
}

.ant-typography h2,
h2.ant-typography {
  margin-bottom: .5em;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 600;
  font-size    : 30px;
  line-height  : 1.35;
}

.ant-typography h3,
h3.ant-typography {
  margin-bottom: .5em;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 600;
  font-size    : 24px;
  line-height  : 1.35;
}

.ant-typography h4,
h4.ant-typography {
  margin-bottom: .5em;
  color        : rgba(0, 0, 0, 0.85);
  font-weight  : 600;
  font-size    : 20px;
  line-height  : 1.4;
}

.ant-typography {
  + {

    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography {
      margin-top: 1.2em;
    }
  }

  div+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  h1+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  h2+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  h3+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  h4+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  li+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  p+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }

  ul+ {

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1.2em;
    }
  }
}

span.ant-typography-ellipsis {
  display: inline-block;
}

.ant-typography {
  a {
    color             : #1890ff;
    text-decoration   : none;
    outline           : none;
    cursor            : pointer;
    -webkit-transition: color .3s;
    transition        : color .3s;

    &:focus,
    &:hover {
      color: #40a9ff;
    }

    &:active {
      color          : #096dd9;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }

    &[disabled] {
      color         : rgba(0, 0, 0, 0.25);
      cursor        : not-allowed;
      pointer-events: none;
    }
  }

  code {
    margin       : 0 .2em;
    padding      : .2em .4em .1em;
    font-size    : 85%;
    background   : rgba(0, 0, 0, 0.06);
    border       : 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }

  mark {
    padding         : 0;
    background-color: #ffe58f;
  }

  ins,
  u {
    text-decoration             : underline;
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink    : auto;
  }

  del,
  s {
    text-decoration: line-through;
  }

  strong {
    font-weight: 600;
  }
}

.ant-typography-copy,
.ant-typography-edit,
.ant-typography-expand {
  color             : #1890ff;
  text-decoration   : none;
  outline           : none;
  cursor            : pointer;
  -webkit-transition: color .3s;
  transition        : color .3s;
  margin-left       : 8px;
}

.ant-typography-copy {

  &:focus,
  &:hover {
    color: #40a9ff;
  }
}

.ant-typography-edit {

  &:focus,
  &:hover {
    color: #40a9ff;
  }
}

.ant-typography-expand {

  &:focus,
  &:hover {
    color: #40a9ff;
  }
}

.ant-typography-copy:active,
.ant-typography-edit:active,
.ant-typography-expand:active {
  color: #096dd9;
}

.ant-typography-copy-success {
  color: #52c41a;

  &:focus,
  &:hover {
    color: #52c41a;
  }
}

.ant-typography-edit-content {
  position: relative;
}

div.ant-typography-edit-content {
  left         : -12px;
  margin-top   : -5px;
  margin-bottom: calc(1em - 6px);
}

.ant-typography-edit-content-confirm {
  position      : absolute;
  right         : 10px;
  bottom        : 8px;
  color         : rgba(0, 0, 0, 0.45);
  pointer-events: none;
}

.ant-typography-edit-content textarea {
  -webkit-transition: none;
  -moz-transition   : none;
  transition        : none;
}

.ant-typography {

  ol,
  ul {
    margin : 0 0 1em;
    padding: 0;
  }

  ol li {
    margin : 0 0 0 20px;
    padding: 0 0 0 4px;
  }

  ul li {
    margin         : 0 0 0 20px;
    padding        : 0 0 0 4px;
    list-style-type: circle;

    li {
      list-style-type: disc;
    }
  }

  ol li {
    list-style-type: decimal;
  }
}

.ant-typography-ellipsis-single-line {
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-typography-ellipsis-multiple-line {
  display           : -webkit-box;
  -webkit-line-clamp: 3;

  /*! autoprefixer: ignore next */

  -webkit-box-orient: vertical;
  overflow          : hidden;
}

.ant-upload {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  outline                      : 0;

  p {
    margin: 0;
  }
}

.ant-upload-btn {
  display: block;
  width  : 100%;
  outline: none;
}

.ant-upload {
  input[type=file] {
    cursor: pointer;
  }

  &.ant-upload-select {
    display: inline-block;
  }

  &.ant-upload-disabled {
    cursor: not-allowed;
  }

  &.ant-upload-select-picture-card {
    display           : table;
    float             : left;
    width             : 104px;
    height            : 104px;
    margin-right      : 8px;
    margin-bottom     : 8px;
    text-align        : center;
    vertical-align    : top;
    background-color  : #fafafa;
    border            : 1px dashed #d9d9d9;
    border-radius     : 4px;
    cursor            : pointer;
    -webkit-transition: border-color .3s ease;
    transition        : border-color .3s ease;

    >.ant-upload {
      display       : table-cell;
      width         : 100%;
      height        : 100%;
      padding       : 8px;
      text-align    : center;
      vertical-align: middle;
    }

    &:hover {
      border-color: #1890ff;
    }
  }

  &.ant-upload-drag {
    position          : relative;
    width             : 100%;
    height            : 100%;
    text-align        : center;
    background        : #fafafa;
    border            : 1px dashed #d9d9d9;
    border-radius     : 4px;
    cursor            : pointer;
    -webkit-transition: border-color .3s;
    transition        : border-color .3s;

    .ant-upload {
      padding: 16px 0;
    }

    &.ant-upload-drag-hover:not(.ant-upload-disabled) {
      border-color: #096dd9;
    }

    &.ant-upload-disabled {
      cursor: not-allowed;
    }

    .ant-upload-btn {
      display: table;
      height : 100%;
    }

    .ant-upload-drag-container {
      display       : table-cell;
      vertical-align: middle;
    }

    &:not(.ant-upload-disabled):hover {
      border-color: #40a9ff;
    }

    p {
      &.ant-upload-drag-icon {
        margin-bottom: 20px;

        .anticon {
          color    : #40a9ff;
          font-size: 48px;
        }
      }

      &.ant-upload-text {
        margin   : 0 0 4px;
        color    : rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }

      &.ant-upload-hint {
        color    : rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }

    .anticon-plus {
      color             : rgba(0, 0, 0, 0.25);
      font-size         : 30px;
      -webkit-transition: all .3s;
      transition        : all .3s;

      &:hover {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    &:hover .anticon-plus {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.ant-upload-picture-card-wrapper {
  zoom   : 1;
  display: inline-block;
  width  : 100%;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-upload-list {
  -webkit-box-sizing           : border-box;
  box-sizing                   : border-box;
  margin                       : 0;
  padding                      : 0;
  color                        : rgba(0, 0, 0, 0.65);
  font-size                    : 14px;
  font-variant                 : tabular-nums;
  line-height                  : 1.5;
  list-style                   : none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings        : "tnum";
  zoom                         : 1;

  &:after,
  &:before {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.ant-upload-list-item-list-type-text:hover {
  .ant-upload-list-item-name-icon-count-1 {
    padding-right: 14px;
  }

  .ant-upload-list-item-name-icon-count-2 {
    padding-right: 28px;
  }
}

.ant-upload-list-item {
  position  : relative;
  height    : 22px;
  margin-top: 8px;
  font-size : 14px;
}

.ant-upload-list-item-name {
  display      : inline-block;
  width        : 100%;
  padding-left : 22px;
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}

.ant-upload-list-item-card-actions {
  position: absolute;
  right   : 0;
  opacity : 0;

  &.picture {
    top        : 25px;
    line-height: 1;
    opacity    : 1;
  }

  .anticon {
    padding-right: 5px;
    color        : rgba(0, 0, 0, 0.45);
  }
}

.ant-upload-list-item-info {
  height            : 100%;
  padding           : 0 12px 0 4px;
  -webkit-transition: background-color .3s;
  transition        : background-color .3s;

  >span {
    display: block;
    width  : 100%;
    height : 100%;
  }

  .anticon-loading,
  .anticon-paper-clip {
    position : absolute;
    top      : 5px;
    color    : rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}

.ant-upload-list-item .anticon-close {
  display           : inline-block;
  font-size         : 12px;
  font-size         : 10px\9;
  -webkit-transform : scale(0.83333333) rotate(0deg);
  -ms-transform     : scale(0.83333333) rotate(0deg);
  transform         : scale(0.83333333) rotate(0deg);
  position          : absolute;
  top               : 6px;
  right             : 4px;
  color             : rgba(0, 0, 0, 0.45);
  line-height       : 0;
  cursor            : pointer;
  opacity           : 0;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}

.ant-upload-list-item {
  .anticon-close:hover {
    color: rgba(0, 0, 0, 0.65);
  }

  &:hover {
    .ant-upload-list-item-info {
      background-color: #e6f7ff;
    }

    .ant-upload-list-item-card-actions,
    .anticon-close {
      opacity: 1;
    }
  }
}

.ant-upload-list-item-error {
  color: #f5222d;

  .ant-upload-list-item-name,
  .anticon-paper-clip {
    color: #f5222d;
  }

  .ant-upload-list-item-card-actions {
    opacity: 1;

    .anticon {
      padding-right: 5px;
      color        : #f5222d;
    }
  }
}

.ant-upload-list-item-progress {
  position    : absolute;
  bottom      : -12px;
  width       : 100%;
  padding-left: 26px;
  font-size   : 14px;
  line-height : 0;
}

.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-list-picture .ant-upload-list-item {
  position     : relative;
  height       : 66px;
  padding      : 8px;
  border       : 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover,
.ant-upload-list-picture .ant-upload-list-item:hover {
  background: rgba(0, 0, 0, 0);
}

.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-list-picture .ant-upload-list-item-error {
  border-color: #f5222d;
}

.ant-upload-list-picture-card .ant-upload-list-item-info,
.ant-upload-list-picture .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: rgba(0, 0, 0, 0);
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading,
.ant-upload-list-picture .ant-upload-list-item-uploading {
  border-style: dashed;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  position   : absolute;
  top        : 8px;
  left       : 8px;
  width      : 48px;
  height     : 48px;
  font-size  : 26px;
  line-height: 54px;
  text-align : center;
  opacity    : .8;
}

.ant-upload-list-picture-card .ant-upload-list-item-icon,
.ant-upload-list-picture .ant-upload-list-item-icon {
  position         : absolute;
  top              : 50%;
  left             : 50%;
  font-size        : 26px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform    : translate(-50%, -50%);
  transform        : translate(-50%, -50%);
}

.ant-upload-list-picture-card .ant-upload-list-item-image,
.ant-upload-list-picture .ant-upload-list-item-image {
  max-width: 100%;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  display : block;
  width   : 48px;
  height  : 48px;
  overflow: hidden;
}

.ant-upload-list-picture-card .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  display           : inline-block;
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  max-width         : 100%;
  margin            : 0 0 0 8px;
  padding-right     : 8px;
  padding-left      : 48px;
  overflow          : hidden;
  line-height       : 44px;
  white-space       : nowrap;
  text-overflow     : ellipsis;
  -webkit-transition: all .3s;
  transition        : all .3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}

.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress,
.ant-upload-list-picture .ant-upload-list-item-progress {
  bottom      : 14px;
  width       : calc(100% - 24px);
  margin-top  : 0;
  padding-left: 56px;
}

.ant-upload-list-picture-card .anticon-close,
.ant-upload-list-picture .anticon-close {
  position   : absolute;
  top        : 8px;
  right      : 8px;
  line-height: 1;
  opacity    : 1;
}

.ant-upload-list-picture-card.ant-upload-list:after {
  display: none;
}

.ant-upload-list-picture-card-container {
  float : left;
  width : 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}

.ant-upload-list-picture-card {
  .ant-upload-list-item {
    float : left;
    width : 104px;
    height: 104px;
    margin: 0 8px 8px 0;
  }

  .ant-upload-list-item-info {
    position: relative;
    height  : 100%;
    overflow: hidden;

    &:before {
      position          : absolute;
      z-index           : 1;
      width             : 100%;
      height            : 100%;
      background-color  : rgba(0, 0, 0, 0.5);
      opacity           : 0;
      -webkit-transition: all .3s;
      transition        : all .3s;
      content           : " ";
    }
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info:before {
    opacity: 1;
  }

  .ant-upload-list-item-actions {
    position          : absolute;
    top               : 50%;
    left              : 50%;
    z-index           : 10;
    white-space       : nowrap;
    -webkit-transform : translate(-50%, -50%);
    -ms-transform     : translate(-50%, -50%);
    transform         : translate(-50%, -50%);
    opacity           : 0;
    -webkit-transition: all .3s;
    transition        : all .3s;

    .anticon-delete,
    .anticon-download,
    .anticon-eye-o {
      z-index           : 10;
      width             : 16px;
      margin            : 0 4px;
      color             : hsla(0, 0%, 100%, 0.85);
      font-size         : 16px;
      cursor            : pointer;
      -webkit-transition: all .3s;
      transition        : all .3s;
    }

    .anticon-delete:hover,
    .anticon-download:hover,
    .anticon-eye-o:hover {
      color: #fff;
    }

    &:hover {
      opacity: 1;
    }
  }

  .ant-upload-list-item-info:hover+.ant-upload-list-item-actions {
    opacity: 1;
  }

  .ant-upload-list-item-thumbnail {
    position     : static;
    display      : block;
    width        : 100%;
    height       : 100%;
    -o-object-fit: cover;
    object-fit   : cover;

    img {
      position     : static;
      display      : block;
      width        : 100%;
      height       : 100%;
      -o-object-fit: cover;
      object-fit   : cover;
    }
  }

  .ant-upload-list-item-name {
    display    : none;
    margin     : 8px 0 0;
    padding    : 0;
    line-height: 1.5;
    text-align : center;
  }

  .anticon-picture+.ant-upload-list-item-name {
    position: absolute;
    bottom  : 10px;
    display : block;
  }

  .ant-upload-list-item-uploading {
    &.ant-upload-list-item {
      background-color: #fafafa;
    }

    .ant-upload-list-item-info {
      height: auto;

      .anticon-delete,
      .anticon-eye-o,
      &:before {
        display: none;
      }
    }
  }

  .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color     : rgba(0, 0, 0, 0.45);
  }

  .ant-upload-list-item-progress {
    bottom      : 32px;
    padding-left: 0;
  }
}

.ant-upload-list {
  .ant-upload-success-icon {
    color      : #52c41a;
    font-weight: 700;
  }

  .ant-upload-animate-enter,
  .ant-upload-animate-inline-enter,
  .ant-upload-animate-inline-leave,
  .ant-upload-animate-leave {
    -webkit-animation-duration : .3s;
    animation-duration         : .3s;
    -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode        : cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  .ant-upload-animate-enter {
    -webkit-animation-name: uploadAnimateIn;
    animation-name        : uploadAnimateIn;
  }

  .ant-upload-animate-leave {
    -webkit-animation-name: uploadAnimateOut;
    animation-name        : uploadAnimateOut;
  }

  .ant-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
    animation-name        : uploadAnimateInlineIn;
  }

  .ant-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
    animation-name        : uploadAnimateInlineOut;
  }
}

@-webkit-keyframes uploadAnimateIn {
  0% {
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateIn {
  0% {
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@-webkit-keyframes uploadAnimateOut {
  to {
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateOut {
  to {
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@-webkit-keyframes uploadAnimateInlineIn {
  0% {
    width  : 0;
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineIn {
  0% {
    width  : 0;
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width  : 0;
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    width  : 0;
    height : 0;
    margin : 0;
    padding: 0;
    opacity: 0;
  }
}